import React from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import Product from "../components/Product";
import TravelShopList from "../components/TravelShopList";
import TravelItem from "../components/TravelItem";

export default function ProductView() {
  let { id } = useParams();
  const [searchOpen, setSearchOpen] = useState(false);

  return (
    <>
      <div className="sticky top-0 z-50">
        <BasicNavbar setSearchOpen={setSearchOpen} />
      </div>

      <TravelItem id={id} />
      <TravelShopList id={id} />

      <Footer />
    </>
  );
}
