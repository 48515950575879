import { useState, useEffect } from "react";
import api from "../services/api";
import {
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
} from "@nextui-org/react";
import ImageGallery from "react-image-gallery";
import Translate from "./TranslationAPI";
import "../utils/quill.css";

const MoreProductInfo = ({ productId, t }) => {
  const [product, setProduct] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure(); // To control modal state

  // Fetch product details
  const fetchProduct = async () => {
    try {
      const response = await api.get(`product-description/`, {
        params: {
          product_id: productId,
        },
      });
      setProduct(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  const images = [
    ...(product?.product_image_url
      ? [
          {
            original: product.product_image_url,
            thumbnail: product.product_image_url,
          },
        ]
      : []),
    ...(Array.isArray(product?.additional_photos)
      ? product.additional_photos.map((photo) => ({
          original: photo,
          thumbnail: photo,
        }))
      : []),
  ];

  return (
    <div className="flex lg:flex-row flex-col justify-between gap-4 px-4 xl:px-0">
      <div className="flex flex-col gap-4 bg-white border-1 shadow-lg rounded-lg p-8 w-full">
        <div className="text-3xl font-bold">
          <Translate text={product?.product_name} />
        </div>
        <div className="py-8">
          {/* Clickable image to open modal */}
          <img
            src={product?.product_image_url}
            className="w-full max-h-[500px] object-contain cursor-pointer"
            onClick={onOpen} // Open modal on click
          />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: product?.product_description }}
          className="quill-content "
        >
          {/* <Translate text={product?.product_description} /> */}
        </div>
      </div>

      {/* Fullscreen Image Modal */}
      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {() => (
            <ModalBody>
              {/* Use ImageGallery for fullscreen view */}
              <ImageGallery items={images} />
            </ModalBody>
          )}
        </ModalContent>
      </Modal>

      <div className="lg:min-w-[500px] bg-white border-1 shadow-lg rounded-lg p-8">
        <div className="text-2xl font-bold mb-4">{t("Parametre")}</div>
        <hr />
        <table className="">
          {product?.parameters.length === 0 && (
            <div className="text-lightgray py-4">
              {t("Neboli nájdené žiadne parametre produktu")}
            </div>
          )}
          {product?.parameters.map((param) => (
            <tr className="hover:bg-slate-50" key={param.name}>
              <td className="font-semibold">
                <Translate text={param.name} />:
              </td>
              <td>
                <Translate text={param.value} />
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export default MoreProductInfo;
