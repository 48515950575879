import React, { useState, useEffect } from "react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import ShopPartnerRegister from "../components/ShopPartnerRegister";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Chip,
} from "@nextui-org/react";
import { motion } from "framer-motion";
import api from "../services/api";

const fadeInVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const PartnerZone = () => {
  const { t } = useTranslation();
  const [shops, setShops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedShop, setSelectedShop] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchShops();
  }, []);

  const fetchShops = async () => {
    try {
      const response = await api.get("/get-partner-shops");
      setShops(response.data);
    } catch (error) {
      console.error("Failed to fetch shops:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusChip = (status) => {
    const statusProps = {
      pending: {
        color: "warning",
        variant: "flat",
        text: t("Čaká na kontrolu"),
      },
      rejected: { color: "danger", variant: "flat", text: t("Zamietnuté") },
      incomplete: {
        color: "warning",
        variant: "flat",
        text: t("Neúplné údaje"),
      },
      approved: { color: "success", variant: "flat", text: t("Aktívne") },
    };

    return (
      <Chip {...statusProps[status]} size="sm">
        {statusProps[status].text}
      </Chip>
    );
  };

  const handleEditShop = (shop) => {
    setSelectedShop(shop);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedShop(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <BasicNavbar />
      <div className="max-w-7xl mx-auto px-4 py-8">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeInVariant}
          className="space-y-8"
        >
          <div className="flex justify-between items-center">
            <h1 className="text-4xl font-bold">{t("Partnerská zóna")}</h1>
            <Button
              color="primary"
              onClick={() => setIsModalOpen(true)}
              className="px-8"
            >
              {t("Pridať obchod")}
            </Button>
          </div>

          <ShopPartnerRegister
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSuccess={() => {
              handleCloseModal();
              fetchShops();
            }}
            initialData={selectedShop}
            mode={selectedShop ? "update" : "create"}
          />

          <div className="bg-white rounded-lg p-6">
            <h2 className="text-2xl font-semibold mb-6">{t("Vaše obchody")}</h2>
            <Table aria-label={t("Tabuľka partnerských e-shopov")}>
              <TableHeader>
                <TableColumn>{t("Názov obchodu")}</TableColumn>
                <TableColumn>{t("Stav")}</TableColumn>
                <TableColumn>{t("Provízie")}</TableColumn>
                <TableColumn>{t("Akcie")}</TableColumn>
              </TableHeader>
              <TableBody
                emptyContent={t("Zatiaľ ste nepridali žiadne e-shopy")}
                isLoading={isLoading}
              >
                {shops.map((shop) => (
                  <TableRow key={shop.id}>
                    <TableCell>{shop.shop_name}</TableCell>
                    <TableCell>{getStatusChip(shop.status)}</TableCell>
                    <TableCell>
                      {shop.invoices?.length > 0
                        ? shop.invoices
                        : t("Zatiaľ neboli vygenerované žiadne provízie")}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="light"
                        color="primary"
                        onClick={() => handleEditShop(shop)}
                      >
                        {t("Upraviť údaje")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </motion.div>
      </div>
      <Footer />
    </>
  );
};

export default PartnerZone;
