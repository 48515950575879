import MyZoneTable from "./MyZoneTable";
import StatItem from "./StatItem";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io"; // Import back arrow icon
import React from "react";

export default function Reviews({ onMenuToggle, t }) {
  // Add onMenuToggle prop
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await api.get("get-my-reviews/");
      setData(response.data);
    } catch (error) {
      console.error("fetchData -> error", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const heads = [t("produkt"), t("druh recenzie"), t("stav")];
  return (
    <div className="pb-12">
      {/* Desktop view: Title without the back arrow */}
      <div className="lg:block hidden uppercase font-bold py-12 text-2xl">
        {t("Moje recenzie")}
      </div>

      {/* Mobile view: Title with the back arrow */}
      <div
        className="lg:hidden uppercase font-bold py-12 text-2xl flex items-center gap-2 cursor-pointer"
        onClick={onMenuToggle} // Trigger menu toggle on click
      >
        <IoIosArrowBack className="h-6 w-6 cursor-pointer text-gray-700" />
        {t("Moje recenzie")}
      </div>

      {/* Desktop view: Display as a table */}
      <div className="hidden lg:block overflow-x-auto">
        <table className="min-w-full bg-white border-separate border-spacing-y-8">
          <thead>
            <tr className="w-full uppercase text-sm leading-normal">
              {heads.map((head, index) => (
                <th
                  key={index}
                  className="py-3 px-6 text-left whitespace-nowrap"
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm font-light">
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td colSpan={heads.length}>
                    <hr />
                  </td>
                </tr>
                <tr key={index} className="border-t border-gray-200">
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    <div className="flex flex-row gap-6 items-center">
                      <div className="w-[64px] h-[64px] shadow-lg rounded-lg items-center">
                        <img
                          src={item.product_image}
                          alt=""
                          className="h-[64px] mx-auto"
                        />
                      </div>
                      <div className="font-semibold">{item.product_name}</div>
                    </div>
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {t("Písomná recenzia")}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    <a
                      className={`py-2 px-4 rounded-md text-white font-semibold ${
                        item.state === "confirmed"
                          ? "bg-green-500"
                          : item.state === "pending"
                            ? "bg-blue/80"
                            : item.state === "clicked"
                              ? "bg-blue/80"
                              : ""
                      }`}
                    >
                      {item.state === "confirmed"
                        ? t("Potvrdená")
                        : item.state === "pending"
                          ? t("Čaká na potvrdenie")
                          : t("Čaká na potvrdenie")}
                    </a>
                  </td>
                  <td className="py-3 px-6 text-left">
                    <BsThreeDotsVertical />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile view: Display as a card layout */}
      <div className="lg:hidden space-y-4">
        {data.map((item, index) => (
          <div
            key={index}
            className="border rounded-lg shadow-md p-4 space-y-2"
          >
            <div className="flex items-center">
              <div className="flex items-center ml-2">
                <div className="w-[64px] h-[64px] shadow-lg rounded-lg items-center aspect-square">
                  <img
                    src={item.product_image}
                    alt=""
                    className="h-[64px] mx-auto aspect-square"
                  />
                </div>
                <span className="font-semibold ml-2">{item.product_name}</span>
              </div>
            </div>
            <div>
              <span className="font-semibold">Druh recenzie: </span> Písomná
              recenzia
            </div>
            <div>
              <span className="font-semibold">Stav: </span>
              <a
                className={`py-1 px-3 rounded-md text-white font-semibold ${
                  item.state === "confirmed"
                    ? "bg-green-500"
                    : item.state === "pending"
                      ? "bg-blue/80"
                      : item.state === "clicked"
                        ? "bg-blue/80"
                        : ""
                }`}
              >
                {item.state === "confirmed"
                  ? "Potvrdená"
                  : item.state === "pending"
                    ? "Čaká na potvrdenie"
                    : "Čaká na potvrdenie"}
              </a>
            </div>
            {/* <div>
              <BsThreeDotsVertical />
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
}
