import BasicNavbar from "../components/BasicNavbar";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import "../utils/quill.css";
import { motion } from "framer-motion";
import ShopPartnerRegister from "../components/ShopPartnerRegister";
import { Divider } from "@nextui-org/react";
import { de } from "date-fns/locale";
import { useEffect } from "react";

const fadeInVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const RegisterNewShop = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const contents = {
    sk: `
Zaregistrujte svoj eshop na Bilionbuy, platforme, ktorá spája dynamickú komunitu online nakupujúcich. Bilionbuy prináša skvelé príležitosti pre obchody, ktoré chcú osloviť nových zákazníkov z našej komunity, a zároveň poskytuje užívateľom výhody v podobe vynikajúcich porovnávacích služieb a finančných odmien, ako sú cashbacky a kariérne benefity. Pridajte sa k nám a zažite nové možnosti rastu a úspechu\!

## 1. krok: Registrácia na Bilionbuy ##

Registrácia na Bilionbuy je rýchla a jednoduchá, stačí nasledovať tieto kroky! Najprv sa [zaregistrujte na stránke Bilionbuy](https://www.bilionbuy.com/register). Po úspešnom prihlásení kliknite v pravom hornom rohu na ikonu vašej fotografie a zvoľte možnosť "Zóna pre partnera". V tejto zóne môžete pridať váš internetový obchod. 


## 2. krok: Pridanie internetového obchodu v Zóne pre partnera ##


Po prihlásení na Bilionbuy kliknite na svoju profilovú fotku v pravom hornom rohu a vyberte možnosť "Zóna pre partnera". Potom jednoducho kliknite na "Pridať internetový obchod". Ak máte viacero internetových obchodov, môžete ich všetky pridať na jednom užívateľskom účte.

## 3. krok: XML feed s produktami vášho internetového obchodu ##

Ak ste úspešne pridali svoj internetový obchod, nezabudnite vložiť URL adresu, kde máte uložený XML feed s produktmi vášho internetového obchodu. Uistite sa, že tento XML feed je vždy aktuálny. Kľudne môžete vložiť aj URL adresu XML feedu, ktorý už máte pripravený pre iné platformy. Ak ste ešte nikdy nevytvárali XML feed, neváhajte nás kontaktovať na partner@bilionbuy.com. Sme tu na to, aby sme vám pomohli!


## 4. krok: Synchronizácia systémov na sledovanie prekliku z Bilionbuy na nákup vo vašom internetovom obchode ##


Ak máte internetový obchod vytvorený na platforme ako napr. Shoptet, Shopify, WooCommerce, Magento, BigCommerce, PrestaShop, Wix eCommerce, Squarespace Commerce, OpenCart alebo iné, synchronizáciu prekliku a nákupu vo Vašom eshope vykonáme na základe dostupných funkcionalít týchto platforiem. Ak máte eshop vytvorený ako vlastné webové riešenie na mieru, synchronizáciu vykonáme na základe implementácie skriptu (kódu) do vášho webu. So synchronizáciou vám naši kolegovia pomôžu.

## 5. krok: Nastavenie odmeny za zverejnenie ponuky internetového obchodu na Bilionbuy ##

Následne vás naši kolegovia budú kontaktovať, aby sme spolu dohodli provízne podmienky za nákupy členov komunity Bilionbuy. Tešíme sa na spoluprácu!

`,
    cs: `Zaregistrujte svůj eshop na platformě Bilionbuy, která spojuje dynamickou komunitu online nakupujících. Bilionbuy přináší skvělé příležitosti pro obchody, které chtějí oslovit nové zákazníky z naší komunity, a zároveň poskytuje uživatelům výhody v podobě vynikajících srovnávacích služeb a finančních odměn, jako jsou cashbacky a kariérní výhody. Přidejte se k nám a zažijte nové příležitosti k růstu a úspěchu!

## Krok 1: Zaregistrujte se na Bilionbuy ##

Registrace na Bilionbuy je rychlá a snadná, stačí postupovat podle následujících kroků! Nejprve se [zaregistrujte na webových stránkách Bilionbuy](https://www.bilionbuy.com/register). Po úspěšném přihlášení klikněte na ikonu své fotografie v pravém horním rohu a vyberte možnost „Partnerská zóna“. V této zóně můžete přidat svůj internetový obchod. 


## Krok 2: Přidání vašeho internetového obchodu do partnerské zóny ##


Po přihlášení do Bilionbuy klikněte na svou profilovou fotografii v pravém horním rohu a vyberte „Partnerská zóna“. Poté jednoduše klikněte na „Přidat internetový obchod“. Pokud máte více internetových obchodů, můžete je přidat všechny na jeden uživatelský účet.

## Krok 3: XML feed s produkty vašeho internetového obchodu ##

Pokud jste úspěšně přidali svůj internetový obchod, nezapomeňte vložit adresu URL, na kterou jste uložili XML feed s produkty vašeho internetového obchodu. Ujistěte se, že tento XML feed je vždy aktuální. Neváhejte také vložit URL adresu XML feedu, ktorý již máte připravený pro jiné platformy. Pokud jste XML feed ještě nikdy nevytvářeli, neváhejte nás kontaktovat na adrese partner@bilionbuy.com. Jsme zde, abychom vám pomohli!


## Krok 4: Synchronizace systémů pro sledování prokliků z Bilionbuy na nákup ve vašem internetovém obchodě ##


Pokud máte internetový obchod vytvořený na platformě, jako je Shoptet, Shopify, WooCommerce, Magento, BigCommerce, PrestaShop, Wix eCommerce, Squarespace Commerce, OpenCart nebo jiné, budeme synchronizovat prokliky a nákupy ve vašem obchodě na základě dostupných funkcí těchto platforem. Pokud máte eshop vytvořený jako vlastní webové řešení, provedeme synchronizaci na základě implementace skriptu (kódu) na vašem webu. Se synchronizací vám pomohou naši kolegové.

## Krok 5: Nastavení odměny za zveřejnění nabídky internetového obchodu v Bilionbuy ##

Dále vás budou naši kolegové kontaktovat, abyste se společně dohodli na podmínkách provize za nákupy provedené členy komunity Bilionbuy. Těšíme se na spolupráci s vámi!

`,
    pl: `Zarejestruj swój sklep internetowy na Bilionbuy, platformie, która łączy dynamiczną społeczność kupujących online. Bilionbuy oferuje ogromne możliwości dla sklepów, które chcą dotrzeć do nowych klientów z naszej społeczności, zapewniając jednocześnie użytkownikom korzyści w postaci doskonałych usług porównawczych i nagród finansowych, takich jak cashbacki i korzyści zawodowe. Dołącz do nas i poznaj nowe możliwości rozwoju i sukcesu!

## Krok 1: Zarejestruj się na Bilionbuy ##

Rejestracja w Bilionbuy jest szybka i łatwa, wystarczy wykonać poniższe kroki! Najpierw [zarejestruj się na stronie Bilionbuy](https://www.bilionbuy.com/register). Po pomyślnym zalogowaniu kliknij ikonę zdjęcia w prawym górnym rogu i wybierz „Strefa partnera”. W tej strefie możesz dodać swój sklep internetowy. 


## Krok 2: Dodaj swój sklep internetowy do Strefy Partnera


Po zalogowaniu się do Bilionbuy kliknij swoje zdjęcie profilowe w prawym górnym rogu i wybierz „Strefa partnera”. Następnie kliknij „Dodaj sklep internetowy”. Jeśli masz wiele sklepów internetowych, możesz dodać je wszystkie na jednym koncie użytkownika.

## Krok 3: Plik XML z produktami sklepu internetowego ##

Jeśli pomyślnie dodałeś swój sklep internetowy, nie zapomnij wkleić adresu URL, pod którym przechowujesz plik XML z produktami swojego sklepu internetowego. Upewnij się, że ten plik XML jest zawsze aktualny. Możesz również wkleić adres URL pliku XML, który jest już gotowy dla innych platform. Jeśli nigdy wcześniej nie tworzyłeś pliku XML, skontaktuj się z nami pod adresem partner@bilionbuy.com. Jesteśmy tutaj, aby Ci pomóc!


## Krok 4: Zsynchronizuj systemy, aby śledzić kliknięcia z Bilionbuy do zakupu w sklepie internetowym ##


Jeśli masz sklep internetowy zbudowany na platformie takiej jak Shoptet, Shopify, WooCommerce, Magento, BigCommerce, PrestaShop, Wix eCommerce, Squarespace Commerce, OpenCart lub innych, zsynchronizujemy kliknięcia i zakupy w Twoim sklepie w oparciu o dostępne funkcjonalności tych platform. Jeśli posiadasz sklep internetowy stworzony jako niestandardowe rozwiązanie internetowe, przeprowadzimy synchronizację w oparciu o implementację skryptu (kodu) w Twojej witrynie. Nasi koledzy pomogą ci w synchronizacji.

## Krok 5: Ustawienie nagrody za opublikowanie oferty sklepu internetowego na Bilionbuy ##

Następnie nasi koledzy skontaktują się z Tobą, aby wspólnie uzgodnić warunki prowizji za zakupy dokonane przez członków społeczności Bilionbuy. Z niecierpliwością czekamy na współpracę!

`,
    hu: `Regisztrálja webáruházát a Bilionbuy-on, amely egy olyan platform, amely az online vásárlók dinamikus közösségét köti össze. A Bilionbuy nagyszerű lehetőségeket kínál azon üzletek számára, amelyek új vásárlókat szeretnének elérni a közösségünkből, miközben a felhasználók számára előnyöket biztosít kiváló összehasonlító szolgáltatások és pénzügyi jutalmak, például készpénz-visszatérítések és karrierjuttatások formájában. Csatlakozzon hozzánk, és tapasztalja meg a növekedés és a siker új lehetőségeit!

## 1. lépés: Regisztráljon a Bilionbuy oldalon ##

A Bilionbuy-ra való regisztráció gyors és egyszerű, csak kövesse az alábbi lépéseket! Először is [regisztráljon a Bilionbuy weboldalán](https://www.bilionbuy.com/register). Miután sikeresen bejelentkezett, kattintson a jobb felső sarokban lévő fénykép ikonra, és válassza a „Partner Zone” lehetőséget. Ebben a zónában hozzáadhatja online üzletét. 


## 2. lépés: Adja hozzá webáruházát a Partner Zónában ##


Miután bejelentkeztél a Bilionbuyba, kattints a profilképedre a jobb felső sarokban, és válaszd a „Partner Zóna” lehetőséget. Ezután egyszerűen kattintson a „Webáruház hozzáadása” gombra. Ha több webáruháza is van, akkor egy felhasználói fiókban mindet hozzáadhatja.

## 3. lépés: XML feed a webshop termékeivel ##

Ha sikeresen hozzáadta webáruházát, ne felejtse el beilleszteni az URL-t, ahol a webáruháza termékeit tartalmazó XML feedet tárolta. Győződjön meg róla, hogy ez az XML feed mindig naprakész. Nyugodtan illessze be annak az XML feednek az URL-jét is, amelyet már készen tart más platformok számára. Ha még soha nem készített XML feedet, forduljon hozzánk bizalommal a partner@bilionbuy.com címen. Segítünk Önnek!


## 4. lépés: Szinkronizálja a rendszereket, hogy nyomon követhesse a Bilionbuyból történő kattintást a vásárlásig az online áruházában ##


Ha olyan platformon létrehozott online áruháza van, mint a Shoptet, Shopify, WooCommerce, Magento, BigCommerce, PrestaShop, Wix eCommerce, Squarespace Commerce, OpenCart vagy más, akkor mi szinkronizáljuk az átkattintást és a vásárlást az áruházában ezen platformok elérhető funkciói alapján. Ha egyedi webes megoldásként létrehozott eshopja van, akkor a szinkronizálást a szkript (kód) weboldalán történő megvalósítása alapján végezzük el. Kollégáink segítenek Önnek a szinkronizálásban.

## 5. lépés: Jutalom beállítása a Bilionbuy oldalon történő webáruház bejegyzésért ##

Ezután kollégáink felveszik Önnel a kapcsolatot, hogy közösen megállapodjanak a Bilionbuy közösség tagjai által végzett vásárlások jutalékfeltételeiről. Várjuk a közös munkát!

`,
    de: `Registrieren Sie Ihren eshop auf Bilionbuy, einer Plattform, die eine dynamische Gemeinschaft von Online-Shoppern verbindet. Bilionbuy bietet großartige Möglichkeiten für Geschäfte, die neue Kunden aus unserer Gemeinschaft erreichen wollen, und bietet den Nutzern gleichzeitig Vorteile in Form von hervorragenden Vergleichsdiensten und finanziellen Belohnungen wie Cashbacks und Karrierevorteilen. Kommen Sie zu uns und erleben Sie neue Möglichkeiten für Wachstum und Erfolg!

## Schritt 1: Registrieren Sie sich bei Bilionbuy.

Die Anmeldung bei Bilionbuy ist schnell und einfach, folgen Sie einfach diesen Schritten! Melden Sie sich zunächst [auf der Bilionbuy-Website](https://www.bilionbuy.com/register) an. Sobald Sie sich erfolgreich angemeldet haben, klicken Sie auf Ihr Foto-Symbol in der oberen rechten Ecke und wählen Sie „Partnerzone“. In diesem Bereich können Sie Ihren Online-Shop hinzufügen. 


## Schritt 2: Fügen Sie Ihren Online Shop in der Partner Zone hinzu.


Nachdem Sie sich bei Bilionbuy eingeloggt haben, klicken Sie oben rechts auf Ihr Profilfoto und wählen Sie „Partnerbereich“. Klicken Sie dann einfach auf „Online Shop hinzufügen“. Wenn Sie mehrere Online-Shops haben, können Sie diese alle in einem Benutzerkonto hinzufügen.

## Schritt 3: XML-Feed mit Ihren Webshop-Produkten ##

Wenn Sie Ihren Webshop erfolgreich hinzugefügt haben, vergessen Sie nicht, die URL einzufügen, unter der Sie den XML-Feed mit den Produkten Ihres Webshops gespeichert haben. Achten Sie darauf, dass dieser XML-Feed immer auf dem neuesten Stand ist. Gerne können Sie auch die URL des XML-Feeds einfügen, den Sie bereits für andere Plattformen bereithalten. Wenn Sie noch nie einen XML-Feed erstellt haben, können Sie sich gerne an uns wenden: partner@bilionbuy.com. Wir sind für Sie da!


## Schritt 4: Synchronisieren Sie die Systeme, um den Klick von Bilionbuy zum Kauf in Ihrem Online-Shop zu verfolgen.


Wenn Sie einen Online-Shop haben, der auf einer Plattform wie Shoptet, Shopify, WooCommerce, Magento, BigCommerce, PrestaShop, Wix eCommerce, Squarespace Commerce, OpenCart oder anderen erstellt wurde, synchronisieren wir die Click-Throughs und Käufe in Ihrem Shop basierend auf den verfügbaren Funktionen dieser Plattformen. Wenn Sie einen eShop als benutzerdefinierte Weblösung erstellt haben, führen wir die Synchronisierung auf der Grundlage der Implementierung des Skripts (Codes) in Ihrer Website durch. Unsere Mitarbeiter helfen Ihnen bei der Synchronisation.

## Schritt 5: Einrichten einer Prämie für das Einstellen eines Online-Shops auf Bilionbuy...

Als nächstes werden sich unsere Kollegen mit Ihnen in Verbindung setzen, um gemeinsam die Provisionsbedingungen für Einkäufe von Mitgliedern der Bilionbuy-Community zu vereinbaren. Wir freuen uns auf die Zusammenarbeit mit Ihnen!

`,
    en: `Register your eshop on Bilionbuy, a platform that connects a dynamic community of online shoppers. Bilionbuy brings great opportunities for stores looking to reach new customers from our community, while also providing users with benefits in the form of excellent comparison services and financial rewards such as cashbacks and career benefits. Join us and experience new opportunities for growth and success!

## Step 1: Register on Bilionbuy ##

Signing up for Bilionbuy is quick and easy, just follow these steps! First, [sign up on the Bilionbuy website](https://www.bilionbuy.com/register). Once you have successfully logged in, click on your photo icon in the top right corner and select "Partner Zone". In this zone you can add your online shop. 


## Step 2: Add your online shop in the Partner Zone ##


Once you have logged in to Bilionbuy, click on your profile photo in the top right corner and select "Partner Zone". Then simply click on "Add Online Store". If you have multiple online stores, you can add them all on one user account.

## Step 3: XML feed with your webshop products ##

If you have successfully added your webshop, don't forget to paste the URL where you have stored the XML feed with your webshop's products. Make sure that this XML feed is always up to date. Feel free to also paste the URL of the XML feed you already have ready for other platforms. If you have never created an XML feed before, feel free to contact us at partner@bilionbuy.com. We are here to help you!


## Step 4: Synchronize systems to track click-through from Bilionbuy to purchase in your online store ##


If you have an online store created on a platform such as Shoptet, Shopify, WooCommerce, Magento, BigCommerce, PrestaShop, Wix eCommerce, Squarespace Commerce, OpenCart or others, we will sync click-through and purchase in your store based on the available functionalities of these platforms. If you have an eshop created as a custom web solution, we will perform the synchronization based on the implementation of the script (code) in your website. Our colleagues will help you with the synchronization.

## Step 5: Setting up a reward for posting an online store listing on Bilionbuy ##

Next, our colleagues will contact you to work together to agree on commission terms for purchases made by members of the Bilionbuy community. We look forward to working with you!

`,
  };

  console.log("Current language:", i18n.language);
  console.log("Available languages:", Object.keys(contents));

  useEffect(() => {
    console.log("Language changed to:", i18n.language);
  }, [i18n.language]);

  const content = contents[i18n.language] || contents["en"];

  return (
    <>
      <BasicNavbar />
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          className="text-center my-12"
          initial="hidden"
          animate="visible"
          variants={fadeInVariant}
        >
          <h1 className="text-5xl font-bold mb-8">
            {t("Ako zaregistrovať nový shop")}
          </h1>
          <div className="quill-content max-w-5xl mx-auto text-left mb-12">
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>

          {/* Registration Form Section */}
          {/* <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariant}
            className="mb-16"
          >
            <ShopPartnerRegister />
          </motion.div> */}

          <Divider className="my-16" />

          {/* Contact Section */}
          <div className="max-w-5xl mx-auto">
            <h2
              className="pt-12 text-primary font-semibold text-5xl mb-12"
              dangerouslySetInnerHTML={{
                __html: t("Chcete sa stať našim partnerom? Kontaktujte nás."),
              }}
            ></h2>

            <motion.div
              className="flex lg:flex-row flex-col justify-center items-center my-24 w-full"
              initial="hidden"
              animate="visible"
              variants={fadeInVariant}
            >
              <div className="flex flex-col gap-4 px-4 pb-4 text-center lg:text-right">
                <h3 className="text-3xl font-bold">Štefan</h3>
                <a
                  href="mailto:partner@bilionbuy.com"
                  className="text-primary font-semibold text-xl underline underline-offset-8"
                >
                  partner@bilionbuy.com
                </a>
                <a
                  href="tel:+421902670377"
                  className="font-semibold text-primary text-xl"
                >
                  +421 902 670 377
                </a>
              </div>
              <div className="flex flex-col justify-center gap-4 text-center ml-8">
                <div className="shadow-lg w-48 h-48 bg-lightgray/50 rounded-full overflow-hidden flex flex-col justify-center">
                  <img
                    className="mx-auto text-white text-8xl"
                    src="https://bbuy.fra1.cdn.digitaloceanspaces.com/userimg/user1.png"
                    alt="Contact person"
                  />
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
      <Footer />
    </>
  );
};

export default RegisterNewShop;
