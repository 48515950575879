import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
export default function Register() {
  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-white">
          <body class="h-full">
          ```
        */}
      <BasicNavbar />
      <Footer />
    </>
  );
}
