import React, { useState, useCallback, useEffect } from "react";
import { Image, Button, Link, Tooltip } from "@nextui-org/react";
import { FaStar, FaQuoteLeft, FaLink } from "react-icons/fa6";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function WhatPeopleSay({ auth }) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    Autoplay({ delay: 12000 }),
  ]);
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const refs = [
    {
      id: 1,
      name: "Erik Jendrišek",
      title: t("Futbalista"),
      subbuton_text: t("Rozhovor s Erikom Jendriškom"),
      text: t(
        "Vnímam veľmi pozitívne, že niekto prišiel so službou, kde sa dá porovnať cena a kvalita z rôznych oblastí života. Veľké plus pre spotrebitela je cashback, kde má možnosť nákupom si niečo privyrobiť alebo znížiť náklady. Vrelo odporúčam Bilionbuy."
      ),
      image:
        "https://bbuy.fra1.cdn.digitaloceanspaces.com/reviews/jendrisek.jpg",
      registrationLink: "https://www.bilionbuy.com/register/?ref=3Y4xYy",
      interviewLink: "https://www.bilionbuy.com/blog/28",
    },
    {
      id: 2,
      name: "Filip Kis",
      title: t("Futbalista"),
      subbuton_text: t("Rozhovor s Filipom Kisom"),
      text: t(
        "Myslím si, že Bilionbuy prináša veľkú hodnotu pre zákazníkov, pretože ponúka možnosť získať cashback, teda vrátenie časti peňazí z nákupu a zároveň disponuje širokým sortimentom produktov, z ktorých si každý dokáže vybrať presne to, čo potrebuje."
      ),
      image:
        "https://bbuy.fra1.cdn.digitaloceanspaces.com/blog/c076e05d-966c-4107-9941-5355d7868ca0.jpg",
      registrationLink: "https://www.bilionbuy.com/register/?ref=eXBd4W",
      interviewLink: "https://www.bilionbuy.com/blog/30",
    },
    {
      id: 3,
      name: "Lazorčák, Štefan",
      title: t("CEO Bilionbuy"),
      text: t(
        "Viac ako desať rokov som premýšľal nad tým, ako vytvoriť systém, ktorý by ponúkal potrebnú kvalitnú službu a zároveň aby umožňoval zarábať vedľajší príjem všetkým, čo majú chuť sa zapojiť. Práve Bilionbuy spojí všetko, čo inde chýba. Porovnanie kvality, ceny a cashbacku. Porovnanie nielen produktu, ale aj služby. Porovnanie nie len v jednej krajine, ale naprieč viacerými štátmi."
      ),
      registrationLink: "https://www.bilionbuy.com/register/",
      image: "https://bbuy.fra1.cdn.digitaloceanspaces.com/userimg/user1.png",
      subbuton_text: t("Rozhovor so Štefanom Lazorčákom"),
      interviewLink: "https://www.bilionbuy.com/blog/29",
    },
  ];

  const copyRefferalCode = () => {
    navigator.clipboard.writeText(
      "https://www.bilionbuy.com/register?ref=" +
        localStorage.getItem("refcode")
    );
  };

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on("select", onSelect);
    return () => emblaApi.off("select", onSelect);
  }, [emblaApi, onSelect]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        <div className="py-16 bg-[#F8FBFF]">
          <div className="flex flex-col gap-8 max-w-screen-2xl mx-auto">
            <div className="text-center">
              <h2 className="text-blue font-semibold my-4">
                {t("Spätná väzba na Bilionbuy")}
              </h2>
              <h3 className="font-bold lg:text-5xl text-4xl leading-tight px-2">
                {t("Čo hovoria ľudia")}
              </h3>
            </div>

            <div className="relative">
              <div className="embla overflow-hidden" ref={emblaRef}>
                <div className="embla__container flex">
                  {refs.map((ref) => (
                    <div
                      key={ref.id}
                      className="embla__slide min-w-0 flex-none w-full"
                    >
                      <div className="flex flex-row justify-center gap-24 relative py-8">
                        <div className="hidden lg:block">
                          <img
                            isBlurred
                            src={
                              ref.image ||
                              "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/Rectangle%201431.png"
                            }
                            alt={`Portrait of ${ref.name}, ${ref.title}`}
                            className="rounded-3xl object-cover w-[400px] h-[480px] shadow-lg "
                          />
                        </div>
                        <div className="flex flex-col gap-12 my-auto lg:text-left text-center items-center lg:items-start">
                          <div>
                            <FaQuoteLeft
                              className="text-blue mx-auto lg:mx-0"
                              aria-label="Quote icon"
                            />
                            <div
                              className="flex flex-row justify-center lg:justify-start text-blue w-32 mt-4"
                              aria-label="Rating"
                            >
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar />
                            </div>
                          </div>

                          <p className="max-w-[450px] px-8 lg:px-0">
                            {ref.text}
                          </p>
                          <div>
                            <p className="font-bold">{ref.name}</p>
                            <p className="text-sm text-lightgray">
                              {ref.title}
                            </p>
                          </div>

                          <div className="flex md:flex-row flex-col gap-2">
                            {auth ? (
                              <div className="flex flex-col md:flex-row gap-2 items-center md:justify-start justify-center">
                                <div className="flex flex-row gap-2">
                                  <Button
                                    as="a"
                                    href="/my-zone#invites"
                                    className="bg-blue text-white rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10"
                                  >
                                    {t("Pozvi priateľa")} &rarr;
                                  </Button>
                                  <Tooltip content={t("click_for_linkcopy")}>
                                    <div
                                      className="p-3 md:p-4 rounded-full border-1 border-primary hover:cursor-pointer"
                                      onClick={copyRefferalCode}
                                    >
                                      <FaLink className="text-primary" />
                                    </div>
                                  </Tooltip>
                                </div>

                                {ref.interviewLink && (
                                  <Button
                                    as="a"
                                    href={ref.interviewLink}
                                    className="bg-transparent text-blue border-blue border rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10"
                                  >
                                    {t(ref.subbuton_text)}
                                  </Button>
                                )}
                              </div>
                            ) : (
                              <div className="flex md:flex-row flex-col gap-2 items-center md:justify-start justify-center">
                                <Button
                                  as="a"
                                  href={ref.registrationLink || "/register"}
                                  className="bg-blue text-white rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10"
                                >
                                  {t("Registrácia zdarma")} &rarr;
                                </Button>
                                {ref.interviewLink && (
                                  <Button
                                    as="a"
                                    href={ref.interviewLink}
                                    className="bg-transparent text-blue border-blue border rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10"
                                  >
                                    {t(ref.subbuton_text)}
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Navigation buttons */}
              <div
                className="absolute top-1/2 -left-4 md:-left-8 lg:-left-12 transform -translate-y-1/2 flex items-center hover:cursor-pointer z-10"
                onClick={scrollPrev}
                aria-label="Previous slide"
              >
                <svg
                  width="46"
                  height="46"
                  viewBox="0 0 46 46"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23 34.5L11.5 23L23 11.5"
                    stroke="black"
                    strokeWidth="5"
                  />
                  <path
                    d="M34.5 34.5L23 23L34.5 11.5"
                    stroke="black"
                    strokeWidth="5"
                  />
                </svg>
              </div>

              <div
                className="absolute top-1/2 -right-4 md:-right-8 lg:-right-12 transform -translate-y-1/2 flex items-center hover:cursor-pointer z-10"
                onClick={scrollNext}
                aria-label="Next slide"
              >
                <svg
                  width="46"
                  height="46"
                  viewBox="0 0 46 46"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23 34.5L34.5 23L23 11.5"
                    stroke="black"
                    strokeWidth="5"
                  />
                  <path
                    d="M11.5 34.5L23 23L11.5 11.5"
                    stroke="black"
                    strokeWidth="5"
                  />
                </svg>
              </div>
            </div>

            {/* Indicators */}
            <div className="flex justify-center mt-4">
              {refs.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full mx-1 ${
                    index === selectedIndex ? "bg-blue" : "bg-gray-300"
                  }`}
                  onClick={() => emblaApi.scrollTo(index)}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
