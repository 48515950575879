import React from "react";
import { Input } from "@nextui-org/react";
import { SearchIcon } from "./SearchIcon.jsx";
import { XMarkIcon } from "@heroicons/react/24/outline";

const NewSearchBar = ({ placeholder, onSearchClick, value, onChange }) => {
  const handleInputClick = () => {
    if (onSearchClick) {
      onSearchClick(value);
    }
  };

  const handleClear = () => {
    onChange("");
  };

  return (
    <Input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      startContent={<SearchIcon size={18} />}
      endContent={
        <div className="flex items-center gap-2">
          {value && (
            <button onClick={handleClear} className="focus:outline-none">
              <XMarkIcon className="h-4 w-4 text-gray-400 hover:text-gray-600" />
            </button>
          )}
          {/* <button
            onClick={handleInputClick}
            className="bg-primary text-white px-8 -mr-4 py-4 rounded-lg"
          >
            Hľadať
          </button> */}
        </div>
      }
      placeholder={placeholder}
      variant="bordered"
      className="rounded-full"
    />
  );
};

export default NewSearchBar;
