import React from "react";
import { useState, useMemo } from "react";
import api from "../services/api";
import {
  Button,
  Input,
  Image,
  Spinner,
  Select,
  SelectItem,
} from "@nextui-org/react";
import validator from "validator";
import { motion, AnimatePresence } from "framer-motion";
import { FaCopy } from "react-icons/fa";
import { Tooltip } from "@nextui-org/tooltip";
import { FaLink } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { logo_url } from "../data/common";

const InviteFriend = () => {
  const { t, i18n } = useTranslation();
  const [friendEmail, setFriendEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState("");
  const [invitationLanguage, setInvitationLanguage] = useState(i18n.language);

  const languages = [
    { label: "🇬🇧", value: "en" },
    { label: "🇸🇰", value: "sk" },
    { label: "🇨🇿", value: "cs" },
    { label: "🇵🇱", value: "pl" },
    { label: "🇩🇪", value: "de" },
    { label: "🇭🇺", value: "hu" },
  ];

  const copyText = () => {
    const text = document.getElementById("copyText").innerText;
    navigator.clipboard.writeText(text);
  };

  const copyRefferalCode = () => {
    navigator.clipboard.writeText(
      "https://www.bilionbuy.com/register/?ref=" +
        localStorage.getItem("refcode")
    );
  };

  const validateEmail = (email) => {
    return validator.isEmail(email);
  };

  const emailIsInvalid = useMemo(() => {
    if (friendEmail === "") return false;
    return validateEmail(friendEmail) ? false : true;
  }, [friendEmail]);

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 20,
      maxHeight: 0,
      overflow: "hidden",
      transition: {
        maxHeight: { duration: 0.5, ease: "easeInOut" },
        opacity: { delay: 0.5 },
        y: { delay: 0.5 },
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      maxHeight: 200,
      transition: {
        maxHeight: { duration: 0.5, ease: "easeInOut" },
        opacity: { duration: 0.5, delay: 0.5 },
        y: { duration: 0.5, delay: 0.5 },
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        opacity: { duration: 0.3 },
        y: { duration: 0.3 },
        maxHeight: { duration: 0.5, delay: 0.3, ease: "easeInOut" },
      },
      transitionEnd: {
        maxHeight: 0,
      },
    },
  };

  const inviteFriend = async () => {
    setIsLoading(true);
    setSuccess("");
    setError("");
    try {
      const response = await api.post("invite-friend/", {
        email: friendEmail,
        user_email: localStorage.getItem("username"),
        invitationLanguage: invitationLanguage,
      });
      setSuccess(t("Pozvánka bola úspešne odoslaná"));
      setTimeout(() => {
        setSuccess("");
      }, 5000);
    } catch (error) {
      console.error("An error occurred:", error);
      setError(error.response.data.message);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-center">
        <Image src={logo_url} className="w-52" />
      </div>
      <div className="text-xl font-bold text-black text-center">
        {t("Pozvite priateľov do BILIONBUY!")}
      </div>
      <div className="flex gap-2">
        <div className="flex-1">
          <Input
            value={friendEmail}
            onChange={(e) => setFriendEmail(e.target.value.replace(/\s/g, ""))}
            placeholder={t("Vložte email")}
            variant="bordered"
            isInvalid={emailIsInvalid}
            color={emailIsInvalid ? "danger" : "success"}
            errorMessage={emailIsInvalid && t("Vložte platný email")}
          />
        </div>
        <Select
          defaultSelectedKeys={[i18n.language]}
          className="w-[70px]"
          onChange={(e) => setInvitationLanguage(e.target.value)}
          variant="bordered"
        >
          {languages.map((lang) => (
            <SelectItem key={lang.value} value={lang.value}>
              {lang.label}
            </SelectItem>
          ))}
        </Select>
      </div>
      <div>
        <Button
          className="bg-blue text-white w-full py-6"
          onClick={inviteFriend}
          isDisabled={friendEmail === "" || emailIsInvalid}
        >
          {isLoading ? <Spinner color="white" /> : <a>{t("Pozvať")}</a>}
        </Button>
      </div>
      <AnimatePresence>
        {error && (
          <motion.div
            key="error"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
          >
            <p className="text-red-500 border-1 border-red-500 rounded-lg p-4 text-sm">
              {error}
            </p>
          </motion.div>
        )}
        {success && (
          <motion.div
            key="success"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
          >
            <p className="text-green-500 border-1 border-green-500 rounded-lg p-4 text-sm">
              {success}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
      <div></div>
      <div className="flex-col flex gap-8 text-black">
        <div>
          <div className="flex flex-col gap-4 pb-8">
            <div className="text-xl font-semibold uppercase">
              {t("Váš odporúčateľský link")}
            </div>
            <div className="flex flex-row gap-2">
              <input
                className="w-[400px] border-1 rounded-lg p-2 border-primary text-primary"
                type="text"
                disabled
                value={
                  "https://www.bilionbuy.com/register?ref=" +
                  localStorage.getItem("refcode")
                }
              />
              <Tooltip
                content={t("Klikni pre skopírovanie odporúčateľského odkazu!")}
              >
                <div
                  className="p-4 rounded-full border-1 border-primary hover:cursor-pointer"
                  onClick={copyRefferalCode}
                >
                  <FaLink className="text-primary" />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>{" "}
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl font-bold">
            {t("Ako mám pozvať svojho priateľa?")}
          </h1>
          <p className="">
            {t("Napríklad, pošli mu tento text")}: <br />
            <Tooltip
              content={t("Kliknutím skopírujete text")}
              placement="bottom"
              className="text-white bg-blue text-sm"
            >
              <div
                id="copyText"
                onClick={copyText}
                className="relative p-4 border-1 rounded-xl mt-4 text-sm hover:cursor-copy group hover:border-blue"
              >
                <FaCopy className="absolute top-2 right-2 text-lightgray group-hover:text-blue" />
                {t(
                  "Poslal som ti pozvánku do Bilionbuy. Je to porovnávač nákupov, ktorý odmeňuje zákazníkov z každého nákupu."
                )}
              </div>
            </Tooltip>
          </p>{" "}
        </div>
        <div className="flex flex-col gap-2 border-1 p-8 shadow-lg rounded-lg ">
          <h1 className="text-2xl font-bold">
            {t("Nezabudnite priateľovi spomenúť výhody Bilionbuy")}
          </h1>
          <div>
            <ul className="flex flex-col gap-2 list-disc ml-4 mt-4">
              <li>{t("Za každý nákup získate odmenu vo forme cashbacku")}</li>
              <li>
                {t("Ste odmeňovaný aj za nákupy ľudí, ktorých odporúčite")}
              </li>
              <li>
                {t(
                  "Komunita je medzinárodná, môžete pozývať aj ľudí zo zahraničia"
                )}
              </li>
              <li>{t("Úplne zdarma, bez žiadnych poplatkov")}</li>
              <li>{t("Vhodné pre influencerov a obchodníkov")}</li>
              <li>
                {t(
                  "Žiadne obmedzujúce podmienky na výplaty cashbackov a odmien"
                )}
              </li>
              <li>{t("Jednoduchý odporúčací systém prostedníctvom linku")}</li>
              <li>{t("Vzdelávací systém na podporu vašich zárobkov")}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InviteFriend;
