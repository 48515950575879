import {
  Button,
  Spinner,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import GoogleSignIn from "../GoogleComponent";
import { useState } from "react";
import { useAuth } from "react-auth-verification-context";
import log_in from "../../utils/Login";
import { useTranslation } from "react-i18next";
import validator from "validator";
import api from "../../services/api";
export default function LoginForm({ onSuccess, showComplementary }) {
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const { t } = useTranslation();
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [isResetting, setIsResetting] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const validateEmail = (email) => validator.isEmail(email);
  const validatePassword = (password) => {
    const minLength = 8;
    const errors = [];

    if (!validator.isLength(password, { min: minLength })) {
      errors.push(
        `${t("Heslo musí obsahovať aspoň")} ${minLength} ${t("znakov")}.`
      );
    }

    return errors.length === 0 ? { valid: true } : { valid: false, errors };
  };

  const emailIsInvalid = username && !validateEmail(username);
  const passwordValidationResult = validatePassword(password);
  const passwordIsInvalid = password && !passwordValidationResult.valid;

  const handleLogin = async () => {
    if (emailIsInvalid || passwordIsInvalid) {
      console.log(t("Zadajte platný e-mail a heslo."));
      return;
    }

    const success = await log_in(username, password, setIsLoading, login);

    if (success) {
      console.log(t("Prihlásenie úspešné"));
      if (onSuccess) {
        onSuccess(); // Call the parent-provided success callback
      }
    } else {
      console.log(t("Prihlásenie zlyhalo"));
      // Handle login failure
    }
  };

  const handleGoogleSuccess = (data) => {
    if (onSuccess) {
      onSuccess(data); // Call the parent-provided success callback
    }
  };

  const handleGoogleFailure = (error) => {
    console.error("Google Sign-In Failure:", error);
    // Handle failure, like showing an error message
  };

  const handlePasswordReset = async () => {
    if (!validateEmail(resetEmail)) {
      console.log(t("Zadajte platný e-mail."));
      return;
    }

    setIsResetting(true);
    try {
      const response = await api.post("request-password-reset/", {
        email: resetEmail,
      });

      if (response.status === 200) {
        setResetSuccess(true);
        console.log(t("Email na obnovenie hesla bol odoslaný"));
      } else {
        console.log(t("Nastala chyba pri odosielaní emailu"));
      }
    } catch (error) {
      console.error("Reset password error:", error);
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
      <div className=" sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST">
          <div className="flex justify-center">
            {showComplementary && (
              <img src="bb-white.png" alt="Logo" className="w-full" />
            )}
          </div>
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              {t("Prihláste sa do vášho účtu")}
            </h2>
          </div>
          <div className="">
            <GoogleSignIn
              onSignInSuccess={handleGoogleSuccess}
              onSignInFailure={handleGoogleFailure}
              className="w-full"
            />
          </div>
          <div>
            <TextField
              className="w-full"
              required
              type="email"
              id="standard-required"
              placeholder={t("E-mail")}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="standard"
              error={emailIsInvalid}
              helperText={emailIsInvalid ? t("Zadajte platný e-mail.") : ""}
            />
          </div>
          <div>
            <TextField
              className="w-full"
              required
              id="standard-required"
              type="password"
              placeholder={t("Heslo")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="standard"
              error={passwordIsInvalid}
              helperText={
                passwordIsInvalid
                  ? passwordValidationResult.errors.join(", ")
                  : ""
              }
            />
          </div>
          <div className="text-sm flex flex-row justify-between text-primary">
            <div>
              <Checkbox className="text-sm" />
              {t("Zapamätať")}
            </div>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsResetModalOpen(true);
              }}
            >
              {t("Zabudnuté heslo?")}
            </a>
          </div>
          <div className="text-sm text-center">
            {t("Kliknutím na tlačidlo nižšie súhlasíte s")}{" "}
            <a
              className="text-primary"
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("terms and conditions")}
            </a>
          </div>
          <div>
            {isLoading ? (
              <Button
                color="primary"
                className="bg-white text-blue flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm"
                disabled
              >
                <Spinner />
              </Button>
            ) : (
              <Button
                color="primary"
                className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
                onClick={handleLogin}
              >
                {t("LOGIN")}
              </Button>
            )}
          </div>
        </form>
        {showComplementary && (
          <p className="mt-10 text-center text-sm text-gray-500">
            {t("Nemáte účet?")}{" "}
            <a
              href="/register"
              className="font-semibold leading-6 text-primary"
            >
              {t("Registrujte sa tu")}
            </a>
          </p>
        )}
      </div>
      <Modal
        isOpen={isResetModalOpen}
        onClose={() => {
          setIsResetModalOpen(false);
          setResetSuccess(false);
          setResetEmail("");
        }}
      >
        <ModalContent>
          <ModalHeader>{t("Obnovenie hesla")}</ModalHeader>
          <ModalBody>
            {resetSuccess ? (
              <div className="text-center">
                <p className="mb-4">
                  {t("Email na obnovenie hesla bol úspešne odoslaný!")}
                </p>
                <p>
                  {t(
                    "Prosím, skontrolujte svoju emailovú schránku a postupujte podľa pokynov."
                  )}
                </p>
              </div>
            ) : (
              <TextField
                className="w-full"
                required
                type="email"
                placeholder={t("Zadajte váš email")}
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                variant="standard"
                error={resetEmail && !validateEmail(resetEmail)}
                helperText={
                  resetEmail && !validateEmail(resetEmail)
                    ? t("Zadajte platný e-mail.")
                    : ""
                }
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              variant="light"
              onClick={() => {
                setIsResetModalOpen(false);
                setResetSuccess(false);
                setResetEmail("");
              }}
            >
              {t("Zavrieť")}
            </Button>
            {!resetSuccess && (
              <Button
                color="primary"
                onClick={handlePasswordReset}
                disabled={isResetting}
              >
                {isResetting ? <Spinner size="sm" /> : t("Odoslať")}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
