import React from "react";
import { TableCell, TableRow, IconButton, Tooltip } from "@mui/material";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const SortableRow = ({ offer, onEdit, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: offer.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TableRow
      ref={setNodeRef}
      style={style}
      hover
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell>
        <div className="flex items-center">
          <IconButton {...attributes} {...listeners} size="small">
            <DragIndicatorIcon />
          </IconButton>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-center gap-2">
          <img
            src={offer.product.product_image_url}
            alt={offer.product.product_name}
            className="w-10 h-10 object-contain"
          />
          <span>{offer.product.product_name}</span>
        </div>
      </TableCell>
      <TableCell>{offer.shop.name}</TableCell>
      <TableCell>{offer.discount ? `${offer.discount}%` : ""}</TableCell>
      <TableCell>
        <div className="flex gap-2">
          <Tooltip title="Upraviť">
            <IconButton onClick={onEdit} size="small" color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>
          {onDelete && (
            <Tooltip title="Vymazať">
              <IconButton
                onClick={() => onDelete(offer)}
                size="small"
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};
