import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { toast } from "react-toastify";
import { format } from "date-fns";
import api from "../../../services/api";
import { SortableRow } from "./SortableRow";
import { OfferModal } from "./OfferModal";
import RefreshIcon from "@mui/icons-material/Refresh";

const countries = [
  { value: "SK", label: "Slovensko" },
  { value: "CZ", label: "Česko" },
];

export const LandingOffersTable = () => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("SK");
  const [offers, setOffers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingOffer, setEditingOffer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [offerToDelete, setOfferToDelete] = useState(null);
  const [offerTypes, setOfferTypes] = useState([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const init = async () => {
      try {
        const response = await api.get("get-offer-types");
        setOfferTypes(response.data);
        if (!selectedType && response.data.length > 0) {
          setSelectedType(response.data[0].id);
        }
      } catch (error) {
        toast.error("Nepodarilo sa načítať typy ponúk");
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (selectedType) {
      setLoading(true);
      try {
        api
          .get("get-landing-offers", {
            params: {
              type: selectedType,
              country: selectedCountry,
            },
          })
          .then((response) => {
            setOffers(response.data);
          })
          .catch((error) => {
            toast.error("Nepodarilo sa načítať ponuky");
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        toast.error("Nepodarilo sa načítať ponuky");
      }
    }
  }, [selectedType, selectedCountry]);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const response = await api.get("get-offer-types");
      setOfferTypes(response.data);
      if (!selectedType && response.data.length > 0) {
        setSelectedType(response.data[0].id);
      }
      toast.success("Údaje boli úspešne obnovené");
    } catch (error) {
      toast.error("Nepodarilo sa obnoviť údaje");
    } finally {
      setLoading(false);
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = offers.findIndex((item) => item.id === active.id);
      const newIndex = offers.findIndex((item) => item.id === over.id);
      const newOrderedOffers = arrayMove(offers, oldIndex, newIndex);

      setOffers(newOrderedOffers);

      try {
        await api.post("update-offers-order/", {
          type: selectedType,
          offers: newOrderedOffers.map((offer, index) => ({
            id: offer.id,
            order: index,
          })),
        });
        toast.success("Poradie bolo úspešne aktualizované");
      } catch (error) {
        toast.error("Nepodarilo sa aktualizovať poradie");
        handleRefresh();
      }
    }
  };

  const handleAddOffer = () => {
    setEditingOffer(null);
    setIsModalOpen(true);
  };

  const handleEditOffer = (offer) => {
    setEditingOffer(offer);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (offer) => {
    setOfferToDelete(offer);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!offerToDelete) return;

    try {
      await api.delete(`delete-offer/${offerToDelete.id}/`, {
        data: { id: offerToDelete.id },
      });
      toast.success("Ponuka bola úspešne vymazaná");
      handleRefresh();
    } catch (error) {
      toast.error("Nepodarilo sa vymazať ponuku");
    } finally {
      setDeleteConfirmOpen(false);
      setOfferToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setOfferToDelete(null);
  };

  return (
    <div className="p-4">
      <Paper className="p-4">
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-4 items-center">
            <FormControl variant="outlined" style={{ minWidth: 200 }}>
              <InputLabel>Trh</InputLabel>
              <Select
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
                label="Trh"
              >
                {countries.map((country) => (
                  <MenuItem key={country.value} value={country.value}>
                    {country.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ minWidth: 200 }}>
              <InputLabel>Typ ponuky</InputLabel>
              <Select
                value={selectedType || ""}
                onChange={(e) => setSelectedType(e.target.value)}
                label="Typ ponuky"
              >
                {offerTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                    {type.active_from && type.active_to && (
                      <span className="text-gray-500 text-sm ml-2">
                        ({format(new Date(type.active_from), "dd.MM.yyyy")} -{" "}
                        {format(new Date(type.active_to), "dd.MM.yyyy")})
                      </span>
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Tooltip title="Obnoviť">
              <IconButton
                onClick={handleRefresh}
                disabled={loading}
                color="primary"
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={handleAddOffer}
            disabled={!selectedType}
          >
            Pridať ponuku
          </Button>
        </div>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={50}>Poradie</TableCell>
                <TableCell>Produkt</TableCell>
                <TableCell>Obchod</TableCell>
                <TableCell width={100}>Zľava (%)</TableCell>
                <TableCell width={100}>Akcie</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                {[...Array(5)].map((_, index) => (
                  <TableRow key={index}>
                    {[...Array(5)].map((_, cellIndex) => (
                      <TableCell key={cellIndex}>
                        <Skeleton animation="wave" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={offers.map((offer) => offer.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <TableBody>
                    {offers.map((offer) => (
                      <SortableRow
                        key={offer.id}
                        offer={offer}
                        onEdit={() => handleEditOffer(offer)}
                        onDelete={() => handleDeleteClick(offer)}
                      />
                    ))}
                  </TableBody>
                </SortableContext>
              </DndContext>
            )}
          </Table>
        </TableContainer>

        {!loading && offers.length === 0 && (
          <div className="text-center py-4 text-gray-500">
            Neboli nájdené žiadne ponuky
          </div>
        )}
      </Paper>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Potvrdiť vymazanie ponuky
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Naozaj chcete vymazať túto ponuku?
            {offerToDelete && (
              <Box mt={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  Produkt: {offerToDelete.product.product_name}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Obchod: {offerToDelete.shop.name}
                </Typography>
                {offerToDelete.discount && (
                  <Typography variant="subtitle2" color="textSecondary">
                    Zľava: {offerToDelete.discount}%
                  </Typography>
                )}
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Zrušiť
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
            autoFocus
          >
            Vymazať
          </Button>
        </DialogActions>
      </Dialog>

      <OfferModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        offer={editingOffer}
        type={selectedType}
        country={selectedCountry}
        onSuccess={handleRefresh}
      />
    </div>
  );
};

export default LandingOffersTable;
