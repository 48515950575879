import React, { useState, useMemo, useEffect } from "react";
import {
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Container,
  Typography,
  FormControlLabel,
  Checkbox,
  Modal,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import api from "../services/api";
import { useTranslation } from "react-i18next";
import CountrySelector from "./CountrySelector";
import validator from "validator";
import ReactMarkdown from "react-markdown";

// Rename the constant to avoid conflict
const defaultTermsContent = {
  sk: `# PODMIENKY ZAPOJENIA INTERNETOVÉHO OBCHODU DO BILIONBUY #

## 1. ZÁKLADNÉ USTANOVENIA
1.1. Bilionbuy je webová platforma poskytujúca prevádzkovateľom internetových obchodov možnosť propagácie predaja vlastných produktov a služieb za vopred dohodnutú odmenu za uskutočnenie predaja prostredníctvom webovej platformy Bilionbuy (ďalej aj "odmena").

1.2. Tieto podmienky upravujú pravidlá zapojenia sa prevádzkovateľa internetového obchodu so svojou ponukou produktov a služieb do trhoviska na webe Bilionbuy.

## 2. DEFINÍCIE POJMOV
2.1. Webová platforma - webová stránka www.bilionbuy.com.

2.2. Prevádzkovateľ trhoviska - prevádzkovateľom webovej platformy je spoločnosť Bilionbuy International s.r.o., IČO: 56190999, so sídlom Farbiarska 53/29, 064 01 Stará Ľubovňa.

2.3. Prevádzkovateľ internetového obchodu - podnikateľ, ktorý prevádzkuje internetový obchod a má záujem o registráciu na webovej platforme Bilionbuy.

2.4. Internetový obchod - webová platforma, v ktorej prevádzkovateľa internetového obchodu ponúka tovar alebo služby na predaj.

2.5. Zákazník - fyzická alebo právnická osoba, ktorá nakúpi v internetovom obchode.

2.6. Lehoty na odstúpenie od zmluvy zo strany zákazníka - je stanovená obchodnými podmienkami prevádzkovateľa internetového obchodu.

## 3. PREDMET SPOLUPRÁCE
3.1. Súhlasom s týmito podmienkami zo strany prevádzkovateľa internetového obchodu dochádza k uzatvoreniu zmluvy o spolupráci medzi zmluvnými stranami, prevádzkovateľom trhoviska a prevádzkovateľom internetového obchodu, predmetom ktorej je záväzok propagovať ponuku tovaru a služieb prevádzkovateľa internetového obchodu zo strany prevádzkovateľa trhoviska na webovej platforme Bilionbuy a záväzok prevádzkovateľa internetového obchodu zaplatiť odmenu za uskutočnenie predaja prostredníctvom webovej platformy Bilionbuy.

3.2. Právny úkon spočívajúci v súhlase s týmito podmienkami prebieha v internej zóne webovej platformy Bilionbuy, a to zašrktnutím súhlasu s podmienkami v sekcii Zóna pre partnera - Pridať internetový obchod.

## 4. ODMENA ZA USKUTOČNENIE PREDAJA PROSTREDNÍCTVOM WEBOVEJ PLATFORMY BILIONBUY
4.1. Prevádzkovateľ trhoviska a prevádzkovateľ internetového obchodu sa dohodli na odmene za uskutočnenie predaja prostredníctvom webovej platformy Bilionbuy v rozsahu, v akom odmeny uviedol prevádzkovateľ internetového obchodu pri pridaní internetového obchodu na webovej platforme Bilionbuy.

## 5. SPLATNOSŤ ODMENY
5.1. Prevádzkovateľ internetového obchodu je povinný zaplatiť odmenu prevádzkovateľovi trhoviska za uskutočnenie predaja prostredníctvom webovej platformy Bilionbuy na základe vystavenej faktúry, so splatnosťou do 5 dní. 

5.2. Nárok na zaplatenie odmeny podľa bodu 5.1. vzniká najskôr dňom nasledujúcim po uplynutí lehoty na odstúpenie od zmluvy zo strany zákazníka.

5.3. Prevádzkovateľ trhoviska je oprávnený vyfakturovať odmenu v jednej faktúre aj za viac uskutočnených predajov.

## 6. POZASTAVENIE PROPAGÁCIE PREVÁDZKOVATEĽA INTERNETOVÉHO OBCHODU
6.1. Prevádzkovateľ trhoviska je oprávnený pozastaviť propagáciu ponuky tovarov a služieb prevádzkovateľa internetového obchodu z nasledovných dôvodov:

a) ak je prevádzkovateľ internetového obchodu v omeškaní s úhradou odmeny,

b) ak prevádzkovateľ internetového obchodu porušuje práva zákazníka na ochranu spotrebiteľa,

c) ak porušuje tieto podmienky.

## 7. ZÁVEREČNÉ USTANOVENIA
7.1. Prevádzkovateľ trhoviska má právo jednostranne upraviť tieto Podmienky v primeranom rozsahu, hlavne za účelom doplnenia konkrétnejšieho znenia podmienok. Prevádzkovateľ internetového obchodu bude na zmenu Podmienok upozornený minimálne 14 dní pred tým, ako nové Podmienky nadobudnú účinnosť, a to prostredníctvom e-mailu. Člen komunity má možnosť zmeny Podmienok odmietnuť, a to tak, že požiada o zrušenie propagácie svojho internetového obchodu na webovej platforme Bilionbuy.

7.2. Práva a povinnosti neupravené týmito podmienkami sa riadia právnymi predpismi platnými v štáte, na ktorý sa prevádzkovanie platformy vzťahuje.

7.3. Tieto všeobecné podmienky nadobúdajú účinnosť dňa 06. 11. 2024.`,
  cz: `# PODMÍNKY ZAPOJENÍ INTERNETOVÉHO OBCHODU DO BILIONBUY #

## 1. ZÁKLADNÍ USTANOVENÍ
1.1 Bilionbuy je webová platforma, která poskytuje provozovatelům internetových obchodů možnost propagovat prodej vlastních výrobků a služeb za předem dohodnutou odměnu za uskutečnění prodeje prostřednictvím webové platformy Bilionbuy (dále také jen „odměna“).

1.2 Tyto podmínky upravují pravidla pro účast provozovatele internetového obchodu s jeho nabídkou produktů a služeb na trhu na webové platformě Bilionbuy.

## 2. DEFINICE POJMŮ
2.1 Webová platforma - internetové stránky www.bilionbuy.com.

2.2 Provozovatel tržiště - provozovatel webové platformy je společnost Bilionbuy International s.r.o., IČO: 56190999, se sídlem Farbiarska 53/29, 064 01 Stará Ľubovňa.

2.3 Provozovatel internetového obchodu - podnikatel, který provozuje internetový obchod a má zájem o registraci na webové platformě Bilionbuy.

2.4 Internetový obchod - webová platforma, na které provozovatel internetového obchodu nabízí zboží nebo služby k prodeji.

2.5 Zákazník - fyzická nebo právnická osoba, která nakupuje v internetovém obchodě.

2.6. Lhůta pro odstoupení od smlouvy ze strany zákazníka - je stanovena obchodními podmínkami provozovatele internetového obchodu.

## 3. PŘEDMĚT SPOLUPRÁCE
3.1. Souhlasem s těmito obchodními podmínkami uzavírá provozovatel internetového obchodu smlouvu o spolupráci mezi smluvními stranami, provozovatelem tržiště a provozovatelem internetového obchodu, jejímž předmětem je závazek propagovat nabídku zboží a služeb provozovatele internetového obchodu na webové platformě Bilionbuy a závazek provozovatele internetového obchodu zaplatit poplatek za uskutečnění prodeje prostřednictvím webové platformy Bilionbuy.

3.2 Právní úkon souhlasu s těmito obchodními podmínkami probíhá ve vnitřní zóně webové platformy Bilionbuy zaškrtnutím souhlasu s obchodními podmínkami v sekci Partnerská zóna - Přidat internetový obchod.

## 4. ODMĚNA ZA USKUTEČNĚNÍ PRODEJE PROSTŘEDNICTVÍM WEBOVÉ PLATFORMY BILIONBUY
4.1 Provozovatel tržiště a Provozovatel internetového obchodu se dohodli na odměně za uskutečnění prodeje prostřednictvím webové platformy Bilionbuy v rozsahu, v jakém je odměna stanovena Provozovatelem internetového obchodu při přidání internetového obchodu na webovou platformu Bilionbuy.

## 5. VÝPLATA ODMĚNY
5.1 Provozovatel internetového obchodu je povinen uhradit Provozovateli tržiště odměnu za uskutečnění prodeje prostřednictvím webové platformy Bilionbuy na základě vystavené faktury se splatností 5 dnů. 

5.2 Nárok na úhradu odměny dle bodu 5.1 vzniká nejdříve dnem následujícím po uplynutí lhůty pro odstoupení od smlouvy ze strany zákazníka.

5.3 Provozovatel tržiště je oprávněn vyúčtovat odměnu jednou fakturou i při více prodejích.

## 6. POZASTAVENÍ PROPAGACE PROVOZOVATELE INTERNETOVÉHO OBCHODU
6.1 Provozovatel tržiště je oprávněn pozastavit propagaci nabídky zboží a služeb provozovatele internetového obchodu z následujících důvodů:

(a) pokud je provozovatel internetového obchodu v prodlení s úhradou odměny,

b) pokud provozovatel internetového obchodu porušuje práva zákazníka na ochranu spotřebitele,

c) pokud poruší tyto obchodní podmínky.

## 7. ZÁVĚREČNÁ USTANOVENÍ
7.1 Provozovatel internetového tržiště má právo jednostranně měnit tyto obchodní podmínky v přiměřeném rozsahu, zejména doplnit obchodní podmínky o konkrétnější znění. O změně obchodních podmínek bude Provozovatel tržiště informován nejméně 14 dní před nabytím účinnosti nových obchodních podmínek, a to e-mailem. Člen Společenství má možnost změny Podmínek odmítnout tím, že požádá o zrušení propagace svého internetového obchodu na webové platformě Bilionbuy.

7.2 Práva a povinnosti neupravené těmito Podmínkami se řídí zákony platnými v zemi, na které je platforma provozována.

7.3 Tyto všeobecné obchodní podmínky vstupují v platnost dne 6. 11. 2024.`,
  pl: `# WARUNKI WSPÓŁPRACY SKLEPU INTERNETOWEGO Z BILIONBUY #

## 1. POSTANOWIENIA PODSTAWOWE
1.1 Bilionbuy to platforma internetowa zapewniająca operatorom sklepów internetowych możliwość promowania sprzedaży własnych produktów i usług w zamian za wcześniej ustalone wynagrodzenie za dokonanie sprzedaży za pośrednictwem platformy internetowej Bilionbuy (zwane dalej również „Wynagrodzeniem”).

1.2 Niniejsze warunki regulują zasady uczestnictwa operatora sklepu internetowego z jego ofertą produktów i usług na rynku na stronie internetowej Bilionbuy.

## 2. DEFINICJE TERMINÓW
2.1 Platforma internetowa - strona internetowa www.bilionbuy.com.

2.2 Operator Marketplace - operatorem platformy internetowej jest spółka Bilionbuy International s.r.o., nr identyfikacyjny: 56190999, z siedzibą pod adresem Farbiarska 53/29, 064 01 Stará Ľubovňa.

2.3 Operator sklepu internetowego - przedsiębiorca, który prowadzi sklep internetowy i jest zainteresowany rejestracją na platformie internetowej Bilionbuy.

2.4 Sklep internetowy - platforma internetowa, na której operator sklepu internetowego oferuje towary lub usługi na sprzedaż.

2.5 Klient - osoba fizyczna lub prawna, która dokonuje zakupów w sklepie internetowym.

2.6 Okres odstąpienia od umowy przez klienta - jest określony w regulaminie operatora sklepu internetowego.

## 3. PRZEDMIOT WSPÓŁPRACY
3.1 Wyrażając zgodę na niniejsze warunki, operator sklepu internetowego zawiera umowę o współpracy między stronami, operatorem marketplace i operatorem sklepu internetowego, której przedmiotem jest obowiązek promowania oferty towarów i usług operatora sklepu internetowego na platformie internetowej Bilionbuy oraz obowiązek uiszczenia przez operatora sklepu internetowego opłaty za dokonanie sprzedaży za pośrednictwem platformy internetowej Bilionbuy.

3.2 Czynność prawna polegająca na wyrażeniu zgody na niniejsze warunki odbywa się w strefie wewnętrznej platformy internetowej Bilionbuy poprzez zaznaczenie zgody na warunki w sekcji Strefa partnera - Dodaj sklep internetowy.

## 4. WYNAGRODZENIE ZA DOKONANIE SPRZEDAŻY ZA POŚREDNICTWEM PLATFORMY INTERNETOWEJ BILIONBUY
4.1 Operator Marketplace i Operator Sklepu Internetowego ustalają wynagrodzenie za dokonanie sprzedaży za pośrednictwem Platformy Internetowej Bilionbuy w zakresie określonym przez Operatora Sklepu Internetowego podczas dodawania Sklepu Internetowego do Platformy Internetowej Bilionbuy.

## 5. ZAPŁATA WYNAGRODZENIA
5.1 Operator Sklepu Internetowego zobowiązany jest do zapłaty wynagrodzenia na rzecz Operatora Marketplace z tytułu dokonania sprzedaży za pośrednictwem Platformy Bilionbuy na podstawie wystawionej faktury z terminem płatności do 5 dni. 

5.2 Prawo do zapłaty wynagrodzenia zgodnie z punktem 5.1 powstaje najwcześniej następnego dnia po upływie terminu do odstąpienia od umowy przez Klienta.

5.3 Operator platformy handlowej jest uprawniony do zafakturowania wynagrodzenia na jednej fakturze również w przypadku sprzedaży wielokrotnej.

## 6. ZAWIESZENIE PROMOCJI OPERATORA SKLEPU INTERNETOWEGO
6.1 Operator marketplace jest uprawniony do zawieszenia promocji oferty towarów i usług operatora sklepu internetowego z następujących powodów:

(a) jeżeli operator sklepu internetowego zalega z zapłatą wynagrodzenia,

b) jeżeli operator sklepu internetowego narusza prawa klienta w zakresie ochrony konsumentów,

c) jeżeli narusza niniejszy regulamin.

## 7. POSTANOWIENIA KOŃCOWE
7.1 Operator Marketplace ma prawo do jednostronnej zmiany niniejszego Regulaminu w odpowiednim zakresie, w szczególności do dodania bardziej szczegółowych sformułowań do Regulaminu. Operator Marketplace zostanie powiadomiony o zmianie Regulaminu co najmniej 14 dni przed wejściem w życie nowego Regulaminu za pośrednictwem poczty elektronicznej. Członek społeczności ma możliwość odrzucenia zmian w Regulaminie, żądając anulowania promocji swojego sklepu internetowego na platformie internetowej Bilionbuy.

7.2 Prawa i obowiązki nieuregulowane niniejszymi Warunkami podlegają prawu obowiązującemu w kraju, w którym działa Platforma.

7.3 Niniejsze ogólne warunki wchodzą w życie z dniem 06/11/2024.`,
  hu: `# AZ ONLINE ÁRUHÁZ ELKÖTELEZETTSÉGÉNEK FELTÉTELEI A BILIONBUY SZÁMÁRA #

## 1. ALAPVETŐ RENDELKEZÉSEK
1.1. A Bilionbuy egy webes platform, amely lehetőséget biztosít az online áruházak üzemeltetőinek, hogy saját termékeik és szolgáltatásaik értékesítését népszerűsítsék, a Bilionbuy webes platformon keresztül történő értékesítésért előre megállapodott díjazásért cserébe (a továbbiakban a „Díjazás”).

1.2. A jelen feltételek szabályozzák az online áruház üzemeltetőjének a Bilionbuy weboldalon található piactéren való termék- és szolgáltatáskínálatával való részvételének szabályait.

## 2. FOGALMAK MEGHATÁROZÁSA
2.1 Webplatform - a www.bilionbuy.com weboldal.

2.2 Piactér üzemeltetője - a webes platform üzemeltetője a Bilionbuy International s.r.o. társaság, azonosítószám: 56190999, székhelye: Farbiarska 53/29, 064 01 Stará Ľubovňa.

2.3. Internetes üzlet üzemeltetője - olyan vállalkozó, aki online üzletet üzemeltet és a Bilionbuy webes platformon való regisztrációban érdekelt.

2.4. Online bolt - olyan webes platform, amelyen az online bolt üzemeltetője árukat vagy szolgáltatásokat kínál eladásra.

2.5 Vevő - az a természetes vagy jogi személy, aki az online áruházban vásárol.

2.6. Vevő elállási ideje - a webáruház üzemeltetőjének általános szerződési feltételei határozzák meg.

## 3. AZ EGYÜTTMŰKÖDÉS TÁRGYA
3.1. A jelen feltételek elfogadásával a webáruház üzemeltetője együttműködési megállapodást köt a felek, a piactér üzemeltetője és a webáruház üzemeltetője között, amelynek tárgya az online áruház üzemeltetőjének a Bilionbuy webes platformon történő áru- és szolgáltatáskínálatának népszerűsítésére vonatkozó kötelezettség, valamint a webáruház üzemeltetőjének a Bilionbuy webes platformon keresztül történő értékesítésért fizetendő díjfizetési kötelezettsége.

3.2 A jelen feltételek elfogadásának jogi aktusa a Bilionbuy webes platform belső zónájában történik a feltételek elfogadásának ellenőrzésével a Partner zóna - Online üzlet hozzáadása részben.

## 4. JUTALOM A BILIONBUY WEBES PLATFORMON KERESZTÜL TÖRTÉNŐ ÉRTÉKESÍTÉSÉRT
4.1. A Piactér-üzemeltető és a webáruház-üzemeltető a Bilionbuy webes platformon keresztül történő értékesítésért járó díjazásról abban a mértékben állapodik meg, amilyen mértékben a díjazást a webáruház-üzemeltető a webáruház Bilionbuy webes platformhoz való hozzáadásakor meghatározza.

## 5. A DÍJAZÁS KIFIZETÉSE
5.1. Az Online Shop Üzemeltető a Bilionbuy Web Platformon keresztül történő értékesítés lebonyolításáért járó díjazást a Piactér Üzemeltető részére kiállított, 5 napon belül esedékes számla alapján köteles megfizetni. 

5.2 Az 5.1. pont szerinti díjazás megfizetésére való jogosultság legkorábban a vásárló elállási idejének lejártát követő napon keletkezik.

5.3 A piactér üzemeltetője jogosult a díjazást egy számlában kiszámlázni több értékesítés esetén is.

## 6. A WEBÁRUHÁZ ÜZEMELTETŐJÉNEK PROMÓCIÓJÁNAK FELFÜGGESZTÉSE
6.1. A piactér üzemeltetője jogosult a webáruház üzemeltetőjének áru és szolgáltatás kínálatának promócióját az alábbi okok miatt felfüggeszteni:

(a) ha az online áruház üzemeltetője a díjazás megfizetésével késedelembe esik,

b) ha az online áruház üzemeltetője megsérti a vásárló fogyasztóvédelmi jogait,

c) ha megsérti a jelen általános szerződési feltételeket.

## 7. ZÁRÓ RENDELKEZÉSEK
7.1. A piactér üzemeltetőjének jogában áll a jelen Általános Szerződési Feltételeket egyoldalúan, a szükséges mértékben módosítani, különösen a Feltételek pontosabb megfogalmazásával kiegészíteni. A Piactér Üzemeltetőjét a Feltételek módosításáról az új Feltételek hatályba lépése előtt legalább 14 nappal e-mailben értesíteni kell. A közösségi tagnak lehetősége van arra, hogy elutasítsa a Feltételek módosításait, kérve a Bilionbuy webes platformon lévő webáruháza promóciójának törlését.

7.2 A jelen Feltételekben nem szabályozott jogokra és kötelezettségekre a Platform működése szerinti ország hatályos jogszabályai az irányadók.

7.3 A jelen általános szerződési feltételek 2024.11.06-án lépnek hatályba.`,
  en: `# TERMS OF ENGAGEMENT OF THE ONLINE STORE TO BILIONBUY #

## 1. BASIC PROVISIONS
1.1 Bilionbuy is a web platform providing online shop operators with the opportunity to promote the sale of their own products and services in return for a pre-agreed remuneration for making a sale via the Bilionbuy web platform (hereinafter also referred to as the "Remuneration").

1.2 These terms and conditions govern the rules for the participation of the online shop operator with its product and service offerings in the marketplace on the Bilionbuy website.

## 2. DEFINITIONS OF TERMS
2.1 Web Platform - the website www.bilionbuy.com.

2.2 Marketplace Operator - the operator of the web platform is the company Bilionbuy International s.r.o., ID No.: 56190999, with registered office at Farbiarska 53/29, 064 01 Stará Ľubovňa.

2.3 Internet shop operator - an entrepreneur who operates an online shop and is interested in registering on the Bilionbuy web platform.

2.4 Online shop - a web platform in which the online shop operator offers goods or services for sale.

2.5 Customer - a natural or legal person who purchases from the online shop.

2.6. Customer's withdrawal period - is determined by the terms and conditions of the online shop operator.

## 3. SUBJECT OF COOPERATION
3.1 By agreeing to these terms and conditions, the online shop operator concludes a cooperation agreement between the parties, the marketplace operator and the online shop operator, the subject matter of which is the obligation to promote the online shop operator's offer of goods and services on the Bilionbuy web platform and the obligation of the online shop operator to pay a fee for making a sale via the Bilionbuy web platform.

3.2 The legal act of agreeing to these terms and conditions takes place in the internal zone of the Bilionbuy web platform by checking the agreement to the terms and conditions in the Partner Zone - Add Online Shop section.

## 4. REWARD FOR MAKING A SALE THROUGH THE BILIONBUY WEB PLATFORM
4.1 The Marketplace Operator and the Online Shop Operator agree on the remuneration for making a sale through the Bilionbuy Web Platform to the extent that the remuneration is specified by the Online Shop Operator when adding the Online Shop to the Bilionbuy Web Platform.

## 5. PAYMENT OF REMUNERATION
5.1 The Online Shop Operator is obliged to pay the remuneration to the Marketplace Operator for making a sale via the Bilionbuy Web Platform on the basis of an invoice issued, due within 5 days. 

5.2 The right to payment of the remuneration according to clause 5.1 shall arise at the earliest on the day following the expiry of the withdrawal period of the customer.

5.3 The marketplace operator is entitled to invoice the remuneration in one invoice also for multiple sales.

## 6. SUSPENSION OF THE PROMOTION OF THE ONLINE SHOP OPERATOR
6.1 The marketplace operator is entitled to suspend the promotion of the offer of goods and services of the online shop operator for the following reasons:

(a) if the online shop operator is in arrears with the payment of remuneration,

b) if the online shop operator violates the customer's consumer protection rights,

c) if it breaches these terms and conditions.

## 7. FINAL PROVISIONS
7.1 The Marketplace Operator has the right to unilaterally modify these Terms and Conditions to the extent appropriate, in particular to add more specific wording to the Terms and Conditions. The Marketplace Operator shall be notified of the change to the Terms and Conditions at least 14 days before the new Terms and Conditions come into force, by e-mail. The Community Member has the possibility to reject the changes to the Terms by requesting the cancellation of the promotion of his/her online shop on the Bilionbuy web platform.

7.2 The rights and obligations not governed by these Terms shall be governed by the laws in force in the country to which the Platform is operating.

7.3 These general terms and conditions come into force on 06/11/2024.`,
  de: `# ALLGEMEINE GESCHÄFTSBEDINGUNGEN DES ONLINESHOPS ZU BILIONBUY

## 1. GRUNDLEGENDE BESTIMMUNGEN
1.1 Bilionbuy ist eine Webplattform, die Online-Shop-Betreibern die Möglichkeit bietet, den Verkauf eigener Produkte und Dienstleistungen gegen eine vorher vereinbarte Vergütung für den Verkauf über die Bilionbuy-Webplattform zu fördern (nachfolgend auch „Vergütung“ genannt).

1.2 Diese Geschäftsbedingungen regeln die Teilnahme des Online-Shop-Betreibers mit seinem Produkt- und Dienstleistungsangebot am Marktplatz auf der Bilionbuy-Website.

## 2. BEGRIFFSDEFINITIONEN
2.1 Webplattform - die Website www.bilionbuy.com.

2.2 Marktplatzbetreiber - der Betreiber der Webplattform ist die Gesellschaft Bilionbuy International s.r.o., ID-Nr.: 56190999, mit Sitz in Farbiarska 53/29, 064 01 Stará Ľubovňa.

2.3 Internet-Shop-Betreiber - ein Unternehmer, der einen Online-Shop betreibt und an einer Registrierung auf der Bilionbuy-Webplattform interessiert ist.

2.4 Onlineshop - eine Webplattform, auf der der Onlineshop-Betreiber Waren oder Dienstleistungen zum Verkauf anbietet.

2.5 Kunde - eine natürliche oder juristische Person, die über den Online-Shop einkauft.

2.6 Widerrufsfrist des Kunden - wird durch die Geschäftsbedingungen des Betreibers des Online-Shops bestimmt.

## 3. GEGENSTAND DER ZUSAMMENARBEIT
3.1 Mit der Zustimmung zu diesen Geschäftsbedingungen kommt zwischen den Parteien, dem Marktplatzbetreiber und dem Onlineshop-Betreiber, ein Kooperationsvertrag zustande, dessen Gegenstand die Verpflichtung zur Bewerbung des Waren- und Dienstleistungsangebots des Onlineshop-Betreibers auf der Bilionbuy-Webplattform und die Verpflichtung des Onlineshop-Betreibers zur Zahlung eines Entgelts für die Vermittlung eines Verkaufs über die Bilionbuy-Webplattform ist.

3.2 Die Rechtshandlung der Zustimmung zu diesen AGB erfolgt im internen Bereich der Bilionbuy-Webplattform durch Ankreuzen der Zustimmung zu den AGB im Bereich Partnerzone - Onlineshop hinzufügen.

## 4. VERGÜTUNG FÜR DEN VERKAUF ÜBER DIE BILIONBUY-WEBPLATTFORM
4.1 Der Marktplatz-Betreiber und der Online-Shop-Betreiber vereinbaren die Vergütung für die Vermittlung eines Verkaufs über die Bilionbuy-Webplattform in dem Umfang, in dem die Vergütung vom Online-Shop-Betreiber beim Einstellen des Online-Shops auf der Bilionbuy-Webplattform angegeben wird.

## 5. AUSZAHLUNG DER VERGÜTUNG
5.1 Der Onlineshop-Betreiber ist verpflichtet, die Vergütung für die Vermittlung eines Verkaufs über die Bilionbuy-Webplattform an den Marktplatz-Betreiber auf der Grundlage einer ausgestellten Rechnung zu zahlen, die innerhalb von 5 Tagen fällig ist. 

5.2 Der Anspruch auf Zahlung der Vergütung nach Ziffer 5.1 entsteht frühestens an dem Tag, der auf den Ablauf der Widerrufsfrist des Kunden folgt.

5.3 Der Marktplatzbetreiber ist berechtigt, die Vergütung auch bei Mehrfachverkäufen in einer Rechnung abzurechnen.

## 6. EINSTELLUNG DER WERBUNG DES ONLINE-SHOPBETREIBERS
6.1 Der Marktplatzbetreiber ist berechtigt, die Bewerbung des Angebots an Waren und Dienstleistungen des Online-Shop-Betreibers aus folgenden Gründen auszusetzen:

(a) wenn der Online-Shop-Betreiber mit der Zahlung der Vergütung im Rückstand ist,

b) wenn der Online-Shop-Betreiber gegen Verbraucherschutzrechte des Kunden verstößt,

c) wenn er gegen diese Allgemeinen Geschäftsbedingungen verstößt.

## 7. SCHLUSSBESTIMMUNGEN
7.1 Der Marktplatzbetreiber hat das Recht, diese AGB einseitig zu ändern, soweit dies angemessen ist, insbesondere die AGB zu konkretisieren. Die Änderung der AGB wird dem Marktplatzbetreiber mindestens 14 Tage vor Inkrafttreten der neuen AGB per E-Mail mitgeteilt. Ein Community-Mitglied hat die Möglichkeit, den Änderungen der Bedingungen zu widersprechen, indem es die Löschung der Bewerbung seines Online-Shops auf der Bilionbuy-Webplattform beantragt.

7.2 Die Rechte und Pflichten, die nicht in diesen Bedingungen geregelt sind, unterliegen den geltenden Gesetzen des Landes, in dem die Plattform betrieben wird.

7.3 Diese Allgemeinen Geschäftsbedingungen treten am 06.11.2024 in Kraft.`,
};

// Update the mapping function to handle different response structures
const mapApiResponseToFormData = (apiResponse) => {
  // Handle case when apiResponse is an array
  const shopData = Array.isArray(apiResponse) ? apiResponse[0] : apiResponse;

  if (!shopData) return null;

  // Handle different possible response structures
  const shop = shopData.shop || shopData;

  return {
    name: shop.name || shopData.shop_name || "",
    country: shop.country || "",
    web_url: shop.web_url || "",
    logo_url: shop.logo_url || "",
    feed_url: shop.feed_url || "",
    description: shop.description || shopData.shop_description || "",
    contact_email: shopData.contact_email || "",
    contact_phone: shopData.contact_phone || "",
    contact_name: shopData.contact_name || "",
    company_name: shopData.company_name || "",
    company_id: shopData.company_id || "",
    tax_id: shopData.tax_id || "",
    vat_id: shopData.vat_id || "",
    company_address: shopData.company_address || "",
    preferred_currency: shopData.preferred_currency || "",
    terms_accepted: shopData.terms_accepted || false,
    default_cashback: shop.default_cashback || "",
    additional_countries: shop.additional_countries || [],
    additional_commision_groups: shop.additional_commision_groups || [],
    id: shopData.id || shop.id || "",
  };
};

// Add this new validation function near the top with other utility functions
const isValidCommissionValue = (value) => {
  // Allow empty string for new inputs
  if (value === "") return true;
  // Check if it's a valid number (including decimals)
  return /^\d*\.?\d*$/.test(value);
};

const ShopPartnerRegister = ({
  onSuccess,
  isOpen,
  onClose,
  initialData = null,
  mode = "create",
}) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Form state
  const [formData, setFormData] = useState({
    name: "",
    country: "",
    web_url: "",
    logo_url: "",
    feed_url: "",
    description: "",
    contact_email: "",
    contact_phone: "",
    contact_name: "",
    company_name: "",
    company_id: "",
    tax_id: "",
    vat_id: "",
    company_address: "",
    preferred_currency: "",
    terms_accepted: false,
  });

  const [selectedCountries, setSelectedCountries] = useState([]);

  // Add these new state variables after the existing formData state
  const [additionalCommissionGroups, setAdditionalCommissionGroups] = useState(
    []
  );

  // Add these new state variables
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [canAcceptTerms, setCanAcceptTerms] = useState(false);
  const [termsContent, setTermsContent] = useState(
    defaultTermsContent[i18n.language] || defaultTermsContent.en
  );

  // Validation states
  const emailIsInvalid = useMemo(() => {
    if (!formData.contact_email || formData.contact_email.trim() === "")
      return false;
    return !validator.isEmail(formData.contact_email);
  }, [formData.contact_email]);

  const urlIsInvalid = useMemo(() => {
    if (!formData.web_url || formData.web_url.trim() === "") return false;
    return !validator.isURL(formData.web_url);
  }, [formData.web_url]);

  const logoUrlIsInvalid = useMemo(() => {
    if (!formData.logo_url || formData.logo_url.trim() === "") return false;
    return !validator.isURL(formData.logo_url);
  }, [formData.logo_url]);

  const feedUrlIsInvalid = useMemo(() => {
    if (!formData.feed_url || formData.feed_url.trim() === "") return false;
    return !validator.isURL(formData.feed_url);
  }, [formData.feed_url]);

  const phoneIsInvalid = useMemo(() => {
    if (!formData.contact_phone || formData.contact_phone.trim() === "")
      return false;
    return !validator.isMobilePhone(formData.contact_phone);
  }, [formData.contact_phone]);

  // Animation variants
  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 20,
      maxHeight: 0,
      overflow: "hidden",
      transition: {
        maxHeight: { duration: 0.5, ease: "easeInOut" },
        opacity: { delay: 0.5 },
        y: { delay: 0.5 },
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      maxHeight: 200,
      transition: {
        maxHeight: { duration: 0.5, ease: "easeInOut" },
        opacity: { duration: 0.5, delay: 0.5 },
        y: { duration: 0.5, delay: 0.5 },
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        opacity: { duration: 0.3 },
        y: { duration: 0.3 },
        maxHeight: { duration: 0.5, delay: 0.3, ease: "easeInOut" },
      },
    },
  };

  // Update the initialData useEffect with safety checks
  useEffect(() => {
    if (initialData) {
      const mappedData = mapApiResponseToFormData(initialData);
      if (mappedData) {
        setFormData(mappedData);
        setSelectedCountries(mappedData.additional_countries || []);
        setAdditionalCommissionGroups(
          mappedData.additional_commision_groups || []
        );
      }
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isFormValid = () => {
    return (
      formData.name &&
      formData.country &&
      formData.web_url &&
      formData.contact_email &&
      formData.contact_phone &&
      formData.contact_name &&
      formData.company_name &&
      formData.company_address &&
      formData.terms_accepted &&
      !emailIsInvalid &&
      !urlIsInvalid &&
      !phoneIsInvalid
      // (!formData.logo_url || !logoUrlIsInvalid) &&
      // (!formData.feed_url || !feedUrlIsInvalid)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setSuccess("");

    try {
      const endpoint =
        mode === "create"
          ? "create-partner-shop/"
          : `update-partner-shop/${formData.id}/`;

      const requestData = {
        ...formData,
        additional_countries: selectedCountries,
        additional_commision_groups: additionalCommissionGroups,
      };

      const response = await api[mode === "create" ? "post" : "put"](
        endpoint,
        requestData
      );

      if (response.data.success) {
        setSuccess(
          t(
            mode === "create"
              ? "Partner shop was successfully registered"
              : "Partner shop was successfully updated"
          )
        );
        if (onSuccess) onSuccess();
        if (onClose) onClose();
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          t("An error occurred while processing the shop")
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Add these new handlers after existing handlers
  const handleAddCommissionGroup = () => {
    setAdditionalCommissionGroups([
      ...additionalCommissionGroups,
      { name: "", value: "", type: "Kategória" },
    ]);
  };

  const handleRemoveCommissionGroup = (index) => {
    setAdditionalCommissionGroups(
      additionalCommissionGroups.filter((_, i) => i !== index)
    );
  };

  // Update the handleCommissionGroupChange function
  const handleCommissionGroupChange = (index, field, newValue) => {
    const updatedGroups = [...additionalCommissionGroups];

    if (field === "value") {
      // Replace comma with decimal point first
      const valueWithPoint = newValue.replace(",", ".");
      // Only update if it's a valid number or empty string
      if (isValidCommissionValue(valueWithPoint)) {
        // Remove any non-numeric characters except decimal point
        const cleanValue = valueWithPoint.replace(/[^\d.]/g, "");
        // Ensure only one decimal point
        const parts = cleanValue.split(".");
        const finalValue = parts[0] + (parts.length > 1 ? "." + parts[1] : "");
        updatedGroups[index][field] = finalValue;
        setAdditionalCommissionGroups(updatedGroups);
      }
    } else {
      updatedGroups[index][field] = newValue;
      setAdditionalCommissionGroups(updatedGroups);
    }
  };

  // Add a useEffect to update terms content when language changes
  useEffect(() => {
    setTermsContent(
      defaultTermsContent[i18n.language] || defaultTermsContent.en
    );
  }, [i18n.language]); // Add i18n.language as a dependency

  // Add this scroll handler
  const handleTermsScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    // Check if user has scrolled to bottom (with a small threshold)
    const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 20;
    if (isBottom) {
      setCanAcceptTerms(true);
    }
  };

  // Replace the existing FormControlLabel with this
  const termsCheckboxSection = (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name="terms_accepted"
            checked={formData.terms_accepted}
            onChange={(e) =>
              handleInputChange({
                target: {
                  name: "terms_accepted",
                  value: e.target.checked,
                },
              })
            }
            required
          />
        }
        label={
          <span
            onClick={() => {
              setTermsModalOpen(true);
              setCanAcceptTerms(false); // Reset the scroll state when opening modal
            }}
            style={{ cursor: "pointer" }}
          >
            {t("I've read terms and conditions and I accept them")}
          </span>
        }
        sx={{ cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault(); // Prevent checkbox from changing
          setTermsModalOpen(true);
          setCanAcceptTerms(false); // Reset the scroll state when opening modal
        }}
      />

      <Modal
        open={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
        aria-labelledby="terms-modal"
      >
        <Container
          maxWidth="md"
          sx={{
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "80vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <Typography variant="h5" component="h2" gutterBottom>
            {t("Podmienky zapojenia")}
          </Typography> */}

          <div
            onScroll={handleTermsScroll}
            style={{
              overflowY: "auto",
              flex: 1,
              marginBottom: "1rem",
              padding: "1rem",
              border: "1px solid #ddd",
              borderRadius: "4px",
            }}
            className="quill-content"
          >
            <ReactMarkdown>{termsContent}</ReactMarkdown>
          </div>

          <Button
            variant="contained"
            fullWidth
            disabled={!canAcceptTerms}
            onClick={() => {
              handleInputChange({
                target: {
                  name: "terms_accepted",
                  value: true,
                },
              });
              setTermsModalOpen(false);
            }}
          >
            {canAcceptTerms
              ? t("Accept Terms")
              : t("Please scroll to the bottom to accept")}
          </Button>
        </Container>
      </Modal>
    </>
  );

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="shop-form-modal">
      <Container
        maxWidth="md"
        sx={{
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          {mode === "create"
            ? t("Register Your Partner Shop")
            : t("Update Partner Shop")}
        </Typography>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Shop Basic Info */}
          <TextField
            fullWidth
            label={t("Shop Name")}
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            margin="normal"
          />
          <TextField
            fullWidth
            label={t("Company Name")}
            name="company_name"
            value={formData.company_name}
            onChange={handleInputChange}
            required
            margin="normal"
          />

          <TextField
            fullWidth
            multiline
            rows={2}
            label={t("Company Address")}
            name="company_address"
            value={formData.company_address}
            onChange={handleInputChange}
            required
            margin="normal"
          />

          <TextField
            fullWidth
            label={t("Company ID")}
            name="company_id"
            value={formData.company_id}
            onChange={handleInputChange}
            margin="normal"
          />

          <TextField
            fullWidth
            label={t("Tax ID")}
            name="tax_id"
            value={formData.tax_id}
            onChange={handleInputChange}
            margin="normal"
          />

          <TextField
            fullWidth
            label={t("VAT ID")}
            name="vat_id"
            value={formData.vat_id}
            onChange={handleInputChange}
            margin="normal"
          />

          <TextField
            select
            fullWidth
            label={t("Preferred Currency")}
            name="preferred_currency"
            value={formData.preferred_currency}
            onChange={handleInputChange}
            margin="normal"
          >
            <MenuItem value="">Select currency</MenuItem>
            <MenuItem value="EUR">EUR</MenuItem>
            <MenuItem value="CZK">CZK</MenuItem>
            <MenuItem value="PLN">PLN</MenuItem>
            <MenuItem value="HUF">HUF</MenuItem>
          </TextField>

          <TextField
            fullWidth
            label={t("Contact Name")}
            name="contact_name"
            value={formData.contact_name}
            onChange={handleInputChange}
            required
            margin="normal"
          />

          <TextField
            fullWidth
            label={t("Contact Email")}
            name="contact_email"
            value={formData.contact_email}
            onChange={handleInputChange}
            required
            error={emailIsInvalid}
            helperText={emailIsInvalid && t("Please enter a valid email")}
            margin="normal"
          />

          <TextField
            fullWidth
            label={t("Contact Phone")}
            name="contact_phone"
            value={formData.contact_phone}
            onChange={handleInputChange}
            required
            error={phoneIsInvalid}
            helperText={
              phoneIsInvalid && t("Please enter a valid phone number")
            }
            margin="normal"
          />

          <TextField
            select
            fullWidth
            label={t("Primary Country")}
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            required
            margin="normal"
          >
            <MenuItem value="">Select country</MenuItem>
            <MenuItem value="cz">
              <span style={{ marginRight: "8px" }}>🇨🇿</span>
              Czech Republic
            </MenuItem>
            <MenuItem value="sk">
              <span style={{ marginRight: "8px" }}>🇸🇰</span>
              Slovakia
            </MenuItem>
            <MenuItem value="pl">
              <span style={{ marginRight: "8px" }}>🇵🇱</span>
              Poland
            </MenuItem>
            <MenuItem value="hu">
              <span style={{ marginRight: "8px" }}>🇭🇺</span>
              Hungary
            </MenuItem>
          </TextField>

          <div>
            <label className="block text-sm font-medium mb-2">
              {t("Additional Countries")}:
            </label>
            <CountrySelector
              selectedCountries={selectedCountries}
              setSelectedCountries={setSelectedCountries}
            />
          </div>

          <TextField
            fullWidth
            label={t("Website URL")}
            name="web_url"
            value={formData.web_url}
            onChange={handleInputChange}
            required
            error={urlIsInvalid}
            helperText={urlIsInvalid && t("Please enter a valid URL")}
            margin="normal"
          />

          <TextField
            fullWidth
            label={t("Logo URL")}
            name="logo_url"
            value={formData.logo_url}
            onChange={handleInputChange}
            error={logoUrlIsInvalid}
            helperText={logoUrlIsInvalid && t("Please enter a valid URL")}
            margin="normal"
          />

          <TextField
            fullWidth
            label={t("Product Feed URL")}
            name="feed_url"
            value={formData.feed_url}
            onChange={handleInputChange}
            error={feedUrlIsInvalid}
            helperText={feedUrlIsInvalid && t("Please enter a valid URL")}
            margin="normal"
          />

          {/* Company Information */}

          {/* <div>
            <label className="block text-sm font-medium mb-2">
              {t("Additional Countries")}:
            </label>
            <CountrySelector
              selectedCountries={selectedCountries}
              setSelectedCountries={setSelectedCountries}
            />
          </div> */}

          <TextField
            fullWidth
            multiline
            rows={4}
            label={t("Description")}
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            margin="normal"
          />

          {/* Commission Groups Section */}

          <div className="space-y-4">
            <Typography variant="subtitle1" gutterBottom>
              {t("Commission Groups")}
            </Typography>
            <div className="bg-gray-50 p-4 rounded-md mb-4 text-sm">
              {t(
                "Commission groups allow you to set different cashback rates for specific categories or brands in your shop. The 'Other' group serves as the default rate for all products that don't match any specific group. For example, you can set:"
              )}
              <ul className="list-disc ml-6 mt-2">
                <li>
                  {t(
                    "Different rates for specific product categories (e.g., Electronics 2%, Fashion 5%)"
                  )}
                </li>
                <li>
                  {t(
                    "Special rates for certain brands (e.g., Samsung 3%, Apple 4%)"
                  )}
                </li>
                <li>
                  {t(
                    "A default rate for all other products not covered by specific groups"
                  )}
                </li>
              </ul>
            </div>
            {/* Default Cashback (Ostatné) */}
            <div className="space-y-2">
              <div className="flex items-center gap-4">
                <TextField
                  label={t("Group")}
                  value={t("Ostatné")}
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                />
                <TextField
                  label={t("Value")}
                  value={formData.default_cashback || ""}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(",", ".");
                    if (isValidCommissionValue(newValue)) {
                      const cleanValue = newValue.replace(/[^\d.]/g, "");
                      const parts = cleanValue.split(".");
                      const finalValue =
                        parts[0] + (parts.length > 1 ? "." + parts[1] : "");
                      handleInputChange({
                        target: {
                          name: "default_cashback",
                          value: finalValue,
                        },
                      });
                    }
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>%</span>
                    ),
                  }}
                  inputProps={{
                    inputMode: "decimal",
                    pattern: "[0-9]*[.,]?[0-9]*",
                  }}
                />
              </div>
            </div>

            {/* Additional Commission Groups */}

            {additionalCommissionGroups.map((group, index) => (
              <div key={index} className="space-y-2">
                <div className="flex items-center gap-4">
                  <TextField
                    select
                    value={group.type || "Kategória"}
                    onChange={(e) =>
                      handleCommissionGroupChange(index, "type", e.target.value)
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                  >
                    <MenuItem value="Kategória">{t("Category")}</MenuItem>
                    <MenuItem value="Značka">{t("Brand")}</MenuItem>
                  </TextField>
                  <TextField
                    label={t("Name")}
                    value={group.name}
                    onChange={(e) =>
                      handleCommissionGroupChange(index, "name", e.target.value)
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <TextField
                    label={t("Value")}
                    value={group.value}
                    onChange={(e) =>
                      handleCommissionGroupChange(
                        index,
                        "value",
                        e.target.value
                      )
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>%</span>
                      ),
                    }}
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "[0-9]*[.,]?[0-9]*",
                    }}
                  />
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleRemoveCommissionGroup(index)}
                  >
                    -
                  </Button>
                </div>
              </div>
            ))}

            <Button
              variant="contained"
              onClick={handleAddCommissionGroup}
              sx={{ mt: 2 }}
            >
              {t("Add Commission Group")}
            </Button>
          </div>

          {termsCheckboxSection}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isFormValid() || isLoading}
            sx={{ mt: 2 }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : mode === "create" ? (
              t("Register Partner Shop")
            ) : (
              t("Update Partner Shop")
            )}
          </Button>

          {/* <Button fullWidth variant="outlined" onClick={onClose} sx={{ mt: 2 }}>
            {t("Cancel")}
          </Button> */}

          <AnimatePresence>
            {error && (
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                sx={{
                  color: "error.main",
                  border: 1,
                  borderColor: "error.main",
                  borderRadius: 1,
                  p: 2,
                  mt: 2,
                }}
              >
                {error}
              </motion.div>
            )}
            {success && (
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                sx={{
                  color: "success.main",
                  border: 1,
                  borderColor: "success.main",
                  borderRadius: 1,
                  p: 2,
                  mt: 2,
                }}
              >
                {success}
              </motion.div>
            )}
          </AnimatePresence>
        </form>
      </Container>
    </Modal>
  );
};

export default ShopPartnerRegister;
