import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  useDisclosure,
} from "@nextui-org/react";
import api from "../services/api";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";

const MagnifyingButton = ({ shopId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [shopItems, setShopItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query

  const fetchShopItems = async (pageNumber = 1, query = "") => {
    try {
      setIsLoading(true);
      const response = await api.get(`get-shop-items/${shopId}`, {
        params: {
          page: pageNumber,
          name: query, // Pass the search query as a parameter
        },
      });
      setShopItems(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
      setTotalItems(response.data.count);
      setIsLoading(false);
    } catch (error) {
      console.error("There was an error fetching the shop items", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchShopItems(page, searchQuery); // Fetch with search query
    }
  }, [isOpen, page]);

  const handleSearch = () => {
    setPage(1); // Reset to the first page
    fetchShopItems(1, searchQuery); // Fetch with search query
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <button
        onClick={onOpen}
        className="px-4 py-2 bg-black font-bold text-white text-base rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>
      </button>
      <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <div>
                  Priradené produkty k shopu{" "}
                  <span className="font-bold">{shopItems[0]?.shop_name}</span> :
                </div>
                {/* Search Input */}
                <div className="flex items-center gap-2 mt-2">
                  <input
                    type="text"
                    placeholder="Hľadať podľa názvu"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <Button
                    className="text-white bg-black"
                    onClick={handleSearch}
                  >
                    Hĺadať
                  </Button>
                </div>
              </ModalHeader>
              <ModalBody>
                {isLoading && (
                  <div className="mx-auto text-center justify-center">
                    <Spinner />
                  </div>
                )}
                {shopItems?.length === 0 && !isLoading && (
                  <div className="pt-4 text-xl font-bold text-danger">
                    Nenašli sa žiadne produkty
                  </div>
                )}
                {shopItems?.length > 0 && (
                  <>
                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th className="text-left">Produkt</th>
                          <th className="text-left">Cena</th>
                          <th className="text-left">Tagy</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {shopItems?.map((item) => (
                          <tr key={item.id}>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 hover:bg-slate-50">
                              <a
                                href={"/view-product/" + item.product_slug}
                                className="flex flex-row gap-2 items-center"
                              >
                                <div>
                                  <img
                                    src={item.img_url}
                                    className="max-w-16 max-h-16"
                                    alt=""
                                  />
                                </div>
                                <div>{item.product_name}</div>
                              </a>
                            </td>
                            <td>{item.price_vat}</td>
                            <td>
                              {" "}
                              <Popover placement="bottom" showArrow={true}>
                                <PopoverTrigger>
                                  <Button className="bg-white border-1">
                                    Tagy
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <div className="px-1 py-2">
                                    <div className="text-small font-bold">
                                      Tagy
                                    </div>
                                    <div className="text-tiny">
                                      <ul>
                                        <li>Stock: {item.tags.stock}</li>
                                        <li>
                                          Stock count: {item.tags.stock_count}
                                        </li>

                                        <li>
                                          Delivery date:{" "}
                                          {item.tags.delivery_date}
                                        </li>
                                        <li>Delivery: {item.tags.delivery}</li>
                                        <li>Gift: {item.tags.gift}</li>
                                        <li>
                                          Extended warranty:{" "}
                                          {item.tags.extended_warranty}
                                        </li>
                                        <li>
                                          Special service:{" "}
                                          {item.tags.special_service}
                                        </li>
                                        <li>
                                          Sales voucher:{" "}
                                          {item.tags.sales_voucher}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </PopoverContent>
                              </Popover>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <div className="flex flex-row gap-2 items-center">
                  <div className="text-sm text-gray-700">
                    Strana {page} z {totalPages} | Proložiek celkovo:{" "}
                    {totalItems}
                  </div>

                  <Button disabled={page <= 1} onClick={handlePreviousPage}>
                    Späť
                  </Button>
                  <Button
                    disabled={page >= totalPages}
                    onClick={handleNextPage}
                  >
                    Ďalej
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default MagnifyingButton;
