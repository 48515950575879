import React, { useState, useEffect } from "react";
import api from "../../services/api";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  useDisclosure,
} from "@nextui-org/react";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";

const UserTable = ({}) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onOpenChange: onEditOpenChange,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onOpenChange: onDeleteOpenChange,
  } = useDisclosure();

  const fetchUsers = async () => {
    try {
      const response = await api.get("get-admin-users");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    onEditOpen();
  };

  const handleDelete = (user) => {
    setSelectedUser(user);
    onDeleteOpen();
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`delete-user/${selectedUser.id}`);
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user: ", error);
    }
    onDeleteOpenChange(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
      additional_info: {
        ...prevUser.additional_info,
        [name]: value,
      },
    }));
  };

  const saveChanges = async () => {
    try {
      const { additional_info, ...userDetails } = selectedUser;
      await api.put(`update-user/${selectedUser.id}/`, {
        ...userDetails,
        additional_info,
      });
      fetchUsers();
    } catch (error) {
      console.error("Error updating user: ", error);
    }
    onEditOpenChange(false);
  };

  return (
    <div>
      <h1 className="text-3xl font-semibold mb-6">Používatelia</h1>
      <table className="min-w-full border-collapse block md:table">
        <thead className="block md:table-header-group">
          <tr className="border border-gray-200 block md:table-row absolute -top-full md:relative">
            <th className="block md:table-cell p-3">ID</th>
            <th className="block md:table-cell p-3">Používateľ</th>
            <th className="block md:table-cell p-3">e-mail</th>
            <th className="block md:table-cell p-3">Dátum pridania</th>
            <th className="block md:table-cell p-3">Odporúčateľ</th>
            <th className="block md:table-cell p-3">Administrátor</th>
            <th className="block md:table-cell p-3">Akcie</th>
          </tr>
        </thead>
        <tbody className="block md:table-row-group">
          {users.map((user) => (
            <tr
              key={user.id}
              className="border border-gray-200 block md:table-row hover:bg-slate-50 text-center"
            >
              <td className="block md:table-cell p-3 ">{user.id}</td>
              <td className="block md:table-cell p-3">
                {user.first_name ? (
                  <span>
                    {user.first_name} {user.last_name}
                  </span>
                ) : (
                  user.username
                )}
              </td>
              <td className="block md:table-cell p-3">{user.email}</td>
              <td className="block md:table-cell p-3">
                {formatDate(user.date_joined)}
              </td>
              <td className="block md:table-cell p-3">{user.is_part_of_ml}</td>
              <td className="block md:table-cell p-3">
                {user.groups.length > 0 ? "Áno" : "Nie"}
              </td>
              <td className="block md:table-cell p-3">
                <div className="flex flex-row gap-2 justify-center">
                  <Button
                    className="bg-white border-1 border-warning text-warning text-lg"
                    onClick={() => handleEdit(user)}
                  >
                    <MdEdit />
                  </Button>
                  <Button
                    className="bg-white border-1 border-danger text-danger text-lg"
                    onClick={() => handleDelete(user)}
                  >
                    <RiDeleteBin5Line />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedUser && (
        <Modal
          isOpen={isEditOpen}
          onOpenChange={onEditOpenChange}
          size="lg"
          scrollBehavior="inside"
        >
          <ModalContent className="p-8">
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1 uppercase">
                  Upraviť používateľa {selectedUser.email}
                </ModalHeader>
                <ModalBody>
                  <Input
                    label="Meno"
                    variant="bordered"
                    placeholder="Meno"
                    name="first_name"
                    value={selectedUser.first_name || ""}
                    onChange={handleInputChange}
                  />
                  <Input
                    label="Priezvisko"
                    variant="bordered"
                    placeholder="Priezvisko"
                    name="last_name"
                    value={selectedUser.last_name || ""}
                    onChange={handleInputChange}
                    className="mt-4"
                  />
                  <Input
                    label="Email"
                    variant="bordered"
                    placeholder="Email"
                    name="email"
                    value={selectedUser.email}
                    onChange={handleInputChange}
                    className="mt-4"
                  />
                  <Input
                    label="Štát"
                    variant="bordered"
                    placeholder="Štát"
                    name="user_country"
                    value={selectedUser.additional_info?.user_country || ""}
                    onChange={handleInputChange}
                    className="mt-4"
                  />
                  <Input
                    label="Telefón"
                    variant="bordered"
                    placeholder="Telefón"
                    name="user_phone"
                    value={selectedUser.additional_info?.user_phone || ""}
                    onChange={handleInputChange}
                    className="mt-4"
                  />
                  <Input
                    label="Adresa"
                    variant="bordered"
                    placeholder="Adresa"
                    name="user_address"
                    value={selectedUser.additional_info?.user_address || ""}
                    onChange={handleInputChange}
                    className="mt-4"
                  />
                  <Input
                    label="Mesto"
                    variant="bordered"
                    placeholder="Mesto"
                    name="user_city"
                    value={selectedUser.additional_info?.user_city || ""}
                    onChange={handleInputChange}
                    className="mt-4"
                  />
                  <Input
                    label="PSČ"
                    variant="bordered"
                    placeholder="PSČ"
                    name="user_zip"
                    value={selectedUser.additional_info?.user_zip || ""}
                    onChange={handleInputChange}
                    className="mt-4"
                  />
                  <Input
                    label="Kraj"
                    variant="bordered"
                    placeholder="Kraj"
                    name="user_state"
                    value={selectedUser.additional_info?.user_state || ""}
                    onChange={handleInputChange}
                    className="mt-4"
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    auto
                    flat
                    color="error"
                    onClick={onClose}
                    className="border-1 text-danger border-danger"
                  >
                    Zrušiť
                  </Button>
                  <Button
                    auto
                    onClick={saveChanges}
                    className="text-white bg-success"
                  >
                    Uložiť
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      )}

      <Modal isOpen={isDeleteOpen} onOpenChange={onDeleteOpenChange} size="sm">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-2xl font-bold uppercase text-center">
                Vymazať užívateľa {selectedUser?.email}
              </ModalHeader>
              <ModalBody>
                <div className="text-center">
                  Určite chcete vymazať používateľa{" "}
                  <strong>{selectedUser.email}</strong>?
                </div>
              </ModalBody>
              <ModalFooter>
                <Button auto flat color="error" onClick={onClose}>
                  Zrušiť
                </Button>
                <Button
                  auto
                  onClick={confirmDelete}
                  className="bg-danger text-white"
                >
                  Vymazať
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default UserTable;
