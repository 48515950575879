import React, { useState, useMemo } from "react";
import { Input, Textarea, Button, Image, Spinner } from "@nextui-org/react";
import { IoMail } from "react-icons/io5";
import validator from "validator";
import api from "../services/api";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function ContactUs() {
  const [email, setEmail] = useState("");
  const [emailText, setEmailText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const { t } = useTranslation();

  const validateEmail = (email) => {
    return validator.isEmail(email);
  };

  const emailIsInvalid = useMemo(() => {
    return email !== "" && !validateEmail(email);
  }, [email]);

  const sendEmail = async () => {
    setIsLoading(true);
    if (emailIsInvalid) {
      console.error("Invalid email. Cannot send.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await api.post("/contact-email/", {
        email: email,
        email_text: emailText,
      });

      // Check if the response status is 200
      if (response.status === 200) {
        // const data = await response.json();
        // console.log("Success:", data);
        setEmailSent(true); // Set emailSent to true only on successful response
      } else {
        console.error("Failed to send email. Status:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setIsLoading(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeOut" }}
    >
      <div className="py-16 bg-white">
        <div className="flex flex-col gap-8 max-w-screen-2xl mx-auto">
          <div className="text-center px-4">
            <h2 className="text-blue font-semibold my-4">
              {t("Kontaktujte nás")}
            </h2>
            <p
              className="font-bold lg:text-5xl text-3xl leading-tight"
              dangerouslySetInnerHTML={{ __html: t("contact_us_text") }}
            ></p>
          </div>
          <div>
            <div className="flex flex-row max-w-[1000px] mx-auto">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col gap-12 my-auto mx-auto w-[350px]"
              >
                {emailSent ? (
                  // Success message after email is sent
                  <div className="flex flex-col justify-center items-center h-full w-full bg-green/50 rounded-3xl">
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, ease: "easeOut" }}
                      className="text-center bg-green-600 text-white p-8 rounded-3xl"
                    >
                      <h3
                        className="font-bold text-2xl"
                        dangerouslySetInnerHTML={{ __html: t("email_success") }}
                      ></h3>
                    </motion.div>
                  </div>
                ) : (
                  // Form to send email
                  <motion.form
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      sendEmail();
                    }}
                  >
                    <label htmlFor="email" className="sr-only">
                      Email
                    </label>
                    <Input
                      className="my-4"
                      id="email"
                      value={email}
                      type="email"
                      label="Email"
                      placeholder="you@example.com"
                      variant="bordered"
                      labelPlacement="outside"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      color={emailIsInvalid ? "danger" : "success"}
                      errorMessage={
                        emailIsInvalid && "Please enter a valid email"
                      }
                      startContent={
                        <IoMail
                          className="text-2xl text-default-400 pointer-events-none flex-shrink-0"
                          aria-hidden="true"
                        />
                      }
                    />
                    <label htmlFor="message" className="sr-only">
                      {t("message")}
                    </label>
                    <Textarea
                      id="message"
                      value={emailText}
                      onChange={(e) => setEmailText(e.target.value)}
                      className=""
                      type="field"
                      label={t("message")}
                      variant="bordered"
                      placeholder={t("message")}
                      labelPlacement="outside"
                      minRows={8}
                    />
                    <Button
                      className="bg-blue text-white rounded-3xl font-semibold text-md my-4"
                      type="submit"
                      aria-label="Send email"
                      fullWidth
                    >
                      {isLoading ? (
                        <Spinner color="white" />
                      ) : (
                        <span>
                          {t("send")} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &rarr;
                        </span>
                      )}
                    </Button>
                  </motion.form>
                )}
              </motion.div>
              <Image
                src="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/man_pointing.png"
                alt="Illustration of a man pointing to symbolize contact"
                className="lg:flex hidden"
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
