import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Select,
  MenuItem,
  DialogContentText,
  Autocomplete,
  FormControl,
  InputLabel,
  Skeleton,
  CircularProgress,
} from "@mui/material";

import api from "../../services/api";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";

const TransactionsTable = ({}) => {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState({
    id: null,
    user: null,
    amount: null,
    date: "",
    status: "",
    afiiliate_id: null,
    click: null,
    shop: null,
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loading, setLoading] = useState(true);
  const [shops, setShops] = useState([]);
  const [shopOptions, setShopOptions] = useState([]);
  const [loadingShops, setLoadingShops] = useState(false);
  const [initialShopLoad, setInitialShopLoad] = useState(false);
  const [shopSearchTerm, setShopSearchTerm] = useState("");

  const [filters, setFilters] = useState({
    user: null,
    status: "",
    date: null,
    shop: "",
    amount: "",
  });

  const statusOptions = [
    { value: "pending", label: "Čakáme za overením nákupu" },
    { value: "confirmed", label: "Čakáme na cashback od partnera" },
    {
      value: "to_be_paid",
      label: "Bude vyplatené v najbližšom výplatnom termíne",
    },
    { value: "payed", label: "Vyplatené" },
    { value: "marked_as_bought", label: "Označené ako nakúpené" },
    { value: "ticket", label: "Odoslané na reklamáciu" },
    { value: "not_bought", label: "Označené ako nenakúpené / skryté" }, // New status option
  ];

  useEffect(() => {
    fetchTransactions();
  }, [page, rowsPerPage, filters]);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page: page.toString(),
        page_size: rowsPerPage.toString(),
      });

      // Add filter parameters
      if (filters.user) queryParams.append("user", filters.user);
      if (filters.status) queryParams.append("status", filters.status);
      if (filters.date)
        queryParams.append("date", format(filters.date, "yyyy-MM-dd"));
      if (filters.shop) queryParams.append("shop", filters.shop);
      if (filters.amount) queryParams.append("amount", filters.amount);

      const response = await api.get(
        `transaction-list/?${queryParams.toString()}`
      );
      setTransactions(response.data.results);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      toast.error("Error fetching transactions");
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async (search = "") => {
    try {
      const response = await api.get("fetch-users/", { params: { search } });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Error fetching users");
    }
  };

  const fetchShops = async (search = "") => {
    try {
      setLoadingShops(true);
      setShopOptions([]); // Clear previous options
      const response = await api.get("get-shops-names/", {
        params: { search },
      });
      setShopOptions(response.data);
    } catch (error) {
      console.error("Error fetching shops:", error);
      toast.error("Error fetching shops");
    } finally {
      setLoadingShops(false);
    }
  };

  const handleUserSearch = (event, newInputValue) => {
    setSearchTerm(newInputValue);
    fetchUsers(newInputValue);
  };

  const handleShopSearch = (event, newInputValue) => {
    if (!initialShopLoad) {
      fetchShops(newInputValue);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when changing rows per page
  };

  const handleOpenDialog = (transaction) => {
    setCurrentTransaction(transaction);
    setOpenDialog(true);
    if (transaction.shop) {
      fetchShopById(transaction.shop);
    } else {
      setShopOptions([]);
    }
    setShopSearchTerm("");
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentTransaction({
      id: null,
      user: null,
      amount: null,
      date: "",
      status: "",
      afiiliate_id: null,
      click: null,
    });
  };

  const handleInputChange = (e) => {
    setCurrentTransaction({
      ...currentTransaction,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserChange = (event, newValue) => {
    setCurrentTransaction({
      ...currentTransaction,
      user: newValue ? newValue.id : null,
    });
  };

  const handleShopChange = (event, newValue) => {
    setCurrentTransaction({
      ...currentTransaction,
      shop: newValue ? newValue.id : null,
    });
  };

  const handleShopInputChange = (event, newInputValue) => {
    setShopSearchTerm(newInputValue);
  };

  const handleDateChange = (newDate) => {
    if (!currentTransaction.id) {
      setCurrentTransaction({
        ...currentTransaction,
        date: newDate,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      if (currentTransaction.id) {
        // Update existing transaction
        const response = await api.put(
          `/transaction-list/`,
          currentTransaction
        );
        if (response.status === 200) {
          toast.success("Transaction updated successfully");
          fetchTransactions();
          handleCloseDialog();
        } else {
          toast.error("Error updating transaction");
        }
      } else {
        // Add new transaction
        const response = await api.post(
          "/transaction-list/",
          currentTransaction
        );
        if (response.status === 201) {
          toast.success("Transaction added successfully");
          fetchTransactions();
          handleCloseDialog();
        } else {
          toast.error("Error adding transaction");
        }
      }
    } catch (error) {
      console.error("Error submitting transaction:", error);
      toast.error(
        error.response?.data?.error || "Error submitting transaction"
      );
    }
  };

  // const handleDelete = async (id) => {
  //   try {
  //     await axios.api(`transaction-list/${id}/`);
  //     fetchTransactions();
  //   } catch (error) {
  //     console.error("Error deleting transaction:", error);
  //   }
  // };

  const handleOpenDeleteDialog = (transaction) => {
    setTransactionToDelete(transaction);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setTransactionToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (transactionToDelete) {
      try {
        await api.delete(`transaction-list/`, {
          data: { id: transactionToDelete.id },
        });
        fetchTransactions();
        handleCloseDeleteDialog();
        toast.success("Transaction deleted successfully");
      } catch (error) {
        console.error("Error deleting transaction:", error);
        toast.error("Error deleting transaction");
      }
    }
  };

  const handleFilterChange = (event, newValue, filterName) => {
    if (filterName === "user") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        user: newValue ? newValue.id : null,
      }));
    } else {
      const { name, value } = event.target;
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value,
      }));
    }
  };

  const handleDateFilterChange = (newDate) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      date: newDate,
    }));
  };

  const clearFilters = () => {
    setFilters({
      user: null,
      status: "",
      date: null,
      shop: "",
      amount: "",
    });
  };

  const getStatusLabel = (statusValue) => {
    const statusOption = statusOptions.find(
      (option) => option.value === statusValue
    );
    return statusOption ? statusOption.label : statusValue;
  };

  const fetchShopById = async (shopId) => {
    try {
      setLoadingShops(true);
      const response = await api.get(`get-shop/${shopId}/`);
      setShopOptions([response.data]);
    } catch (error) {
      console.error("Error fetching shop:", error);
      toast.error("Error fetching shop");
    } finally {
      setLoadingShops(false);
    }
  };

  useEffect(() => {
    const fetchShops = async () => {
      if (shopSearchTerm) {
        try {
          setLoadingShops(true);
          const response = await api.get("get-shops-names/", {
            params: { search: shopSearchTerm },
          });
          setShopOptions(response.data);
        } catch (error) {
          console.error("Error fetching shops:", error);
          toast.error("Error fetching shops");
        } finally {
          setLoadingShops(false);
        }
      } else {
        setShopOptions([]);
      }
    };

    const debounceTimer = setTimeout(() => {
      fetchShops();
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [shopSearchTerm]);

  return (
    <div>
      {/* Filtering row */}
      <div className="flex flex-wrap gap-4 mb-4">
        <Autocomplete
          options={users}
          getOptionLabel={(option) => option.email}
          value={users.find((user) => user.id === filters.user) || null}
          onChange={(event, newValue) =>
            handleFilterChange(event, newValue, "user")
          }
          onInputChange={(event, newInputValue) => {
            handleUserSearch(event, newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="user"
              label="Používateľ"
              variant="outlined"
              size="small"
            />
          )}
          style={{ width: 200 }}
        />
        <Select
          name="status"
          value={filters.status}
          onChange={handleFilterChange}
          displayEmpty
          variant="outlined"
          size="small"
        >
          <MenuItem value="">Všetky stavy</MenuItem>
          {statusOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Dátum"
            value={filters.date}
            onChange={handleDateFilterChange}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
        <TextField
          name="shop"
          label="Obchod"
          value={filters.shop}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
        />
        <TextField
          name="amount"
          label="Čiastka"
          value={filters.amount}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
          type="number"
        />
        <Button variant="outlined" onClick={clearFilters}>
          Vyčistiť filtre
        </Button>
      </div>

      <div className="flex flex-row justify-between py-2">
        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel id="rows-per-page-label">Riadkov na stránku</InputLabel>
          <Select
            labelId="rows-per-page-label"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            label="Riadkov na stránku"
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={75}>75</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenDialog({})}
          className=""
        >
          Pridať transakciu
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Používateľ</TableCell>
              <TableCell>Obchod</TableCell>
              <TableCell>Pôvod obchodu</TableCell>
              <TableCell>Čiastka</TableCell>
              <TableCell>Dátum</TableCell>
              <TableCell>Stav</TableCell>
              <TableCell>Klik</TableCell>
              <TableCell>Akcie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array.from(new Array(rowsPerPage)).map((_, index) => (
                <TableRow key={index}>
                  {Array.from(new Array(9)).map((_, cellIndex) => (
                    <TableCell key={cellIndex}>
                      <Skeleton animation="wave" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : transactions.length > 0 ? (
              transactions.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>{transaction.id}</TableCell>
                  <TableCell>
                    {transaction.username
                      ? transaction.username
                      : transaction.click_user}{" "}
                    ({transaction.user_id})
                  </TableCell>
                  <TableCell>{transaction.shop_name}</TableCell>
                  <TableCell>{transaction.shop_origin}</TableCell>
                  <TableCell>{transaction.amount}</TableCell>
                  <TableCell>{transaction.formatted_date}</TableCell>
                  <TableCell>{getStatusLabel(transaction.status)}</TableCell>
                  <TableCell>{transaction.click}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleOpenDialog(transaction)}>
                      Upraviť
                    </Button>
                    <Button onClick={() => handleOpenDeleteDialog(transaction)}>
                      Vymazať
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-between items-center mt-4">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
        />
        <div>
          Stránka {(page - 1) * rowsPerPage + 1} -{" "}
          {Math.min(page * rowsPerPage, transactions.length)} z{" "}
          {transactions.length}
        </div>
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {currentTransaction.id
            ? "Upraviť transakciu"
            : "Pridať novú transakciu"}
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            options={users}
            getOptionLabel={(option) => option.email}
            value={
              users.find((user) => user.id === currentTransaction.user) || null
            }
            onChange={handleUserChange}
            onInputChange={handleUserSearch}
            renderInput={(params) => (
              <TextField
                {...params}
                name="user"
                label="Používateľ"
                fullWidth
                margin="normal"
              />
            )}
          />
          <TextField
            name="amount"
            label="Čiastka"
            value={currentTransaction.amount || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Dátum"
              value={currentTransaction.date || null}
              onChange={handleDateChange}
              readOnly={!!currentTransaction.id}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    ...params.InputProps,
                    readOnly: !!currentTransaction.id,
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Select
            name="status"
            label="Stav"
            value={currentTransaction.status || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <TextField
            name="click"
            label="Klik"
            value={currentTransaction.click || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <Autocomplete
            options={shopOptions}
            getOptionLabel={(option) => option.name}
            value={
              shopOptions.find((shop) => shop.id === currentTransaction.shop) ||
              null
            }
            onChange={handleShopChange}
            onInputChange={handleShopInputChange}
            loading={loadingShops}
            renderInput={(params) => (
              <TextField
                {...params}
                name="shop"
                label="Obchod"
                fullWidth
                margin="normal"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingShops ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Zrušiť</Button>
          <Button onClick={handleSubmit}>Potvrdiť</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Potvrdiť vymazanie transakcie"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ste si istý, že chcete vymazať túto transakciu? Túto akciu nie je
            možné vrátiť späť.
          </DialogContentText>
          {transactionToDelete && (
            <div className="mt-4">
              <p>
                <strong>ID transakcie:</strong> {transactionToDelete.id}
              </p>
              <p>
                <strong>Používateľ:</strong>{" "}
                {transactionToDelete.username || transactionToDelete.click_user}{" "}
                ({transactionToDelete.user_id})
              </p>
              <p>
                <strong>Obchod:</strong> {transactionToDelete.shop_name}
              </p>
              <p>
                <strong>Čiastka:</strong> {transactionToDelete.amount}
              </p>
              <p>
                <strong>Dátum:</strong> {transactionToDelete.formatted_date}
              </p>
              <p>
                <strong>Stav:</strong> {transactionToDelete.display_status}
              </p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Zrušiť</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Potvrdiť vymazanie
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TransactionsTable;
