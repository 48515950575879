import React from "react";
import { IconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import WalletBalance from "./WalletBalance";
import WalletTransactions from "./WalletTransactions";
import WalletPayouts from "./WalletPayouts";
import WalletStatements from "./WalletStatements";

export default function WalletContent({
  activeItemId,
  onMenuToggle,
  EmptyState,
}) {
  const renderContent = () => {
    switch (activeItemId) {
      case 0:
        return <WalletBalance EmptyState={EmptyState} />;
      case 1:
        return <WalletTransactions EmptyState={EmptyState} />;
      case 2:
        return <WalletPayouts EmptyState={EmptyState} />;
      case 3:
        return <WalletStatements EmptyState={EmptyState} />;
      default:
        return <WalletBalance EmptyState={EmptyState} />;
    }
  };

  return (
    <div className="pt-12">
      <div className="xl:hidden mb-4">
        <IconButton onClick={onMenuToggle} size="large">
          <MenuIcon />
        </IconButton>
      </div>
      {renderContent()}
    </div>
  );
}
