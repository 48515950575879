import BasicNavbar from "../components/BasicNavbar";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import "../utils/quill.css";

const TermsAndConditions = () => {
  const { i18n } = useTranslation();

  // Get the current language key
  const languageKey = i18n.language;

  const termsContent = {
    sk: `
**Podmienky komunity bilionbuy**

- Podmienky užívania webu Bilionbuy členom komunity  
- Podmienky internetového predaja  
- Podmienky zodpovednosti za obsah príspevkov a recenzií  
- Ochrana osobných údajov na webe Bilionbuy

**Podmienky užívania webu Bilionbuy členom komunity**

**1\. Základné ustanovenia**

1.1. Bilionbuy je webová platforma poskytujúca internetový predaj vlastných a partnerských produktov a služieb, a zároveň umožňujúca registrovaným členom komunity získavať finančné a iné výhody vo forme cashbacku alebo odmien.

1.2. Tieto podmienky upravujú pravidlá užívania webovej platformy Bilionbuy, pravidlá zapojenia sa do komunity Bilionbuy, a z toho vyplývajúcich výhod pre registrovaných členov komunity Bilionbuy.

**2\. Definície pojmov**

2.1. Webová platforma \- webová stránka [www.bilionbuy.com](http://www.bilionbuy.com).

2.2. Prevádzkovateľ \- prevádzkovateľom webovej platformy je spoločnosť Bilionbuy International s.r.o., IČO: 56190999, so sídlom Farbiarska 53/29, 064 01 Stará Ľubovňa.

2.3. Člen komunity \- registráciou na webovej platforme sa registrovaný automaticky stáva členom komunity Bilionbuy.

2.4. Obchodná sieť \- do obchodnej siete člena komunity Bilionbuy patria všetci priamo aj nepriamo zaregistrovaní priatelia, ktorí boli pozvaní do komunity Bilionbuy priamo členom komunity alebo nepriamo od ním pozvaných zaregistrovaných priateľov.

2.5. Priamo pozvaný priateľ \- priateľ, ktorého do Bilionbuy pozval člen komunity Bilionbuy priamo prostredníctvom vlastnej aktivity, zaslaním pozvánky cez webovú platformu alebo zaslaním odporúčacieho linku. 

2.6. Nepriamo pozvaný priateľ \- priateľ, ktorého do Bilionbuy pozval niektorý z priateľov, ktorých člen komunity Bilionbuy pozval alebo niektorý z ich priateľov.

**3\. Registrácia a pozvanie do komunity Bilionbuy**

3.1. Registrácia prebieha na webovej platforme. 

3.2. Registrácia je možná na základe pozvania (odporúčania) od priateľa alebo priamo registráciou na webovej platforme bez odporúčateľa.

3.3. Registrácia na základe pozvania prebieha zaslaním odporúčacieho linku alebo priamo vo webovej platforme zaslaním pozvánky cez funkciu “Pozvať priateľa”.

3.4. Registráciou sa registrovaný automaticky stáva členom komunity Bilionbuy bez povinnosti platenia akýchkoľvek poplatkov za členstvo v komunite Bilionbuy.

3.5. Registráciou na webovej platforme získava člen komunity výhody členstva v komunite Bilionbuy.

**4\. Výhody členstva v komunite Bilionbuy**

4.1. Bilionbuy nestanovuje žiadne obmedzujúce podmienky na získanie výhod. Nie sú stanovené žiadne minimálne nákupy alebo minimálne aktivity. Finančné výhody sú ovplyvnené dosiahnutým obratom nákupov.

4.2. Člen komunity Bilionbuy má nárok na nasledujúce výhody za členstvo v komunite Bilionbuy. 

4.3. Cashback z vlastného nákupu \- výška cashbacku z vlastného nákupu je uvedená pri internetovom obchode, konkrétnom produkte alebo službe uvedenom na webovej platforme Bilionbuy a je rozličná pri každom internetovom obchode, produkte alebo službe.

4.4. Priamy odporúčateľský cashback \- je forma cashbacku stanovená za nákupy priamo pozvaných priateľov. Výška priameho odporúčateľského cashbacku je uvedená pri internetovom obchode, produkte alebo službe uvedenom na webovej platforme Bilionbuy a je rozličná pri každom internetovom obchode, produkte alebo službe.

4.5. Kariérne odmeny \- je forma odmeny stanovená za odporúčanie a budovanie komunity Bilionbuy podľa získaných bodov za nákupy obchodnej siete člena komunity Bilionbuy. Podmienky dosiahnutia kariérnych pozícií a výšku odmien na týchto pozíciách sa dozviete v časti “Kariérna cesta”.

4.6. Cieľový bonus \- je forma odmeny stanovená pre člena komunity Bilionbuy, v prípade ak ním priamo pozvaný člen komunity Bilionbuy, dosiahne v danom mesiaci kariérnu pozíciu Partner. V tomto prípade sa člen komunity zaradzuje medzi skupinu členov komunity Bilionbuy, ktorým je rovnakým dielom vyplácaný cieľový bonus. Cieľový bonus tvorí sumu pozostávajúcu z 1 % obratu Bilionbuy bez DPH.

4.7. Iné špeciálne výhody \- sú to ďalšie výhody, o ktorých Bilionbuy informuje členov komunity Bilionbuy prostredníctvom e-mailu alebo webovej platformy.

**5\. Body, sledovacie obdobie a kariérna cesta**

5.1. Za každý nákup získava člen komunity Bilionbuy body. Počet bodov je uvedený pri produkte alebo službe uvedenom na webe Bilionbuy a je rozličný pri každom produkte alebo službe.

5.2. Člen komunity Bilionbuy pre účely získania kariérnych odmien zbiera body v každom mesiaci od nuly. Vždy na začiatku mesiaca sa nazbierané body vynulujú.

5.3. Sledovacie obdobie je obdobie, v ktorom sa sleduje dosiahnutý obrat člena komunity Bilionbuy a všetkých jeho podriadených členov komunity Bilionbuy. 

5.3. Sledovacie obdobie trvá od 1\. dňa do posledného dňa každého mesiaca. Vždy na začiatku mesiaca sa začína od dosiahnutého obratu 0\.

5.4. Do sledovacieho obdobia sa započítava výlučne obrat, ktorý tvoria zaplatené provízie za uskutočnené nákupy za produkty a služby. Nepostačuje, že bola zaplatená čiastka za nákup, musí byť zaplatená provízia do Bilionbuy. Doba trvania zaplatenia provízie do Bilionbuy sa líši od jednotlivých partnerov a táto doba môže byť od 1 do 40 dní.

5.5. Tabuľka kariérnej cesty

![][image1]

5.6. Člen komunity Bilionbuy má nárok na vyplatenie kariérnej odmeny podľa dosiahnutnej pozície v kariérnej ceste podľa počtu získaných bodov v danom mesiaci vynásobených eurovou hodnotou. V prípade, ak mu časť bodového obratu získal jemu podriadený člen komunity Bilionbuy, má nadriadený člen komunity Bilionbuy nárok na rozdielovú odmenu eurovej hodnoty.

**6\. Výplatné termíny**

6.1. Cashback odmeny sú vyplácané každý týždeň, vždy v pondelok. 

6.2. Kariérne odmeny sú vyplácané jedenkrát mesačne, do 5\. dňa nasledujúceho mesiaca, za ktorý patrí členovi komunity BILIONBUY kariérna odmena.

**7\. Reklamácie**

7.1. Reklamácie poskytnutých finančných výhod sa uskutočňuje prostredníctvom e-mailovej komunikácie [info@bilionbuy.com](mailto:info@bilionbuy.com) alebo prostredníctvom internej zóny webovej platformy.

7.2. Bilionbuy sa zaväzuje vyriešiť reklamáciu najneskôr do 30 dní odo dňa doloženia všetkých informácií potrebných k reklamácii.

**8\. Záverečné ustanovenia**

8.1. Prevádzkovateľ si vyhradzuje právo, že web Bilionbuy nemusí byť vždy dostupný, najmä kvôli technickým problémom alebo pravidelnej údržbe. Za prípadné škody spôsobené nedostupnosťou, zmenami na webe Bilionbuy nenesie Prevádzkovateľ zodpovednosť.

8.2. Prevádzkovateľ má právo jednostranne upraviť tieto Podmienky v primeranom rozsahu, hlavne za účelom doplnenia konkrétnejšieho znenia podmienok. Člen komunity bude na zmenu Podmienok upozornený minimálne 14 dní pred tým, ako nové Podmienky nadobudnú účinnosť, a to prostredníctvom e-mailu alebo webovej platformy. Člen komunity má možnosť zmeny Podmienok odmietnuť, a to tak, že požiada o zrušenie svojho účtu na webe Bilionbuy alebo prestane web Bilionbuy používať.

8.3. Práva a povinnosti neupravené týmito podmienkami sa riadia právnymi predpismi platnými v štáte, na ktorý sa prevádzkovanie platformy vzťahuje.

8.4. Tieto všeobecné podmienky nadobúdajú účinnosť dňa 19\. 09\. 2024\.

**Podmienky internetového predaja**

**1\. Základné ustanovenia**

1.1. Podmienky internetového predaja upravujú práva a povinnosti zmluvných strán pri uzatváraní kúpnej zmluvy dojednanej na diaľku medzi partnerom prevádzkovateľa a kupujúcim, predmetom ktorej je predaj tovaru alebo služby.

**2\. Definície pojmov**

2.1. Webová platforma \- web www.bilionbuy.com.

2.2. Partnerský internetový obchod \- je internetový obchod partnera Bilionbuy, ktorý je zobrazovaný na webe Bilionbuy na základe osobitnej spolupráce medzi Bilionbuy a partnerom.

2.3. Partner \- prevádzkovateľ internetového obchodu, na ktorého v porovnaní produktov a služieb odkazuje Bilionbuy, pričom samotná objednávka a uzatvorenie kúpnej zmluvy prebieha v internetovom obchode Partnera na vlastný účet a zodpovednosť Partnera.

**3\. Predaj prostredníctvom Bilionbuy**

3.1. Člen komunity Bilionbuy alebo nezaregistrovaný užívateľ je oprávnený vyhľadať produkt, o ktorého kúpu má záujem alebo vyhľadať partnerský internetový obchod, v ktorom chce nakupovať. V tomto prípade sa internetový predaj neuskutočňuje na strane Bilionbuy, ale priamo v partnerskom internetovom obchode, a to v súlade s obchodnými podmienkami partnerského internetového obchodu.

**4\. Záverečné ustanovenia**

4.1. Otázky neupravené týmito podmienkami sa riadia príslušnými platnými právnymi predpismi.

4.2. Tieto podmienky internetového predaja doplňujú Podmienky užívania webu Bilionbuy členom komunity.

4.3. Tieto podmienky internetového predaja nadobúdajú účinnosť dňa 03\. 09\. 2024\.

**Podmienky vkladania príspevkov a recenzií na webovú platformu**

**1\. Základné ustanovenia**

Tieto podmienky vkladania príspevkov a recenzií na webovú platformu Bilionbuy slúžia ako možnosť členov komunity Bilionbuy podieľať sa na obsahu webu prostredníctvom zdieľania vlastných skúsenosti so zakúpenými produktmi alebo službami (tzv. recenzie).

**2\. Zodpovednosť za obsah recenzie**

2.1. Člen komunity Bilionbuy nesie v plnom rozsahu občianskoprávnu a trestnú zodpovednosť za obsah svojej recenzie. 

2.2. Člen komunity Bilionbuy je povinný zdieľať takú recenziu, ktorá je pravdivá a nebola vytvorená za účelom poškodzovania konkurenta v konkurenčnom boji.

**3\. Overovanie obsahu recenzie**

3.1. Na webovej platforme Bilionbuy sa zverejňujú overené, ale aj neoverené recenzie, ktorú sú na webe viditeľne označené ako overené, resp. neoverené.

3.2. Bilionbuy si vyhradzuje právo overovať akúkoľvek recenziu poskytnutú členom komunity Bilionbuy, a za tým účelom je oprávnený žiadať od člena komunity alebo od partnera prevádzkovateľa potrebné dôkazy.

3.3. Podať námietku proti nezákonnému obsahu recenzie, resp. požiadať o overenie zákonnosti obsahu recenzie je oprávnený podať partner prevádzkovateľa formou e-mailovej adresy [partner@bilionbuy.com](mailto:partner@bilionbuy.com), pričom uvedenie o ktorú recenziu ide a dôvody podania námietky.

3.4. Bilionbuy si vyhradzuje právo zmazať neoverené recenzie, vymyslené recenzie alebo recenzie, ktoré z veľkej pravdepodobnosti nie sú skutočné.

**4\. Záverečné ustanovenia**

4.1. Tieto podmienky vkladania príspevkov a recenzií na webovú platformu doplňujú Podmienky užívania webu Bilionbuy členom komunity.

4.2. Tieto podmienky vkladania príspevkov a recenzií na webovú platformu nadobúdajú účinnosť dňa 03\. 09\. 2024\.

**Ochrana osobných údajov**

Prevádzkovateľ:

Bilionbuy International s.r.o.

sídlo: Farbiarska 53/29, 064 01 Stará Ľubovňa

zapísané v OR Mestského súdu Bratislava III, oddiel: Sro, vl.č. 177746/B

štatutárny orgán: Mgr. Štefan Lazorčák, konateľ

Prevádzkovateľ spracováva bežné kategórie osobných údajov ako aj osobitnú kategóriu osobných údajov.

Bežné kategórie osobných údajov, ktoré prevádzkovateľ spracováva sú:  
Meno, priezvisko, podobizeň, dátum narodenia, adresa trvalého pobytu, korešpondenčná adresa, štátnu príslušnosť, IBAN, telefón, e-mail, IP adresa, číslo produktu (zmluvy), údaje o používaných produktoch a službách ako napríklad údaje o využívaných produktoch a službách, údaje súvisiace s vybavovaním Vašich požiadaviek, socio-demografické údaje ako napríklad vek, pohlavie, audiozáznamy ako napríklad nahrávky hovorov uskutočnených prostredníctvom kontaktného centra, údaje súvisiace s používaním našich webových stránok a aplikácií (napríklad cookies), údaje súvisiace so sledovaním našich marketingových kanálov ako napríklad časy otvárania zaslaných správ, na akom zariadení informácie čítate, aké technické parametre a operačný systém má dané zariadenie, údaje súvisiace s plnením vašich zmluvných povinností a záväzkov, údaje vyplývajúce z aktivity na sociálnych sieťach, odvodené údaje vyplývajúce z Vašich nastavení, údajov o produktoch a službách, údajoch o používaní našich webových stránok.

Osobitné kategórie osobných údajov prevádzkovateľ nespracováva.

Prevádzkovateľ spracováva osobné údaje za účelom:

- propagácie prevádzkovateľa, najmä odosielanie newslettera, e-mailové správy, e-maily reklamnej povahy, SMS správy, SMS reklamnej povahy,   
- komunikácie s dotknutou osobou, napr. uskutočňovanie on-line alebo telefonickej komunikácie,  
- evidencie a zverejňovania recenzií a referencií,  
- organizovania podujatí,  
- archivácie a štatistiky,  
- správy cookies,  
- evidencie a realizácie zmluvných vzťahov dotknutej osoby uzatvorených s prevádzkovateľom, jeho obchodnými partnermi alebo s inými subjektami a prípadného vymáhania pohľadávok,  
- získavania, zaznamenávania, zhromažďovania, ukladania osobných údajov do informačného systému, sprístupňovanie, uchovávanie, upravovanie a pozmeňovanie, vyhľadávanie, prehliadanie, odovzdávanie, triedenie a ich kombinovanie za účelom evidencie obchodných výsledkov, veľkosti obchodnej siete a klientskej siete, odmien, evidencie zaevidovaných, resp. zadaných obchodov,  
- sprístupnenia vybraných osobných údajov členom komunity Bilionbuy, ktorí sú v rámci kariéry zaradení ako nadriadení,  
- spracovania a zverejňovania fotografií, na ktorých je vyobrazená podobizeň člena komunity Bilionbuy, a to za účelom marketingovej činnosti, propagačnej činnosti a zverejnenie podobizne v osobnom profile vo webovej platforme,  
- identifikácie a overenia identifikácie klientov a ich zástupcov,  
- uzatvárania a plnenia zmluvných vzťahov vrátane predzmluvných vzťahov,  
- vzťahového manažmentu,  
- správy zmluvných vzťahov vrátane vykonávania zmien a ich ukončenia,  
- prijímania a vybavovanie podnetov a sťažností klientov, resp. členov komunity Bilionbuy,  
- zdokumentovania a zabezpečenia činností členov komunity Bilionbuy vzhľadom na plnenie záväzkov vyplývajúcich z uzatvorených zmluvných vzťahov v súlade s ustanoveniami osobitných predpisov,  
- plnenia povinností vyplývajúcich členom Bilionbuy zo zákona č. 297/2008 Z. z. o ochrane pred legalizáciou príjmov z trestnej činnosti a o ochrane pred financovaním terorizmu,  
- ďalšie činnosti súvisiace s plnením si úloh a povinností Bilionbuy podľa platných právnych predpisov,  
- marketing pri poskytovaní produktov a služieb,  
- spotrebiteľské súťaže (vyhodnotenie a určenie výhercov a súťažiacich),  
- súťaže členov komunity Bilionbuy (vyhodnotenie a určenie výhercov a súťažiacich).  


Právny základ spracúvania osobných údajov pre vyššie uvedené účely je skutočnosť, že spracúvanie osobných údajov je nevyhnutné na plnenie zmluvy, ktorej zmluvnou stranou je dotknutá osoba. Všetky dotknuté účely sú nevyhnutné na účely plnenia zmluvy, avšak pre prípad akýchkoľvek pochybností, týmto dotknutá osoba vyjadruje súhlas na spracúvanie osobných údajov na vyššie uvedené účely. Toto spracúvanie osobných údajov je odvolateľné len v prípade ukončenia zmluvného vzťahu s prevádzkovateľom. Zmluva, o ktorú sa naše spracovanie opiera vzniká zadaním vašich údajov prostredníctvom registrácie alebo v internej zóne.

Osobné údaje sú poskytované v súvislosti s vyššie uvedenými účelmi iba oprávneným osobám u prevádzkovateľa, ktoré osobné údaje spracúvajú z dôvodu spracúvania na základe vyššie uvedených účelov, a tiež sprostredkovateľom, ktorí spracúvajú osobné údaje v mene prevádzkovateľa, a to na základe sprostredkovateľskej zmluvy. 

Osobné údaje dotknutej osoby prevádzkovateľ v súvislosti s vyššie uvedenými účelmi uchováva po dobu určenú osobitnými predpismi, v ostatných prípadoch po dobu trvania zmluvného vzťahu.

Prenos osobných údajov do tretej krajiny v súvislosti s vyššie uvedenými účelmi sa neuskutočňuje.

Poskytovanie osobných údajov v súvislosti s vyššie uvedenými účelmi je zákonnou požiadavkou.

Prevádzkovateľ informuje dotknutú osobu, že v súvislosti s vyššie uvedenými účelmi vykonáva automatizované rozhodovanie a profilovanie. V rámci toho prostredníctvom technickej operácie zisťuje, o čo používateľ na digitálnom mieste prejavil záujem, a na základe toho ďalej zisťuje, či bol záujem premietnutý v obchod, a ak nie, tak mu do budúcna zasiela informácie digitálnou formou o možnom uzatvorení obchodu (napr. formou newsletteru, obchodnej resp. reklamnej ponuky formou e-mailu alebo SMS a pod.). Táto činnosť sa vykonáva v záujme zabezpečenia lepšieho používateľského zážitku a z pohľadu dotknutej osoby okrem ponuky produktov a služieb nepredstavuje pre ňu žiadne iné nebezpečenstvo ani následky.

Medzi práva dotknutej osoby patria:  
Právo na prístup k svojim osobným údajom  
Právo na opravu svojich osobných údajov  
Právo na výmaz svojich osobných údajov (právo „na zabudnutie“)  
Právo na obmedzenie spracúvania svojich osobných údajov  
Právo namietať proti spracúvaniu svojich osobných údajov  
Právo na prenosnosť svojich osobných údajov  
Právo podať sťažnosť dozornému orgánu, t.j. Úradu na ochranu osobných údajov SR  
Právo namietať automatizované individuálne rozhodovanie a profilovanie   
Právo podať návrh na začatie konania podľa § 100 zákona o ochrane osobných údajov  
Právo na odvolanie súhlasu s poskytnutím osobných údajov  
Kontaktné údaje zodpovednej osoby:   
Mgr. Štefan Lazorčák  
e-mail: info@bilionbuy.com

[image1]: https://bbuy.fra1.cdn.digitaloceanspaces.com/terms/terms_sk.png
`,
    cs: ` Neprolozeno`,
    en: ` Not yet translated`,
    pl: ` Neprolzeno`,
  };

  const terms = termsContent[languageKey] || termsContent["sk"]; // Default to 'sk' if language not found

  return (
    <>
      <BasicNavbar />
      <div className="text-center my-12">
        <h1 className="text-5xl font-semibold">Obchodné podmienky</h1>
      </div>
      <div className="quill-content max-w-5xl">
        <ReactMarkdown>{terms}</ReactMarkdown>
      </div>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
