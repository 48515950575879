import React, { useEffect, useState } from "react";
import api from "../services/api";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/system";
import { Avatar } from "@nextui-org/react";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { RxAvatar } from "react-icons/rx";
import Translate from "./TranslationAPI";

const DisplayBar = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${LinearProgress.root}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${LinearProgress.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ProductReview = ({ reviewData, t }) => {
  const pros = JSON.parse(reviewData.pros);
  const cons = JSON.parse(reviewData.cons);

  return (
    <div>
      <div>
        <hr />
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2 items-center mt-3">
          <div>
            <Avatar
              src={reviewData.user_image}
              fallback={<RxAvatar className="text-white text-4xl" />}
            />
          </div>
          <div>
            <div className="font-semibold">
              {reviewData.user_info[1] ? (
                <span>
                  {reviewData.user_info[1]} {reviewData.user_info[2]}{" "}
                </span>
              ) : (
                reviewData.user_info[0]
              )}
            </div>
            <div className="text-sm text-lightgray">
              {reviewData.formatted_date}
            </div>
          </div>
        </div>
        {reviewData.status === "verified" ? (
          <div className="flex flex-row gap-2 text-primary items-center">
            {t("Overená recenzia")} <FaCheck />
          </div>
        ) : (
          <div className="flex flex-row gap-2 text-lightgray items-center text-right">
            {t("Neoverená recenzia")}
          </div>
        )}
      </div>
      <div className="ml-12 mt-2">
        <div className="flex flex-row items-center gap-2">
          <div className="font-bold text-primary">
            {(reviewData.mark / 5) * 100} %{" "}
          </div>
          <div>
            <DisplayBar
              variant="determinate"
              value={(reviewData.mark / 5) * 100}
              className="w-32"
            />
          </div>
        </div>
        <div className="mt-2">
          <div className="flex flex-row gap-12">
            {" "}
            <div className="flex flex-col text-success">
              <div className="flex items-center"></div>
              <ul className="">
                {pros.map((pro, index) => (
                  <li key={index} className="flex items-center">
                    <FaPlusCircle className="mr-2" /> <Translate text={pro} />
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex flex-col text-danger ">
              <div className="flex items-center"></div>
              <ul className="">
                {cons.map((con, index) => (
                  <li key={index} className="flex items-center">
                    <FaMinusCircle className="mr-2" /> <Translate text={con} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <Translate text={reviewData.text} />
        </div>
      </div>
    </div>
  );
};

const ProductReviews = ({ productId, t }) => {
  const [reviews, setReviews] = useState({
    reviews: [],
    total_recommends: 0,
    total_no_recommends: 0,
  });

  const fetchReviews = async () => {
    try {
      const response = await api.get("get-product-reviews/", {
        params: { product_id: productId },
      });
      setReviews(response.data);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, [productId]);

  return (
    <div className="flex flex-col gap-6 xl:px-0 px-4">
      <div className="bg-white rounded-md p-4">
        <div className="flex md:flex-row flex-wrap md:justify-between justify-center">
          <div className="">
            <div className="text-xl">{t("Hodnotenia produktu")}</div>
            <div className="flex flex-col justify-center">
              <div className="text-5xl font-semibold text-center mt-4">
                {reviews.average_mark} %
              </div>
              <div className="mx-auto mt-2">
                <DisplayBar
                  variant="determinate"
                  value={reviews.average_mark}
                  className="w-32"
                />
              </div>
              <div className="text-center mt-4 text-lightgray">
                {t("priemer z")} {reviews.total_reviews} {t("hodnotení")}
              </div>
            </div>
          </div>
          <div className="flex flex-col text-lightgray">
            <div className="flex flex-row items-center mt-8">
              5 &nbsp;
              <DisplayBar
                variant="determinate"
                value={(reviews.total_5_star / reviews.total_reviews) * 100}
                className="w-64"
              />{" "}
              &nbsp;
              {reviews.total_5_star} x
            </div>
            <div className="flex flex-row items-center">
              4 &nbsp;
              <DisplayBar
                variant="determinate"
                value={(reviews.total_4_star / reviews.total_reviews) * 100}
                className="w-64"
              />{" "}
              &nbsp;
              {reviews.total_4_star} x
            </div>
            <div className="flex flex-row items-center">
              3 &nbsp;
              <DisplayBar
                variant="determinate"
                value={(reviews.total_3_star / reviews.total_reviews) * 100}
                className="w-64"
              />{" "}
              &nbsp;
              {reviews.total_3_star} x
            </div>
            <div className="flex flex-row items-center">
              2 &nbsp;
              <DisplayBar
                variant="determinate"
                value={(reviews.total_2_star / reviews.total_reviews) * 100}
                className="w-64"
              />{" "}
              &nbsp;
              {reviews.total_2_star} x
            </div>
            <div className="flex flex-row items-center">
              1 &nbsp;
              <DisplayBar
                variant="determinate"
                value={(reviews.total_1_star / reviews.total_reviews) * 100}
                className="w-64"
              />{" "}
              &nbsp;
              {reviews.total_1_star} x
            </div>
          </div>
          <div className="flex md:flex-row flex-wrap gap-4 mt-4 justify-center md:justify-start">
            <div className="">
              <span className="bg-success-50 p-2 px-4 rounded-3xl">
                {reviews.total_recommends} {t("zákazníkov odporúča produkt")}
              </span>
            </div>
            <div>
              <span className="bg-danger-50 p-2 px-4 rounded-3xl">
                {reviews.total_no_recommends}{" "}
                {t("zákazníkov neodporúča produkt")}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <div>Vybraná recenzia</div> */}
      <div className="bg-white rounded-md p-4">
        {reviews.reviews.map((review, index) => (
          <ProductReview key={index} reviewData={review} t={t} />
        ))}
      </div>
    </div>
  );
};

export default ProductReviews;
