import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { Save } from "@mui/icons-material";
import { toast } from "react-toastify";
import api from "../../../services/api";

const NewParameterInput = React.memo(({ product, param }) => {
  const [localValue, setLocalValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    setIsEditing(newValue !== "");
  };

  const handleSave = async () => {
    try {
      await api.post(`/products/${product.id}/parameters/`, {
        parameter_id: param.id,
        value: localValue,
      });
      toast.success("New product parameter saved");
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving new parameter:", error);
      toast.error("Failed to save new parameter. Please try again.");
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        value={localValue}
        onChange={handleChange}
        variant="outlined"
        size="small"
        placeholder="New value"
      />
      {isEditing && (
        <Button
          onClick={handleSave}
          startIcon={<Save />}
          size="small"
          style={{ marginLeft: 8 }}
        >
          Save
        </Button>
      )}
    </div>
  );
});

export default NewParameterInput;
