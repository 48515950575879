import React, { useState, useEffect, useCallback } from "react";
import ItemCard from "./ItemCard";
import useEmblaCarousel from "embla-carousel-react";
import { useTranslation } from "react-i18next";

const SimilarProducts = ({ productId }) => {
  const { t } = useTranslation();
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [chunkSize, setChunkSize] = useState(4);

  const fetchSimilarProducts = async () => {
    setLoading(true);
    setError(null);
    try {
      // Replace this with your actual API call
      const response = await fetch(`/api/similar-products/${productId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch similar products");
      }
      const data = await response.json();
      setSimilarProducts(data);
    } catch (error) {
      console.error("Error fetching similar products:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSimilarProducts();
  }, [productId]);

  // Function to handle screen resizing and adjust chunk size
  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    if (width >= 1024) {
      setChunkSize(4); // Large screens
    } else if (width >= 768) {
      setChunkSize(2); // Medium screens
    } else {
      setChunkSize(1); // Small screens
    }
  }, []);

  useEffect(() => {
    handleResize(); // Initial check on mount
    window.addEventListener("resize", handleResize); // Listen for window resizing
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const previousSlide = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const nextSlide = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  // Function to split the products array into chunks
  const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };

  if (loading) {
    return null; // Or you could return a loading spinner if preferred
  }

  if (error || similarProducts.length === 0) {
    return null; // Return nothing if there's an error or no similar products
  }

  const productChunks = chunkArray(similarProducts, chunkSize);

  return (
    <div className="max-w-screen-2xl mx-auto px-4 md:px-8">
      <div className="flex flex-col lg:flex-row justify-between py-8 md:py-12">
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold">
          {t("Podobné produkty")}
        </h2>
        <p className="text-md md:text-lg lg:text-xl font-light max-w-[700px] mt-4 lg:mt-0">
          {/* {t("Objavte ďalšie produkty, ako je tento")} */}
        </p>
      </div>
      <div className="relative flex items-center">
        <div
          className="absolute -left-4 md:-left-8 lg:-left-12 flex items-center hover:cursor-pointer z-10"
          onClick={previousSlide}
          aria-label="Previous slide"
        >
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            focusable="false"
          >
            <path d="M23 34.5L11.5 23L23 11.5" stroke="black" strokeWidth="5" />
            <path
              d="M34.5 34.5L23 23L34.5 11.5"
              stroke="black"
              strokeWidth="5"
            />
          </svg>
        </div>

        <div
          className="embla overflow-hidden py-8 md:py-12 relative"
          ref={emblaRef}
        >
          <div className="embla__container flex relative">
            {productChunks.map((chunk, index) => (
              <div
                className="embla__slide flex-[0_0_100%] flex justify-between px-4 md:px-8"
                key={index}
              >
                {chunk.map((product, i) => (
                  <div
                    className="w-full sm:w-1/2 lg:w-1/4 px-2 md:px-4 flex justify-center"
                    key={i}
                  >
                    <ItemCard product={product} type="similar" />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="absolute top-0 bottom-0 left-0 w-12 md:w-16 lg:w-24 bg-gradient-to-r from-white to-transparent pointer-events-none"></div>
          <div className="absolute top-0 bottom-0 right-0 w-12 md:w-16 lg:w-24 bg-gradient-to-l from-white to-transparent pointer-events-none"></div>
        </div>

        <div
          className="absolute -right-4 md:-right-8 lg:-right-12 flex items-center hover:cursor-pointer z-10"
          onClick={nextSlide}
          aria-label="Next slide"
        >
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            focusable="false"
          >
            <path d="M23 34.5L34.5 23L23 11.5" stroke="black" strokeWidth="5" />
            <path
              d="M11.5 34.5L23 23L11.5 11.5"
              stroke="black"
              strokeWidth="5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SimilarProducts;
