import { IoMdArrowDropright } from "react-icons/io";
import { useTranslation } from "react-i18next";
export default function StatItem({
  title,
  subtitle,
  value,
  stat,
  timespan,
  color,
  oc,
  dc,
  mlc,
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4 bg-slate-50 p-8 ">
      <div className="mb-2">
        <div className="uppercase font-semibold">{t(title)}</div>
        {subtitle && (
          <div className="text-gray-500 uppercase text-xs">{t(subtitle)}</div>
        )}
      </div>
      <div className="flex flex-col gap-1">
        {oc !== undefined && (
          <div className="uppercase text-sm">
            <span className="font-bold">{t("Vlastný cashback")}</span>: {oc} €
          </div>
        )}
        {dc !== undefined && (
          <div className="uppercase text-sm">
            <span className="font-bold">{t("Priamy cashback")}</span>: {dc} €
          </div>
        )}
        {mlc !== undefined && (
          <div className="uppercase text-sm">
            <span className="font-bold">{t("Cashback zo štruktúry")}</span>:{" "}
            {mlc} €
          </div>
        )}
      </div>{" "}
      <div className={`font-bold text-2xl text-${color}`}>{value}</div>
      <div className={`bg-${color} h-[4px]`}>&nbsp;</div>
      {stat ? (
        <div className="flex flex-row items-center">
          <IoMdArrowDropright className={`text-green-500 -rotate-90`} />
          <span className="font-bold">{stat}</span> &nbsp;
          <span className="text-lightgray uppercase text-xs items-center">
            {t(timespan)}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
