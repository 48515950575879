import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import api from "../../services/api";

export default function WalletTransactions({ EmptyState }) {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTransactions = async (pageNum = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/get-user-wallet-transactions/?page=${pageNum}`
      );
      const transactionsData = Array.isArray(response.data)
        ? response.data
        : [];

      if (pageNum === 1) {
        setTransactions(transactionsData);
      } else {
        setTransactions((prev) => [...prev, ...transactionsData]);
      }
      setHasMore(transactionsData.length === 10);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions(1);
  }, []);

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchTransactions(nextPage);
  };

  const formatAmount = (amount) => {
    const numAmount = parseFloat(amount);
    const prefix = numAmount >= 0 ? "+ " : "- ";
    return `${prefix}${Math.abs(numAmount).toFixed(2)}`;
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("sk-SK", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  if (isLoading) {
    return <EmptyState message={t("Načítavam...")} />;
  }

  if (!transactions || transactions.length === 0) {
    return <EmptyState message={t("Žiadne transakcie")} />;
  }

  return (
    <div>
      <Typography variant="h5" component="h2" className="mb-4">
        {t("História pohybov v elektronickej peňaženke")}
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>{t("Dátum")}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{t("Suma")}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                {t("Popis pohybu")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  {formatDate(transaction.date)}
                </TableCell>
                <TableCell
                  className={
                    parseFloat(transaction.amount) >= 0
                      ? "text-green-600"
                      : "text-red-600"
                  }
                  style={{ whiteSpace: "nowrap" }}
                >
                  {formatAmount(transaction.amount)}
                </TableCell>
                <TableCell>{transaction.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {hasMore && (
        <div className="flex justify-center mt-4">
          <Button
            variant="outlined"
            onClick={handleLoadMore}
            disabled={isLoading}
          >
            {isLoading ? t("Načítavam...") : t("Zobraziť viac")}
          </Button>
        </div>
      )}
    </div>
  );
}
