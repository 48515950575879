import React, { useState, useEffect } from "react";
import api from "../services/api";
import {
  Checkbox,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Spinner,
  Skeleton,
} from "@nextui-org/react";
import BlogCreateOrEdit from "../components/BlogCreateOrEdit";
import { motion, AnimatePresence } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function BlogsTable() {
  const [blogs, setBlogs] = useState([]);
  const [openBlogId, setOpenBlogId] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [deleteBlogId, setDeleteBlogId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      setIsFetching(true);
      const response = await api.get("list-blogs/");
      setBlogs(response.data);
      setIsFetching(false);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const toggleEditForm = (blogId) => {
    setOpenBlogId((prevId) => (prevId === blogId ? null : blogId));
    setIsCreating(false); // Close the create form if open
  };

  const toggleCreateForm = () => {
    // Close any open edit form before toggling create form
    setOpenBlogId(null);
    setIsCreating((prev) => !prev);
  };

  const confirmDeleteBlog = (blogId) => {
    console.log("Confirm delete blog with id:", blogId);
    setDeleteBlogId(blogId);
    onOpen();
  };

  const deleteBlog = async (onClose) => {
    console.log("Deleting blog with id:", deleteBlogId);
    setLoading(true);
    try {
      const response = await api.post(`delete-blog/${deleteBlogId}/`);
      console.log(response.data);
      // Remove the deleted blog from the state
      setBlogs((prevBlogs) =>
        prevBlogs.filter((blog) => blog.id !== deleteBlogId)
      );
      onClose();
      setDeleteBlogId(null);
      toast.success("Blog deleted successfully!");
    } catch (error) {
      console.error("Error deleting blog:", error);
      toast.error("Failed to delete blog.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateOrCreateSuccess = (message) => {
    toast.success(message);
    fetchBlogs();
    setIsCreating(false);
    setOpenBlogId(null);
  };

  const handleUpdateOrCreateError = (message) => {
    toast.error(message);
  };

  const slideAnimation = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: "auto" },
    exit: { opacity: 0, height: 0 },
  };

  return (
    <>
      <div className="p-4 border-1 rounded-xl m-4 shadow-md">
        <div className="flex flex-row justify-between">
          <div className="text-3xl font-bold">Blogy</div>
          <Button
            className="mr-16 bg-black text-white font-bold py-2 px-8 rounded-lg"
            onPress={toggleCreateForm}
            isDisabled={isFetching}
          >
            Vytvoriť +
          </Button>
        </div>

        <hr className="my-4" />
        <div>
          {isFetching ? (
            <div>
              {Array(5)
                .fill()
                .map((_, index) => (
                  <div key={index} className="mb-4">
                    <Skeleton height="20px" width="80%" className="mb-2" />
                    <Skeleton height="20px" width="60%" />
                  </div>
                ))}
            </div>
          ) : blogs.length === 0 && !isCreating ? (
            <div>
              <p>No blog entries found.</p>
            </div>
          ) : (
            <table className="table-auto w-full items-center text-center">
              <thead>
                <tr className="">
                  <th>Titulok</th>
                  <th>Kategória</th>
                  <th>Dátum</th>
                  <th>Možnosti</th>
                </tr>
              </thead>
              <tbody>
                <AnimatePresence>
                  {isCreating && blogs.length === 0 && (
                    <motion.tr
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={slideAnimation}
                      transition={{ duration: 0.5 }}
                    >
                      <td colSpan="5" className="text-left p-12">
                        <hr className="mb-4" />
                        <BlogCreateOrEdit
                          create={true}
                          blogContent={{}} // Pass an empty object
                          onSuccess={() =>
                            handleUpdateOrCreateSuccess(
                              "Blog created successfully!"
                            )
                          }
                          onError={() =>
                            handleUpdateOrCreateError("Failed to create blog.")
                          }
                        />
                      </td>
                    </motion.tr>
                  )}
                  {blogs.map((blog) => (
                    <React.Fragment key={blog.id}>
                      <tr className="hover:bg-slate-50">
                        <td>
                          <a href={"/blog/" + blog.id}>{blog.title_sk}</a>
                        </td>
                        <td>{blog.category}</td>
                        <td>
                          {new Date(blog.date_modified).toLocaleDateString()}
                        </td>
                        <td className="flex flex-row gap-4 justify-center">
                          <div
                            onClick={() => toggleEditForm(blog.id)}
                            className="text-warning border-1 border-warning px-4 rounded-lg font-semibold hover:text-white hover:bg-warning cursor-pointer"
                          >
                            Edit
                          </div>
                          <div
                            onClick={() => confirmDeleteBlog(blog.id)}
                            className="text-danger border-1 border-danger px-4 rounded-lg font-semibold hover:text-white hover:bg-danger cursor-pointer"
                          >
                            Delete
                          </div>
                        </td>
                      </tr>
                      <AnimatePresence>
                        {openBlogId === blog.id && (
                          <motion.tr
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={slideAnimation}
                            transition={{ duration: 0.5 }}
                          >
                            <td colSpan="5" className="text-left p-12">
                              <hr className="mb-4" />
                              <BlogCreateOrEdit
                                blogContent={blog}
                                onSuccess={() =>
                                  handleUpdateOrCreateSuccess(
                                    "Blog updated successfully!"
                                  )
                                }
                                onError={() =>
                                  handleUpdateOrCreateError(
                                    "Failed to update blog."
                                  )
                                }
                              />
                            </td>
                          </motion.tr>
                        )}
                      </AnimatePresence>
                    </React.Fragment>
                  ))}
                </AnimatePresence>
              </tbody>
            </table>
          )}
          {isCreating && blogs.length !== 0 && (
            <AnimatePresence>
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={slideAnimation}
                transition={{ duration: 0.5 }}
                className="mt-4"
              >
                <BlogCreateOrEdit
                  create={true}
                  blogContent={{}} // Pass an empty object
                  onSuccess={() =>
                    handleUpdateOrCreateSuccess("Blog created successfully!")
                  }
                  onError={() =>
                    handleUpdateOrCreateError("Failed to create blog.")
                  }
                />
              </motion.div>
            </AnimatePresence>
          )}
        </div>
      </div>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Potvrďte vymazanie
              </ModalHeader>
              <ModalBody>
                <p>
                  Určite chcete tento blog vymazať? Túto akciu nemožno vrátiť
                  späť.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Zrušiť
                </Button>
                <Button color="primary" onPress={() => deleteBlog(onClose)}>
                  {loading ? <Spinner /> : "Vymazať"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <ToastContainer />
    </>
  );
}
