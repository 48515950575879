import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  CodeBracketIcon,
  EllipsisVerticalIcon,
  FlagIcon,
  StarIcon,
} from "@heroicons/react/20/solid";
import { RxCross2 } from "react-icons/rx";
import { FaRegCheckCircle } from "react-icons/fa";
import { Spinner, Button, Image } from "@nextui-org/react";
import { useRef, useState, useEffect } from "react";
import api from "../services/api";
import { debounce } from "lodash";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RegisteredClick() {
  const [isLoading, setIsLoading] = useState(false);
  const [shopname, setShopname] = useState("");
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10); // Added state for page size
  const [isDisabled, setIsDisabled] = useState(false);
  const [regByShop, setRegByShop] = useState([]);
  const [casbackAviable, setCasbackAviable] = useState([]);

  const fetchFilteredData = async () => {
    setIsDisabled(true);
    setIsLoading(true);
    const baseUrl = "get-user-clicks/";
    let query = `?shopname=${shopname}&page_size=${pageSize}&page=${currentPage}`;

    // Adjust query based on 'all' value
    query = query.replace(/=all/g, "");
    const token = localStorage.getItem("jwt");
    console.log(token);
    try {
      const response = await api.get(baseUrl + query);
      //   const data = await response.json();
      setItems(response.data.results); // Set shop data
      setTotalPages(response.data.total_pages); // Set total page count
      setCurrentPage(response.data.current_page); // Update current page
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsDisabled(false);
    setIsLoading(false);
  };

  const debouncedFetchFilteredData = debounce(fetchFilteredData, 300);

  // Button click handler
  const handleClick = () => {
    setIsDisabled(true);
    debouncedFetchFilteredData();
    setTimeout(() => {
      setIsDisabled(false);
    }, 2000);
  };

  const handleSetCurrentPage = (page) => {
    setIsDisabled(true);
    setCurrentPage(page);
    setTimeout(() => {
      setIsDisabled(false);
    }, 300);
  };
  useEffect(() => {
    debouncedFetchFilteredData();
  }, [currentPage, pageSize]);

  return (
    <>
      {isLoading ? (
        <div className="text-center">
          <Spinner></Spinner>
        </div>
      ) : (
        <ul>
          {items.map((item) => (
            <li key={item.id}>
              <div className="bg-white px-4 py-5 sm:px-6 hover:bg-[#F5F5F5] shadow-lg my-4">
                <div className="flex space-x-3">
                  <div className="flex-shrink-0">
                    <div className="w-12 h-12">
                      <Image
                        isBlurred
                        className="my-auto rounded-none"
                        src={item.product_img_url}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="text-sm font-semibold text-gray-900">
                      <a href={item.product_url} className="">
                        {item.product_name}
                      </a>
                    </p>
                    <p>{item.shop_name}</p>
                    <p className="text-sm text-gray-500">
                      <a className="">{item.formatted_date}</a>
                    </p>
                  </div>
                  <div className="min-w-0 flex flex-col items-center">
                    <p className="text-sm text-slate-400">
                      Registrované obchodom
                    </p>
                    <p className="my-auto">
                      {!item.regByShop ? (
                        <RxCross2 color="red" />
                      ) : (
                        <FaRegCheckCircle color="green" />
                      )}{" "}
                    </p>
                  </div>
                  <div className="min-w-0 flex flex-col items-center">
                    <p className="text-sm text-slate-400">Nárok na províziu</p>
                    <p className="my-auto">
                      {!item.cashback_aviable ? (
                        <RxCross2 color="red" />
                      ) : (
                        <FaRegCheckCircle color="green" />
                      )}
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 self-center">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "flex px-4 py-2 text-sm"
                                  )}
                                >
                                  <StarIcon
                                    className="mr-3 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span>Sledovať</span>
                                </a>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "flex px-4 py-2 text-sm"
                                  )}
                                >
                                  <FlagIcon
                                    className="mr-3 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span>Nahlásiť námietku</span>
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className="flex gap-5 justify-end">
        <Button className="bg-white ring-1 ring-gray-300 shadow-lg">
          &larr;
        </Button>
        <Button className="bg-white ring-1 ring-gray-300 shadow-lg">
          &rarr;
        </Button>
      </div>
    </>
  );
}
