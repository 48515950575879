import React, { useState, useEffect } from "react";
import { Button, Link } from "@nextui-org/react";
import Cookies from "js-cookie";
import { motion } from "framer-motion";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", "accepted", { expires: 365 });
    document.cookie = "G_ENABLED_IDPS=google; SameSite=None; Secure";

    setIsVisible(false);
  };

  const handleReject = () => {
    Cookies.set("cookieConsent", "rejected", { expires: 365 });
    setIsVisible(false);
    // Logic to disable non-essential cookies
  };

  if (!isVisible) {
    return null;
  }

  // Animation variants
  const containerVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", damping: 25, stiffness: 120 },
    },
    exit: { y: 50, opacity: 0, transition: { duration: 0.3 } },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
      className="fixed bottom-0 left-0 right-0 z-[9999]"
    >
      <div className="sticky mx-auto max-w-3xl mb-8 bg-white text-black p-4 justify-between items-center flex flex-col shadow-lg border-1 rounded-md gap-8">
        <div className="pt-8 font-semibold text-center">
          Používame súbory cookies na zaznamenanie vašich nákupov za účelom
          vyplatenia cashbacku priamo vám ako užívateľovi. <br />
          <br />
          Za týmto účelom je nevyhnutné aby ste vypli akékoľvek{" "}
          <strong>aplikácie na blokovanie reklám</strong> a{" "}
          <strong>prijali súbory cookie</strong> na našej webstránke, ale aj na{" "}
          <strong>webstránkach našich partnerov</strong> po prekliku do ich
          internetového obchodu.
          {/* <br />
          <br />
          Používame súbory cookie na zlepšenie{" "}
          <Link href="/">Vášho zážitku</Link>. <br />
          Pokračovaním súhlasíte s našimi{" "}
          <Link href="/terms-and-conditions">
            zásadami používania súborov cookie
          </Link>
          . */}
        </div>
        <div className="flex flex-row gap-4">
          <Button onClick={handleAccept} className="bg-blue text-white w-36">
            Prijať
          </Button>
          <Button onClick={handleReject} className="bg-red-500 text-white w-36">
            Odmietnuť
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

export default CookieConsent;
