import React from "react";
import { Card, CardBody, Image } from "@nextui-org/react";

export const ProductCard = ({ itemData, onClick, t }) => {
  return (
    <Card
      isBlurred
      className="border-none md:w-[450px] w-[320px] cursor-pointer"
      shadow="md"
      onClick={onClick}
    >
      <CardBody>
        <div className="flex flex-row items-center gap-4 overflow-hidden">
          <div className="">
            <Image
              alt={itemData.product_name}
              className="object-contain w-12 h-12 max-w-[4rem] max-h-[4rem]"
              shadow="none"
              src={itemData.product_image_url}
            />
          </div>
          <div className="">
            <div className="">
              <div className="">
                <h3 className="font-semibold text-foreground/90 md:line-clamp-1 line-clamp-2 md:w-auto w-[220px]">
                  {itemData.product_name}
                </h3>
                <h1 className="text-xs  line-clamp-2 w-[220px]">
                  {itemData.assigned_category_name
                    ? itemData.assigned_category_name + " | "
                    : ""}{" "}
                  {itemData.price_range}
                  {itemData.offer_count > 1 ? (
                    <>
                      <br />
                      {t("Počet ponúk")}: {itemData.offer_count}
                    </>
                  ) : null}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
