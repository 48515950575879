import { IoMdCheckmark } from "react-icons/io";
import { HiCursorClick } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { Tooltip } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Spinner } from "@nextui-org/react";
import { Accordion, AccordionItem } from "@nextui-org/react";

import api from "../services/api";

const MyReviews = ({}) => {
  const [reviews, setInvites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await api.get("get-my-reviews/");
      setInvites(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="my-8 ">
      <div className="shadow-md border-1 p-8">
        <Accordion variant="light">
          <AccordionItem key="1" aria-label="Pozvánky" title="Moje recenzie">
            <table className="w-full text-left mt-4">
              <thead className="">
                <th className="p-0">Produkt</th>
                <th className="p-0">Dátum</th>

                <th className="p-0">Stav</th>
              </thead>
              <tbody>
                {isLoading ? (
                  <Spinner className="mx-auto justify-center" />
                ) : (
                  <>
                    {reviews.map((review) => (
                      <tr key={review.id} className="hover:bg-slate-50 text-sm">
                        <td className="">{review.product_name}</td>
                        <td>{review.formatted_date}</td>

                        <td className="">
                          <div>
                            {review.state === "confirmed" && (
                              <Tooltip
                                content="Overená recenzia"
                                placement="right"
                                className="text-white bg-green-500 text-sm "
                              >
                                <div>
                                  <IoMdCheckmark className="text-green-500 ml-4" />
                                </div>
                              </Tooltip>
                            )}
                            {review.state === "valid" && (
                              <Tooltip
                                content="Rezenzia je platná"
                                placement="right"
                                className="text-white bg-yellow-600 text-sm "
                              >
                                <div>
                                  <HiCursorClick className="text-yellow-600 ml-4" />{" "}
                                </div>
                              </Tooltip>
                            )}
                            {review.state === "pending" && (
                              <Tooltip
                                content="Rezenie nieje overená"
                                placement="right"
                                className="text-white bg-red-600 text-sm "
                              >
                                <div>
                                  <RxCross2 className="text-red-600 ml-4" />{" "}
                                </div>
                              </Tooltip>
                            )}{" "}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default MyReviews;
