import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  categories,
  foodCategories,
  servicesCategories,
  travelCategories,
  eventCategories,
  bazarCategories,
} from "../data/categories";
import ShopCardLarge from "./ShopCardLarge";

const ProductCategories = ({ category }) => {
  const [openCategory, setOpenCategory] = useState(null);
  const [activeButton, setActiveButton] = useState("categories");

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const subcategoriesRef = useRef(null);
  const shopsRef = useRef(null);

  const getCategoryName = (category) => {
    const currentLang = i18n.language;
    return currentLang === "cs"
      ? category.category_name_cz
      : category.category_name_sk;
  };

  const handleCategoryClick = (category) => {
    const categoryName = getCategoryName(category);

    if (openCategory === categoryName) {
      setOpenCategory(null);
      return;
    }

    setOpenCategory(categoryName);
  };

  const categoryMap = {
    shopping: categories,
    food: foodCategories,
    travel: travelCategories,
    services: servicesCategories,
    event: eventCategories,
    second_hand: bazarCategories,
  };

  const productCategories = categoryMap[category] || [];

  const scrollToSubcategories = () => {
    setActiveButton("categories");
    if (subcategoriesRef.current) {
      const headerOffset = 160; // Adjust this value to match the height of your sticky header
      const elementPosition =
        subcategoriesRef.current.getBoundingClientRect().top +
        window.pageYOffset;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const navigateToCatalog = () => {
    setActiveButton("catalog");
    navigate(`/category/${openCategory}`);
  };

  return (
    <div className="px-8 py-6 max-w-screen-2xl border-bottom-2 mx-auto bg-white">
      <h1 className="text-3xl font-bold pb-3 uppercase lg:block hidden">
        {t("Kategórie")}
      </h1>
      <hr />

      <div className="flex flex-col gap-4">
        {/* Categories Menu */}
        <div className="flex flex-wrap text-lg py-6">
          {productCategories.map((categoryItem) => {
            const categoryName = getCategoryName(categoryItem);
            return (
              <div
                key={categoryName}
                className={`flex items-center p-2 rounded-lg cursor-pointer m-2 ${
                  openCategory === categoryName
                    ? "bg-gray-200"
                    : "hover:bg-slate-50"
                }`}
                onClick={() => handleCategoryClick(categoryItem)}
              >
                <h2 className="text-lg font-semibold">
                  <div className="flex flex-row gap-2 items-center">
                    <categoryItem.icon />
                    {categoryName}
                  </div>
                </h2>
              </div>
            );
          })}
        </div>

        {openCategory && (
          <>
            {/* Updated left-aligned buttons with active state, visible only for shopping category */}
            {category === "shopping" && (
              <div className="flex mb-8 ml-4 gap-8 flex-wrap">
                <button
                  className={`text-lightgray hover:text-black transition-colors duration-300 ${
                    activeButton === "categories"
                      ? "text-white bg-primary px-4 py-1 rounded-lg"
                      : ""
                  }`}
                  onClick={scrollToSubcategories}
                >
                  {t("Kategórie")}
                </button>
                <button
                  className={`text-lightgray hover:text-black transition-colors duration-300 ${
                    activeButton === "catalog"
                      ? "text-white bg-primary px-4 py-1 rounded-lg"
                      : ""
                  }`}
                  onClick={navigateToCatalog}
                >
                  {t("Katalóg")}
                </button>
              </div>
            )}
            <hr />

            {/* Subcategories Section */}
            <div ref={subcategoriesRef}>
              <div className="px-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-4 pt-4 mb-4">
                  {(
                    productCategories.find(
                      (cat) => getCategoryName(cat) === openCategory
                    )?.subcategories || []
                  ).map((subcategory) => (
                    <div key={subcategory.category_name} className="py-1">
                      <a
                        href={"/category/" + subcategory.category_name}
                        className="font-bold"
                      >
                        {subcategory.category_name}
                      </a>
                      <div className="text-lightgray flex flex-col gap-2 pt-2">
                        {subcategory.subcategories.slice(0, 5).map((subsub) => (
                          <a key={subsub} href={"/category/" + subsub}>
                            {subsub}
                          </a>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Shops Section - Now independent from subcategories */}
            <div ref={shopsRef} className="px-8">
              <div className="flex flex-wrap gap-4 mb-12">
                {/* Assuming shops data is available in the category object */}
                {(
                  productCategories.find(
                    (cat) => getCategoryName(cat) === openCategory
                  )?.shops || []
                ).map((shop) => (
                  <div
                    key={shop.id}
                    className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
                  >
                    <ShopCardLarge shop={shop} />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <hr />
    </div>
  );
};

export default ProductCategories;
