import { IoMdCheckmark } from "react-icons/io";
import { HiCursorClick } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { Tooltip } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Spinner } from "@nextui-org/react";
import { Accordion, AccordionItem } from "@nextui-org/react";

import api from "../services/api";

const InvitesTables = ({}) => {
  const [invites, setInvites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await api.get("get-invites/");
      setInvites(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="my-8 ">
      <div className="shadow-md border-1 p-8">
        {/* <div className="text-2xl font-bold">Pozvánky</div> */}
        <Accordion variant="light">
          <AccordionItem key="1" aria-label="Pozvánky" title="Pozvánky">
            <table className="w-full text-left mt-4">
              <thead className="">
                <th className="p-0">Email</th>
                <th className="p-0">Dátum</th>

                <th className="p-0">Stav</th>
              </thead>
              <tbody>
                {isLoading ? (
                  <Spinner className="mx-auto justify-center" />
                ) : (
                  <>
                    {invites.map((invite) => (
                      <tr key={invite.id} className="hover:bg-slate-50 text-sm">
                        <td className="">{invite.email}</td>
                        <td>{invite.formatted_date}</td>

                        <td className="">
                          <div>
                            {invite.status === "registered" && (
                              <Tooltip
                                content="Užívateľ sa zaregistroval"
                                placement="right"
                                className="text-white bg-green-500 text-sm "
                              >
                                <div>
                                  <IoMdCheckmark className="text-green-500 ml-4" />
                                </div>
                              </Tooltip>
                            )}
                            {invite.status === "clicked" && (
                              <Tooltip
                                content="Užívateľ klikol na pozvánku"
                                placement="right"
                                className="text-white bg-yellow-600 text-sm "
                              >
                                <div>
                                  <HiCursorClick className="text-yellow-600 ml-4" />{" "}
                                </div>
                              </Tooltip>
                            )}
                            {invite.status === "pending" && (
                              <Tooltip
                                content="Užívateľ neotvoril pozvánku"
                                placement="right"
                                className="text-white bg-red-600 text-sm "
                              >
                                <div>
                                  <RxCross2 className="text-red-600 ml-4" />{" "}
                                </div>
                              </Tooltip>
                            )}{" "}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
              {/* <tr>
          <td className="p-4">test@email.com</td>
          <td className="p-4">
            <IoMdCheckmark className="text-green-500 ml-4" />
            <HiCursorClick className="text-yellow-600  ml-4" />
            <RxCross2 className="text-red-600  ml-4" />
          </td>
        </tr> */}
            </table>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="shadow-md border-1 p-8 my-8">
        <Accordion variant="light">
          <AccordionItem
            key="1"
            aria-label="Moja štruktúra"
            title="Moja štruktúra"
          ></AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default InvitesTables;
