import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  InputLabel,
  Select,
  Drawer, // Import Drawer component for side menu
  Pagination, // Add this import
} from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import { Spinner } from "@nextui-org/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import { IoIosArrowBack } from "react-icons/io"; // Import back arrow icon
import { MdFilterList } from "react-icons/md"; // Import filter icon
import { Skeleton } from "@nextui-org/react"; // Add this import
import StorefrontIcon from "@mui/icons-material/Storefront"; // Import the shop icon

const CustomMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    boxShadow: "none", // Remove shadow
    border: "1px solid #ddd", // Optional: Add border
  },
}));

export default function Clicks({ onMenuToggle, t }) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);
  const [ticketMessage, setTicketMessage] = useState("");
  const [filter, setFilter] = useState(0);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false); // State for filter drawer
  const [pageSize, setPageSize] = useState(5); // Add this state for page size
  const [statusFilter, setStatusFilter] = useState("all");
  const [userFilter, setUserFilter] = useState("");
  const observer = useRef();
  const open = Boolean(anchorEl);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setData([]); // Clear the current data
    setPage(1); // Reset to first page
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setPage(1);
    setData([]);
  };

  const handleUserFilterChange = (event) => {
    setUserFilter(event.target.value);
    setPage(1);
    setData([]);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    setData([]); // Clear current data when changing pages
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1); // Reset to first page when changing page size
    setData([]); // Clear current data
  };

  const fetchData = async (filterValue, pageNumber) => {
    try {
      setIsLoading(true);
      const response = await api.get("get-user-clicks/", {
        params: {
          filter: filterValue,
          page: pageNumber,
          page_size: pageSize,
          status: statusFilter !== "all" ? statusFilter : undefined,
          user: filter !== 0 ? userFilter || undefined : undefined,
        },
      });
      setData(response.data.results);
      setTotalPages(response.data.total_pages);
      setIsLoading(false);
    } catch (error) {
      console.error("fetchData -> error", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(filter, page);
  }, [filter, page, pageSize, statusFilter, userFilter]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogAction(null);
    setTicketMessage("");
  };

  const handleConfirmAction = async () => {
    if (dialogAction && selectedItem) {
      try {
        if (dialogAction === "hide") {
          await api.post("hide-click/", { id: selectedItem.id });
          setData((prevData) =>
            prevData.filter((item) => item.id !== selectedItem.id)
          );
          toast.success(t("Record hidden!"));
        } else if (dialogAction === "ticket") {
          await api.post("ticket-click/", {
            id: selectedItem.id,
            message: ticketMessage,
          });
          toast.success(t("Ticket sent successfully!"));
          // Update the local state to reflect the change
          setData((prevData) =>
            prevData.map((item) =>
              item.id === selectedItem.id ? { ...item, status: "ticket" } : item
            )
          );
        } else if (dialogAction === "setBought") {
          await api.post("set-transaction-as-bought/", { id: selectedItem.id });
          toast.success(t("Marked as bought successfully!"));
          // Optionally update the local state to reflect the change
          setData((prevData) =>
            prevData.map((item) =>
              item.id === selectedItem.id
                ? { ...item, status: "marked_as_bought" }
                : item
            )
          );
        }
      } catch (error) {
        console.error("handleConfirmAction -> error", error);
        toast.error("Action failed. Please try again.");
      }
    }
    handleDialogClose();
  };

  // Add this new function to handle button clicks
  const handleActionClick = (action, item) => {
    setSelectedItem(item);
    setDialogAction(action);
    setDialogOpen(true);
  };

  // Add this function to check if any item has a commission claim
  const hasAnyCommissionClaim = useCallback(() => {
    return data.some((item) => item.commission_claim);
  }, [data]);

  // Modify the heads array to be dynamic
  const heads = useMemo(() => {
    const baseHeads = [t("shop"), t("používateľ"), t("dátum"), t("status")];
    if (hasAnyCommissionClaim()) {
      baseHeads.push(t("nárok na províziu"));
    }
    baseHeads.push(""); // for action buttons
    return baseHeads;
  }, [t, hasAnyCommissionClaim]);

  // Add this function to render skeleton loading
  const renderSkeletonLoading = () => (
    <>
      {[...Array(pageSize)].map((_, index) => (
        <React.Fragment key={index}>
          <tr>
            <td colSpan={heads.length}>
              <hr />
            </td>
          </tr>
          <tr className="border-t border-gray-200">
            <td className="py-3 px-6">
              <div className="flex items-center space-x-4">
                <Skeleton className="w-16 h-16 rounded" /> {/* Shop logo */}
                <Skeleton className="h-4 w-24" /> {/* Shop name */}
              </div>
            </td>
            <td className="py-3 px-6">
              <Skeleton className="h-4 w-32" /> {/* User name */}
            </td>
            <td className="py-3 px-6">
              <Skeleton className="h-4 w-32" /> {/* Date/Time skeleton */}
            </td>
            <td className="py-3 px-6">
              <Skeleton className="h-8 w-24 rounded-md" /> {/* Status button */}
            </td>
            {hasAnyCommissionClaim() && (
              <td className="py-3 px-6">
                <Skeleton className="h-6 w-6 rounded-full" />{" "}
                {/* Commission claim icon */}
              </td>
            )}
            <td className="py-3 px-6">
              <Skeleton className="h-8 w-8 rounded-full" />{" "}
              {/* Action button */}
            </td>
          </tr>
        </React.Fragment>
      ))}
    </>
  );

  const renderMobileSkeletonLoading = () => (
    <>
      {[...Array(pageSize)].map((_, index) => (
        <div key={index} className="border rounded-lg shadow-md p-4 space-y-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Skeleton className="w-16 h-16 rounded" /> {/* Shop logo */}
              <Skeleton className="h-4 w-24" /> {/* Shop name */}
            </div>
            <Skeleton className="h-8 w-8 rounded-full" /> {/* Action button */}
          </div>
          <div>
            <Skeleton className="h-4 w-32" /> {/* User name */}
          </div>
          <div>
            <Skeleton className="h-4 w-32" /> {/* Date/Time skeleton */}
          </div>
          <div>
            <Skeleton className="h-8 w-24 rounded-md" /> {/* Status button */}
          </div>
          {hasAnyCommissionClaim() && (
            <div className="flex items-center space-x-2">
              <Skeleton className="h-4 w-32" /> {/* Commission claim text */}
              <Skeleton className="h-6 w-6 rounded-full" />{" "}
              {/* Commission claim icon */}
            </div>
          )}
        </div>
      ))}
    </>
  );

  // Update the status display in both desktop and mobile views
  const getStatusDisplay = (status) => {
    switch (status) {
      case "confirmed":
        return {
          text: t("Čakáme na cashback od partnera"),
          color: "bg-yellow-500",
        };
      case "pending":
        return { text: t("Čakáme za overením nákupu"), color: "bg-blue/80" };
      case "to_be_paid":
        return {
          text: t("Bude vyplatené v najbližšom výplatnom termíne"),
          color: "bg-orange",
        };
      case "payed":
        return { text: t("Vyplatené"), color: "bg-lime-500" };
      case "ticket":
        return { text: t("Odoslané na reklamáciu"), color: "bg-primary" };
      case "marked_as_bought":
        return { text: t("Označené ako nakúpené"), color: "bg-green" };
      default:
        return { text: t("Neznáme"), color: "bg-black" };
    }
  };

  return (
    <div className="pb-12">
      <ToastContainer />
      <div className="flex xl:flex-row flex-col justify-between items-center">
        {/* Desktop view: Title without the back arrow */}
        <div className="lg:block hidden uppercase font-bold py-12 text-3xl whitespace-nowrap">
          {t("Moje prekliky a nákupy")}
        </div>

        {/* Mobile view: Title with the back arrow */}
        <div
          className="lg:hidden uppercase font-bold py-12 text-2xl flex items-center gap-2 cursor-pointer"
          onClick={onMenuToggle} // Trigger menu toggle on click
        >
          <IoIosArrowBack className="h-6 w-6 cursor-pointer text-gray-700" />
          {t("Moje prekliky a nákupy")}
        </div>

        {/* Desktop filter dropdown */}
        <div className="mb-4 w-full text-right hidden lg:flex flex-row items-center justify-end gap-2">
          <InputLabel id="filter">{t("Filter")}</InputLabel>
          <Select
            labelId="filter"
            id="filter"
            value={filter}
            label={t("Filter")}
            onChange={handleFilterChange}
            className="xl:w-[200px]"
          >
            <MenuItem value={0}>{t("Vlastné")}</MenuItem>
            <MenuItem value={1}>{t("Priame odporúčanie")}</MenuItem>
            <MenuItem value={2}>{t("Zo štruktúry")}</MenuItem>
          </Select>
          <InputLabel id="status-filter">{t("Status")}</InputLabel>
          <Select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            className="xl:w-[150px]"
          >
            <MenuItem value="all">{t("Všetky statusy")}</MenuItem>
            <MenuItem value="confirmed">
              {t("Čakáme na cashback od partnera")}
            </MenuItem>
            <MenuItem value="pending">
              {t("Čakáme za overením nákupu")}
            </MenuItem>
            <MenuItem value="to_be_paid">
              {t("Bude vyplatené v najbližšom výplatnom termíne")}
            </MenuItem>
            <MenuItem value="payed">{t("Vyplatené")}</MenuItem>
            <MenuItem value="ticket">{t("Prebieha reklamácia")}</MenuItem>
            <MenuItem value="marked_as_bought">
              {t("Označené ako nakúpené")}
            </MenuItem>
          </Select>

          {filter !== 0 && (
            <TextField
              value={userFilter}
              onChange={handleUserFilterChange}
              placeholder={t("Filter používateľa")}
              className="xl:w-[200px]"
            />
          )}
        </div>

        {/* Mobile filter button */}
        <div className="lg:hidden flex w-full justify-end p-4">
          <button
            onClick={() => setIsFilterDrawerOpen(true)}
            className="text-primary flex items-center gap-2"
          >
            <MdFilterList className="text-2xl" />
            {t("Filtrovať")}
          </button>
        </div>
      </div>

      {/* Filter Drawer for Mobile */}
      <Drawer
        anchor="right"
        open={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
      >
        <div className="w-[250px] p-4 flex flex-col gap-4">
          <div className="font-bold text-xl">{t("Filter")}</div>
          <Select
            labelId="filter"
            id="filter"
            value={filter}
            label={t("Filter")}
            onChange={handleFilterChange}
            fullWidth
          >
            <MenuItem value={0}>{t("Vlastné")}</MenuItem>
            <MenuItem value={1}>{t("Priame odporúčanie")}</MenuItem>
            <MenuItem value={2}>{t("Zo štruktúry")}</MenuItem>
          </Select>

          <Select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            fullWidth
          >
            <MenuItem value="all">{t("Všetky statusy")}</MenuItem>
            <MenuItem value="confirmed">
              {t("Čakáme na cashback od partnera")}
            </MenuItem>
            <MenuItem value="pending">
              {t("Čakáme za overením nákupu")}
            </MenuItem>
            <MenuItem value="to_be_paid">
              {t("Bude vyplatené v najbližšom výplatnom termíne")}
            </MenuItem>
            <MenuItem value="payed">{t("Vyplatené")}</MenuItem>
            <MenuItem value="ticket">{t("Prebieha reklamácia")}</MenuItem>
            <MenuItem value="marked_as_bought">
              {t("Označené ako nakúpené")}
            </MenuItem>
          </Select>

          {filter !== 0 && (
            <TextField
              value={userFilter}
              onChange={handleUserFilterChange}
              placeholder={t("Filter používateľa")}
              fullWidth
            />
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsFilterDrawerOpen(false)}
          >
            {t("Zavrieť")}
          </Button>
        </div>
      </Drawer>

      {/* Desktop view: Display as a table */}
      <div className="hidden lg:block overflow-x-auto">
        <table className="min-w-full bg-white border-separate border-spacing-y-8">
          <thead>
            <tr className="w-full uppercase text-sm leading-normal">
              {heads.map((head, index) => (
                <th
                  key={index}
                  className="py-3 px-6 text-left whitespace-nowrap"
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm font-light">
            {data.length === 0 && !isLoading ? (
              <tr>
                <td colSpan={heads.length} className="text-center py-4">
                  {t("Zatiaľ neboli uskutočnené žiadne prekliky ani nákupy")}
                </td>
              </tr>
            ) : isLoading ? (
              renderSkeletonLoading()
            ) : (
              data.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td colSpan={heads.length}>
                      <hr />
                    </td>
                  </tr>
                  <tr key={index} className="border-t border-gray-200">
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      <div className="flex flex-col items-center">
                        {filter === 0 ? (
                          <>
                            <a
                              href={item.product_url}
                              className="w-[128px] h-[64px] flex items-center justify-center"
                            >
                              <img
                                src={item.shop_logo_url}
                                alt=""
                                className="h-[64px] mx-auto object-contain"
                              />
                            </a>
                            <span className="text-sm font-semibold mt-2 text-center">
                              {item.shop_name}
                            </span>
                          </>
                        ) : (
                          <div className="flex flex-col items-center">
                            <StorefrontIcon
                              style={{ fontSize: 64, color: "#888" }}
                            />
                            <span className="text-sm font-semibold mt-2 text-center">
                              {t("Obchod")}
                            </span>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      {item.user_last_name
                        ? item.user_first_name + " " + item.user_last_name
                        : item.user_email
                          ? item.user_email
                          : "Neznámy"}
                    </td>
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      {item.formatted_date}
                    </td>
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      <a
                        className={`py-2 px-4 rounded-md text-white font-semibold whitespace-nowrap ${
                          getStatusDisplay(item.status).color
                        }`}
                      >
                        {getStatusDisplay(item.status).text}
                      </a>
                    </td>
                    {hasAnyCommissionClaim() && (
                      <td className="py-3 px-6 text-left whitespace-nowrap">
                        <div className="text-center text-xs">
                          {item.commission_claim ? (
                            <div className="flex flex-row gap-2 justify-center">
                              {filter === 0 && (
                                <>
                                  <div className="bg-primary text-white font-semibold rounded-3xl px-2 py-1">
                                    {item.commission_claim.oc}
                                  </div>
                                  <div className="bg-primary text-white font-semibold rounded-3xl px-2 py-1">
                                    {item.commission_claim.sc}
                                  </div>
                                </>
                              )}
                              {filter === 1 && (
                                <>
                                  <div className="bg-primary text-white font-semibold rounded-3xl px-2 py-1">
                                    {item.commission_claim.dc}
                                  </div>
                                  <div className="bg-primary text-white font-semibold rounded-3xl px-2 py-1">
                                    {item.commission_claim.sc}
                                  </div>{" "}
                                </>
                              )}
                              {filter === 2 && (
                                <div className="bg-primary text-white font-semibold rounded-3xl px-2 py-1">
                                  {item.commission_claim.sc}
                                </div>
                              )}
                            </div>
                          ) : (
                            "-"
                          )}
                        </div>
                      </td>
                    )}
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      <div className="flex flex-col space-y-2">
                        {filter === 0 && (
                          <>
                            {(item.status === "pending" ||
                              item.status === "marked_as_bought") && (
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() => handleActionClick("hide", item)}
                              >
                                {t("Skryť a označiť za nenakúpené")}
                              </Button>
                            )}
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => handleActionClick("ticket", item)}
                            >
                              {t("Reklamovať")}
                            </Button>
                            {item.status === "pending" && (
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() =>
                                  handleActionClick("setBought", item)
                                }
                              >
                                {t("Označiť za nakúpené")}
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Mobile view: Display as a card layout */}
      <div className="lg:hidden space-y-4">
        {data.length === 0 && !isLoading ? (
          <div className="text-center py-4">
            {t("Zatiaľ neboli uskutočnené žiadne prekliky ani nákupy")}
          </div>
        ) : isLoading ? (
          renderMobileSkeletonLoading()
        ) : (
          data.map((item, index) => (
            <div
              key={index}
              className="border rounded-lg shadow-md p-4 space-y-2"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {filter === 0 ? (
                    <>
                      <a
                        href={item.product_url}
                        className="w-[64px] h-[64px] items-center"
                      >
                        <img
                          src={item.shop_logo_url}
                          alt=""
                          className="h-[64px] mx-auto object-contain"
                        />
                      </a>
                      <span className="text-sm font-semibold ml-2">
                        {item.shop_name}
                      </span>
                    </>
                  ) : (
                    <div className="flex items-center">
                      <StorefrontIcon style={{ fontSize: 64, color: "#888" }} />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <span className="font-semibold">{t("Používateľ")}: </span>
                {item.user_last_name
                  ? item.user_first_name + " " + item.user_last_name
                  : item.user_email
                    ? item.user_email
                    : "Neznámy"}
              </div>
              <div>
                <span className="font-semibold">{t("Dátum")}: </span>
                {item.formatted_date}
              </div>
              <div>
                <span className="font-semibold">{t("Status")}: </span>
                <a
                  className={`py-1 px-3 rounded-md text-white font-semibold ${
                    getStatusDisplay(item.status).color
                  }`}
                >
                  {getStatusDisplay(item.status).text}
                </a>
              </div>
              {hasAnyCommissionClaim() && (
                <div className="flex flex-row gap-4 items-center">
                  <span className="font-semibold">
                    {t("Nárok na províziu")}:{" "}
                  </span>
                  <div className="flex flex-row gap-2">
                    {item.commission_claim ? (
                      <>
                        {filter === 0 && (
                          <>
                            <div className="bg-primary text-white font-semibold rounded-3xl px-2 py-1">
                              {item.commission_claim.oc}
                            </div>
                            <div className="bg-primary text-white font-semibold rounded-3xl px-2 py-1">
                              {item.commission_claim.sc}
                            </div>
                          </>
                        )}
                        {filter === 1 && (
                          <div className="bg-primary text-white font-semibold rounded-3xl px-2 py-1">
                            {item.commission_claim.dc}
                          </div>
                        )}
                        {filter === 2 && (
                          <div className="bg-primary text-white font-semibold rounded-3xl px-2 py-1">
                            {item.commission_claim.sc}
                          </div>
                        )}
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              )}
              {filter === 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {(item.status === "pending" ||
                    item.status === "marked_as_bought") && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleActionClick("hide", item)}
                    >
                      {t("Skryť")}
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleActionClick("ticket", item)}
                  >
                    {t("Reklamovať")}
                  </Button>
                  {item.status === "pending" && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleActionClick("setBought", item)}
                    >
                      {t("Označiť za nakúpené")}
                    </Button>
                  )}
                </div>
              )}
            </div>
          ))
        )}
      </div>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Potvrdenie akcie")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selectedItem && (
              <>
                {t("Ste si istý, že chcete")}{" "}
                {dialogAction === "hide"
                  ? t("Skryť")
                  : dialogAction === "ticket"
                    ? t("Reklamovať")
                    : t("Označiť za nakúpené")}{" "}
                {t("Položku")} <strong>{selectedItem.product_name}</strong>{" "}
                {t("od používateľa")}{" "}
                <strong>
                  {selectedItem.user_last_name
                    ? selectedItem.user_first_name +
                      " " +
                      selectedItem.user_last_name
                    : selectedItem.user_email
                      ? selectedItem.user_email
                      : t("Neznáme")}
                </strong>
                ?
              </>
            )}
            {dialogAction === "ticket" && (
              <TextField
                autoFocus
                margin="dense"
                id="ticketMessage"
                label={t("Správa")}
                type="text"
                fullWidth
                multiline
                rows={4}
                value={ticketMessage}
                onChange={(e) => setTicketMessage(e.target.value)}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("Zrušiť")}
          </Button>
          <Button onClick={handleConfirmAction} color="primary" autoFocus>
            {t("Potvrdiť")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Pagination controls */}
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center">
          <span className="mr-2">{t("Položiek na stránku:")}</span>
          <Select
            value={pageSize}
            onChange={handlePageSizeChange}
            className="w-20"
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
    </div>
  );
}
