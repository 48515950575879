import MyZoneTable from "./MyZoneTable";
import StatItem from "./StatItem";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LiaDownloadSolid } from "react-icons/lia";
import { IoIosArrowBack } from "react-icons/io"; // Import back arrow icon

const stats = [
  {
    title: "Počet pozvaných priateľov",
    value: "100",
    stat: 120,
    timespan: "Za posledný mesiac",
    color: "green-500",
  },
  {
    title: "Počet pozvaných priateľov za posledných 30 dní",
    value: "10",
    stat: 4.6,
    timespan: "Za posledný mesiac",
    color: "red-500",
  },
];

const data = [
  {
    date: "2021-09-10",
    cashback: "100",
    recommendation: "120",
    career: "Pozvánka z e-mailu",
    download: "",
  },
  {
    date: "2021-09-10",
    cashback: "100",
    recommendation: "120",
    career: "Link",
    download: "",
  },
  {
    date: "2021-09-10",
    cashback: "100",
    recommendation: "120",
    career: "Link",
    download: "",
  },
];

export default function Commision({ onMenuToggle, t }) {
  // Add onMenuToggle prop
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await api.get("get-my-commisions/");
      setData(response.data);
    } catch (error) {
      console.error("fetchData -> error", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const heads = [
    t("uzávierka"),
    t("provízie cashback"),
    t("provízie odporúčanie"),
    t("kariéra"),
    t("stiahnuť"),
  ];

  return (
    <div className="pb-12">
      {/* Desktop view: Title without the back arrow */}
      <div className="lg:block hidden uppercase font-bold pt-12 text-2xl">
        {t("Moje provízie")}
      </div>

      {/* Mobile view: Title with the back arrow */}
      <div
        className="lg:hidden uppercase font-bold py-12 text-2xl flex items-center gap-2 cursor-pointer"
        onClick={onMenuToggle} // Trigger menu toggle on click
      >
        <IoIosArrowBack className="h-6 w-6 cursor-pointer text-gray-700" />
        {t("Moje provízie")}
      </div>

      <div className="flex flex-col gap-2 mt-2 mb-12">
        <div>{t("Cashback provízie sa generujú týždenne, každý pondelok")}</div>
        <div>
          {t(
            "Kariérne odmeny sa generujú mesačne, vždy 1. deň nasledujúceho mesiaca"
          )}
        </div>
      </div>

      {/* Table Headings for Desktop */}
      <div className="hidden lg:flex space-x-4 font-bold uppercase">
        {heads.map((head, index) => (
          <div key={index} className="flex-1">
            {head}
          </div>
        ))}
      </div>
      <hr className="my-2 hidden lg:block" />

      {data.length === 0 ? (
        <div className="text-center text-2xl text-lightgray my-24">
          {t("Zatiaľ na vašom účte nebola vygenerovaná žiadna provízia")}
        </div>
      ) : (
        data.map((item, index) => (
          <div key={index}>
            {/* Mobile View: Card Style */}
            <div className="lg:hidden border rounded-lg shadow-md p-4 space-y-2 mb-2">
              <div>
                <span className="font-semibold">Uzávierka: </span>
                {item.formatted_date}
              </div>
              <div>
                <span className="font-semibold">Provízie Cashback: </span>
                {item.total[0]} EUR
              </div>
              <div>
                <span className="font-semibold">Provízie Odporúčanie: </span>
                {item.total[1]} EUR
              </div>
              <div>
                <span className="font-semibold">Kariéra: </span>
                {item.total[2]} EUR
              </div>
              <div className="flex flex-row items-center gap-3">
                <span className="font-semibold">Stiahnuť: </span>
                <a href={item.pdf_link} target="_blank">
                  <LiaDownloadSolid />
                </a>
              </div>
            </div>

            {/* Desktop View: Table Style */}
            <div className="hidden lg:flex space-x-4 my-8 items-center">
              <div className="flex-1">{item.formatted_date}</div>
              <div className="flex-1"> {item.total[0]} EUR </div>
              <div className="flex-1 ">{item.total[1]} EUR</div>
              <div className="flex-1">{item.total[2]} EUR</div>
              <div className="flex-1">
                <a href={item.pdf_link} target="_blank">
                  <LiaDownloadSolid className="mx-8" />
                </a>
              </div>
            </div>
            <hr className="hidden lg:block" />
          </div>
        ))
      )}
    </div>
  );
}
