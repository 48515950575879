// Modal.js
import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const backdropVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const modalVariants = {
  hidden: { scale: 0.75, opacity: 0 },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};
const Modal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = isOpen ? "hidden" : originalStyle;
    return () => (document.body.style.overflow = originalStyle);
  }, [isOpen]);

  const handleBackdropClick = (e) => {
    if (e.target.id === "modal-backdrop") {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        id="modal-backdrop"
        onClick={handleBackdropClick}
        className="fixed inset-0 bg-gray-800 bg-opacity-50 overflow-y-auto h-screen w-screen backdrop-blur-lg z-50 px-4 py-6 flex items-start sm:items-center justify-center"
        variants={backdropVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <motion.div
          className="relative bg-white rounded-lg text-left overflow-y-auto w-full sm:max-w-lg p-12 shadow-lg "
          onClick={(e) => e.stopPropagation()}
          variants={modalVariants}
        >
          {children}
          <button
            onClick={onClose}
            className="absolute top-0 right-0 text-gray-400 bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          ></button>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Modal;
