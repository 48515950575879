import React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import {
  AccountBalanceWallet,
  History,
  Payment,
  Assessment,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function WalletMenu({
  setActiveItem,
  activeItemId,
  onMenuToggle,
}) {
  const { t } = useTranslation();

  const menuItems = [
    {
      id: 0,
      text: t("Stav peňaženky"),
      icon: <AccountBalanceWallet />,
      hash: "balance",
    },
    {
      id: 1,
      text: t("História pohybov"),
      icon: <History />,
      hash: "transactions",
    },
    {
      id: 2,
      text: t("História výplat"),
      icon: <Payment />,
      hash: "payouts",
    },
    {
      id: 3,
      text: t("Uzávierky - kariéra"),
      icon: <Assessment />,
      hash: "statements",
    },
  ];

  const handleItemClick = (itemId, hash) => {
    setActiveItem(itemId);
    window.location.hash = hash;
    if (onMenuToggle) onMenuToggle();
  };

  return (
    <Paper elevation={2} className="p-2">
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              selected={activeItemId === item.id}
              onClick={() => handleItemClick(item.id, item.hash)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}
