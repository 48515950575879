import React, { useState, useCallback } from "react";
import {
  TextField,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  Box,
  Avatar,
} from "@mui/material";
import debounce from "lodash/debounce";
import api from "../../../services/api";

export const ProductSearch = ({ selectedProduct, onSelect, country }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchResults = async (value) => {
    if (!value) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    try {
      const response = await api.get(`get-product-data`, {
        params: {
          product_name: value,
          country: country,
        },
      });

      const data = response.data;
      const productsArray = Object.keys(data).map((key) => data[key]);
      setSearchResults(productsArray);
    } catch (error) {
      console.error("Error searching products:", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounced search function
  const debouncedFetch = useCallback(
    debounce((value) => fetchResults(value), 500),
    [country]
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
    debouncedFetch(value);
  };

  const ProductCard = ({ product, onClick }) => (
    <Card
      onClick={onClick}
      sx={{
        mb: 1,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      }}
    >
      <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
        <Box display="flex" alignItems="center" gap={2}>
          <Avatar
            src={product.product_image_url}
            alt={product.product_name}
            variant="rounded"
            sx={{ width: 60, height: 60 }}
          />
          <Box>
            <Typography variant="subtitle1" component="div">
              {product.product_name}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
              {product.product_description?.slice(0, 100)}
              {product.product_description?.length > 100 ? "..." : ""}
            </Typography>
            {product.linkedshops && product.linkedshops.length > 0 && (
              <Typography variant="body2" color="primary" sx={{ mt: 0.5 }}>
                {product.linkedshops.length} dostupných obchodov
              </Typography>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <div>
      <TextField
        fullWidth
        label="Vyhľadať produkt"
        value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
        variant="outlined"
        InputProps={{
          endAdornment: loading && <CircularProgress size={20} />,
        }}
      />

      {selectedProduct ? (
        <Box mt={2}>
          <ProductCard product={selectedProduct} />
        </Box>
      ) : (
        searchResults.length > 0 && (
          <Box
            mt={2}
            sx={{
              maxHeight: 400,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            {searchResults.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                onClick={() => {
                  onSelect(product);
                  setSearchTerm("");
                  setSearchResults([]);
                }}
              />
            ))}
          </Box>
        )
      )}
    </div>
  );
};
