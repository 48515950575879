import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Tooltip,
  Skeleton,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { format } from "date-fns";

const OfferTypeModal = ({ open, onClose, offerType, onSuccess }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [activeFrom, setActiveFrom] = useState(null);
  const [activeTo, setActiveTo] = useState(null);
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (offerType) {
      setName(offerType.name || "");
      setDescription(offerType.description || "");
      setActiveFrom(
        offerType.active_from ? new Date(offerType.active_from) : null
      );
      setActiveTo(offerType.active_to ? new Date(offerType.active_to) : null);
      setActive(offerType.active ?? true);
    } else {
      setName("");
      setDescription("");
      setActiveFrom(null);
      setActiveTo(null);
      setActive(true);
    }
  }, [offerType, open]);

  const handleSubmit = async () => {
    if (!name) {
      toast.error("Prosím vyplňte názov");
      return;
    }

    setLoading(true);
    try {
      const payload = {
        name,
        description,
        active_from: activeFrom ? format(activeFrom, "yyyy-MM-dd") : null,
        active_to: activeTo ? format(activeTo, "yyyy-MM-dd") : null,
        active,
      };

      if (offerType) {
        await api.put(`update-offer-type/${offerType.id}/`, payload);
      } else {
        await api.post("create-offer-type/", payload);
      }

      toast.success(
        `Typ ponuky bol úspešne ${offerType ? "upravený" : "vytvorený"}`
      );
      onSuccess();
      onClose();
    } catch (error) {
      toast.error(
        `Nepodarilo sa ${offerType ? "upraviť" : "vytvoriť"} typ ponuky`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {offerType ? "Upraviť typ ponuky" : "Vytvoriť nový typ ponuky"}
      </DialogTitle>
      <DialogContent>
        <div className="space-y-4 mt-4">
          <TextField
            fullWidth
            label="Názov"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            fullWidth
            label="Popis"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={3}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="flex gap-4">
              <DatePicker
                label="Aktívne od"
                value={activeFrom}
                onChange={setActiveFrom}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <DatePicker
                label="Aktívne do"
                value={activeTo}
                onChange={setActiveTo}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </div>
          </LocalizationProvider>
          <FormControlLabel
            control={
              <Switch
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
                color="primary"
              />
            }
            label="Aktívne"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Zrušiť
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Ukladá sa..." : "Uložiť"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const OfferTypesTable = () => {
  const [offerTypes, setOfferTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingOfferType, setEditingOfferType] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState(null);

  const fetchOfferTypes = async () => {
    setLoading(true);
    try {
      const response = await api.get("get-offer-types");
      setOfferTypes(response.data);
    } catch (error) {
      toast.error("Nepodarilo sa načítať typy ponúk");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOfferTypes();
  }, []);

  const handleAddType = () => {
    setEditingOfferType(null);
    setIsModalOpen(true);
  };

  const handleEditType = (offerType) => {
    setEditingOfferType(offerType);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (offerType) => {
    setTypeToDelete(offerType);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!typeToDelete) return;

    try {
      await api.delete(`delete-offer-type/${typeToDelete.id}/`);
      toast.success("Typ ponuky bol úspešne vymazaný");
      fetchOfferTypes();
    } catch (error) {
      toast.error("Nepodarilo sa vymazať typ ponuky");
    } finally {
      setDeleteConfirmOpen(false);
      setTypeToDelete(null);
    }
  };

  return (
    <div className="p-4">
      <Paper className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Typy ponúk</h2>
          <Button variant="contained" color="primary" onClick={handleAddType}>
            Pridať typ ponuky
          </Button>
        </div>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Názov</TableCell>
                <TableCell>Popis</TableCell>
                <TableCell>Aktívne od</TableCell>
                <TableCell>Aktívne do</TableCell>
                <TableCell>Stav</TableCell>
                <TableCell width={100}>Akcie</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? [...Array(3)].map((_, index) => (
                    <TableRow key={index}>
                      {[...Array(6)].map((_, cellIndex) => (
                        <TableCell key={cellIndex}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : offerTypes.map((type) => (
                    <TableRow key={type.id}>
                      <TableCell>{type.name}</TableCell>
                      <TableCell>{type.description}</TableCell>
                      <TableCell>
                        {type.active_from
                          ? format(new Date(type.active_from), "dd.MM.yyyy")
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {type.active_to
                          ? format(new Date(type.active_to), "dd.MM.yyyy")
                          : "-"}
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={type.active}
                          onChange={async (e) => {
                            try {
                              await api.put(`update-offer-type/${type.id}/`, {
                                ...type,
                                active: e.target.checked,
                              });
                              fetchOfferTypes();
                              toast.success("Stav bol úspešne zmenený");
                            } catch (error) {
                              toast.error("Nepodarilo sa zmeniť stav");
                            }
                          }}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-2">
                          <Tooltip title="Upraviť">
                            <IconButton
                              onClick={() => handleEditType(type)}
                              size="small"
                              color="primary"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Vymazať">
                            <IconButton
                              onClick={() => handleDeleteClick(type)}
                              size="small"
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <OfferTypeModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        offerType={editingOfferType}
        onSuccess={fetchOfferTypes}
      />

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Potvrdiť vymazanie</DialogTitle>
        <DialogContent>
          Naozaj chcete vymazať tento typ ponuky?
          {typeToDelete && (
            <div className="mt-2">
              <strong>Názov:</strong> {typeToDelete.name}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Zrušiť</Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Vymazať
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
