import { Button } from "@nextui-org/react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import GoogleSignIn from "../GoogleComponent";
import { registerUser } from "../../utils/Register";
import login from "../../utils/Login";
import validator from "validator";
import { useTranslation } from "react-i18next";

export default function RegisterForm({ onSuccess, showComplementary }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const validatePassword = (password) => {
    const minLength = 8;
    const maxLength = 20;
    const errors = [];

    if (!validator.isLength(password, { min: minLength })) {
      errors.push(
        `${t("Heslo musí obsahovať aspoň")} ${minLength} ${t("znakov")}.`
      );
    }
    if (!validator.isLength(password, { max: maxLength })) {
      errors.push(
        `${t("Heslo nesmie obsahovať viac ako")} ${maxLength} ${t("znakov")}.`
      );
    }

    return errors.length === 0 ? { valid: true } : { valid: false, errors };
  };

  const validateEmail = (email) => validator.isEmail(email);

  const validateConfirmPassword = (password, confirmPassword) =>
    password === confirmPassword;

  const emailIsInvalid = email && !validateEmail(email);
  const passwordValidationResult = validatePassword(password);
  const passwordIsInvalid = password && !passwordValidationResult.valid;
  const confirmPasswordIsInvalid =
    confirmPassword && !validateConfirmPassword(password, confirmPassword);

  const isFormValid =
    email &&
    password &&
    confirmPassword &&
    !emailIsInvalid &&
    !passwordIsInvalid &&
    !confirmPasswordIsInvalid;

  const handleRegister = (e) => {
    e.preventDefault(); // Prevent form submission if button is clicked
    if (!isFormValid || isSubmitting) return;

    setIsSubmitting(true);
    const referralCode = localStorage.getItem("referralCode");
    registerUser({ email, password, confirmPassword, referralCode })
      .then(() => {
        console.log("Registered successfully!");
        return login(email, password, setIsLoading);
      })
      .then(() => {
        if (onSuccess) {
          onSuccess(); // Handle success via parent callback
        }
      })
      .catch((err) => {
        alert("Failed to register");
        console.log(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleGoogleSuccess = (data) => {
    console.log("Google Sign-In Success:", data);
    if (onSuccess) {
      onSuccess(); // Handle success via parent callback
    }
  };

  const handleGoogleFailure = (error) => {
    console.error("Google Sign-In Failure:", error);
    // Handle failure (e.g., show an error message)
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-0 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        {showComplementary && <img src="bb-white.png" alt="Brand Logo" />}

        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {t("Registrujte sa do BILIONBUY")}!
        </h2>
      </div>
      <div className="max-w-sm mx-auto min-h-[100px]">
        <GoogleSignIn
          onSignInSuccess={handleGoogleSuccess}
          onSignInFailure={handleGoogleFailure}
        />
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6 flex flex-col" action="#" method="POST">
          <div>
            <TextField
              className="w-full"
              required
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="standard"
              error={emailIsInvalid}
              helperText={emailIsInvalid ? t("Zadajte platný e-mail.") : ""}
            />
          </div>
          <div>
            <TextField
              className="w-full"
              required
              type="password"
              placeholder={t("Heslo")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="standard"
              error={passwordIsInvalid}
              helperText={
                passwordIsInvalid
                  ? passwordValidationResult.errors.join(", ")
                  : ""
              }
            />
          </div>
          <div>
            <TextField
              className="w-full"
              required
              type="password"
              placeholder={t("Zopakujte heslo")}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              variant="standard"
              error={confirmPasswordIsInvalid}
              helperText={
                confirmPasswordIsInvalid ? t("Heslá sa nezhodujú.") : ""
              }
            />
          </div>
          <div className="text-sm text-center">
            {t("Kliknutím na tlačidlo nižšie súhlasíte s")}{" "}
            <a
              className="text-primary"
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("terms and conditions")}
            </a>
          </div>
          <div>
            <Button
              color="primary"
              className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm ${
                !isFormValid ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleRegister}
              disabled={!isFormValid || isLoading || isSubmitting}
            >
              {isLoading || isSubmitting ? t("PLEASE WAIT...") : t("REGISTER")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
