import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import api from "../services/api";
import { useNavbar } from "./NavbarContext";
import { logo_url } from "../data/common";
import NewSearchBar from "./NewSearchBar";
import SearchBar from "./SearchBar";
import ProfileComponent from "./ProfileComponent";
import LocationSelector from "./LocationSelector";
import ProductCategories from "./ProductCategories";
import { FaAngleDown } from "react-icons/fa6";
import { HiMiniSquares2X2 } from "react-icons/hi2";
import { IoChevronBack } from "react-icons/io5";
import CategoryContent from "./CategoryContent";
import { categoryService } from "../services/categoryService";
import {
  allCategories,
  categories,
  foodCategories,
  servicesCategories,
  travelCategories,
  eventCategories,
  bazarCategories,
} from "../data/categories";
import ShopCardLarge from "./ShopCardLarge";
import { Spinner } from "@nextui-org/react";

const MenuItem = ({
  name,
  category,
  soon,
  isNew,
  activeCategory,
  onClick,
  isMobile,
  tsoon,
  tnew,
}) => (
  <div
    className={`flex gap-2 items-center rounded-lg pl-4 pr-8 py-2 transition-fade duration-200 ${
      activeCategory === category && !isMobile ? "bg-primary text-white" : ""
    } ${soon ? "cursor-not-allowed" : "cursor-pointer"}`}
    onClick={(e) => {
      e.stopPropagation();
      if (!soon && onClick) {
        onClick(category, isMobile);
      }
    }}
    role="menuitem"
    aria-haspopup="true"
  >
    <FaAngleDown
      className={`transform transition-transform ${
        activeCategory === category ? "rotate-180" : ""
      }`}
      aria-hidden="true"
    />
    {name}
    {soon && (
      <span
        className="ml-1 text-xs bg-primary rounded-3xl text-white font-bold px-2 py-1"
        aria-label={`${name} coming soon`}
      >
        {tsoon}
      </span>
    )}
    {isNew && (
      <span
        className="ml-1 text-xs bg-pink rounded-3xl text-white font-bold px-2 py-1 animate-pulse"
        aria-label={`New category: ${name}`}
      >
        {tnew}
      </span>
    )}
  </div>
);

const CategoryDetailView = ({ category, onBack, currentLocale, isLoading }) => {
  const [activeView, setActiveView] = useState("categories");
  const { t } = useTranslation();

  const LoadingState = () => (
    <div className="flex justify-center items-center py-12">
      <Spinner size="lg" color="primary" />
    </div>
  );

  const EmptyState = ({ message }) => (
    <div className="flex flex-col items-center justify-center py-12 text-gray-500">
      <p className="text-lg">{message}</p>
    </div>
  );

  return (
    <div className="bg-white">
      <div className="max-w-screen-2xl mx-auto">
        {/* Header with back button and category name */}
        <div className="flex items-center gap-3 py-3">
          <button
            onClick={onBack}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <IoChevronBack className="w-5 h-5" />
          </button>
          <div className="flex items-center gap-2">
            {category.icon && <category.icon className="w-5 h-5" />}
            <span className="font-semibold">
              {currentLocale === "cs"
                ? category.category_name_cz
                : category.category_name_sk}
            </span>
          </div>
        </div>

        {/* View toggle buttons */}
        <div className="flex gap-4 py-4">
          <button
            onClick={() => setActiveView("categories")}
            className={`px-4 py-2 rounded-lg transition-colors ${
              activeView === "categories"
                ? "bg-primary text-white"
                : "text-gray-500 hover:text-gray-700"
            }`}
          >
            {t("Kategórie")}
          </button>
          <button
            onClick={() => setActiveView("shops")}
            className={`px-4 py-2 rounded-lg transition-colors ${
              activeView === "shops"
                ? "bg-primary text-white"
                : "text-gray-500 hover:text-gray-700"
            }`}
          >
            {t("Obchody")}
          </button>
        </div>

        {/* Content area */}
        <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
          {/* Categories View */}
          {activeView === "categories" && (
            <>
              {isLoading ? (
                <LoadingState />
              ) : !category.subcategories?.length ? (
                <EmptyState
                  message={t("Žiadne kategórie nie sú k dispozícii")}
                />
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-4 pt-4 mb-4">
                  {category.subcategories
                    .filter(
                      (subcategory) =>
                        subcategory.subcategories &&
                        subcategory.subcategories.length > 0
                    )
                    .map((subcategory) => (
                      <div key={subcategory.category_name} className="py-1">
                        <a
                          href={"/category/" + subcategory.category_name}
                          className="font-bold"
                        >
                          {subcategory.category_name}
                        </a>
                        <div className="text-lightgray flex flex-col gap-2 pt-2">
                          {subcategory.subcategories
                            .slice(0, 5)
                            .map((subsub) => (
                              <a key={subsub} href={"/category/" + subsub}>
                                {subsub}
                              </a>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </>
          )}

          {/* Shops View */}
          {activeView === "shops" && (
            <>
              {isLoading ? (
                <LoadingState />
              ) : !category.shops?.length ? (
                <EmptyState message={t("Žiadne obchody nie sú k dispozícii")} />
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                  {category.shops.map((shop) => (
                    <ShopCardLarge key={shop.id} shop={shop} />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

// Update these animation variants
const listVariants = {
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

const detailVariants = {
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

const calculateVisibleItems = (width) => {
  if (width >= 1536) return 8; // 2xl
  if (width >= 1280) return 7; // xl
  if (width >= 1024) return 6; // lg
  if (width >= 768) return 5; // md
  if (width >= 640) return 4; // sm
  return 3; // xs
};

export default function BasicNavbar() {
  const { activeCategory, toggleCategory } = useNavbar();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [hoveringMenu, setHoveringMenu] = useState(false);
  const [hoveringContent, setHoveringContent] = useState(false);
  const location = localStorage.getItem("location");
  const [message, setMessage] = useState(null);
  const { t } = useTranslation();
  const productCategoriesRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState(() => {
    return localStorage.getItem("lastSearchQuery") || "";
  });
  const [currentLocale, setCurrentLocale] = useState(() => {
    return localStorage.getItem("locale") || "sk";
  });
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [overflowCategories, setOverflowCategories] = useState([]);
  const [categoryData, setCategoryData] = useState(null);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [allSubcategories, setAllSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activeView, setActiveView] = useState("categories");
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleSearchClick = (query) => {
    setSearchQuery(query);
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  const handleQueryChange = (newQuery) => {
    setSearchQuery(newQuery);
    localStorage.setItem("lastSearchQuery", newQuery);
  };

  const menuItems = [
    { name: t("Nakupovanie"), category: "shopping", soon: false, new: false },
    { name: t("Potraviny"), category: "food", soon: false, new: false },
    { name: t("Cestovanie"), category: "travel", soon: false, new: false },
    { name: t("Služby"), category: "services", soon: false, new: false },
    { name: t("Podujatia"), category: "event", soon: false, new: false },
    {
      name: t("Bazár"),
      category: "second_hand",
      soon: false,
      new: false,
    },
  ];

  const handleMenuItemClick = (category, isMobile) => {
    // Toggle the category if it's already open
    toggleCategory(activeCategory === category ? null : category);
    setMobileMenuOpen(false); // Close mobile menu if open

    if (productCategoriesRef.current) {
      const headerHeight = document.querySelector("header")?.offsetHeight || 0;
      const elementPosition =
        productCategoriesRef.current.getBoundingClientRect().top +
        window.pageYOffset;
      const offsetPosition = elementPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const fetchUserInfo = async () => {
    try {
      const response = await api.get("get-user-data/");
      setUserInfo(response.data);
      localStorage.setItem("refcode", response.data.referral_code);
    } catch (error) {
      console.error("fetchUserData -> error", error);
    }
  };

  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    setIsAuthenticated(!!jwt);
    const refcode = localStorage.getItem("refcode");

    if (!refcode && jwt) {
      fetchUserInfo();
    }
  }, []);

  useEffect(() => {
    const handleLocaleChange = () => {
      setCurrentLocale(localStorage.getItem("locale") || "sk");
    };

    window.addEventListener("storage", handleLocaleChange);
    return () => window.removeEventListener("storage", handleLocaleChange);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      const maxVisibleItems = calculateVisibleItems(window.innerWidth);
      const allCats = [
        ...categories,
        ...foodCategories,
        ...servicesCategories,
        ...travelCategories,
        ...eventCategories,
        ...bazarCategories,
      ];
      setVisibleCategories(allCats.slice(0, maxVisibleItems));
      setOverflowCategories(allCats.slice(maxVisibleItems));
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial calculation

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Framer Motion variants for slide-in animation from left
  const slideInVariants = {
    hidden: { x: "-100%" },
    visible: { x: 0 },
    exit: { x: "-100%" },
  };

  const handleCategorySelect = async (category) => {
    setSelectedMainCategory(category);
    setShowAllCategories(false);

    try {
      const data = await categoryService.getCategoryContent(category.id);
      setCategoryData(data);
    } catch (error) {
      console.error("Error loading category content:", error);
    }
  };

  const handleBackToCategories = () => {
    setSelectedMainCategory(null);
  };

  const handleSubcategorySelect = (category, subcategory) => {
    setShowAllCategories(false);
    // Handle navigation or content display for the selected subcategory
    console.log("Selected:", category.tag_name, subcategory.category_name_sk);
    // You can add navigation logic here
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleMainCategoryClick = async (category) => {
    // Toggle the selected category
    if (selectedCategory?.tag_name === category.tag_name) {
      setSelectedCategory(null);
      return;
    }

    setIsLoading(true); // Start loading
    setSelectedCategory({ ...category }); // Set initial category data to show structure
    setActiveView("categories"); // Reset to categories view when changing category

    try {
      // Fetch subcategories
      const subcategoriesResponse = await api.get("subcats", {
        params: { category: category.tag_name },
      });

      // Fetch shops
      const shopsResponse = await api.get("category-shops", {
        params: { category: category.tag_name },
      });

      // Update the selected category with fetched data
      setSelectedCategory({
        ...category,
        subcategories: subcategoriesResponse.data.subcategories || [],
        shops: shopsResponse.data || [],
      });
    } catch (error) {
      console.error("Error fetching category data:", error);
      // Optionally show error state
      setSelectedCategory({
        ...category,
        subcategories: [],
        shops: [],
      });
    } finally {
      setIsLoading(false); // End loading regardless of outcome
    }
  };

  // Add this useEffect to handle body scrolling
  useEffect(() => {
    if (selectedCategory) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [selectedCategory]);

  return (
    <>
      {/* Desktop Navbar */}
      <div className="hidden lg:block sticky top-0 z-30">
        <header
          className={`sticky top-0 w-full backdrop-blur-lg z-30 ${
            activeCategory ? "bg-white" : "bg-white"
          }`}
          aria-label="Main navigation"
        >
          {message && (
            <div
              className="w-full text-center flex justify-center bg-primary text-white h-16 items-center"
              role="alert"
            >
              {message}
            </div>
          )}

          {/* Main Navbar */}
          <nav
            className="mx-auto flex gap-5 items-center justify-center py-2 lg:px-8"
            aria-label="Global"
          >
            {/* Logo */}
            <div className="items-center justify-center">
              <Link to="/" className="w-full max-w-[300px]" aria-label="Home">
                <img
                  className="max-w-[300px]"
                  src={logo_url}
                  alt="Bilionbuy Logo"
                />
              </Link>
            </div>

            {/* Search and Partner Shops */}
            <div className="hidden lg:flex w-[1000px] lg:gap-x-12 items-center">
              <Link
                to="/shops/"
                className="flex items-center gap-2 min-w-fit group"
              >
                <HiMiniSquares2X2 className="w-8 h-8 text-lightgray group-hover:text-primary" />
                <div className="flex flex-col text-xs text-lightgray group-hover:text-primary transition-colors">
                  <span className="max-w-[107px]">
                    {t("Partnerské cashback obchody")}
                  </span>
                </div>
              </Link>
              <div className="flex-1" onClick={() => setSearchOpen(true)}>
                <NewSearchBar
                  placeholder={t(
                    "Prehľadávajte produkty, kategórie, obchody, zážitky..."
                  )}
                  onSearchClick={handleSearchClick}
                  value={searchQuery}
                  onChange={handleQueryChange}
                />
              </div>
            </div>

            {/* Location and Auth */}
            <div className="lg:flex flex-row gap-2 hidden">
              <LocationSelector tooltip={t("Vyberte krajinu a jazyk")} />
            </div>
            <div className="hidden lg:flex ml-4 lg:justify-items-center lg:gap-8 text-lightgray items-center">
              {isAuthenticated ? (
                <ProfileComponent />
              ) : (
                <>
                  <Link to="/login" className="text-sm">
                    {t("Login")}
                  </Link>
                  <Link
                    to="/register"
                    className="text-white bg-blue px-8 py-2 rounded-3xl"
                  >
                    {t("Register")}
                  </Link>
                </>
              )}
            </div>
          </nav>

          {/* Categories Section */}
          <div className="border-b border-gray-200 bg-white">
            <div className="max-w-screen-2xl mx-auto xl:px-4">
              <AnimatePresence mode="wait">
                {selectedCategory ? (
                  <motion.div
                    key="detail"
                    initial="exit"
                    animate="enter"
                    exit="exit"
                    variants={detailVariants}
                    transition={{ duration: 0.2 }}
                  >
                    <CategoryDetailView
                      category={selectedCategory}
                      onBack={() => setSelectedCategory(null)}
                      currentLocale={currentLocale}
                      isLoading={isLoading}
                    />
                  </motion.div>
                ) : (
                  <motion.div
                    key="list"
                    initial="exit"
                    animate="enter"
                    exit="exit"
                    variants={listVariants}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="flex flex-col">
                      {/* Main categories row */}
                      <div className="hidden lg:flex items-center gap-3 py-3">
                        {visibleCategories.map((category) => (
                          <button
                            key={category.tag_name}
                            onClick={() => handleMainCategoryClick(category)}
                            className="px-3 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 flex items-center gap-2 whitespace-nowrap"
                          >
                            {category.icon && (
                              <category.icon className="w-4 h-4" />
                            )}
                            {currentLocale === "cs"
                              ? category.category_name_cz
                              : category.category_name_sk}
                          </button>
                        ))}
                        <div className="relative">
                          <button
                            onClick={() =>
                              setShowAllCategories(!showAllCategories)
                            }
                            className="px-3 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 flex items-center gap-1 whitespace-nowrap"
                          >
                            {t("Ďalšie")}{" "}
                            <FaAngleDown
                              className={`transform transition-transform duration-200 ${
                                showAllCategories ? "rotate-180" : ""
                              }`}
                            />
                          </button>
                        </div>
                      </div>

                      {/* Expanded categories dropdown */}
                      <AnimatePresence>
                        {showAllCategories && (
                          <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            className="overflow-hidden"
                          >
                            <div className="py-4 border-b border-gray-100">
                              <div className="flex flex-wrap gap-3">
                                {overflowCategories.map((category) => (
                                  <motion.button
                                    key={category.tag_name}
                                    className="px-3 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 flex items-center gap-2 whitespace-nowrap"
                                    onClick={() => {
                                      handleMainCategoryClick(category);
                                      setShowAllCategories(false);
                                    }}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                  >
                                    {category.icon && (
                                      <category.icon className="w-4 h-4" />
                                    )}
                                    {currentLocale === "cs"
                                      ? category.category_name_cz
                                      : category.category_name_sk}
                                  </motion.button>
                                ))}
                              </div>
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </header>
      </div>

      {/* Mobile Navbar */}
      <div className="block lg:hidden sticky top-0 z-30">
        <header
          className="sticky top-0 w-full backdrop-blur-lg bg-white z-30"
          aria-label="Mobile navigation"
        >
          {/* Top bar with logo and menu */}
          <nav className="flex items-center justify-between px-4 py-2">
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <Link to="/shops/" className="flex items-center gap-1">
                <HiMiniSquares2X2 className="w-4 h-4 text-lightgray" />
              </Link>
            </div>

            <Link to="/" className="flex-shrink-0" aria-label="Home">
              <img src={logo_url} alt="Bilionbuy Logo" className="h-8 w-auto" />
            </Link>

            <div className="flex items-center">
              {isAuthenticated ? (
                <ProfileComponent />
              ) : (
                <Link
                  to="/login"
                  className="text-white bg-blue px-4 py-2 rounded-3xl text-sm"
                >
                  {t("Login")}
                </Link>
              )}
            </div>
          </nav>

          {/* Search bar */}
          <div className="px-4 py-2" onClick={() => setSearchOpen(true)}>
            <NewSearchBar
              placeholder={t(
                "Prehľadávajte produkty, kategórie, obchody, zážitky..."
              )}
              onSearchClick={handleSearchClick}
              value={searchQuery}
              onChange={handleQueryChange}
            />
          </div>

          {/* Categories Section - Mobile */}
          <div className="border-b border-gray-200 bg-white overflow-x-auto">
            <div className="px-4">
              <div className="flex flex-col">
                {selectedCategory ? (
                  <CategoryDetailView
                    category={selectedCategory}
                    onBack={() => setSelectedCategory(null)}
                    currentLocale={currentLocale}
                    isLoading={isLoading}
                  />
                ) : (
                  <>
                    {/* Main categories row */}
                    <div className="flex lg:hidden items-center gap-2 py-3 overflow-x-auto no-scrollbar">
                      {visibleCategories.map((category) => (
                        <button
                          key={category.tag_name}
                          onClick={() => handleMainCategoryClick(category)}
                          className="px-3 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 flex items-center gap-2 whitespace-nowrap flex-shrink-0"
                        >
                          {category.icon && (
                            <category.icon className="w-4 h-4" />
                          )}
                          {currentLocale === "cs"
                            ? category.category_name_cz
                            : category.category_name_sk}
                        </button>
                      ))}
                      <button
                        onClick={() => setShowAllCategories(!showAllCategories)}
                        className="px-3 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 flex items-center gap-1 whitespace-nowrap flex-shrink-0"
                      >
                        {t("Ďalšie")}{" "}
                        <FaAngleDown
                          className={`transform transition-transform duration-200 ${
                            showAllCategories ? "rotate-180" : ""
                          }`}
                        />
                      </button>
                    </div>

                    {/* Expanded categories */}
                    <AnimatePresence>
                      {showAllCategories && (
                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: "auto", opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          className="overflow-hidden"
                        >
                          <div className="py-4 border-t border-gray-100">
                            <div className="flex flex-wrap gap-2">
                              {overflowCategories.map((category) => (
                                <motion.button
                                  key={category.tag_name}
                                  className="px-3 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 flex items-center gap-2 whitespace-nowrap"
                                  onClick={() => {
                                    handleMainCategoryClick(category);
                                    setShowAllCategories(false);
                                  }}
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                >
                                  {category.icon && (
                                    <category.icon className="w-4 h-4" />
                                  )}
                                  {currentLocale === "cs"
                                    ? category.category_name_cz
                                    : category.category_name_sk}
                                </motion.button>
                              ))}
                            </div>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>

        {/* Mobile Menu Dialog - Simplified */}
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        >
          <div className="fixed inset-0 z-10" />
          <motion.div
            variants={slideInVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
          >
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <img
                  className="h-8 w-auto"
                  src={logo_url}
                  alt="Bilionbuy Logo"
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {/* Only show essential menu items */}
                  {!isAuthenticated && (
                    <>
                      <Link
                        to="/login"
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        {t("Login")}
                      </Link>
                      <Link
                        to="/register"
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        {t("Register")}
                      </Link>
                    </>
                  )}
                  <div className="flex flex-row items-center gap-2">
                    {t("Krajina a jazyk:")}
                    <LocationSelector tooltip={t("Vyberte krajinu a jazyk")} />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </Dialog>
      </div>

      {/* Global Search Bar */}
      <SearchBar
        isOpen={searchOpen}
        closeSearch={handleSearchClose}
        initialQuery={searchQuery}
        onQueryChange={handleQueryChange}
        className="w-screen h-screen z-[9999]"
      />

      {/* Product Categories Section */}
      <div ref={productCategoriesRef}>
        {activeCategory && <ProductCategories category={activeCategory} />}
      </div>

      {/* Category Content Component */}
      {selectedMainCategory && categoryData && (
        <CategoryContent
          category={categoryData}
          shops={categoryData.shops}
          onBack={handleBackToCategories}
        />
      )}

      {/* Add this CSS to your global styles or a stylesheet */}
      <style jsx>{`
        .no-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .no-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      `}</style>
    </>
  );
}
