import { Button, Link, Spinner } from "@nextui-org/react";
import { motion } from "framer-motion";
import CreateReview from "./CreateReview";
import api from "../services/api";
import React, { useState, useEffect } from "react";
import { Select, SelectItem } from "@nextui-org/select";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { useLeaveModal } from "./LeaveModalContext";

const ShopHero = ({ data, t }) => {
  const { openModal } = useLeaveModal();
  const registerClick = async (shopId) => {
    try {
      await api.post(`register-click/`, {
        shopId: shopId,
      });
    } catch (error) {
      console.error("Error registering click: ", error);
    }
  };

  // Add this formatting function at the beginning of the component
  const formatNumber = (number) => {
    const rounded = Math.round(number * 100) / 100;
    return Number.isInteger(rounded)
      ? rounded
      : rounded.toFixed(2).replace(".", ",");
  };

  const handleRedirectClick = (shopUrl, shopId, shopName) => {
    const userToken = localStorage.getItem("jwt");
    const isAuthenticated = !!userToken;

    if (isAuthenticated) {
      registerClick(shopId);
    }

    openModal(
      shopName,
      () => {
        const link = document.createElement("a");
        link.href = shopUrl;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      isAuthenticated
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeOut" }}
    >
      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto">
          <div className="pt-8">
            <div className="flex flex-col items-center gap-8 pt-8 pb-16">
              <div className="w-full max-w-[300px] overflow-hidden rounded-lg mb-6">
                <img
                  src={data?.logo_url}
                  alt={`${data?.name} logo`}
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="flex flex-col items-center text-center">
                <h1 className="text-3xl sm:text-5xl md:text-3xl font-bold tracking-tight text-gray-900 mb-4">
                  {data?.name}
                </h1>
                <p className="text-base text-gray-500 mb-8 max-w-2xl px-4 lg:px-0">
                  {data?.description}
                </p>
                <Button
                  as={Link}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleRedirectClick(
                      data.campaing_link
                        ? data.final_url
                        : `https://www.${data.name}`,
                      data.id,
                      data.name
                    );
                  }}
                  className="bg-blue text-white rounded-3xl px-10 text-lg font-semibold uppercase"
                >
                  {t("Do obchodu")} &rarr;
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Commission Groups */}
      {data.campaing_link && (
        <>
          {data.additional_commision_groups &&
          data.additional_commision_groups.length > 0 ? (
            <div className="max-w-screen-xl mx-auto mt-8">
              <h2 className="text-4xl font-bold mb-4 text-center px-4 lg:px-0">
                {t("Cashbackové skupiny")}
              </h2>
              <div className="flex flex-wrap gap-4 gap-y-8 mb-8 justify-center mt-10">
                {data.additional_commision_groups.map((group, index) => (
                  <div key={index} className="text-sm">
                    <span className="p-3 rounded-lg shadow-md border-1 flex flex-wrap gap-2 justify-center mx-4 lg:mx-4">
                      <span className="text-gray-500">
                        {group.type} <strong>{group.name}</strong>{" "}
                      </span>
                      <span>
                        <span className="text-white bg-primary px-2 py-1 rounded-3xl font-bold text-xs">
                          {formatNumber(
                            group.value * data.shop_direct_coeff_return
                          )}{" "}
                          %
                        </span>{" "}
                        <span className="text-white bg-primary px-2 py-1 rounded-3xl font-bold text-xs">
                          DRC{" "}
                          {formatNumber(
                            group.value * data.shop_refferal_coeff_return
                          )}{" "}
                          %
                        </span>{" "}
                        <span className="text-white bg-primary px-2 py-1 rounded-3xl font-bold text-xs">
                          {formatNumber(
                            group.value * data.shop_ml_coeff_return * 10
                          )}{" "}
                          B
                        </span>
                      </span>
                    </span>
                  </div>
                ))}
                <div className="text-sm">
                  <span className="p-3 rounded-lg shadow-md border-1 flex flex-wrap gap-2 justify-center mx-4 lg:mx-4">
                    <span>{t("Ostatné")} </span>
                    <span>
                      <span className="text-white bg-primary px-2 py-1 rounded-3xl font-bold text-xs">
                        {formatNumber(
                          data.default_cashback * data.shop_direct_coeff_return
                        )}{" "}
                        %
                      </span>{" "}
                      <span className="text-white bg-primary px-2 py-1 rounded-3xl font-bold text-xs">
                        DRC{" "}
                        {formatNumber(
                          data.default_cashback *
                            data.shop_refferal_coeff_return
                        )}{" "}
                        %
                      </span>{" "}
                      <span className="text-white bg-primary px-2 py-1 rounded-3xl font-bold text-xs">
                        {formatNumber(
                          data.default_cashback * data.shop_ml_coeff_return * 10
                        )}{" "}
                        B
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="max-w-screen-xl mx-auto mt-8 flex justify-center">
              <div className="text-sm">
                <span className="p-3 rounded-lg shadow-md border-1">
                  {t("Cashback z obchodu")}{" "}
                  <span className="text-white bg-primary px-2 py-1 rounded-3xl font-bold text-xs">
                    {formatNumber(
                      data.default_cashback * data.shop_direct_coeff_return
                    )}{" "}
                    %
                  </span>{" "}
                  <span className="text-white bg-primary px-2 py-1 rounded-3xl font-bold text-xs">
                    DRC{" "}
                    {formatNumber(
                      data.default_cashback * data.shop_refferal_coeff_return
                    )}{" "}
                    %
                  </span>{" "}
                  <span className="text-white bg-primary px-2 py-1 rounded-3xl font-bold text-xs">
                    {formatNumber(
                      data.default_cashback * data.shop_ml_coeff_return * 10
                    )}{" "}
                    B
                  </span>
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </motion.div>
  );
};

export default ShopHero;
