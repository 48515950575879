import React, { useState, useEffect } from "react";
import { GrCart } from "react-icons/gr";
import { FaAngleDown } from "react-icons/fa";
import { Spinner } from "@nextui-org/react";
import ReactCountryFlag from "react-country-flag";
import { Tooltip } from "@nextui-org/tooltip";
import LanguageModal from "./LanguageModal";
import { useTranslation } from "react-i18next";

const countryCodes = {
  sk: "SK", // Slovakia
  cs: "CZ", // Czechia
  pl: "PL", // Poland
  hu: "HU", // Hungary
  en: "GB", // English (United Kingdom)
  de: "DE", // Germany
};

const LocationSelector = ({ icon, tooltip }) => {
  const { i18n } = useTranslation();
  const [location, setLocation] = useState(<Spinner size="small" />);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const currentLanguage = i18n.language;

  useEffect(() => {
    const locale = localStorage.getItem("locale");

    // Open modal if no locale is set
    if (!locale) {
      setModalOpen(true);
    }

    if (countryCodes[currentLanguage]) {
      setLocation(countryCodes[currentLanguage]);
    } else {
      setError("Country flag not found for the selected language.");
    }
  }, [currentLanguage]);

  const openLocaleModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="relative group z-0">
        <Tooltip content={tooltip}>
          <div
            className="flex items-center gap-2  p-3 rounded-3xl cursor-pointer hover:bg-white transition-all duration-300"
            onClick={openLocaleModal}
          >
            <div>{icon}</div>
            <div className="flex items-center gap-2">
              <span className="whitespace-nowrap overflow-hidden items-center transition-transform duration-300 transform group-hover:translate-x-0 flex flex-row gap-2">
                {typeof location === "string" ? (
                  <ReactCountryFlag
                    countryCode={location}
                    svg
                    className="w-4"
                    title={location}
                  />
                ) : (
                  location
                )}
              </span>
            </div>
          </div>
        </Tooltip>
      </div>
      <LanguageModal openModal={modalOpen} onClose={closeModal} />
    </>
  );
};

export default LocationSelector;
