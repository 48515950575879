import React, { useState, useEffect } from "react";
import { NextUIProvider, Image } from "@nextui-org/react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import api from "../services/api";
import SearchBar from "../components/SearchBar";
import { useTranslation } from "react-i18next";
import "../utils/quill.css";
import Translate from "../components/TranslationAPI";

const Blog = () => {
  const { id } = useParams();
  const { i18n } = useTranslation(); // Get i18n instance
  const [searchOpen, setSearchOpen] = useState(false);
  const [blogContent, setBlogContent] = useState(null);
  const [error, setError] = useState(null);

  const fetchBlog = async () => {
    try {
      const url = `get-blog/${id}`;
      const response = await api.get(url);
      setBlogContent(response.data);
      setError(null); // Clear any previous errors
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError("Blog not found");
      } else {
        setError("An error occurred while fetching the blog");
      }
      setBlogContent(null); // Clear any previous content
    }
  };

  const translatedContent = (content) => {
    const translated = <Translate text={content} />;
    console.log("Translated content: ", translated);
    return translated;
  };
  useEffect(() => {
    fetchBlog();
  }, [id]);

  if (!blogContent) {
    return (
      <div className="mx-auto max-w-8xl sm:px-6 lg:px-0">
        <BasicNavbar setSearchOpen={setSearchOpen} />
        <SearchBar
          isOpen={searchOpen}
          closeSearch={() => setSearchOpen(false)}
        />
        <div className="text-center mt-8">Loading...</div>
        <Footer />
      </div>
    );
  }

  const languageCode = i18n.language; // Get current language code

  const titleField = `title_${languageCode}`;
  const perexField = `perex_${languageCode}`;
  const contentField = `content_${languageCode}`;

  return (
    <div className="mx-auto max-w-8xl sm:px-6 lg:px-0">
      <div className="sticky top-0 z-50">
        <BasicNavbar setSearchOpen={setSearchOpen} />
      </div>
      <SearchBar isOpen={searchOpen} closeSearch={() => setSearchOpen(false)} />
      {error ? (
        <div className="text-center text-red-500 mt-8">{error}</div>
      ) : (
        <>
          <div className="xl:w-[1600px] h-[400px] overflow-hidden rounded-lg mx-auto shadow-md">
            <img
              src={blogContent.cover_photo_url}
              alt={blogContent[titleField]}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="max-w-7xl mx-auto mt-8 px-4 xl:px-0">
            <div className="flex xl:flex-row flex-wrap gap-2 justify-between">
              <div className="xl:text-4xl text-3xl font-semibold">
                {blogContent[titleField] ? (
                  blogContent[titleField]
                ) : (
                  <Translate text={blogContent.title_sk} />
                )}
              </div>
              <div className="text-white font-semibold bg-pink py-2 px-6 rounded-3xl ">
                {blogContent.category}
              </div>
            </div>
            <div className="flex flex-row gap-24 mt-4">
              <div className="text-blue font-semibold">
                Autor: {blogContent.author}
              </div>
              <div className="text-lightgray">{blogContent.formatted_date}</div>
            </div>
            {/* {blogContent[contentField] ? ( */}
            <div
              className="my-16 quill-content"
              dangerouslySetInnerHTML={{
                __html: blogContent[contentField]
                  ? blogContent[contentField]
                  : blogContent.content_sk,
              }}
            ></div>
            {/* ) : (
              <div className="my-16 quill-content">
                {translatedContent(blogContent.content_sk)}
              </div>
            )} */}
          </div>
        </>
      )}
      <Footer />
    </div>
  );
};

export default Blog;
