import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import ShopHero from "../components/ShopHero";
import ShopProducts from "../components/ShopProducts";
import api from "../services/api";
import FilteredItems from "../components/FilteredItems";

export default function ViewShop() {
  let { id } = useParams();
  const [searchOpen, setSearchOpen] = useState(false);
  const [shopData, setShopData] = useState(null);
  const [shopProducts, setShopProducts] = useState(null);

  // const fetchShopData = async () => {
  //   try {
  //     const response = await api.get(`get-manufacturer-data/`, {
  //       params: {
  //         shop_id: id,
  //       },
  //     });
  //     setShopData(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchShopData();
  //   fetchShopProducts();
  // }, []);

  return (
    <>
      <div className="sticky top-0 z-50">
        <BasicNavbar setSearchOpen={setSearchOpen} />
      </div>
      <div>
        <div className="flex justify-center py-24">
          <h1 className="text-9xl font-bold text-center mt-10 bg-gradient-to-r from-[#3769EF] to-[#FD60A9] bg-clip-text text-transparent">
            {id}
          </h1>
        </div>
        <hr />
        <FilteredItems
          id={id}
          visible={true}
          fetchCall={"get-manufacturer-products"}
        />
      </div>
      <Footer />
    </>
  );
}
