import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Create } from "@mui/icons-material";
import EANInput from "./EANInput";
import ParameterInput from "./ParameterInput";
import NewParameterInput from "./NewParameterInput";

const ProductRow = React.memo(
  ({
    product,
    parameters,
    onUpdateParameter,
    onCreateParameter,
    onDeleteParameter,
    onUpdateProduct,
    onEditClick,
  }) => {
    const [loadingStates, setLoadingStates] = useState({});

    const handleEANUpdate = async (newEAN) => {
      setLoadingStates((prev) => ({ ...prev, ean: true }));
      await onUpdateProduct(product.id, { product_ean: newEAN });
      setLoadingStates((prev) => ({ ...prev, ean: false }));
    };

    const handleParameterUpdate = async (paramId, newValue) => {
      setLoadingStates((prev) => ({ ...prev, [paramId]: true }));
      await onUpdateParameter(product.id, paramId, newValue);
      setLoadingStates((prev) => ({ ...prev, [paramId]: false }));
    };

    const handleParameterCreate = async (paramId, newValue) => {
      setLoadingStates((prev) => ({ ...prev, [paramId]: true }));
      await onCreateParameter(product.id, {
        parameter_id: paramId,
        value: newValue,
      });
      setLoadingStates((prev) => ({ ...prev, [paramId]: false }));
    };

    const handleParameterDelete = async (paramId) => {
      setLoadingStates((prev) => ({ ...prev, [paramId]: true }));
      await onDeleteParameter(product.id, paramId);
      setLoadingStates((prev) => ({ ...prev, [paramId]: false }));
    };

    return (
      <TableRow>
        <TableCell
          sx={{
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 1000,
            minWidth: 300,
            width: 300,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={product.product_image_url}
              alt={product.product_name}
              style={{ width: 40, height: 40, marginRight: 8 }}
            />

            <div className="flex flex-col">
              <div
                style={{
                  flexGrow: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>{product.product_name}</strong>
              </div>
              <div className="text-xs">
                Počet prepojených SK shopov: {product.linkedshops_sk}
              </div>
              <div className="text-xs">
                Počet cudzokrajných shopov: {product.linkedshops_foreign}
              </div>
            </div>

            <IconButton onClick={() => onEditClick(product.id)} size="small">
              <Create fontSize="small" />
            </IconButton>
          </div>
        </TableCell>
        <TableCell>{product.category?.name}</TableCell>
        <TableCell>
          {loadingStates.ean ? (
            <CircularProgress size={20} />
          ) : (
            <EANInput product={product} onUpdate={handleEANUpdate} />
          )}
        </TableCell>
        {parameters.map((param) => {
          const existingParam = product.parameters.find(
            (p) => p.name === param.name
          );
          return (
            <TableCell key={param.id}>
              {loadingStates[param.id] ? (
                <CircularProgress size={20} />
              ) : existingParam ? (
                <ParameterInput
                  product={product}
                  param={existingParam}
                  onUpdate={(newValue) =>
                    handleParameterUpdate(existingParam.id, newValue)
                  }
                  onDelete={() => handleParameterDelete(existingParam.id)}
                />
              ) : (
                <NewParameterInput
                  product={product}
                  param={param}
                  onCreate={(newValue) =>
                    handleParameterCreate(param.id, newValue)
                  }
                />
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
);

export default ProductRow;
