import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";

const AgeConfirmationModal = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={true} onClose={onCancel} placement="center" backdrop="blur">
      <ModalContent>
        <ModalHeader>
          <h3 className="text-xl font-bold">
            {t("age_verification_required")}
          </h3>
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col gap-4">
            <p>{t("adult_content_warning")}</p>
            <p className="font-bold">{t("age_confirmation_notice")}</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="light" onPress={onCancel}>
            {t("cancel")}
          </Button>
          <Button color="primary" onPress={onConfirm}>
            {t("confirm")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AgeConfirmationModal;
