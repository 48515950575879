import { Image, Button, Link, Tooltip } from "@nextui-org/react";
import { FaRegCirclePlay, FaLink } from "react-icons/fa6";
import React, { useState } from "react";
import VideoModal from "./VideoModal";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function HowCashbackWorks({ auth }) {
  const [isOpen, setIsOpen] = useState(false);
  const videoId = "dQw4w9WgXcQ"; // Replace with your YouTube video ID
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const { t } = useTranslation();

  const copyRefferalCode = () => {
    navigator.clipboard.writeText(
      "https://www.bilionbuy.com/register?ref=" +
        localStorage.getItem("refcode")
    );
  };

  return (
    <>
      <VideoModal isOpen={isOpen} videoId={videoId} onClose={closeModal} />

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        <div className="py-16 bg-white px-8">
          <div className="flex flex-col gap-8 max-w-screen-2xl mx-auto">
            <div className="flex flex-row justify-center gap-24">
              <div className="hidden lg:block">
                <img
                  className="h-full w-full object-cover rounded-3xl shadow-lg"
                  src="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/cashback_work.png"
                  alt="Illustration of how cashback works"
                />
              </div>
              <div className="flex flex-col gap-12 my-auto text-center lg:text-left">
                <div>
                  <h2 className="text-blue font-semibold my-4">
                    {t("Výhody Cashbacku")}
                  </h2>
                  <h3 className="font-bold lg:text-5xl text-3xl leading-tight lg:max-w-[800px]">
                    {t(
                      "Každý nákup je dôvodom na šetrenie. S nami vďaka cashbacku šetríte pri každom nákupe. Ako funguje cashback?"
                    )}
                  </h3>
                </div>

                <div>
                  <ul className="font-semibold list-disc tracking-wide list-inside flex flex-col leading-loose">
                    <li>{t("Úplne zdarma, bez žiadnych poplatkov")}</li>
                    <li>{t("Nárok na priamy cashback z vašich nákupov")}</li>
                    <li>
                      {t("Nárok na kariérne odmeny z nákupov vašich priateľov")}
                    </li>
                    <li>
                      {t("Žiadne obmedzujúce podmienky na výplaty cashbacku")}
                    </li>
                    <li>{t("Vzdelávací systém na podporu vašich zárobkov")}</li>
                  </ul>
                </div>
                <div className="flex lg:flex-row flex-col gap-8 lg:mx-0 mx-auto w-56 lg:w-auto">
                  {auth ? (
                    <div className="flex flex-row gap-2 items-center md:justify-start justify-center">
                      <Button
                        as="a"
                        href="/my-zone#invites"
                        className="bg-blue text-white rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10"
                      >
                        {t("Pozvi priateľa")} &rarr;
                      </Button>
                      <Tooltip content={t("click_for_linkcopy")}>
                        <div
                          className="p-3 md:p-4 rounded-full border-1 border-primary hover:cursor-pointer"
                          onClick={copyRefferalCode}
                        >
                          <FaLink className="text-primary" />
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex md:flex-row flex-col gap-2 items-center md:justify-start justify-center">
                      <Button
                        as="a"
                        href="/register"
                        className="bg-blue text-white rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10"
                      >
                        {t("Registrácia zdarma")} &rarr;
                      </Button>
                      <Button
                        as="a"
                        href="/blog#vyhody-cashbacku/"
                        className="bg-transparent text-blue border-blue border rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10"
                      >
                        {t("Viac informácií")}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
