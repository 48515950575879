import React, { useEffect } from "react";
import { Card, CardBody, Image } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

export const ShopCard = ({ itemData }) => {
  useEffect(() => {
    // Get original body overflow value to restore later
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    navigate(`/view-shop/${itemData.id}`);
  };

  return (
    <Card
      isBlurred
      className="border-none bg-white w-[295px] max-w-[300px]"
      shadow="md"
      as="a"
      href={`/view-shop/${itemData.id}`}
      onClick={handleClick}
    >
      <CardBody>
        <div className="flex flex-row items-center gap-4 overflow-hidden">
          <div className="">
            <Image
              alt={itemData.product_name}
              className="object-contain w-16 h-16 max-w-[6rem] max-h-[6rem]"
              shadow="none"
              src={itemData.logo_url}
            />
          </div>
          <div className="">
            <div className="">
              <div className="">
                <h3 className="font-semibold text-sm text-foreground/90">
                  {itemData.name}
                </h3>
                {/* Additional components can be uncommented or added here */}
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
