import { Button, Image, Link, Spinner } from "@nextui-org/react";
import api from "../services/api";
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ProductReviews from "./ProductReviews";

const registerClick = async (linkId) => {
  const userToken = localStorage.getItem("jwt");
  const response = await api.post("register-click/", { linkId, userToken });
  console.log("Click registered:", response.data);
};

const fetchProductShops = async (productId) => {
  const url = `get-travel-shop-links/${productId}/`;
  const response = await api.get(url);
  return response.data;
};

export default function TravelShopList({ id }) {
  const [productShops, setProductShops] = useState([]);
  const [selectedKey, setSelectedKey] = useState("price");
  const [selector, setSelector] = useState("price");

  const [reviews, setReviews] = useState([]);
  const [advices, setAdvices] = useState([]);

  const fetchProductReviews = async (productId) => {
    const url = `get-travel-reviews/${productId}/`;
    try {
      const response = await api.get(url);
      setReviews(response.data.reviews);
    } catch (error) {
      console.error("Error fetching product reviews:", error);
      // You can also set an error state here if needed
      setReviews([]);
    }
  };

  const fetchProductParameters = async (productId) => {
    const url = `get-travel-reviews/${productId}/`;
    try {
      const response = await api.get(url);
      setReviews(response.data.reviews);
    } catch (error) {
      console.error("Error fetching product parameters:", error);
      // You can also set an error state here if needed
      setReviews([]);
    }
  };

  const fetchProductAdvices = async (productId) => {
    const url = `get-travel-advices/${productId}/`;
    try {
      const response = await api.get(url);
      setAdvices(response.data.reviews);
    } catch (error) {
      console.error("Error fetching product advices:", error);
      // You can also set an error state here if needed
      setAdvices([]);
    }
  };

  const handleSelectorChange = (name) => {
    setSelector(name);
    setSelectedKey(name);
  };

  const normalizePrice = (price) =>
    parseFloat(price?.replace(",", ".").replace(/[^\d.]/g, ""));

  const sortByPrice = () => {
    const sortedProducts = [...productShops].sort(
      (a, b) => normalizePrice(a.price_vat) - normalizePrice(b.price_vat)
    );
    setProductShops(sortedProducts);
    setSelectedKey("price");
  };

  const sortByCashback = () => {
    const sortedProducts = [...productShops].sort(
      (a, b) => (b.computed_cashback || 0) - (a.computed_cashback || 0)
    );
    setProductShops(sortedProducts);
    setSelectedKey("cashback");
  };

  const sortByPriceAndCashback = () => {
    const sortedProducts = [...productShops].sort((a, b) => {
      const priceA = normalizePrice(a.price_vat) - (a.computed_cashback || 0);
      const priceB = normalizePrice(b.price_vat) - (b.computed_cashback || 0);
      return priceA - priceB;
    });
    setProductShops(sortedProducts);
    setSelectedKey("overall");
  };

  useEffect(() => {
    (async () => {
      const data = await fetchProductShops(id);
      setProductShops(data);
      fetchProductReviews(id);
      fetchProductAdvices(id);
      fetchProductParameters(id);
      // sortByPrice();
    })();
  }, [id]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1, delay: 0.5, ease: "easeOut" }}
    >
      <div className="mt-12">
        <hr />
      </div>
      <div
        className="mx-auto max-w-7xl flex gap-10 py-4 font-semibold text-lightgray"
        id="comparison"
      >
        <button
          onClick={() => {
            sortByPrice();
            handleSelectorChange("price");
          }}
          className={`${
            selectedKey === "price"
              ? "text-blue underline underline-offset-8"
              : "hover:text-blue"
          } cursor-pointer`}
        >
          Najlacnejšie v obchode
        </button>
        <button
          onClick={() => {
            sortByCashback();
            handleSelectorChange("cashback");
          }}
          className={`${
            selectedKey === "cashback"
              ? "text-blue underline underline-offset-8"
              : "hover:text-blue"
          } cursor-pointer`}
        >
          Najväčší cashback
        </button>
        <button
          onClick={() => {
            sortByPriceAndCashback();
            handleSelectorChange("overall");
          }}
          className={`${
            selectedKey === "overall"
              ? "text-blue underline underline-offset-8"
              : "hover:text-blue"
          } cursor-pointer`}
        >
          Najvýhodnejšie v bilionbuy
        </button>

        <button
          onClick={() => handleSelectorChange("reviews")}
          className={`${
            selectedKey === "reviews"
              ? "text-blue underline underline-offset-8"
              : "hover:text-blue"
          } cursor-pointer`}
        >
          Recenzie
        </button>
        <button
          onClick={() => handleSelectorChange("advice")}
          className={`${
            selectedKey === "advice"
              ? "text-blue underline underline-offset-8"
              : "hover:text-blue"
          } cursor-pointer`}
        >
          Poradňa
        </button>
      </div>
      <div className="bg-[#F1F3F5] py-12">
        <div className="mx-auto max-w-screen-xl">
          {(productShops.length > 0 &&
            (selector === "price" || selector === "cashback")) ||
          selector === "overall" ? (
            <motion.ul layout>
              <AnimatePresence>
                {productShops.map((shop, index) => (
                  <motion.li
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                    key={index}
                    className="relative flex justify-between gap-x-6 py-5 bg-white hover:bg-gray-50 rounded-sm h-[166px] px-5 my-8 overflow-visible shadow-md border-1 hover:shadow-lg "
                  >
                    <div className="flex items-center">
                      <img
                        src={shop.image}
                        alt=""
                        className="w-36 max-w-36 max-h-24 p-4 "
                      />
                      <div className="text-orange font-bold underline underline-offset-4">
                        {shop.shopname}
                      </div>
                    </div>
                    <div className="max-w-[400px] flex flex-1 flex-wrap  gap-y-0 gap-x-2 items-center text-xs font-bold h-[82px] my-auto">
                      {shop.discount > 0 ? (
                        <button className="text-white bg-orange px-2  rounded-3xl py-1">
                          Zĺava: {shop.discount}%
                        </button>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="mr-24 flex flex-col my-auto gap-4">
                      <div className="text-orange font-bold text-right text-xl">
                        &nbsp;
                        {shop.price
                          ? shop.price?.replace(".", ",")
                          : "N/A"}{" "}
                        {shop.price_currency
                          ? shop.price_currency?.replace("EUR", "€")
                          : ""}
                      </div>
                      <Button
                        as={Link}
                        href={shop.url}
                        target="_blank"
                        onClick={() => {
                          registerClick(shop.id);
                        }}
                        className="bg-orange text-white rounded-3xl px-10 text-lg font-semibold"
                      >
                        Do obchodu &rarr;
                      </Button>
                    </div>
                    {shop.computed_cashback ? (
                      <div className="shadow-lg absolute -right-10 -top-5 flex flex-col	 items-center  justify-center w-32 h-32 bg-blue text-white rounded-full">
                        <div className="text-xl font-bold">
                          {(
                            shop.computed_cashback *
                            normalizePrice(shop.price_vat)
                          ).toFixed(2)}{" "}
                          {shop.currency
                            ? shop.currency?.replace("EUR", "€")
                            : ""}
                        </div>
                        <div className="text-sm">cashback</div>
                      </div>
                    ) : (
                      <div className="hidden"></div>
                    )}
                  </motion.li>
                ))}
              </AnimatePresence>
            </motion.ul>
          ) : null}
        </div>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          {selector === "reviews" ? <ProductReviews productId={id} /> : null}
        </div>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          {selector === "advice" ? (
            reviews.length > 0 ? (
              <div>Poradňa</div>
            ) : (
              <div className="text-2xl text-lightgray font-semibold text-center">
                Pre daný produkt neboli nájdené žiadne záznamy
              </div>
            )
          ) : null}
        </div>
      </div>
    </motion.div>
  );
}
