import { useEffect, useState, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "../services/api";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import debounce from "lodash/debounce";

const CategorySelector = ({
  selectedCategories = [],
  setSelectedCategories,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  // Fetch categories when the component mounts or selectedCategories change
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        let fetchedCategories = [];
        if (selectedCategories.length > 0) {
          // Fetch details for each pre-selected category
          const promises = selectedCategories.map((id) =>
            api
              .get(`get-category/?id=${id}`)
              .then((response) =>
                Array.isArray(response.data) ? response.data[0] : response.data
              )
          );
          fetchedCategories = await Promise.all(promises);
        } else {
          // Fetch all categories if no pre-selected categories
          const response = await api.get(`get-category/`);
          fetchedCategories = Array.isArray(response.data)
            ? response.data
            : [response.data];
        }

        setOptions((prevOptions) => {
          const newOptions = [...prevOptions];
          fetchedCategories.forEach((category) => {
            if (
              category &&
              !newOptions.some((option) => option.id === category.id)
            ) {
              newOptions.push(category);
            }
          });
          return newOptions;
        });
      } catch (error) {
        console.error("Error fetching categories: ", error);
      }
    };

    fetchCategories();
  }, [selectedCategories]);

  // Get selected category objects based on selectedCategories IDs
  const selectedOptions = options.filter((option) =>
    selectedCategories.includes(option.id)
  );

  const handleChange = (event, newValue) => {
    const selectedIds = newValue.map((option) => option.id);
    setSelectedCategories(selectedIds);
  };

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.length > 2) {
        try {
          const response = await api.get(`search-categories/?query=${query}`);
          const newOptions = Array.isArray(response.data) ? response.data : [];
          setOptions((prevOptions) => {
            const mergedOptions = [...prevOptions];
            newOptions.forEach((category) => {
              if (!mergedOptions.some((option) => option.id === category.id)) {
                mergedOptions.push(category);
              }
            });
            return mergedOptions;
          });
        } catch (error) {
          console.error("Error searching categories: ", error);
        }
      }
    }, 300),
    []
  );

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    debouncedSearch(newInputValue);
  };

  return (
    <Autocomplete
      disablePortal
      multiple
      id="categories-selector"
      options={options}
      getOptionLabel={(option) => option?.category_fullname || ""}
      renderOption={(props, option) => (
        <li {...props}>{option?.category_fullname || ""}</li>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option?.category_name || ""}
            {...getTagProps({ index })}
            key={option?.id || index}
          />
        ))
      }
      value={selectedOptions}
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField {...params} label="Kategórie" variant="outlined" />
      )}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      freeSolo
    />
  );
};

CategorySelector.propTypes = {
  selectedCategories: PropTypes.array.isRequired,
  setSelectedCategories: PropTypes.func.isRequired,
};

export default CategorySelector;
