import React from "react";
import { useParams } from "react-router-dom";

function ProductView() {
  let { id } = useParams();

  return (
    <div>
      <h1>Product Details</h1>
      <p>Showing comparison for product ID: {id}</p>
    </div>
  );
}

export default ProductView;
