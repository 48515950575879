import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TablePagination,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Save, Clear } from "@mui/icons-material";
import api from "../../services/api";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";

const CategoryMappings = () => {
  const [mappings, setMappings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [nameFilter, setNameFilter] = useState("");
  const [debouncedNameFilter, setDebouncedNameFilter] = useState("");
  const [languageFilter, setLanguageFilter] = useState("");
  const [editedNames, setEditedNames] = useState({});
  const [showSaveButtons, setShowSaveButtons] = useState({});

  const languageOptions = [
    { value: "cz", label: "Čeština" },
    { value: "hu", label: "Maďarčina" },
    { value: "de", label: "Nemčina" },
    { value: "en", label: "Angličtina" },
    { value: "pl", label: "Poľština" },
  ];

  const debouncedSetFilter = useCallback(
    debounce((value) => {
      setDebouncedNameFilter(value);
    }, 500),
    []
  );

  const fetchMappings = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        page: page + 1,
        items_per_page: rowsPerPage,
        name: debouncedNameFilter,
        language: languageFilter,
      };

      const response = await api.get("get-category-mappings/", { params });
      const { results, count, total_pages, current_page } = response.data;
      setMappings(results);
      setTotalCount(count);
    } catch (error) {
      console.error("Error fetching category mappings:", error);
      toast.error("Failed to load category mappings. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, debouncedNameFilter, languageFilter]);

  useEffect(() => {
    fetchMappings();
  }, [fetchMappings]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNameFilterChange = (event) => {
    const value = event.target.value;
    setNameFilter(value);
    debouncedSetFilter(value);
  };

  const handleLanguageFilterChange = (event) => {
    setLanguageFilter(event.target.value);
    setPage(0);
  };

  const handleNameChange = (id, newValue) => {
    setEditedNames((prev) => ({
      ...prev,
      [id]: newValue,
    }));
    setShowSaveButtons((prev) => ({
      ...prev,
      [id]: true,
    }));
  };

  const handleDiscard = (id) => {
    setEditedNames((prev) => {
      const newState = { ...prev };
      delete newState[id];
      return newState;
    });
    setShowSaveButtons((prev) => ({
      ...prev,
      [id]: false,
    }));
  };

  const handleSave = async (id) => {
    try {
      const response = await api.post("update-category-mapping/", {
        id: id,
        category_name: editedNames[id],
      });

      // Use the returned data to update the state
      if (response.data.success) {
        setMappings((prevMappings) =>
          prevMappings.map((mapping) =>
            mapping.id === id
              ? {
                  ...mapping,
                  category_name: response.data.data.category_name,
                  category_fullname: response.data.data.category_fullname,
                }
              : mapping
          )
        );

        // Clear the edited state and hide buttons
        setEditedNames((prev) => {
          const newState = { ...prev };
          delete newState[id];
          return newState;
        });
        setShowSaveButtons((prev) => ({
          ...prev,
          [id]: false,
        }));

        toast.success("Názov kategórie bol úspešne aktualizovaný");
      }
    } catch (error) {
      console.error("Error updating category name:", error);
      toast.error("Nepodarilo sa aktualizovať názov kategórie");
    }
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
        <TextField
          label="Filtrovať podľa názvu"
          variant="outlined"
          value={nameFilter}
          onChange={handleNameFilterChange}
          style={{ flexGrow: 1 }}
        />
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel>Jazyk</InputLabel>
          <Select
            value={languageFilter}
            onChange={handleLanguageFilterChange}
            label="Jazyk"
          >
            {languageOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Názov kategórie</TableCell>
              <TableCell>Celý názov kategórie</TableCell>
              <TableCell>Názov cieľovej kategórie</TableCell>
              <TableCell>Celý názov cieľovej kategórie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : mappings.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Neboli nájdené žiadne mapovanie kategórií.
                </TableCell>
              </TableRow>
            ) : (
              mappings.map((mapping) => (
                <TableRow key={mapping.id}>
                  <TableCell>{mapping.id}</TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <TextField
                        variant="standard"
                        defaultValue={mapping.category_name}
                        placeholder={mapping.category_name}
                        onChange={(e) =>
                          handleNameChange(mapping.id, e.target.value)
                        }
                        value={editedNames[mapping.id] || mapping.category_name}
                        size="small"
                      />
                      {showSaveButtons[mapping.id] && (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => handleSave(mapping.id)}
                            color="primary"
                          >
                            <Save fontSize="small" />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => handleDiscard(mapping.id)}
                            color="error"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>{mapping.category_fullname}</TableCell>
                  <TableCell>
                    {mapping.pointer_category.category_name}
                  </TableCell>
                  <TableCell>
                    {mapping.pointer_category.category_fullname}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Počet na stránku:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
      />
    </div>
  );
};

export default CategoryMappings;
