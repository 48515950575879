import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  lazy,
  Suspense,
} from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Paper,
  Box,
  Autocomplete,
  Pagination,
  TextField,
  Button,
  IconButton,
  Skeleton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import api from "../../../services/api";
import debounce from "lodash/debounce";
import ProductRow from "./ProductRow";
import FilterListIcon from "@mui/icons-material/FilterList";

// Lazy load the ProductEditModal
const ProductEditModal = lazy(() => import("../ProductEditModal"));

const ProductsMainTable = ({ onEditProduct }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [manualPage, setManualPage] = useState("");
  const [tempManualPage, setTempManualPage] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [eanFilter, setEanFilter] = useState(false);

  const fetchParameters = useCallback(async (categoryId) => {
    if (!categoryId) return;
    try {
      const response = await api.get("/parameters", {
        params: { category_id: categoryId },
      });
      setParameters(
        response.data.map((param) => ({
          ...param,
          isVisible: param.isVisible ?? true,
        }))
      );
    } catch (error) {
      console.error("Error fetching parameters:", error);
      toast.error("Failed to load parameters. Please try again.");
    }
  }, []);

  const handleShowProducts = useCallback(
    async (pageNumber = 1, itemsPerPageOverride = null) => {
      if (!selectedCategory) {
        toast.error("Please select a category first.");
        return;
      }
      setLoading(true);
      try {
        await fetchParameters(selectedCategory.id);
        const response = await api.get("/products", {
          params: {
            category: selectedCategory.id,
            page: pageNumber,
            itemsPerPage: itemsPerPageOverride || itemsPerPage,
            ...(eanFilter ? { eanFilter: true } : {}),
          },
        });
        setProducts(response.data.products);
        setTotalPages(response.data.pagination.totalPages);
        setPage(pageNumber);
      } catch (error) {
        console.error("Error fetching products:", error);
        toast.error("Failed to load products. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [selectedCategory, fetchParameters, itemsPerPage, eanFilter]
  );

  const handleParameterVisibilityChange = useCallback(
    async (parameterId, isVisible) => {
      try {
        await api.post(`/parameters/${parameterId}/visibility`, { isVisible });
        setParameters((prev) =>
          prev.map((param) =>
            param.id === parameterId ? { ...param, isVisible } : param
          )
        );
        toast.success("Parameter visibility updated successfully");
      } catch (error) {
        console.error("Error updating parameter visibility:", error);
        toast.error("Failed to update parameter visibility. Please try again.");
      }
    },
    []
  );

  const handleUpdateParameter = useCallback(
    async (productId, parameterId, updatedValue) => {
      try {
        const response = await api.put(
          `/products/${productId}/parameters/${parameterId}`,
          { value: updatedValue }
        );
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === productId
              ? {
                  ...product,
                  parameters: product.parameters.map((param) =>
                    param.id === parameterId
                      ? { ...param, ...response.data }
                      : param
                  ),
                }
              : product
          )
        );
        toast.success("Parameter updated successfully");
      } catch (error) {
        console.error("Error updating parameter:", error);
        toast.error("Failed to update parameter. Please try again.");
      }
    },
    []
  );

  const debouncedSearch = useMemo(
    () =>
      debounce(async (query) => {
        if (query.length > 2) {
          try {
            const response = await api.get(`search-categories/?query=${query}`);
            setCategoryOptions(
              Array.isArray(response.data) ? response.data : []
            );
          } catch (error) {
            console.error("Error searching categories: ", error);
          }
        }
      }, 300),
    []
  );

  const handleInputChange = useCallback(
    (event, newInputValue) => {
      setInputValue(newInputValue);
      debouncedSearch(newInputValue);
    },
    [debouncedSearch]
  );

  const handleManualPageChange = useCallback((event) => {
    setTempManualPage(event.target.value);
  }, []);

  const handleGoToPage = () => {
    const pageNumber = parseInt(tempManualPage, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setManualPage(tempManualPage);
      handleShowProducts(pageNumber);
    } else {
      toast.error("Invalid page number");
    }
  };

  const sortedParameters = useMemo(() => {
    return [...parameters].sort((a, b) => {
      if (a.isVisible === b.isVisible) return 0;
      return a.isVisible ? -1 : 1;
    });
  }, [parameters]);

  const handleDeleteProduct = useCallback(async (productId) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await api.delete(`/products/${productId}`);
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== productId)
        );
        toast.success("Product deleted successfully");
      } catch (error) {
        console.error("Error deleting product:", error);
        toast.error("Failed to delete product. Please try again.");
      }
    }
  }, []);

  const handleUpdateProduct = useCallback(async (productId, updatedData) => {
    try {
      let response;
      if ("product_ean" in updatedData) {
        // If updating EAN, use the specific EAN endpoint
        response = await api.put(`/products/${productId}/ean/`, updatedData);
      } else {
        // For other updates, use the general update endpoint
        response = await api.put(`/products/${productId}`, updatedData);
      }

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId
            ? {
                ...product,
                ...response.data,
                product_ean: updatedData.product_ean || product.product_ean,
              }
            : product
        )
      );
      toast.success("Product updated successfully");
    } catch (error) {
      console.error("Error updating product:", error);
      toast.error("Failed to update product. Please try again.");
    }
  }, []);

  const handleCreateParameter = useCallback(async (productId, paramData) => {
    try {
      const response = await api.post(
        `/products/${productId}/parameters`,
        paramData
      );
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId
            ? { ...product, parameters: [...product.parameters, response.data] }
            : product
        )
      );
      toast.success("Parameter created successfully");
    } catch (error) {
      console.error("Error creating parameter:", error);
      toast.error("Failed to create parameter. Please try again.");
    }
  }, []);

  const handleDeleteParameter = useCallback(async (productId, parameterId) => {
    try {
      await api.delete(
        `/products/${productId}/parameters/${parameterId}/delete`
      );
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId
            ? {
                ...product,
                parameters: product.parameters.filter(
                  (param) => param.id !== parameterId
                ),
              }
            : product
        )
      );
      toast.success("Parameter deleted successfully");
    } catch (error) {
      console.error("Error deleting parameter:", error);
      toast.error("Failed to delete parameter. Please try again.");
    }
  }, []);

  const handleEditClick = useCallback(async (productId) => {
    try {
      const response = await api.get(`/products/${productId}/`);
      const productData = response.data;
      setEditingProduct({
        ...productData,
        content: productData.product_description,
        additionalPhotos: productData.additional_photos || [],
        iframes: productData.video_iframe || [],
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching product details:", error);
      toast.error("Failed to load product details. Please try again.");
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setEditingProduct(null);
  }, []);

  const renderSkeletonRows = () => {
    return Array(itemsPerPage)
      .fill()
      .map((_, index) => (
        <TableRow key={`skeleton-${index}`}>
          <TableCell>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Skeleton
                variant="rectangular"
                width={40}
                height={40}
                sx={{ marginRight: 1 }}
              />
              <Skeleton variant="text" width={200} />
            </Box>
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={100} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={100} />
          </TableCell>
          {parameters.map((param) => (
            <TableCell key={param.id}>
              <Skeleton variant="text" width={100} />
            </TableCell>
          ))}
        </TableRow>
      ));
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    handleShowProducts(1, newItemsPerPage); // Pass the new value directly
  };

  const toggleEanFilter = useCallback(() => {
    setEanFilter((prev) => !prev);
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      handleShowProducts(1);
    }
  }, [selectedCategory, eanFilter, handleShowProducts]);

  return (
    <div className="">
      <Box sx={{ marginBottom: 2 }}>
        <Autocomplete
          disablePortal
          id="category-selector"
          options={categoryOptions}
          getOptionLabel={(option) => option?.category_fullname || ""}
          renderOption={(props, option) => (
            <li {...props}>{option?.category_fullname || ""}</li>
          )}
          value={selectedCategory}
          onChange={(event, newValue) => {
            setSelectedCategory(newValue);
            if (newValue) {
              fetchParameters(newValue.id);
            } else {
              setParameters([]);
            }
          }}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          renderInput={(params) => (
            <TextField {...params} label="Kategória" variant="outlined" />
          )}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
        />
        <Button
          onClick={() => handleShowProducts()}
          disabled={loading || !selectedCategory}
          variant="contained"
          style={{ marginTop: 8 }}
        >
          Zobraziť produkty
        </Button>
      </Box>

      <Paper elevation={3} className="mb-8 p-4">
        <Box sx={{ width: "100%", overflowX: "auto" }}>
          <Table
            aria-label="Tabuľka produktov"
            stickyHeader
            sx={{ minWidth: 800 }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    background: "white",
                    zIndex: 1100,
                    minWidth: 300,
                    width: 300,
                  }}
                >
                  Produkt
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>Kategória</TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  EAN
                  <IconButton
                    onClick={toggleEanFilter}
                    size="small"
                    color={eanFilter ? "primary" : "default"}
                  >
                    <FilterListIcon />
                  </IconButton>
                </TableCell>
                {sortedParameters.map((param) => (
                  <TableCell key={param.id} sx={{ minWidth: 200 }}>
                    {param.name}
                    <IconButton
                      onClick={() =>
                        handleParameterVisibilityChange(
                          param.id,
                          !param.isVisible
                        )
                      }
                      size="small"
                    >
                      {param.isVisible ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? renderSkeletonRows()
                : products.map((product) => (
                    <ProductRow
                      key={product.id}
                      product={product}
                      parameters={sortedParameters}
                      onUpdateParameter={handleUpdateParameter}
                      onCreateParameter={handleCreateParameter}
                      onDeleteParameter={handleDeleteParameter}
                      onUpdateProduct={handleUpdateProduct}
                      onEditClick={handleEditClick}
                    />
                  ))}
            </TableBody>
          </Table>
        </Box>
      </Paper>

      {!loading && products.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 2,
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => handleShowProducts(value)}
            color="primary"
          />
          <FormControl variant="outlined" size="small">
            <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page-label"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              label="Rows per page"
            >
              {[5, 10, 25, 50, 100].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Prejsť na stranu"
            type="number"
            value={tempManualPage}
            onChange={handleManualPageChange}
            inputProps={{ min: 1, max: totalPages }}
            sx={{ width: 100 }}
            size="small"
          />
          <Button variant="contained" onClick={handleGoToPage} size="small">
            Ísť
          </Button>
        </Box>
      )}
      <Suspense fallback={<CircularProgress />}>
        {isModalOpen && editingProduct && (
          <ProductEditModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            product={editingProduct}
            onUpdate={handleUpdateProduct}
          />
        )}
      </Suspense>
    </div>
  );
};

export default React.memo(ProductsMainTable);
