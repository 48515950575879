function SentryPage() {
  // Define the method inline if it's for testing purposes
  function methodDoesNotExist() {
    throw new Error("This is a test error.");
  }

  return (
    <div>
      <button onClick={methodDoesNotExist}>Trigger Error</button>
    </div>
  );
}

export default SentryPage;
