import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import AccountInfo from "../components/AccountInfo";
import RegisteredClick from "../components/RegisteredClick";

export default function MyClicks() {
  return (
    <>
      <BasicNavbar />
      <div className="mx-auto text-center text-3xl font-bold">
        Moje prekliky
      </div>
      <div className="max-w-5xl mx-auto py-12">
        <RegisteredClick />
      </div>
      <Footer />
    </>
  );
}
