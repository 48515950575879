import React from "react";
import { useAdultContent } from "../../providers/AdultContentProvider";

export const withAdultContent = (WrappedComponent) => {
  return function WithAdultContentWrapper(props) {
    const { requestAgeConfirmation } = useAdultContent();

    if (!props.product?.adult_content) {
      return <WrappedComponent {...props} />;
    }

    const handleClick = (e) => {
      e.preventDefault();
      requestAgeConfirmation(() => {
        // Handle the original click event after age confirmation
        if (props.onClick) {
          props.onClick(e);
        }
      });
    };

    return (
      <div className="relative" onClick={handleClick}>
        <div className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded-md text-sm z-10">
          18+
        </div>
        <WrappedComponent {...props} />
      </div>
    );
  };
};
