import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Skeleton,
  Button,
  Collapse,
  Drawer, // Import Drawer for side menu
} from "@mui/material";
import { MdExpandMore, MdFilterList } from "react-icons/md"; // Add filter icon
import { HiMagnifyingGlass } from "react-icons/hi2";
import PriceGraph from "./PriceGraph";
import api from "../services/api";
import useDebounce from "../hooks/useDebounce";
import { Input } from "@nextui-org/react";

const FilterComponent = ({
  filter,
  setFilter,
  setPriceFilter,
  id,
  step,
  fetchCall,
  t,
}) => {
  const [parameters, setParameters] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [minPrice, setMinPrice] = useState(0); // State for min price
  const [maxPrice, setMaxPrice] = useState(1000); // State for max price
  const [selectedFilters, setSelectedFilters] = useState({});
  const [searchQueries, setSearchQueries] = useState({});
  const [nameQuery, setNameQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [visibleFilters, setVisibleFilters] = useState(5);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false); // State for filter side menu

  const debouncedNameQuery = useDebounce(nameQuery, 500);

  useEffect(() => {
    setFilter((prevFilters) => ({
      ...prevFilters,
      name: debouncedNameQuery || undefined,
    }));
  }, [debouncedNameQuery, setFilter]);

  const fetchParameters = async () => {
    try {
      setLoading(true);
      const response = await api.get(fetchCall + "-params/", {
        params: { id: id },
      });
      setParameters([]);
      // setParameters(response.data.parameters);
      setManufacturers(response.data.manufacturers);
      setPriceRange(response.data.price_ranges || []);
      setMinPrice(response.data.price_min || 0);
      setMaxPrice(response.data.price_max || 1000);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParameters();
  }, [id]);

  const handleCheckboxChange = (filterName, value) => {
    setSelectedFilters((prevSelectedFilters) => {
      const newSelectedFilters = { ...prevSelectedFilters };
      if (!newSelectedFilters[filterName]) {
        newSelectedFilters[filterName] = [];
      }
      if (newSelectedFilters[filterName].includes(value)) {
        newSelectedFilters[filterName] = newSelectedFilters[filterName].filter(
          (item) => item !== value
        );
      } else {
        newSelectedFilters[filterName].push(value);
      }
      if (newSelectedFilters[filterName].length === 0) {
        delete newSelectedFilters[filterName];
      }
      setFilter(newSelectedFilters);
      return newSelectedFilters;
    });
  };

  const handleSearchChange = (filterName, value) => {
    setSearchQueries((prevSearchQueries) => ({
      ...prevSearchQueries,
      [filterName]: value.toLowerCase(),
    }));
  };

  const handleShowMore = () => {
    setVisibleFilters((prev) => prev + 5);
  };

  const handleShowLess = () => {
    setVisibleFilters(5);
  };

  return (
    <>
      {/* Filter Icon Button for Mobile */}
      <div className="md:hidden flex justify-end p-4">
        <button
          onClick={() => setIsFilterMenuOpen(true)}
          className="text-primary flex items-center gap-2"
        >
          <MdFilterList className="text-2xl" />
          {t("Filtrovať")}
        </button>
      </div>

      {/* Side Menu (Drawer) for Filters */}
      <Drawer
        anchor="left"
        open={isFilterMenuOpen}
        onClose={() => setIsFilterMenuOpen(false)}
      >
        <div className="w-[350px] p-4 flex flex-col gap-8">
          <div className="font-bold text-2xl flex justify-between items-center">
            {t("Filtrovanie")}
            <Button variant="text" onClick={() => setIsFilterMenuOpen(false)}>
              {t("Zavrieť")}
            </Button>
          </div>

          <Input
            radius="lg"
            label={t("Hľadať podľa názvu")}
            placeholder=""
            variant="bordered"
            fullWidth
            value={nameQuery}
            onChange={(e) => setNameQuery(e.target.value)}
            contentLeft={<HiMagnifyingGlass />}
            css={{ marginBottom: "8px" }}
          />

          {/* Manufacturers Filter */}
          {manufacturers?.length > 0 && (
            <>
              <Accordion
                disableGutters
                elevation={0}
                square
                sx={{
                  "&:before": {
                    display: "none",
                  },
                  border: "none",
                  backgroundColor: "transparent",
                }}
              >
                <AccordionSummary
                  expandIcon={<MdExpandMore />}
                  sx={{
                    padding: 0,
                    minHeight: "unset",
                    "&.Mui-expanded": { minHeight: "unset" },
                  }}
                >
                  <span className="font-semibold">{t("Výrobca")}</span>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <Input
                    variant="bordered"
                    placeholder={t("Výrobca")}
                    fullWidth
                    onChange={(e) =>
                      handleSearchChange("manufacturer", e.target.value)
                    }
                    contentLeft={<HiMagnifyingGlass />}
                    css={{ marginBottom: "8px" }}
                    startContent={<HiMagnifyingGlass />}
                    className="pb-4"
                  />
                  <div className="flex flex-col max-h-[250px] overflow-y-scroll">
                    {loading
                      ? Array.from({ length: 5 }).map((_, index) => (
                          <Skeleton key={index} width="100%" height="40px" />
                        ))
                      : manufacturers
                          .filter(
                            (manufacturer) =>
                              manufacturer.name &&
                              manufacturer.name
                                .toLowerCase()
                                .includes(searchQueries["manufacturer"] || "")
                          )
                          .map((manufacturer) => (
                            <FormControlLabel
                              key={manufacturer.name}
                              control={
                                <Checkbox
                                  sx={{
                                    padding: "0 8px 0 0",
                                    marginTop: "-2px",
                                  }}
                                  checked={selectedFilters[
                                    "manufacturer"
                                  ]?.includes(manufacturer.name)}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      "manufacturer",
                                      manufacturer.name
                                    )
                                  }
                                />
                              }
                              label={`${manufacturer.name} (${manufacturer.count})`}
                              sx={{
                                alignItems: "flex-start",
                                margin: 0,
                                marginBottom: "2px",
                              }}
                            />
                          ))}
                  </div>
                </AccordionDetails>
              </Accordion>
              <hr />
            </>
          )}

          {/* Price Graph */}
          {loading ? (
            <Skeleton width="100%" height="200px" />
          ) : (
            <PriceGraph
              label="Cena od - do"
              data={priceRange}
              min={minPrice}
              max={maxPrice}
              step={step}
              setPriceFilter={setPriceFilter}
            />
          )}

          <hr />

          {/* Parameter Filters */}
          {parameters.slice(0, visibleFilters).map((filter, index) => (
            <Collapse key={index} in={true} timeout={300}>
              <Accordion
                disableGutters
                elevation={0}
                square
                sx={{
                  "&:before": {
                    display: "none",
                  },
                  border: "none",
                  backgroundColor: "transparent",
                }}
              >
                <AccordionSummary
                  expandIcon={<MdExpandMore />}
                  sx={{
                    padding: 0,
                    minHeight: "unset",
                    "&.Mui-expanded": { minHeight: "unset" },
                  }}
                >
                  <span className="font-semibold">{filter.name}</span>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <Input
                    variant="bordered"
                    placeholder={`${filter.name}`}
                    fullWidth
                    onChange={(e) =>
                      handleSearchChange(filter.name, e.target.value)
                    }
                    contentLeft={<HiMagnifyingGlass />}
                    css={{ marginBottom: "8px" }}
                    startContent={<HiMagnifyingGlass />}
                    className="pb-4"
                  />
                  <div className="flex flex-col max-h-[250px] overflow-y-scroll">
                    {filter.values
                      .filter(
                        (value) =>
                          value.value &&
                          value.value
                            .toLowerCase()
                            .includes(searchQueries[filter.name] || "")
                      )
                      .map((value, idx) => (
                        <FormControlLabel
                          key={idx}
                          control={
                            <Checkbox
                              sx={{
                                padding: "0 8px 0 0",
                                marginTop: "-2px",
                              }}
                              checked={selectedFilters[filter.name]?.includes(
                                value.value
                              )}
                              onChange={() =>
                                handleCheckboxChange(filter.name, value.value)
                              }
                            />
                          }
                          label={`${value.value} (${value.count})`}
                          sx={{
                            alignItems: "flex-start",
                            margin: 0,
                            marginBottom: "2px",
                          }}
                        />
                      ))}
                  </div>
                </AccordionDetails>
              </Accordion>
              <hr />
            </Collapse>
          ))}

          {parameters.length > visibleFilters && (
            <Button variant="text" onClick={handleShowMore}>
              Zobraziť viac
            </Button>
          )}

          {visibleFilters > 5 && (
            <Button variant="text" onClick={handleShowLess}>
              Skryť
            </Button>
          )}
        </div>
      </Drawer>

      {/* Filters always visible on larger screens */}
      <div className="hidden md:flex flex-col gap-8 max-w-[300px] min-w-[300px] pl-4">
        <div className="font-bold text-2xl">
          {t("Filtrovanie")} <hr />
        </div>

        <Input
          radius="lg"
          label={t("Hľadať podľa názvu")}
          placeholder=""
          variant="bordered"
          fullWidth
          value={nameQuery}
          onChange={(e) => setNameQuery(e.target.value)}
          contentLeft={<HiMagnifyingGlass />}
          css={{ marginBottom: "8px" }}
        />

        {/* Manufacturers Filter */}
        {manufacturers?.length > 0 && (
          <>
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={{
                "&:before": {
                  display: "none",
                },
                border: "none",
                backgroundColor: "transparent",
              }}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                sx={{
                  padding: 0,
                  minHeight: "unset",
                  "&.Mui-expanded": { minHeight: "unset" },
                }}
              >
                <span className="font-semibold">{t("Výrobca")}</span>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Input
                  variant="bordered"
                  placeholder={t("Výrobca")}
                  fullWidth
                  onChange={(e) =>
                    handleSearchChange("manufacturer", e.target.value)
                  }
                  contentLeft={<HiMagnifyingGlass />}
                  css={{ marginBottom: "8px" }}
                  startContent={<HiMagnifyingGlass />}
                  className="pb-4"
                />
                <div className="flex flex-col max-h-[250px] overflow-y-scroll">
                  {loading
                    ? Array.from({ length: 5 }).map((_, index) => (
                        <Skeleton key={index} width="100%" height="40px" />
                      ))
                    : manufacturers
                        .filter(
                          (manufacturer) =>
                            manufacturer.name &&
                            manufacturer.name
                              .toLowerCase()
                              .includes(searchQueries["manufacturer"] || "")
                        )
                        .map((manufacturer) => (
                          <FormControlLabel
                            key={manufacturer.name}
                            control={
                              <Checkbox
                                sx={{
                                  padding: "0 8px 0 0",
                                  marginTop: "-2px",
                                }}
                                checked={selectedFilters[
                                  "manufacturer"
                                ]?.includes(manufacturer.name)}
                                onChange={() =>
                                  handleCheckboxChange(
                                    "manufacturer",
                                    manufacturer.name
                                  )
                                }
                              />
                            }
                            label={`${manufacturer.name} (${manufacturer.count})`}
                            sx={{
                              alignItems: "flex-start",
                              margin: 0,
                              marginBottom: "2px",
                            }}
                          />
                        ))}
                </div>
              </AccordionDetails>
            </Accordion>
            <hr />
          </>
        )}

        {/* Price Graph */}
        {loading ? (
          <Skeleton width="100%" height="200px" />
        ) : (
          <PriceGraph
            label="Cena od - do"
            data={priceRange}
            min={minPrice}
            max={maxPrice}
            step={step}
            setPriceFilter={setPriceFilter}
          />
        )}

        <hr />

        {/* Parameter Filters */}
        {parameters.slice(0, visibleFilters).map((filter, index) => (
          <Collapse key={index} in={true} timeout={300}>
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={{
                "&:before": {
                  display: "none",
                },
                border: "none",
                backgroundColor: "transparent",
              }}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                sx={{
                  padding: 0,
                  minHeight: "unset",
                  "&.Mui-expanded": { minHeight: "unset" },
                }}
              >
                <span className="font-semibold">{filter.name}</span>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Input
                  variant="bordered"
                  placeholder={`${filter.name}`}
                  fullWidth
                  onChange={(e) =>
                    handleSearchChange(filter.name, e.target.value)
                  }
                  contentLeft={<HiMagnifyingGlass />}
                  css={{ marginBottom: "8px" }}
                  startContent={<HiMagnifyingGlass />}
                  className="pb-4"
                />
                <div className="flex flex-col max-h-[250px] overflow-y-scroll">
                  {filter.values
                    .filter(
                      (value) =>
                        value.value &&
                        value.value
                          .toLowerCase()
                          .includes(searchQueries[filter.name] || "")
                    )
                    .map((value, idx) => (
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            sx={{
                              padding: "0 8px 0 0",
                              marginTop: "-2px",
                            }}
                            checked={selectedFilters[filter.name]?.includes(
                              value.value
                            )}
                            onChange={() =>
                              handleCheckboxChange(filter.name, value.value)
                            }
                          />
                        }
                        label={`${value.value} (${value.count})`}
                        sx={{
                          alignItems: "flex-start",
                          margin: 0,
                          marginBottom: "2px",
                        }}
                      />
                    ))}
                </div>
              </AccordionDetails>
            </Accordion>
            <hr />
          </Collapse>
        ))}

        {parameters.length > visibleFilters && (
          <Button variant="text" onClick={handleShowMore}>
            {t("Zobraziť viac")}
          </Button>
        )}

        {visibleFilters > 5 && (
          <Button variant="text" onClick={handleShowLess}>
            {t("Skryť")}
          </Button>
        )}
      </div>
    </>
  );
};

export default FilterComponent;
