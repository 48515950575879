import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Box,
  Autocomplete,
  Pagination,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import api from "../../services/api";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";

export default function WalletTransactionsAdmin() {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    date: "",
    status: "",
    amount: "",
  });

  // Form state
  const [formData, setFormData] = useState({
    amount: "",
    description: "",
    user: null,
  });

  const fetchTransactions = async (pageNum = 1) => {
    setIsLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page: pageNum.toString(),
      });

      if (filters.date) {
        queryParams.append("date", format(filters.date, "yyyy-MM-dd"));
      }
      if (filters.status) queryParams.append("status", filters.status);
      if (filters.amount) queryParams.append("amount", filters.amount);

      const response = await api.get(
        `/get-all-wallet-transactions/?${queryParams.toString()}`
      );

      setTransactions(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUsers = async (search = "") => {
    try {
      const response = await api.get("fetch-users/", { params: { search } });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchTransactions(page);
  }, [page, filters]);

  const handleUserSearch = (event, newInputValue) => {
    setSearchTerm(newInputValue);
    fetchUsers(newInputValue);
  };

  const handleOpenDialog = (transaction = null) => {
    if (transaction) {
      setFormData({
        amount: transaction.amount,
        description: transaction.description,
        user: users.find((u) => u.id === transaction.user) || null,
      });
      setSelectedTransaction(transaction);
    } else {
      setFormData({
        amount: "",
        description: "",
        user: null,
      });
      setSelectedTransaction(null);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTransaction(null);
    setFormData({
      amount: "",
      description: "",
      user: null,
    });
  };

  const handleSubmit = async () => {
    try {
      const submitData = {
        ...formData,
        user: formData.user?.id,
      };

      if (selectedTransaction) {
        await api.put(
          `/update-wallet-transaction/${selectedTransaction.id}/`,
          submitData
        );
      } else {
        await api.post("/create-wallet-transaction/", submitData);
      }
      fetchTransactions(page);
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving transaction:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm(t("Skutočne chcete vymazať túto transakciu?"))) {
      try {
        await api.delete(`/delete-wallet-transaction/${id}/`);
        fetchTransactions(page);
      } catch (error) {
        console.error("Error deleting transaction:", error);
      }
    }
  };

  const formatAmount = (amount, currency) => {
    return `${parseFloat(amount || 0).toFixed(2)} ${currency}`;
  };

  const filteredTransactions = transactions.filter((transaction) => {
    const matchesSearch =
      (transaction.description
        ? transaction.description
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        : false) ||
      (transaction.user
        ? transaction.user.toString().includes(searchTerm)
        : false);

    const matchesDateFrom =
      !filters.dateFrom ||
      (transaction.date &&
        new Date(transaction.date) >= new Date(filters.dateFrom));

    const matchesDateTo =
      !filters.dateTo ||
      (transaction.date &&
        new Date(transaction.date) <= new Date(filters.dateTo));

    const matchesAmountMin =
      !filters.amountMin ||
      (transaction.amount &&
        parseFloat(transaction.amount) >= parseFloat(filters.amountMin));

    const matchesAmountMax =
      !filters.amountMax ||
      (transaction.amount &&
        parseFloat(transaction.amount) <= parseFloat(filters.amountMax));

    return (
      matchesSearch &&
      matchesDateFrom &&
      matchesDateTo &&
      matchesAmountMin &&
      matchesAmountMax
    );
  });

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("sk-SK", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPage(1); // Reset to first page when filters change
  };

  return (
    <div className="p-4">
      <Box className="flex justify-between items-center mb-4">
        <Typography variant="h5">{t("Správa transakcií peňaženky")}</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          {t("Nová transakcia")}
        </Button>
      </Box>

      {/* Filters */}
      <Box className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={t("Dátum")}
            value={filters.date}
            onChange={(newDate) => {
              setFilters((prev) => ({
                ...prev,
                date: newDate,
              }));
              setPage(1);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <TextField
          label={t("Suma")}
          type="number"
          name="amount"
          value={filters.amount}
          onChange={handleFilterChange}
          inputProps={{ step: "0.01" }}
        />
        <TextField
          label={t("Status")}
          name="status"
          value={filters.status}
          onChange={handleFilterChange}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Dátum")}</TableCell>
              <TableCell>{t("Používateľ")}</TableCell>
              <TableCell>{t("Suma")}</TableCell>
              <TableCell>{t("Popis")}</TableCell>
              <TableCell align="right">{t("Akcie")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {t("Načítavam...")}
                </TableCell>
              </TableRow>
            ) : transactions.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {t("Žiadne transakcie")}
                </TableCell>
              </TableRow>
            ) : (
              transactions.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>{formatDate(transaction.date)}</TableCell>
                  <TableCell>{transaction.user}</TableCell>
                  <TableCell
                    className={
                      parseFloat(transaction.amount || 0) >= 0
                        ? "text-green-600"
                        : "text-red-600"
                    }
                  >
                    {formatAmount(transaction.amount, transaction.currency)}
                  </TableCell>
                  <TableCell>{transaction.description || "-"}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => handleOpenDialog(transaction)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(transaction.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box className="flex justify-center mt-4">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>

      {/* Create/Edit Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedTransaction
            ? t("Upraviť transakciu")
            : t("Vytvoriť novú transakciu")}
        </DialogTitle>
        <DialogContent>
          <Box className="space-y-4 pt-4">
            <Autocomplete
              options={users}
              getOptionLabel={(option) => `${option.email} (${option.id})`}
              value={formData.user}
              onChange={(event, newValue) => {
                setFormData({ ...formData, user: newValue });
              }}
              onInputChange={handleUserSearch}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Používateľ")}
                  fullWidth
                  margin="normal"
                />
              )}
            />
            <TextField
              label={t("Suma")}
              type="number"
              fullWidth
              value={formData.amount}
              onChange={(e) =>
                setFormData({ ...formData, amount: e.target.value })
              }
              inputProps={{ step: "0.01" }}
            />
            <TextField
              label={t("Popis")}
              fullWidth
              value={formData.description || ""}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              multiline
              rows={3}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t("Zrušiť")}</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {selectedTransaction ? t("Uložiť") : t("Vytvoriť")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
