import React, { useState, useEffect, useCallback } from "react";
import { TextField, Autocomplete, Grid } from "@mui/material";
import api from "../services/api";
import { CircularProgress } from "@mui/material";

function DualAutocomplete({
  initialName = "",
  initialValue = "",
  onNameChange,
  onValueChange,
}) {
  // State management
  const [nameOptions, setNameOptions] = useState([]);
  const [nameLoading, setNameLoading] = useState(false);
  const [nameInputValue, setNameInputValue] = useState(initialName);
  const [valueInputValue, setValueInputValue] = useState(initialValue);
  const [selectedName, setSelectedName] = useState(null);
  const [valueOptions, setValueOptions] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  // Fetch parameter names and their values from the API
  const fetchNameOptions = useCallback(async (query) => {
    setNameLoading(true);
    try {
      const response = await api.get("/get-param-names-with-values", {
        params: { query },
      });
      setNameOptions(response.data);
    } catch (error) {
      console.error("Error fetching name options:", error);
    } finally {
      setNameLoading(false);
    }
  }, []);

  // Handle name input change
  const handleNameInputChange = (event, newInputValue) => {
    setNameInputValue(newInputValue);
    if (onNameChange) {
      onNameChange(newInputValue);
    }
  };

  // Handle value input change
  const handleValueInputChange = (event, newInputValue) => {
    setValueInputValue(newInputValue);
    if (onValueChange) {
      onValueChange(newInputValue);
    }
  };

  // Effect to fetch name options with debouncing
  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    if (nameInputValue) {
      const timeout = setTimeout(() => {
        fetchNameOptions(nameInputValue);
      }, 300); // Adjust the debounce delay as needed
      setDebounceTimeout(timeout);
    } else {
      setNameOptions([]);
      setValueOptions([]);
    }
  }, [nameInputValue, fetchNameOptions]);

  // Effect to update value options when a name is selected
  useEffect(() => {
    if (selectedName) {
      const matchingName = nameOptions.find(
        (option) => option.name === selectedName.name
      );
      if (matchingName) {
        setValueOptions(matchingName.values || []);
      } else {
        setValueOptions([]);
      }
    } else {
      setValueOptions([]);
    }
  }, [selectedName, nameOptions]);

  // Effect to initialize states with provided initial values
  useEffect(() => {
    setNameInputValue(initialName);
    setValueInputValue(initialValue);
    setSelectedName(initialName ? { name: initialName } : null);
  }, [initialName, initialValue]);

  return (
    <Grid container spacing={2}>
      {/* Autocomplete for Parameter Name */}
      <Grid item xs={6}>
        <Autocomplete
          freeSolo
          disablePortal
          value={selectedName}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              setSelectedName({ name: newValue });
              if (onNameChange) onNameChange(newValue);
            } else if (newValue && newValue.inputValue) {
              setSelectedName({ name: newValue.inputValue });
              if (onNameChange) onNameChange(newValue.inputValue);
            } else {
              setSelectedName(newValue);
              if (onNameChange) onNameChange(newValue ? newValue.name : "");
            }
            setNameInputValue(newValue ? newValue.name : "");
          }}
          inputValue={nameInputValue}
          onInputChange={handleNameInputChange}
          options={nameOptions}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Názov parametra"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {nameLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>

      {/* Autocomplete for Parameter Value */}
      <Grid item xs={6}>
        <Autocomplete
          disablePortal
          freeSolo
          value={valueInputValue}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              setValueInputValue(newValue);
              if (onValueChange) onValueChange(newValue);
            } else if (newValue && newValue.inputValue) {
              setValueInputValue(newValue.inputValue);
              if (onValueChange) onValueChange(newValue.inputValue);
            } else {
              setValueInputValue(newValue || "");
              if (onValueChange) onValueChange(newValue || "");
            }
          }}
          inputValue={valueInputValue}
          onInputChange={handleValueInputChange}
          options={valueOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Hodnota parametra"
              variant="outlined"
            />
          )}
          disabled={!selectedName}
        />
      </Grid>
    </Grid>
  );
}

export default DualAutocomplete;
