// DownloadButton.js

import React, { useState } from "react";
import { Spinner } from "@nextui-org/react";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { Button } from "@nextui-org/react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/dropdown";

import api from "../services/api";

const DownloadButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  const triggerCsvProcessing = async () => {
    try {
      setIsLoading(true);
      // Call the API to trigger CSV generation and receive the blob response
      const response = await api({
        url: "generate-shop-csv/", // Adjust if your endpoint is different
        method: "GET",
        responseType: "blob", // Important: this tells axios to download the binary data as a blob
      });

      // Create a download link for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "shops.csv"); // Set the file name for the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up to release object URL

      setIsLoading(false);
    } catch (error) {
      console.error("Error generating CSV:", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center mr-4">
      {/* Download Button */}
      {isLoading ? (
        <Spinner />
      ) : (
        <Dropdown>
          <DropdownTrigger>
            <Button variant="bordered">
              <PiDownloadSimpleBold />
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions">
            <DropdownItem key="download">
              <button className="text-sm" onClick={triggerCsvProcessing}>
                Stiahnuť zoznam ako CSV
              </button>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  );
};

export default DownloadButton;
