import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "@nextui-org/react";
import parse from "html-react-parser";

const authKey = "ccffde72-8508-4909-8854-5606711f1163"; // Replace with your actual DeepL API key

const Translate = ({ text }) => {
  const { i18n } = useTranslation();
  const [translatedText, setTranslatedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // const localStorageLocale = localStorage.getItem("locale");
  // const targetLanguage = i18n.language.toUpperCase(); // Get the current language from i18n

  // const translateText = async (textToTranslate, language) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await fetch("https://api.deepl.com/v2/translate", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //       body: new URLSearchParams({
  //         auth_key: authKey,
  //         text: textToTranslate,
  //         target_lang: language,
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to translate text.");
  //     }

  //     const data = await response.json();
  //     setTranslatedText(data.translations[0].text);
  //   } catch (err) {
  //     setError("Failed to translate text.");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (text && targetLanguage !== localStorageLocale) {
  //     translateText(text, targetLanguage);
  //   } else {
  //     setTranslatedText(text); // Use original text if no translation is needed
  //   }
  // }, [text, targetLanguage, localStorageLocale]);

  // return isLoading ? text : error ? text : translatedText;
  return text;
};

export default Translate;
