import { useState } from "react";
import { Button, Spinner } from "@nextui-org/react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import validator from "validator";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

export default function ResetPasswordForm({ token, onSuccess }) {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 20,
      height: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
      height: "auto",
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      height: 0,
      transition: {
        duration: 0.3,
        ease: "easeIn",
      },
    },
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const errors = [];

    if (!validator.isLength(password, { min: minLength })) {
      errors.push(
        `${t("Heslo musí obsahovať aspoň")} ${minLength} ${t("znakov")}.`
      );
    }

    return errors.length === 0 ? { valid: true } : { valid: false, errors };
  };

  const passwordValidationResult = validatePassword(password);
  const passwordIsInvalid = password && !passwordValidationResult.valid;
  const passwordsDontMatch = confirmPassword && password !== confirmPassword;

  const handleResetPassword = async () => {
    if (passwordIsInvalid) {
      setError(passwordValidationResult.errors.join(", "));
      return;
    }

    if (passwordsDontMatch) {
      setError(t("Heslá sa nezhodujú"));
      return;
    }

    setIsLoading(true);
    try {
      const response = await api.post("reset-password/", {
        token: token,
        password: password,
      });

      if (response.status === 200) {
        setSuccess(true);
        if (onSuccess) {
          onSuccess();
        }
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setError(t("Nastala chyba pri obnovení hesla"));
      }
    } catch (error) {
      console.error("Reset password error:", error);
      setError(t("Nastala chyba pri obnovení hesla"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {t("Obnovenie hesla")}
        </h2>
        <form className="space-y-6 mt-6" action="#" method="POST">
          <div>
            <TextField
              className="w-full"
              required
              type="password"
              placeholder={t("Nové heslo")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="standard"
              error={passwordIsInvalid}
              helperText={
                passwordIsInvalid
                  ? passwordValidationResult.errors.join(", ")
                  : ""
              }
            />
          </div>
          <div>
            <TextField
              className="w-full"
              required
              type="password"
              placeholder={t("Potvrďte nové heslo")}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              variant="standard"
              error={passwordsDontMatch}
              helperText={passwordsDontMatch ? t("Heslá sa nezhodujú") : ""}
            />
          </div>

          <AnimatePresence mode="wait">
            {error && (
              <motion.div
                key="error"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={containerVariants}
                className="overflow-hidden"
              >
                <p className="text-red-500 border-1 border-red-500 rounded-lg p-4 text-sm">
                  {error}
                </p>
              </motion.div>
            )}
            {success && (
              <motion.div
                key="success"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={containerVariants}
                className="overflow-hidden"
              >
                <p className="text-green-500 border-1 border-green-500 rounded-lg p-4 text-sm">
                  <div className="mb-2">{t("Heslo bolo úspešne zmenené")}</div>
                  <div className="text-sm text-gray-600">
                    {t("Budete presmerovaný na prihlasovaciu stránku")}...
                  </div>
                </p>
              </motion.div>
            )}
          </AnimatePresence>

          <div>
            {isLoading ? (
              <Button
                color="primary"
                className="bg-white text-blue flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm"
                disabled
              >
                <Spinner />
              </Button>
            ) : (
              <Button
                color="primary"
                className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
                onClick={handleResetPassword}
              >
                {t("Obnoviť heslo")}
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
