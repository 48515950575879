import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "@nextui-org/react";
import api from "../../services/api";

const UploadCSV = ({ postRoute }) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }

    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await api.post(postRoute, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status !== 200) {
        throw new Error("Upload failed");
      }

      const responseData = response.data;
      toast.success(`Upload successful!`);
    } catch (error) {
      toast.error(`Upload failed: ${error.message}`);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="flex flex-row gap-4 items-center">
      <div className="text-sm font-semibold">Nahrať CSV:</div>
      <form
        onSubmit={handleSubmit}
        className="flex gap-3 flex-row items-center"
      >
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className=""
        />
        <button
          type="submit"
          className="border-1 px-4 py-1 rounded-3xl bg-black text-white font-semibold flex items-center justify-center"
          disabled={isUploading}
        >
          {isUploading ? <Spinner color="white" size="sm" /> : "Spracovať"}
        </button>
      </form>
    </div>
  );
};

export default UploadCSV;
