import { User } from "@nextui-org/react";
import { MdOutlineSpeed } from "react-icons/md";
import { RiArrowRightSLine } from "react-icons/ri";
import { GoPencil } from "react-icons/go";
import { FaCalendarAlt } from "react-icons/fa";
import { PiLaptopBold } from "react-icons/pi";
import { GrNotes } from "react-icons/gr";
import { PiTreeStructure } from "react-icons/pi";
import { RiNewspaperLine } from "react-icons/ri";
import { AiOutlineStock } from "react-icons/ai";
import { LuDownload } from "react-icons/lu";
import { useTranslation } from "react-i18next";

const MyZoneMenu = ({ setActiveItem, activeItemId, onMenuToggle }) => {
  const { t } = useTranslation();
  const img_url = localStorage.getItem("user_image");
  const username = localStorage.getItem("username");

  const menuItems = [
    { id: 0, icon: MdOutlineSpeed, label: t("DASHBOARD") },
    { id: 1, icon: GoPencil, label: t("UPRAVIŤ PROFIL") },
    { id: 2, icon: FaCalendarAlt, label: t("POZVÁNKY") },
    { id: 3, icon: PiLaptopBold, label: t("NÁKUPY") },
    { id: 4, icon: GrNotes, label: t("RECENZIE") },
    { id: 5, icon: PiTreeStructure, label: t("ŠTRUKTÚRA") },
    { id: 6, icon: RiNewspaperLine, label: t("PROVÍZIE") },
    { id: 7, icon: AiOutlineStock, label: t("POZVAŤ PRIATEĽA") },
    { id: 8, icon: LuDownload, label: t("MATERIÁLY") },
  ];

  const handleItemClick = (id) => {
    setActiveItem(id); // Set the active item
    onMenuToggle(); // Close the drawer
  };

  return (
    <div className="flex flex-col lg:shadow-md mb-12">
      <div className="bg-[#438AFE] text-white flex flex-row gap-4 h-[100px] items-center px-8">
        <div className="xl:block hidden">
          <User
            avatarProps={{
              isBordered: true,
              src: img_url,
            }}
          />
        </div>
        <div className="uppercase font-bold xl:text-xl text-sm">{username}</div>
      </div>
      <div className="uppercase">
        <ul className="flex flex-col mx-auto pb-12">
          {menuItems.map((item) => (
            <li
              key={item.id}
              className={`flex flex-row items-center justify-between py-6 px-12 hover:cursor-pointer ${
                activeItemId === item.id ? "bg-slate-50" : ""
              } hover:bg-slate-50`}
              onClick={() => handleItemClick(item.id)}
            >
              <div className="flex flex-row gap-6 items-center">
                <item.icon className="text-primary" />
                {item.label}
              </div>
              <RiArrowRightSLine />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MyZoneMenu;
