import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import api from "../../services/api";

export default function WalletStatements({ EmptyState }) {
  const { t } = useTranslation();
  const [statements, setStatements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchStatements = async () => {
      setIsLoading(true);
      try {
        const response = await api.get("/get-user-statements/");
        const statementsData = Array.isArray(response.data)
          ? response.data
          : [];
        setStatements(statementsData);
      } catch (error) {
        console.error("Error fetching statements:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStatements();
  }, []);

  const handleDownload = (pdfLink) => {
    if (!pdfLink) return;
    window.open(pdfLink, "_blank");
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("sk-SK", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formatAmount = (amount) => {
    return `${parseFloat(amount).toFixed(2)} EUR`;
  };

  if (isLoading) {
    return <EmptyState message={t("Načítavam...")} />;
  }

  if (!statements || statements.length === 0) {
    return <EmptyState message={t("Žiadne uzávierky")} />;
  }

  return (
    <div>
      <Typography variant="h5" component="h2" className="mb-4">
        {t("Uzávierky - kariéra")}
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                {t("Obdobie")}
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                {t("Typ uzávierky")}
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">
                {t("Suma")}
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{t("Stav")}</TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                {t("Akcie")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statements.map((statement) => (
              <TableRow key={statement.id}>
                <TableCell>{formatDate(statement.date)}</TableCell>
                <TableCell>{statement.type}</TableCell>
                <TableCell align="right">
                  {formatAmount(statement.amount)}
                </TableCell>
                <TableCell>
                  <span
                    className={
                      statement.status === "completed"
                        ? "text-green-600"
                        : "text-orange-500"
                    }
                  >
                    {statement.status}
                  </span>
                </TableCell>
                <TableCell align="center">
                  {statement.pdf_link ? (
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={() => handleDownload(statement.pdf_link)}
                    >
                      {t("STIAHNUŤ")}
                    </Button>
                  ) : (
                    "-"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
