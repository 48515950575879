import StatItem from "./StatItem";
import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import api from "../../services/api";
import { Skeleton } from "@nextui-org/react";

export default function Carrer({ onMenuToggle, t }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await api.get("get-user-stats/");
      setData(response.data);
    } catch (error) {
      console.error("fetchData -> error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="pb-12">
      <div className="lg:block hidden uppercase font-bold pt-12 text-3xl">
        {t("Kariéra")}
      </div>
      <div
        className="lg:hidden uppercase font-bold py-12 text-2xl flex items-center gap-2 cursor-pointer"
        onClick={onMenuToggle}
      >
        <IoIosArrowBack className="h-6 w-6 cursor-pointer text-gray-700" />
        Kariéra
      </div>
      <div>
        <div className="grid gap-12 xl:grid-cols-2">
          {loading
            ? // Skeleton loader
              Array(6)
                .fill()
                .map((_, index) => (
                  <div key={index} className="space-y-3">
                    <Skeleton className="w-3/4 h-6 rounded-lg" />
                    <Skeleton className="w-1/2 h-12 rounded-lg" />
                    <Skeleton className="w-5/6 h-4 rounded-lg" />
                  </div>
                ))
            : // Actual data
              data.map((item, index) => (
                <StatItem
                  key={index}
                  title={item.title}
                  subtitle={item.subtitle}
                  value={item.value}
                  stat={item.stat}
                  timespan={item.timespan}
                  color={item.color}
                  oc={item.oc}
                  dc={item.dc}
                  mlc={item.mlc}
                />
              ))}
        </div>
      </div>
    </div>
  );
}
