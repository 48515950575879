import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import api from "../../services/api";

export default function WalletBalance({ EmptyState }) {
  const { t } = useTranslation();
  const [walletData, setWalletData] = useState({
    balance: "0.00",
    user: {
      billing_info: {
        iban: "",
        currency: "EUR",
      },
    },
  });

  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [isWithdrawalModalOpen, setWithdrawalModalOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        const response = await api.get("/get-user-wallet");
        setWalletData(response.data);
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      }
    };

    fetchWalletData();
  }, []);

  const handleWithdrawalClick = () => {
    const newErrors = [];

    if (!walletData.user?.billing_info) {
      newErrors.push(t("Nemáte vyplnené fakturačné údaje"));
    }
    if (!walletData.user?.billing_info?.iban) {
      newErrors.push(t("Nemáte vyplnený bankový účet"));
    }

    setErrors(newErrors);

    if (newErrors.length === 0) {
      setWithdrawalModalOpen(true);
    }
  };

  const handleWithdrawalSubmit = () => {
    const currentBalance = parseFloat(walletData.balance);
    const requestedAmount = parseFloat(withdrawalAmount);

    if (isNaN(requestedAmount) || requestedAmount <= 0) {
      setErrors([t("Zadajte platnú sumu na vyplatenie")]);
      return;
    }

    if (requestedAmount > currentBalance) {
      setErrors([t("Na účte nemáte dostatok prostriedkov")]);
      return;
    }

    setErrors([]);
    setWithdrawalModalOpen(false);
    setConfirmationModalOpen(true);
  };

  const handleConfirmWithdrawal = async () => {
    try {
      // Call create-wallet-transaction endpoint
      await api.post("create-wallet-transaction/", {
        amount: -Math.abs(parseFloat(withdrawalAmount)), // Ensure it's negative for withdrawal
      });

      // Refresh wallet data after withdrawal
      const response = await api.get("/get-user-wallet");
      setWalletData(response.data);

      setConfirmationModalOpen(false);
      setWithdrawalAmount("");
    } catch (error) {
      console.error("Error processing withdrawal:", error);
      setErrors([t("Chyba pri spracovaní výplaty")]);
    }
  };

  if (!walletData) {
    return <EmptyState message={t("Žiadne dáta o peňaženke")} />;
  }

  return (
    <div className="space-y-4">
      <Typography variant="h5" component="h2" className="mb-4">
        {t("Stav peňaženky")}
      </Typography>

      {errors.map((error, index) => (
        <Alert severity="error" key={index} className="mb-2">
          {error}
        </Alert>
      ))}

      <Card className="mb-4">
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t("Stav elektronickej peňaženky")}
          </Typography>
          <Typography variant="h3">
            {parseFloat(walletData.balance).toFixed(2)}{" "}
            {walletData.user?.billing_info?.currency || "EUR"}
          </Typography>
        </CardContent>
      </Card>

      <Box className="mt-6">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleWithdrawalClick}
        >
          {t("Požiadať o vyplatenie na bankový účet")}
        </Button>
      </Box>

      {/* Withdrawal Modal */}
      <Dialog
        open={isWithdrawalModalOpen}
        onClose={() => {
          setWithdrawalModalOpen(false);
          setErrors([]);
        }}
      >
        <DialogTitle>{t("Vyplatenie na bankový účet")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" className="mb-4">
            {t("Dostupný zostatok")}: {walletData.balance}{" "}
            {walletData.user?.billing_info?.currency || "EUR"}
          </Typography>
          <Typography variant="body1" className="mb-4">
            {t("Bankový účet")}: {walletData.user?.billing_info?.iban}
          </Typography>
          <TextField
            label={t("Suma na vyplatenie")}
            type="number"
            fullWidth
            value={withdrawalAmount}
            onChange={(e) => setWithdrawalAmount(e.target.value)}
            className="mt-4"
            inputProps={{
              min: 0,
              max: parseFloat(walletData.balance),
              step: "0.01",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setWithdrawalModalOpen(false);
              setErrors([]);
            }}
          >
            {t("Zrušiť")}
          </Button>
          <Button onClick={handleWithdrawalSubmit} color="primary">
            {t("Pokračovať")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Modal */}
      <Dialog
        open={isConfirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      >
        <DialogTitle>{t("Potvrdenie výplaty")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" className="mb-2">
            {t("Suma")}: {withdrawalAmount}{" "}
            {walletData.user?.billing_info?.currency || "EUR"}
          </Typography>
          <Typography variant="body1" className="mb-4">
            {t("Bankový účet")}: {walletData.user?.billing_info?.iban}
          </Typography>
          <Typography variant="body1" color="error">
            {t(
              "Skutočne chcete poslať výplatu na vyššie uvedený bankový účet?"
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationModalOpen(false)}>
            {t("Zrušiť")}
          </Button>
          <Button onClick={handleConfirmWithdrawal} color="primary">
            {t("Potvrdiť")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
