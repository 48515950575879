import { LuDownload } from "react-icons/lu";
import { IoIosArrowBack } from "react-icons/io"; // Import back arrow icon
import { useTranslation } from "react-i18next";

const Materials = ({ onMenuToggle, t }) => {
  const { i18n } = useTranslation(); // Get i18n instance

  // Add career plan documents mapping
  const careerPlanDocs = [
    { lang: "SK", label: "Slovensky" },
    { lang: "CZ", label: "Česky" },
    { lang: "PL", label: "Polski" },
    { lang: "EN", label: "English" },
    { lang: "DE", label: "Deutsch" },
  ];

  return (
    <div className="py-12 flex flex-col gap-8">
      {/* Desktop view: Title without the back arrow */}
      <h1 className="lg:block hidden text-3xl font-bold uppercase">
        {t("Materiály")}
      </h1>

      {/* Mobile view: Title with the back arrow */}
      <div
        className="lg:hidden text-3xl font-bold uppercase flex items-center gap-2 cursor-pointer"
        onClick={onMenuToggle} // Trigger menu toggle on click
      >
        <IoIosArrowBack className="h-6 w-6 cursor-pointer text-gray-700" />
        {t("Materiály")}
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-3xl font-semibold">{t("Kariérny plán")}</div>
        {careerPlanDocs.map((doc) => (
          <a
            key={doc.lang}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://bbuy.fra1.cdn.digitaloceanspaces.com/materials/${doc.lang}_KARIE%CC%81RNY%20PLA%CC%81N%20BB.pdf`}
            className="flex flex-row gap-2 items-center"
          >
            {t("Stiahnuť")} ({doc.label}) <LuDownload />
          </a>
        ))}
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-3xl font-semibold">
          {t("Grafiky na sociálne siete")}
        </div>
        <a href="#" className="flex flex-row gap-2 items-center">
          {/* {t("Stiahnuť")} <LuDownload /> */}
          {t("Čoskoro dostupné")}
        </a>
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-3xl font-semibold">{t("Prezentácia")}</div>
        <a href="#" className="flex flex-row gap-2 items-center">
          {/* {t("Stiahnuť")} <LuDownload /> */}
          {t("Čoskoro dostupné")}
        </a>
      </div>
    </div>
  );
};
export default Materials;

// https://bbuy.fra1.cdn.digitaloceanspaces.com/materials/SK_KARIE%CC%81RNY%20PLA%CC%81N%20BB.pdf
