import BasicNavbar from "../components/BasicNavbar";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import "../utils/quill.css";
import { CiMail } from "react-icons/ci";
import { motion } from "framer-motion";

const fadeInVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const Contact = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  return (
    <>
      <BasicNavbar />
      <motion.div
        className="text-center my-12"
        initial="hidden"
        animate="visible"
        variants={fadeInVariant}
      >
        <h1 className="text-5xl font-bold">{t("Kontakt")}</h1>
      </motion.div>
      <hr />
      <div className="max-w-5xl mx-auto">
        <motion.div
          className="flex lg:flex-row flex-col justify-between items-center my-24"
          initial="hidden"
          animate="visible"
          variants={fadeInVariant}
        >
          <div className=" flex-col gap-4 flex lg:hidden px-4 pb-4 text-center lg:text-left">
            <h3 className="text-3xl font-bold">
              {t("Pre zákazníkov, členov komunity Bilionbuy")}
            </h3>
            <ul className="text-xl font-semibold flex flex-col gap-2 list-disc list-inside">
              <li>{t("riešenie registrácií nových členov")}</li>
              <li>{t("riešenie reklamácií tovaru, doručenia, provízii")}</li>
            </ul>
          </div>
          <div className="flex flex-col justify-center gap-4 text-center mx-auto text-xl">
            <div className="shadow-lg w-64 h-64 bg-lightgray/50 rounded-full overflow-hidden flex flex-col justify-center mx-auto">
              <CiMail className="mx-auto text-white text-8xl" />
            </div>
            <a
              href="mailto:helpdesk@bilionbuy.com"
              className="text-primary underline underline-offset-4 font-semibold"
            >
              helpdesk@bilionbuy.com
            </a>
          </div>
          <div className=" flex-col gap-4 lg:flex hidden">
            <h3 className="text-3xl font-bold">
              {t("Pre zákazníkov, členov komunity Bilionbuy")}
            </h3>
            <ul className="text-xl font-semibold flex flex-col gap-2 list-disc list-inside">
              <li>{t("riešenie registrácií nových členov")}</li>
              <li>{t("riešenie reklamácií tovaru, doručenia, provízii")}</li>
            </ul>
          </div>
        </motion.div>
        <motion.div
          className="flex lg:flex-row flex-col justify-between items-center my-24"
          initial="hidden"
          animate="visible"
          variants={fadeInVariant}
        >
          <div className="flex flex-col gap-4 mx-auto px-4 pb-4 text-center lg:text-left">
            <h3 className="text-3xl font-bold">
              {t("Pre partnerov, internetové obchody")}
            </h3>
            <ul className="text-xl font-semibold flex flex-col gap-2 list-disc list-inside">
              <li>{t("riešenie prepojenia s Bilionbuy")}</li>
              <li>{t("partnerský servis")}</li>
            </ul>
          </div>
          <div className="flex flex-col justify-center gap-4 text-center  ">
            <div className="shadow-lg w-64 h-64 bg-lightgray/50 rounded-full overflow-hidden flex flex-col justify-center ">
              <CiMail className="mx-auto text-white text-8xl" />
            </div>
            <div className="flex flex-col gap-2 text-xl">
              <div className="font-bold text-2xl">Štefan</div>
              <a
                href="mailto:partner@bilionbuy.com"
                className="text-primary underline underline-offset-4 font-semibold"
              >
                partner@bilionbuy.com
              </a>
              <a
                href="tel:+421902670377"
                className="font-semibold text-primary"
              >
                +421 902 670 377
              </a>
            </div>
          </div>
        </motion.div>
        <hr />
        <motion.div
          className="lg:text-5xl text-3xl font-semibold text-center pb-24 leading-snug mt-12"
          initial="hidden"
          animate="visible"
          variants={fadeInVariant}
        >
          <h2
            className=" font-semibold leading-snug"
            dangerouslySetInnerHTML={{
              __html: t("Chcete nás kontaktovať kvôli niečomu inému"),
            }}
          ></h2>
          <a
            href="mailto:info@bilionbuy.com"
            className="text-primary font-semibold underline underline-offset-8"
          >
            info@bilionbuy.com
          </a>
        </motion.div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
