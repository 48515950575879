import FilterComponent from "./FilterComponent";
import ProductItemCard from "./ProductItemCard";
import { useEffect, useState } from "react";
import api from "../services/api";
import { Spinner, Skeleton } from "@nextui-org/react";
import SortingOptions from "./SortingOptions";
import { useAdultContent } from "../providers/AdultContentProvider";
import { useTranslation } from "react-i18next";
const FilteredItems = ({ items, id, visible, fetchCall }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState(""); // State for sorting
  const [priceFilter, setPriceFilter] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [verifiedProducts, setVerifiedProducts] = useState(new Set());
  const { hasConfirmedAge } = useAdultContent();

  // Initialize the view state from localStorage, with a default fallback
  const [view, setView] = useState(localStorage.getItem("viewMode") || "grid");

  // Save the view mode to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("viewMode", view);
  }, [view]);

  const fetchProducts = async (pageNumber = 1, initial = false) => {
    try {
      const params = { id: id, page: pageNumber };

      if (Object.keys(filter).length > 0) {
        params.filter = JSON.stringify(filter);
      }

      if (priceFilter.length === 2) {
        params.price_range = JSON.stringify(priceFilter);
      }

      if (sort) {
        params.sort_by = sort; // Add sort parameter to the API call
      }

      const response = await api.get(fetchCall, { params });
      setProducts((prevProducts) =>
        pageNumber === 1
          ? response.data.products
          : [...prevProducts, ...response.data.products]
      );
      setPrices(response.data.prices);
      setManufacturers(response.data.manufacturers);
      setHasNext(response.data.pagination.has_next);
      if (initial) {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (initial) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setPage(1);
    fetchProducts(1, true);
  }, [id]);

  useEffect(() => {
    if (!initialLoad) {
      fetchProducts(1);
    }
  }, [filter, priceFilter]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts(1); // Fetch products again when the sort option changes
  }, [sort]);

  const handleLoadMore = async () => {
    setLoadingMore(true);
    const nextPage = page + 1;
    await fetchProducts(nextPage);
    setPage(nextPage);
    setLoadingMore(false);
  };

  const handleProductVerified = (productId) => {
    setVerifiedProducts((prev) => new Set([...prev, productId]));
  };

  const areAllProductsVerified = () => {
    const adultProducts = products.filter((p) => p.adult_content);
    if (adultProducts.length === 0) return true;

    return adultProducts.every((p) => verifiedProducts.has(p.id));
  };

  return (
    <section
      className="flex md:flex-row flex-col gap-12 xl:py-4 xl:px-0 overflow-x-hidden w-full justify-center "
      aria-labelledby="filtered-items"
    >
      {visible && (
        <aside>
          {loading ? (
            <Skeleton width="300px" height="600px" />
          ) : (
            <div className="flex flex-row items-center justify-between">
              <FilterComponent
                id={id}
                setFilter={setFilter}
                setPriceFilter={setPriceFilter}
                manufacturers={manufacturers}
                min={prices[0]}
                max={prices[1]}
                step={(prices[1] - prices[0]) / 40}
                fetchCall={fetchCall}
                t={t}
              />
              <nav className="md:hidden block" aria-label="Sorting Options">
                <SortingOptions
                  setSort={setSort}
                  view={view}
                  setView={setView}
                />
              </nav>
            </div>
          )}
        </aside>
      )}

      <div className="flex flex-col">
        <nav className="pb-8 hidden md:block" aria-label="Sorting Options">
          <SortingOptions
            setSort={setSort}
            view={view}
            setView={setView}
            t={t}
          />
        </nav>

        <div className="">
          {loading ? (
            <div
              className="flex flex-wrap gap-12"
              aria-label="Loading Products"
            >
              {Array.from({ length: 9 }).map((_, index) => (
                <Skeleton key={index} width="300px" height="400px" />
              ))}
            </div>
          ) : products.length === 0 ? (
            <div className="flex justify-center py-12">
              <p>No data has been found for this category</p>
            </div>
          ) : !areAllProductsVerified() ? (
            <div className="flex justify-center items-center min-h-[400px]">
              <Spinner />
            </div>
          ) : (
            <div
              className={`${
                view === "grid"
                  ? "flex flex-wrap justify-center"
                  : "flex flex-col gap-4 "
              }`}
              aria-labelledby="product-list"
            >
              {products.map((product) => (
                <article
                  key={product.id}
                  className={view === "list" ? "flex items-center gap-4" : ""}
                  aria-label={product.product_name}
                >
                  <ProductItemCard
                    product={product}
                    view={view}
                    isClickable={true}
                    onVerified={() => handleProductVerified(product.id)}
                  />
                </article>
              ))}
            </div>
          )}
          {hasNext && !loading && (
            <div className="flex justify-center py-6 text-center">
              <button
                onClick={handleLoadMore}
                disabled={loadingMore}
                className=" px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors disabled:opacity-50 flex items-center gap-2"
                aria-label="Load More Products"
              >
                {loadingMore ? (
                  <>
                    <Spinner size="sm" color="white" />
                  </>
                ) : (
                  t("Zobraziť viac")
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default FilteredItems;
