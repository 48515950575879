import React, { useState, useCallback, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { FormControlLabel, Checkbox } from "@mui/material";

const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  const debouncedCallback = useCallback(
    (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  return debouncedCallback;
};

const PriceGraph = ({
  data = [], // Array of price range objects with count
  min = 0,
  max = 1000,
  step = 1,
  setPriceFilter,
  label,
}) => {
  const [value, setValue] = useState([min, max]);
  const [selectedPriceRange, setSelectedPriceRange] = useState(null); // For managing the selected checkbox

  const debouncedSetPriceFilter = useDebounce(setPriceFilter, 500);

  useEffect(() => {
    debouncedSetPriceFilter(value);
  }, [value, debouncedSetPriceFilter]);

  const handleChange = (event, newValue) => {
    const newMin = Math.max(min, Math.floor(newValue[0]));
    const newMax = Math.ceil(newValue[1]);
    setSelectedPriceRange(null); // Reset checkbox selection when slider is moved
    setValue([newMin, newMax]);
  };

  const handleCheckboxChange = (priceRange) => {
    if (
      selectedPriceRange &&
      selectedPriceRange.min === priceRange.min &&
      selectedPriceRange.max === priceRange.max
    ) {
      // Unchecking the checkbox
      setSelectedPriceRange(null);
      setValue([min, max]); // Reset to full range
    } else {
      // Checking the checkbox
      setSelectedPriceRange(priceRange);
      setValue([priceRange.min, priceRange.max]);
    }
  };

  const parseRange = (range) => {
    const [from, to] = range
      .replace("*", "0") // Replace '*' with '0' for min
      .replace("*", max.toString()) // Replace '*' with max value
      .split("-")
      .map((value) => parseFloat(value));
    return { from, to: to || max }; // Ensure 'to' has a fallback to max if undefined
  };

  const calculateColor = (item_value) => {
    if (item_value >= value[0] && item_value <= value[1]) {
      return "#0166FF";
    } else {
      return "#E8EAEE";
    }
  };

  const calculateHeight = (count, maxCount) => {
    return (count / maxCount) * 100; // Percentage height based on max count
  };

  // Number of bars to display in the graph
  const numberOfBars = 50;
  const intervalSize = (max - min) / numberOfBars;

  // Create an array of values from min to max with an exponential distribution
  const barData = Array.from({ length: numberOfBars }, (_, index) => {
    const barMin = min + index * intervalSize;
    const barMax = barMin + intervalSize;
    const count = Math.pow(index + 1, 1.7); // Slight exponential curve
    return { min: barMin, max: barMax, count };
  });

  const maxCount = Math.max(...barData.map((bar) => bar.count));

  return (
    <>
      <div className="font-semibold">{label}</div>
      <div className="flex flex-row justify-between items-end h-24">
        {barData.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "flex-end",
              flex: 1,
              height: "100%",
            }}
          >
            <svg
              width="4"
              height="100%"
              viewBox="0 0 4 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y={100 - calculateHeight(item.count, maxCount)}
                width="4"
                height={calculateHeight(item.count, maxCount)}
                rx="2"
                fill={calculateColor(item.min)}
              />
            </svg>
          </div>
        ))}
      </div>
      <Box sx={{ width: 300, mt: 2 }}>
        <Slider
          getAriaLabel={() => "Price range"}
          value={value}
          min={min}
          max={max}
          onChange={handleChange}
          valueLabelDisplay="auto"
          getAriaValueText={(value) => `${value}`}
          step={step}
          color="primary"
        />
      </Box>

      <div className="flex flex-row justify-between text-center items-center mt-2">
        <input
          type="text"
          placeholder={`${min}`}
          value={value[0]}
          onChange={(e) =>
            handleChange(null, [
              Math.max(min, Math.floor(Number(e.target.value))),
              value[1],
            ])
          }
          className="w-[100px] border rounded-xl px-2 py-2"
          style={{ appearance: "textfield" }}
        />
        <span>-</span>
        <input
          type="text"
          placeholder={`${max}`}
          value={value[1]}
          onChange={(e) =>
            handleChange(null, [value[0], Math.ceil(Number(e.target.value))])
          }
          className="w-[100px] border rounded-xl px-2 py-2"
          style={{ appearance: "textfield" }}
        />
      </div>

      <div className="mt-4">
        {data.map((range, index) => {
          const { from, to } = parseRange(range.range);

          // Adjust label for special cases
          const rangeLabel =
            from === 0 && to === 100
              ? "0 - 100"
              : from === 500
              ? "500+"
              : `${from} - ${to}`;

          return (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectedPriceRange &&
                      selectedPriceRange.min === from &&
                      selectedPriceRange.max === to
                    }
                    onChange={() =>
                      handleCheckboxChange({
                        min: from,
                        max: to,
                      })
                    }
                  />
                }
                label={`${rangeLabel} (${range.count})`}
                sx={{
                  alignItems: "center",
                  margin: 0,
                  marginLeft: "-8px",
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PriceGraph;
