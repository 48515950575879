// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0166FF",
    },
    blue: {
      main: "#0166FF",
    },
    orange: {
      main: "#FF6600",
    },
    purple: {
      main: "#DC508E",
    },
    pink: {
      main: "#FA61AB",
    },
    productblue: {
      main: "#0166FF",
    },
    serviceblue: {
      main: "#0166FF",
    },
    lightgray: {
      main: "#9C9D9D",
    },
  },
});

export default theme;
