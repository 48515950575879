import { useEffect, useState, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Autocomplete,
  AutocompleteItem,
  Skeleton,
  Spinner,
} from "@nextui-org/react";
import { FaMagnifyingGlass } from "react-icons/fa6";

import categories_list from "../../utils/data";
import api from "../../services/api";

const CategoriesTable = () => {
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [productPage, setProductPage] = useState(1);
  const [productTotalPages, setProductTotalPages] = useState(1);
  const [productLoading, setProductLoading] = useState(false);
  const [productCount, setProductCount] = useState(0);

  const {
    isOpen: isEditModalOpen,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();
  const {
    isOpen: isMagnifyModalOpen,
    onOpen: onOpenMagnifyModal,
    onClose: onCloseMagnifyModal,
  } = useDisclosure();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const {
    isOpen: isCreateModalOpen,
    onOpen: onOpenCreateModal,
    onClose: onCloseCreateModal,
  } = useDisclosure();
  const [modalSize, setModalSize] = useState("md");

  const fetchCategoryProducts = useCallback(async (categoryId, page = 1) => {
    setProductLoading(true);
    try {
      const response = await api.get("get-category-products/", {
        params: { category: categoryId, page },
      });
      setCategoryProducts(response.data.results);
      setProductTotalPages(Math.ceil(response.data.count / 10));
      setProductCount(response.data.count);
    } catch (error) {
      console.error("fetchCategoryProducts -> error", error);
    } finally {
      setProductLoading(false);
    }
  }, []);

  const fetchCategories = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await api.get("get-admin-categories/", {
        params: { search: searchName, page, page_size: 10 },
      });
      const newCategories = response.data.results;
      setCategories(newCategories);
      setTotalPages(Math.ceil(response.data.count / 10));
    } catch (error) {
      console.error("fetchCategories -> error", error);
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  }, [searchName, page, loading]);

  const handleAutocompleteChange = (value) => {
    setFormData((formData) => ({
      ...formData,
      parent_fullname: categories_list[value].replace(" / ", " | "),
    }));
  };

  const createCategory = async () => {
    try {
      await api.post("create-category/", formData);
      fetchCategories(true);
      toast.success("Category created successfully");
    } catch (error) {
      console.error("createCategory -> error", error);
      toast.error("Category creation failed");
    }
  };

  const updateCategory = async () => {
    try {
      await api.post("update-category/", formData);
      fetchCategories(true);
      toast.success("Category updated successfully");
    } catch (error) {
      console.error("updateCategory -> error", error);
      toast.error("Category update failed");
    }
  };

  useEffect(() => {
    fetchCategories(true);
  }, [page]);

  const handleSearch = () => {
    setPage(1);
    fetchCategories(true);
  };

  const handleProductPageChange = (newPage) => {
    setProductPage(newPage);
    if (selectedCategory) {
      fetchCategoryProducts(selectedCategory.id, newPage);
    }
  };

  const handleOpenMagnifyModal = (category) => {
    setFormData(category);
    setSelectedCategory(category);
    setModalSize("5xl");
    setProductPage(1);
    fetchCategoryProducts(category.id, 1);
    onOpenMagnifyModal();
  };

  return (
    <div className="bg-white shadow-lg border-1 rounded-lg">
      <div className="px-4 py-5 sm:px-6 flex justify-between">
        <div className="flex flex-row gap-6 items-center">
          <h3 className="text-3xl font-bold text-gray-900">Kategórie</h3>
          <div>
            Názov:{" "}
            <input
              type="text"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              className="border-1 p-1 rounded-lg"
            />{" "}
            <button
              onClick={handleSearch}
              className="bg-black text-white p-2 rounded-lg text-sm font-semibold"
            >
              Hľadať
            </button>
          </div>
        </div>

        <div
          className="px-8 py-2 border-1 rounded-lg text-lightgray hover:cursor-pointer"
          onClick={() => {
            setModalSize("md");
            setFormData([]);
            onOpenCreateModal();
          }}
        >
          +
        </div>
      </div>
      <div className="border-1 m-4 rounded-lg border-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 backdrop-blur-md">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Názov
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Celý strom kategórie
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Úroveň
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {initialLoading
              ? [...Array(10)].map((_, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4">
                      <Skeleton height={20} width="100%" />
                    </td>
                    <td className="px-6 py-4">
                      <Skeleton height={20} width="100%" />
                    </td>
                    <td className="px-6 py-4">
                      <Skeleton height={20} width="100%" />
                    </td>
                    <td className="px-6 py-4">
                      <Skeleton height={20} width="100%" />
                    </td>
                  </tr>
                ))
              : categories.map((category) => (
                  <tr key={category.id} className="hover:bg-slate-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      {category.category_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {category.category_fullname}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {category.order}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex flex-row gap-4">
                        <div
                          className="text-lightgray border-1 p-1 rounded-lg hover:cursor-pointer items-center px-2"
                          onClick={() => handleOpenMagnifyModal(category)}
                        >
                          <FaMagnifyingGlass className="my-1" />
                        </div>
                        <div
                          className="text-lightgray border-1 p-1 rounded-lg hover:cursor-pointer"
                          onClick={() => {
                            setFormData(category);
                            setModalSize("5xl");
                            onOpenEditModal();
                          }}
                        >
                          Editovať
                        </div>
                        <div
                          className="text-lightgray hover:cursor-pointer p-1 rounded-lg border-1"
                          onClick={() => {
                            setFormData(category);
                            setModalSize("5xl");
                            onOpenDeleteModal();
                          }}
                        >
                          Zmazať
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center gap-4 p-4">
        <span>
          Strana {page} z {totalPages}
        </span>
        <button
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
          className="bg-black text-white p-2 px-4 rounded-lg text-sm font-semibold"
          disabled={page === 1}
        >
          Späť
        </button>

        <button
          onClick={() =>
            setPage((prev) => (prev < totalPages ? prev + 1 : prev))
          }
          className="bg-black text-white p-2 px-4 rounded-lg text-sm font-semibold"
          disabled={page === totalPages}
        >
          Ďalej
        </button>
      </div>

      {/* Magnify Modal */}
      <Modal
        size={modalSize}
        isOpen={isMagnifyModalOpen}
        onClose={onCloseMagnifyModal}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Priradené produkty ku kategórií
              </ModalHeader>
              <ModalBody>
                {productLoading ? (
                  <div className="mx-auto my-auto">
                    <Spinner />
                  </div>
                ) : (
                  <div className="flex flex-col gap-4">
                    {categoryProducts.map((product) => (
                      <a
                        href={"/view-product/" + product.id}
                        key={product.id}
                        className="flex gap-4 items-center"
                      >
                        <img
                          src={product.product_image_url}
                          alt={product.product_name}
                          className="w-16 h-16 object-cover"
                        />
                        <span>{product.product_name}</span>
                      </a>
                    ))}
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <div className="flex justify-end items-center gap-4 p-4">
                  <span>
                    Strana {productPage} z {productTotalPages} | Nájdených{" "}
                    {productCount} produktov
                  </span>
                  <button
                    onClick={() => handleProductPageChange(productPage - 1)}
                    className="bg-black text-white p-2 px-4 rounded-lg text-sm font-semibold"
                    disabled={productPage === 1}
                  >
                    Späť
                  </button>
                  <button
                    onClick={() => handleProductPageChange(productPage + 1)}
                    className="bg-black text-white p-2 px-4 rounded-lg text-sm font-semibold"
                    disabled={productPage === productTotalPages}
                  >
                    Ďalej
                  </button>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* Create Modal */}
      <Modal
        size={modalSize}
        isOpen={isCreateModalOpen}
        onClose={onCloseCreateModal}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Vytvoriť kategóriu
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-4">
                  {/* Form or content for creating a category */}
                  <div>
                    Názov:{" "}
                    <input
                      value={formData.category_name || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          category_name: e.target.value,
                        })
                      }
                      type="text"
                      className="border-1 rounded-lg p-1 w-full"
                    />{" "}
                  </div>
                  <div>
                    Nadradená kategória:{" "}
                    <Autocomplete
                      label="Nadradená kategória"
                      className="w-full"
                      variant="bordered"
                      size="sm"
                      fullWidth={true}
                      defaultInputValue={null}
                      onSelectionChange={handleAutocompleteChange}
                    >
                      {categories_list.map((category, index) => (
                        <AutocompleteItem key={index} value={category}>
                          {category}
                        </AutocompleteItem>
                      ))}
                    </Autocomplete>
                  </div>
                  <div>
                    Celá cesta:{" "}
                    <input
                      disabled
                      value={
                        formData.parent_fullname
                          ? formData.parent_fullname
                          : formData.category_fullname?.replace(
                              formData.category_name,
                              ""
                            ) +
                            " | " +
                            formData.category_name
                      }
                      type="text"
                      className="border-1 rounded-lg p-1 w-full"
                    />{" "}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" onPress={onClose && createCategory}>
                  Create
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* Edit Modal */}
      <Modal
        size={modalSize}
        isOpen={isEditModalOpen}
        onClose={onCloseEditModal}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Edit Category
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-4">
                  {/* Form or content for creating a category */}
                  <div>
                    Názv:{" "}
                    <input
                      value={formData.category_name || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          category_name: e.target.value,
                        })
                      }
                      type="text"
                      className="border-1 rounded-lg p-1 w-full"
                    />{" "}
                  </div>
                  <div>
                    Nadradená kategória:{" "}
                    <Autocomplete
                      label={
                        formData.category_fullname?.replace(
                          formData.category_name,
                          ""
                        ) || "Nadradená kategória"
                      }
                      className="w-full"
                      variant="bordered"
                      size="sm"
                      fullWidth={true}
                      defaultInputValue={formData.category_fullname?.replace(
                        formData.category_name,
                        ""
                      )}
                      onSelectionChange={handleAutocompleteChange}
                    >
                      {categories_list.map((category, index) => (
                        <AutocompleteItem key={index} value={category}>
                          {category}
                        </AutocompleteItem>
                      ))}
                    </Autocomplete>
                  </div>
                  <div>
                    Celá cesta:{" "}
                    <input
                      disabled
                      value={
                        formData.parent_fullname
                          ? formData.parent_fullname
                          : formData.category_fullname?.replace(
                              formData.category_name,
                              ""
                            ) +
                            " | " +
                            formData.category_name
                      }
                      type="text"
                      className="border-1 rounded-lg p-1 w-full"
                    />{" "}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={() => {
                    updateCategory();
                    onClose();
                  }}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* Delete Modal */}
      <Modal
        size={modalSize}
        isOpen={isDeleteModalOpen}
        onClose={onCloseDeleteModal}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Delete Category
              </ModalHeader>
              <ModalBody>
                <p>Mazanie kategórií nieje povolené</p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="primary" isDisabled={true} onPress={onClose}>
                  Delete
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CategoriesTable;
