import { Avatar } from "@nextui-org/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { IoIosArrowBack } from "react-icons/io"; // Import back arrow icon
import React from "react";
const generateBackgroundColor = (level) => {
  const baseColor = 100; // Start with slate-100
  const increment = 100; // Increment by 100
  const colorLevel = baseColor + level * increment;
  return `bg-slate-${colorLevel}`;
};

export default function Structure({ onMenuToggle, t }) {
  // Add onMenuToggle prop
  const [data, setData] = useState([]);
  const [nestedData, setNestedData] = useState({});
  const [openStatus, setOpenStatus] = useState({});

  const fetchData = async (selected_user_id = null, parentId = null) => {
    try {
      const endpoint = selected_user_id
        ? `get-user-structure/?selected_user=${selected_user_id}`
        : "get-user-structure/";
      const response = await api.get(endpoint);
      const nestedUsers = response.data.direct_assigned_users;
      if (parentId !== null) {
        setNestedData((prev) => ({
          ...prev,
          [parentId]: nestedUsers,
        }));
      } else {
        setData(nestedUsers);
      }
    } catch (error) {
      console.error("fetchData -> error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleNestedStructure = (id) => {
    setOpenStatus((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
    if (!openStatus[id]) {
      fetchData(id, id);
    }
  };

  const heads = [
    "#",
    "",
    "E-mail",
    t("Meno"),
    t("Assigned Users"),
    t("Pozvánka od"),
    t("Posledné prihlásenie"),
    t("Posledný nákup"),
    "",
  ];

  const renderNestedRows = (parentId, level) => {
    if (nestedData[parentId]) {
      if (nestedData[parentId].length === 0) {
        return (
          <div className="text-gray-500 py-4">
            {t("User has no users in his structure")}
          </div>
        );
      }
      return nestedData[parentId].map((nestedItem, index) => (
        <React.Fragment key={nestedItem.id}>
          {/* Desktop View for Nested Rows */}
          <div
            className="hidden lg:grid grid-cols-9 gap-4 items-center text-left my-4 hover:cursor-pointer hover:bg-slate-50 rounded-lg p-2"
            onClick={() => toggleNestedStructure(nestedItem.id)}
          >
            <div className="text-center">{index + 1}</div>
            <div className="flex items-center justify-center">
              <Avatar
                src={nestedItem.user_photo}
                alt="user"
                className="w-12 h-12 rounded-full"
              />
            </div>
            <div className="truncate">{nestedItem.email}</div>
            <div className="truncate">
              {nestedItem.last_name
                ? nestedItem.first_name + " " + nestedItem.last_name
                : "Neznáme"}
            </div>
            <div className="truncate">
              {nestedItem.total_assigned_users || 0}
            </div>
            <div className="truncate">
              {nestedItem.invite_from || "Neznáme"}
            </div>
            <div className="truncate">{nestedItem.last_login || "Neznáme"}</div>
            <div className="truncate">
              {nestedItem.last_purchase || "Neznáme"}
            </div>
            <div className="flex justify-center">
              <BsThreeDotsVertical />
            </div>
          </div>

          {/* Mobile View for Nested Rows */}
          <div
            className="lg:hidden border rounded-lg shadow-md p-4 space-y-2 mb-2 hover:bg-slate-50 hover:cursor-pointer"
            onClick={(e) => {
              e.stopPropagation(); // Add this to prevent event bubbling
              toggleNestedStructure(nestedItem.id);
            }}
          >
            <div className="flex items-center">
              <span className="font-semibold">#: </span>
              <span className="ml-2">{index + 1}</span>
            </div>
            <div className="flex items-center">
              <span className="font-semibold">E-mail: </span>
              <span className="ml-2">{nestedItem.email}</span>
            </div>
            <div>
              <span className="font-semibold">{t("Meno")}: </span>
              {nestedItem.last_name
                ? nestedItem.first_name + " " + nestedItem.last_name
                : t("Neznáme")}
            </div>
            <div>
              <span className="font-semibold">Pozvánka od: </span>
              {nestedItem.invite_from || t("Neznáme")}
            </div>
            <div>
              <span className="font-semibold">
                {t("Posledné prihlásenie")}:{" "}
              </span>
              {nestedItem.last_login || t("Neznáme")}
            </div>
            <div>
              <span className="font-semibold">{t("Posledný nákup")}: </span>
              {nestedItem.last_purchase || t("Neznáme")}
            </div>
            <div>
              <span className="font-semibold">{t("Assigned Users")}: </span>
              <span>{nestedItem.total_assigned_users || 0}</span>
            </div>
            {/* <div className="flex justify-center">
              <BsThreeDotsVertical />
            </div> */}
          </div>

          {/* Render additional nested rows if expanded */}
          {openStatus[nestedItem.id] && (
            <div onClick={(e) => e.stopPropagation()}>
              {" "}
              {/* Add this wrapper with stopPropagation */}
              {renderNestedRows(nestedItem.id, level + 1)}
            </div>
          )}
        </React.Fragment>
      ));
    } else if (nestedData[parentId] === null) {
      return (
        <div className="text-red-500" style={{ paddingLeft: level * 16 }}>
          {t("This user has no registered users in his structure")}
        </div>
      );
    }
    return null;
  };

  const renderRow = (item) => (
    <div key={item.id} className="hover:cursor-pointer">
      <div
        className="grid grid-cols-7 gap-4 items-center text-left my-4"
        onClick={() => toggleNestedStructure(item.id)}
      >
        <div className="flex items-center justify-center">
          <Avatar
            src={item.user_photo}
            alt="user"
            className="w-12 h-12 rounded-full"
          />
        </div>
        <div className="truncate">{item.email}</div>
        <div className="truncate">
          {item.last_name
            ? item.first_name + " " + item.last_name
            : t("Neznáme")}
        </div>
        <div className="truncate">{item.total_assigned_users || 0}</div>
        <div className="truncate">
          {item.invite_from ? item.invite_from : t("Neznáme")}
        </div>
        <div className="truncate">
          {item.last_login ? item.last_login : t("Neznáme")}
        </div>
        <div className="truncate">
          {item.last_purchase ? item.last_purchase : t("Neznáme")}
        </div>
        <div className="flex justify-center">
          <BsThreeDotsVertical />
        </div>
      </div>
      <hr />
      {openStatus[item.id] && renderNestedRows(item.id, 1)}
    </div>
  );

  return (
    <div className="pb-12">
      {/* Desktop view: Title without the back arrow */}
      <div className="lg:block hidden uppercase font-bold py-12 text-2xl">
        {t("moja štruktúra")}
      </div>

      {/* Mobile view: Title with the back arrow */}
      <div
        className="lg:hidden uppercase font-bold py-12 text-2xl flex items-center gap-2 cursor-pointer"
        onClick={onMenuToggle} // Trigger menu toggle on click
      >
        <IoIosArrowBack className="h-6 w-6 cursor-pointer text-gray-700" />
        {t("moja štruktúra")}
      </div>

      {/* Desktop view: Display as a table */}
      <div className="hidden lg:block overflow-x-auto">
        <table className="min-w-full bg-white border-separate border-spacing-y-8">
          <thead>
            <tr className="w-full uppercase text-sm leading-normal">
              {heads.map((head, index) => (
                <th
                  key={index}
                  className="py-3 px-6 text-left whitespace-nowrap"
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm font-light">
            {data.map((item, index) => (
              <React.Fragment key={item.id}>
                <tr
                  onClick={() => toggleNestedStructure(item.id)}
                  className="hover:bg-slate-50 hover:cursor-pointer"
                >
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    <div className="flex items-center justify-center">
                      <Avatar
                        src={item.user_photo}
                        alt="user"
                        className="w-12 h-12 rounded-full"
                      />
                    </div>
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {item.email}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {item.last_name
                      ? item.first_name + " " + item.last_name
                      : t("Neznáme")}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {item.total_assigned_users || 0}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {item.invite_from || t("Neznáme")}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {item.last_login || t("Neznáme")}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {item.last_purchase || t("Neznáme")}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    <BsThreeDotsVertical />
                  </td>
                </tr>
                <tr>
                  <td colSpan={heads.length}>
                    <hr />
                  </td>
                </tr>
                {openStatus[item.id] && (
                  <tr>
                    <td colSpan={heads.length}>
                      {renderNestedRows(item.id, 1)}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile view: Display as a card layout */}
      <div className="lg:hidden space-y-4">
        {data.map((item, index) => (
          <div
            key={item.id}
            className="border rounded-lg shadow-md p-4 space-y-2 hover:bg-slate-50 hover:cursor-pointer"
            onClick={() => toggleNestedStructure(item.id)}
          >
            <div className="flex items-center">
              <span className="font-semibold">#: </span>
              <span className="ml-2">{index + 1}</span>
            </div>
            <div className="flex items-center">
              <span className="font-semibold">E-mail: </span>
              <span className="ml-2">{item.email}</span>
            </div>
            <div>
              <span className="font-semibold">{t("Meno")}: </span>
              {item.last_name
                ? item.first_name + " " + item.last_name
                : "Neznáme"}
            </div>
            <div>
              <span className="font-semibold">{t("Assigned Users")}: </span>
              <span>{item.total_assigned_users || 0}</span>
            </div>
            <div>
              <span className="font-semibold">{t("Pozvánka od")}: </span>
              {item.invite_from || "Neznáme"}
            </div>
            <div>
              <span className="font-semibold">
                {t("Posledné prihlásenie")}:{" "}
              </span>
              {item.last_login || "Neznáme"}
            </div>
            <div>
              <span className="font-semibold">{t("Posledný nákup")}: </span>
              {item.last_purchase || "Neznáme"}
            </div>
            {openStatus[item.id] && renderNestedRows(item.id, 1)}
          </div>
        ))}
      </div>
    </div>
  );
}
