// utils/register.js

import api from "../services/api"; // Adjust the import path based on your project structure

export const registerUser = async (userData) => {
  const { password, confirmPassword } = userData;

  if (password !== confirmPassword) {
    throw new Error("Passwords do not match");
  }

  try {
    const response = await api.post("sign-up/", {
      username: userData.email.split("@")[0],
      email: userData.email,
      password: userData.password,
      referralCode: userData.referralCode, // Make sure the key name here matches the backend expectation
    });
    console.log("User registered:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error registering user:", error.response);
    throw error;
  }
};
