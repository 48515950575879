import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import { NextUIProvider } from "@nextui-org/react";
import { AuthProvider } from "./services/auth-context";
import theme from "./services/theme";
import { ThemeProvider } from "@mui/material/styles";
import "./i18n"; // This ensures i18n is initialized

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <NextUIProvider>
      <React.Suspense fallback="loading">
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </React.Suspense>
    </NextUIProvider>
  </React.StrictMode>
);
