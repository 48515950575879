import React, { useState } from "react";
import Modal from "./Modal";
import api from "../services/api";
import { Button, Spinner } from "@nextui-org/react";

const EditButton = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [deleteInput, setDeleteInput] = useState("");
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);

  const handleDeleteInputChange = (e) => {
    setDeleteInput(e.target.value);
    setIsDeleteConfirmed(e.target.value.trim() === data.name); // Assuming validation against the shop name
  };

  const handleDelete = async () => {
    if (!isDeleteConfirmed) return; // Ensure confirmation input is correct

    setIsLoading(true);
    setMessage("");
    try {
      const response = await api.delete(`delete-shop/${data.id}`);
      if (response.status === 200) {
        setMessage("Obchod bol vymazaný!");
        // Optionally close modal or take other actions
        setIsOpen(false);
      } else {
        throw new Error("Pri vymazávaní obchodu došlo k chybe");
      }
    } catch (error) {
      setMessage("Pri vymazávaní obchodu došlo k chybe: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="px-4 py-2 bg-danger font-bold text-white rounded-md hover:bg-blue-700 focus:outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
          />
        </svg>
      </button>

      {isOpen && (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <div className="p-4 w-full flex flex-col gap-4  space-y-4 text-center">
            <h2 className="text-3xl font-bold ">Potvrdenie vymazania</h2>
            <div className="text-black flex flex-col">
              <div>Potvrďte príkaz na vymazanie shopu</div>
              <span className="font-bold text-black text-xl py-4">
                {data.name}{" "}
              </span>{" "}
              <div>napísaním jeho názvu:</div>
            </div>
            <input
              type="text"
              value={deleteInput}
              onChange={handleDeleteInputChange}
              className="border-2 border-gray-300 rounded p-2 w-full"
            />
            <div className="text-xs text-center flex flex-wrap">
              Vymazanie obchodu je nevratný proces. <br />
              Vymazanie obchodu z databázy môže trvať aj niekoľko hodín.{" "}
            </div>
            <button
              onClick={handleDelete}
              disabled={!isDeleteConfirmed}
              className={`mt-4 px-4 py-4 ${
                isDeleteConfirmed
                  ? "bg-red-600 hover:bg-red-700"
                  : "bg-gray-500"
              } text-white font-bold rounded-md`}
            >
              {isLoading ? <Spinner /> : "Vymazať"}
            </button>
            {message && <p>{message}</p>}
          </div>
        </Modal>
      )}
    </>
  );
};

export default EditButton;
