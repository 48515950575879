import React, { useState, useEffect } from "react";
import { Button, Skeleton } from "@nextui-org/react";
import api from "../services/api";
import ShopCardLarge from "./ShopCardLarge";
import { motion, AnimatePresence } from "framer-motion";

const RelatedShops = ({ productSlug, t }) => {
  const [loading, setLoading] = useState(true);
  const [shops, setShops] = useState([]);
  const [error, setError] = useState(null);
  const [chunkSize, setChunkSize] = useState(4);

  useEffect(() => {
    const fetchRelatedShops = async () => {
      try {
        setLoading(true);
        const shopsResponse = await api.get(
          `get-other-shops-by-category/${productSlug}`
        );
        setShops(shopsResponse.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch related shops");
        setLoading(false);
      }
    };

    if (productSlug) {
      fetchRelatedShops();
    }
  }, [productSlug]);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 1280) {
      setChunkSize(4);
    } else if (width >= 768) {
      setChunkSize(2);
    } else {
      setChunkSize(1);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) {
    return null;
  }

  if (error || shops.length === 0) {
    return null;
  }

  return (
    <div className="bg-[#F1F3F5] pb-12">
      <div className="max-w-screen-xl mx-auto lg:px-0 px-4">
        <div className="mx-auto ">
          <h2 className="text-2xl font-bold mt-12 mb-6">
            {t("Obchody, ktoré ponúkajú podobné produkty v danej kategórií")}
          </h2>

          <motion.div
            layout
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
          >
            <AnimatePresence>
              {shops.map((shop, index) => (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3 }}
                  key={index}
                >
                  <ShopCardLarge shop={shop} />
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default RelatedShops;
