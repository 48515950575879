import React from "react";
import { motion } from "framer-motion";

const CategoryContent = ({ category, shops, onBack }) => {
  return (
    <div className="category-content">
      <button onClick={onBack} className="back-button">
        Back to Categories
      </button>
      <h2>{category.name}</h2>
      <div className="subcategories">
        {category.subcategories.map((subcategory) => (
          <motion.div
            key={subcategory.id}
            className="subcategory"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {subcategory.name}
          </motion.div>
        ))}
      </div>
      <div className="shops">
        {shops.map((shop) => (
          <motion.div
            key={shop.id}
            className="shop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {shop.name}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default CategoryContent;
