import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import RegisterForm from "./auth/RegisterForm";
import LoginForm from "./auth/LoginForm";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { useLeaveModal } from "./LeaveModalContext";
import { useTranslation } from "react-i18next";
import { logo_url } from "../data/common";

const LeaveModal = () => {
  const { isOpen, closeModal, confirmLeave, shopName, isAuthenticated } =
    useLeaveModal();
  const { t } = useTranslation();
  const [formType, setFormType] = useState("register");
  const [expanded, setExpanded] = useState(false);

  // Callback function to handle success within the modal
  const handleRegisterSuccess = () => {
    console.log("Registration successful inside modal");
    closeModal(); // Close the modal without redirection
    window.location.reload(); // Refresh the page
  };

  const handleLoginSuccess = () => {
    console.log("Login successful inside modal");
    closeModal(); // Close the modal without redirection
    window.location.reload(); // Refresh the page
  };

  // Toggle between 'register' and 'login'
  const toggleForm = (type) => {
    setFormType(type);
    setExpanded(true);
  };

  // Handle expand button click
  const handleExpandClick = () => {
    setExpanded(true);
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={closeModal} placement="top-center">
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1 text-center text-3xl">
          {!isAuthenticated ? t("Ste prihlásený?") : null}
        </ModalHeader>
        <ModalBody className="text-center text-lg flex flex-col gap-4">
          {isAuthenticated ? (
            <>
              {/* <div>
                {t("Odchádzate na stránku")} <strong>{shopName}</strong>.
              </div> */}
              <img
                src={logo_url}
                alt="BillionBuy Logo"
                className="mx-auto w-64 h-auto"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Zapnuté aplikácie na <strong>blokovanie reklám alebo trackovania</strong> a <strong>nesúhlas s používaním súborov cookies</strong> v internetovom obchode"
                  ),
                }}
              />
              <div>
                <strong>{shopName}</strong>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    "môže spôsobiť <strong>nepriradenie cashbacku</strong> na váš účet v Bilionbuy."
                  ),
                }}
              />
            </>
          ) : (
            <>
              <div>
                {t(
                  "Pozor! nie ste prihlásený a preto Vám nebude zarátaný cashback z nákupu na"
                )}{" "}
                <strong>{shopName}</strong>?
              </div>
              <hr />
              <Button
                radius="sm"
                fullWidth
                onPress={confirmLeave}
                className="border-1 border-primary text-primary bg-white"
              >
                {t("Prejsť na stránku obchodu bez prihlásenia")}
              </Button>
              <AnimatePresence initial={false}>
                {!expanded && (
                  <motion.div
                    key="initial-buttons"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                  >
                    <Button
                      fullWidth
                      onPress={handleExpandClick}
                      className="bg-primary text-white font-semibold"
                    >
                      {t("Registrovať")}
                    </Button>
                    <p className="text-center text-sm text-gray-500 mt-4">
                      {t("Máte už účet?")}{" "}
                      <button
                        className="font-semibold leading-6 text-primary"
                        onClick={() => toggleForm("login")}
                      >
                        {t("Prihláste sa")}
                      </button>
                    </p>
                  </motion.div>
                )}

                {expanded && (
                  <motion.div
                    key="expanded-content"
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {formType === "register" ? (
                      <>
                        <RegisterForm
                          onSuccess={handleRegisterSuccess}
                          showComplementary={false}
                        />
                        <p className="text-center text-sm text-gray-500 mt-4">
                          {t("Máte už účet?")}{" "}
                          <button
                            className="font-semibold leading-6 text-primary"
                            onClick={() => toggleForm("login")}
                          >
                            {t("Prihláste sa")}
                          </button>
                        </p>
                      </>
                    ) : (
                      <>
                        <LoginForm
                          onSuccess={handleLoginSuccess}
                          showComplementary={false}
                        />
                        <p className="text-center text-sm text-gray-500 mt-4">
                          {t("Nemáte účet?")}{" "}
                          <button
                            className="font-semibold leading-6 text-primary"
                            onClick={() => toggleForm("register")}
                          >
                            {t("Registrujte sa")}
                          </button>
                        </p>
                      </>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </>
          )}
        </ModalBody>
        <ModalFooter className="flex flex-row justify-center">
          {isAuthenticated ? (
            <>
              <Button color="primary" onPress={confirmLeave}>
                {t("Rozumiem, chcem ísť do obchodu")}
              </Button>
              {/* <Button color="danger" variant="light" onPress={closeModal}>
                {t("Zrušiť")}
              </Button> */}
            </>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LeaveModal;
