const categories_list = [
  "Auto-moto",
  "Auto-moto / Náplne a kvapaliny",
  "Auto-moto / Náplne a kvapaliny / Autochémia",
  "Auto-moto / Náplne a kvapaliny / Mazivá",
  "Auto-moto / Náplne a kvapaliny / Prevádzkové kvapaliny",
  "Auto-moto / Náplne a kvapaliny / Aditíva",
  "Auto-moto / Elektromobilita",
  "Auto-moto / Elektromobilita / Príslušenstvo pre elektromobily",
  "Auto-moto / Pneumatiky",
  "Auto-moto / Všetko pre autá",
  "Auto-moto / Všetko pre autá / Autodoplnky",
  "Auto-moto / Všetko pre autá / Autodoplnky / Autodoplnky do interiéru",
  "Auto-moto / Všetko pre autá / Autodoplnky / Povinná a odporúčaná výbava",
  "Auto-moto / Všetko pre autá / Tuning",
  "Auto-moto / Všetko pre autá / Autodiely",
  "Auto-moto / Všetko pre autá / Autodiely / Svetlá na auto",
  "Auto-moto / Všetko pre autá / Autodiely / Diely podvozku",
  "Auto-moto / Všetko pre autá / Autodiely / Diely podvozku / Diely k autokolesám",
  "Auto-moto / Všetko pre autá / Autodiely / Brzdný systém auta",
  "Auto-moto / Všetko pre autá / Autodiely / Diely karosérie",
  "Auto-moto / Všetko pre autá / Autodiely / Diely karosérie / Dvere od auta",
  "Auto-moto / Všetko pre autá / Autodiely / Diely interiéru",
  "Auto-moto / Všetko pre autá / Autodiely / Diely v motorovom priestore",
  "Auto-moto / Všetko pre autá / Autodiely / Diely v motorovom priestore / Filtre do auta",
  "Auto-moto / Všetko pre autá / Autodiely / Diely v motorovom priestore / Zapaľovanie do áut",
  "Auto-moto / Všetko pre autá / Autodiely / Výfukový systém",
  "Auto-moto / Všetko pre autá / Autokozmetika",
  "Auto-moto / Všetko pre autá / Autokozmetika / Starostlivosť o lak",
  "Auto-moto / Všetko pre autá / Disky na auto",
  "Auto-moto / Všetko pre autá / Elektronika do auta",
  "Auto-moto / Všetko pre autá / Elektronika do auta / GPS",
  "Auto-moto / Všetko pre autá / Elektronika do auta / Auto Hi-Fi",
  "Auto-moto / Všetko pre autá / Nosiče, boxy a koše na auto",
  "Auto-moto / Všetko pre autá / Nosiče, boxy a koše na auto / Nosiče bicyklov na auto",
  "Auto-moto / Všetko pre autá / Nosiče, boxy a koše na auto / Boxy na auto",
  "Auto-moto / Všetko pre autá / Nosiče, boxy a koše na auto / Nosiče lyží a snowboardov",
  "Auto-moto / Všetko pre motorky",
  "Auto-moto / Všetko pre motorky / Príslušenstvo na motorku",
  "Auto-moto / Všetko pre motorky / Príslušenstvo na motorku / Batožiny na motocykel",
  "Auto-moto / Všetko pre motorky / Náhradné diely pre motorky",
  "Auto-moto / Všetko pre motorky / Náhradné diely pre motorky / Moto svetlá",
  "Auto-moto / Všetko pre motorky / Náhradné diely pre motorky / Diely motora pre motorku",
  "Auto-moto / Všetko pre motorky / Náhradné diely pre motorky / Súčiastky prevodoviek pre motorku",
  "Auto-moto / Všetko pre motorky / Náhradné diely pre motorky / Páčky a páky na motorku",
  "Auto-moto / Všetko pre motorky / Náhradné diely pre motorky / Súčiastky spojky pre motorku",
  "Auto-moto / Všetko pre motorky / Náhradné diely pre motorky / Moto brzdy",
  "Auto-moto / Všetko pre motorky / Oblečenie na motorku",
  "Bývanie a doplnky",
  "Bývanie a doplnky / Bytové doplnky",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do spálne",
  "Bývanie a doplnky / Bytové doplnky / Kúpeľňové doplnky",
  "Bývanie a doplnky / Bytové doplnky / Kúpeľňové doplnky / Doplnky k vaniam a sprchovacím kútom",
  "Bývanie a doplnky / Bytové doplnky / Kúpeľňové doplnky / Doplnky k umývadlám",
  "Bývanie a doplnky / Bytové doplnky / Kúpeľňové doplnky / Doplnky k WC",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do predsiene",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Stolovanie",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Varenie",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Pečenie",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Skladovanie a balenie potravín",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Kuchynské náčinia",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Kuchynské náčinia / Kuchynské nože a príslušenstvo",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Kuchynské náčinia / Krájače",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Kuchynské náčinia / Strúhadlá, lisy a lúpače",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Kuchynské náčinia / Otvárače a vývrtky",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Kuchynské náčinia / Varešky, metličky a naberačky",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Kuchynské náčinia / Sitá a cedidlá",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Kuchynské náčinia / Meranie a váženie",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Organizácia kuchyne",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kuchyne / Zaváranie",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do obývacej izby",
  "Bývanie a doplnky / Bytové doplnky / Vešiaky a stojany na oblečenie",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do kancelárie",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do detskej izby",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do detskej izby / Detské úložné priestory",
  "Bývanie a doplnky / Bytové doplnky / Doplnky do detskej izby / Hranie a tvorenie",
  "Bývanie a doplnky / Bytové doplnky / Záhradné doplnky",
  "Bývanie a doplnky / Bytové doplnky / Záhradné doplnky / Záhradné domčeky, altány, pergoly",
  "Bývanie a doplnky / Bytové doplnky / Záhradné doplnky / Záhradné slnečníky a tieniace vybavenie",
  "Bývanie a doplnky / Bytové doplnky / Záhradné doplnky / Hojdacie siete a príslušenstvo",
  "Bývanie a doplnky / Nábytok",
  "Bývanie a doplnky / Nábytok / Detský nábytok",
  "Bývanie a doplnky / Nábytok / Detský nábytok / Detské postele a postieľky",
  "Bývanie a doplnky / Nábytok / Detský nábytok / Detské stoličky a stúpadlá",
  "Bývanie a doplnky / Nábytok / Detský nábytok / Detské stoly",
  "Bývanie a doplnky / Nábytok / Záhradný nábytok",
  "Bývanie a doplnky / Nábytok / Záhradný nábytok / Záhradné hojdačky a príslušenstvo",
  "Bývanie a doplnky / Nábytok / Skrine a komody",
  "Bývanie a doplnky / Nábytok / Kuchyňa",
  "Bývanie a doplnky / Nábytok / Kuchyňa / Kuchynský nábytok",
  "Bývanie a doplnky / Nábytok / Kuchyňa / Kuchynské drezy a batérie",
  "Bývanie a doplnky / Nábytok / Sedacie súpravy a pohovky",
  "Bývanie a doplnky / Nábytok / Kreslá a sedenie",
  "Bývanie a doplnky / Nábytok / Nábytkové zostavy",
  "Bývanie a doplnky / Nábytok / Postele a rošty",
  "Bývanie a doplnky / Nábytok / Stoly a stolíky",
  "Bývanie a doplnky / Nábytok / Stoličky a stolčeky",
  "Bývanie a doplnky / Nábytok / Lavice a lavičky",
  "Bývanie a doplnky / Nábytok / Úložné priestory",
  "Bývanie a doplnky / Nábytok / Matrace a toppery",
  "Bývanie a doplnky / Sanita a vybavenie kúpeľní",
  "Bývanie a doplnky / Sanita a vybavenie kúpeľní / Vane a príslušenstvo",
  "Bývanie a doplnky / Sanita a vybavenie kúpeľní / Sprchy a príslušenstvo",
  "Bývanie a doplnky / Sanita a vybavenie kúpeľní / Sprchy a príslušenstvo / Príslušenstvo k sprchovacím kútom",
  "Bývanie a doplnky / Sanita a vybavenie kúpeľní / Umývadlá a príslušenstvo",
  "Bývanie a doplnky / Sanita a vybavenie kúpeľní / WC a príslušenstvo",
  "Bývanie a doplnky / Osvetlenie",
  "Bývanie a doplnky / Osvetlenie / Detské osvetlenie",
  "Bývanie a doplnky / Osvetlenie / Dekoratívne osvetlenie",
  "Bývanie a doplnky / Osvetlenie / Orientačné osvetlenie",
  "Bývanie a doplnky / Osvetlenie / Príslušenstvo k svietidlám",
  "Bývanie a doplnky / Osvetlenie / Príslušenstvo k svietidlám / Príslušenstvo k LED pásikom",
  "Bývanie a doplnky / Bytové dekorácie",
  "Bývanie a doplnky / Bytové dekorácie / Hodiny a budíky",
  "Bývanie a doplnky / Bytové dekorácie / Sviatočné dekorácie",
  "Bývanie a doplnky / Bytové dekorácie / Sviatočné dekorácie / Vianoce",
  "Bývanie a doplnky / Bytové dekorácie / Kvety a stojany",
  "Bývanie a doplnky / Bytové dekorácie / Vône do bytu a sviečky",
  "Bývanie a doplnky / Bytové dekorácie / Vône do bytu a sviečky / Arómaterapia",
  "Bývanie a doplnky / Bytové dekorácie / Vône do bytu a sviečky / Príslušenstvo pre aróma lampy",
  "Bývanie a doplnky / Bytové dekorácie / Nástenné dekorácie",
  "Bývanie a doplnky / Bytové dekorácie / Detské dekorácie",
  "Bývanie a doplnky / Bytový textil",
  "Bývanie a doplnky / Bytový textil / Lôžkoviny a textil do spálne",
  "Bývanie a doplnky / Bytový textil / Lôžkoviny a textil do spálne / Posteľná bielizeň",
  "Bývanie a doplnky / Bytový textil / Bytový textil do chodby",
  "Bývanie a doplnky / Bytový textil / Bytový textil do obývacej izby",
  "Bývanie a doplnky / Bytový textil / Bytový textil do kuchyne",
  "Bývanie a doplnky / Bytový textil / Bytový textil do kuchyne / Obrusy a prestieranie",
  "Bývanie a doplnky / Bytový textil / Bytový textil do kúpeľne",
  "Bývanie a doplnky / Bytový textil / Bytový textil do detskej izby",
  "Bývanie a doplnky / Bytový textil / Bytový textil do detskej izby / Bytový textil pre bábätká",
  "Bývanie a doplnky / Obývacia izba",
  "Bývanie a doplnky / Obývacia izba / Stolíky do obývacej izby",
  "Bývanie a doplnky / Spálne",
  "Bývanie a doplnky / Chodba",
  "Bývanie a doplnky / Domáce potreby a upratovanie",
  "Bývanie a doplnky / Domáce potreby a upratovanie / Pranie a žehlenie",
  "Detský tovar",
  "Detský tovar / Dojčenské potreby",
  "Detský tovar / Dojčenské potreby / Cumlíky a doplnky",
  "Detský tovar / Cestovanie s deťmi",
  "Detský tovar / Cestovanie s deťmi / Doplnky k autosedačkám",
  "Detský tovar / Cestovanie s deťmi / Nosenie detí",
  "Detský tovar / Cestovanie s deťmi / Detské kočíky a príslušenstvo",
  "Detský tovar / Detská výživa a kŕmenie",
  "Detský tovar / Detská výživa a kŕmenie / Detská výživa",
  "Detský tovar / Detská výživa a kŕmenie / Detská výživa / Detské nápoje",
  "Detský tovar / Detská výživa a kŕmenie / Detské fľaše na pitie",
  "Detský tovar / Detská výživa a kŕmenie / Doplnky pre dojčenské fľaše",
  "Detský tovar / Prebaľovanie",
  "Detský tovar / Prebaľovanie / Koše na plienky a vrecká",
  "Detský tovar / Kúpanie detí",
  "Detský tovar / Kúpanie detí / Detské osušky",
  "Detský tovar / Vybavenie detskej izby",
  "Detský tovar / Vybavenie detskej izby / Detské spacie vaky a zavinovačky",
  "Detský tovar / Vybavenie detskej izby / Detské pestúnky a monitory dychu",
  "Detský tovar / Vybavenie detskej izby / Hracie deky, podložky a hrazdičky",
  "Detský tovar / Pre matku",
  "Detský tovar / Pre matku / Dojčenie",
  "Dielňa, stavba, záhrada",
  "Dielňa, stavba, záhrada / Záhrada",
  "Dielňa, stavba, záhrada / Záhrada / Zavlažovanie",
  "Dielňa, stavba, záhrada / Záhrada / Jazierka a doplnky",
  "Dielňa, stavba, záhrada / Záhrada / Jazierka a doplnky / Starostlivosť o jazierka",
  "Dielňa, stavba, záhrada / Záhrada / Záhradné plachty, fólie a textílie",
  "Dielňa, stavba, záhrada / Záhrada / Starostlivosť o rastliny a pestovanie rastlín",
  "Dielňa, stavba, záhrada / Záhrada / Starostlivosť o rastliny a pestovanie rastlín / Starostlivosť o rastliny",
  "Dielňa, stavba, záhrada / Záhrada / Saunovanie",
  "Dielňa, stavba, záhrada / Záhrada / Saunovanie / Textil do sauny",
  "Dielňa, stavba, záhrada / Záhrada / Záhradný nábytok a ďalšie vybavenie",
  "Dielňa, stavba, záhrada / Záhrada / Záhradná technika",
  "Dielňa, stavba, záhrada / Záhrada / Záhradná technika / Príslušenstvo k záhradnej technike",
  "Dielňa, stavba, záhrada / Záhrada / Záhradné grily, krby a udiarne",
  "Dielňa, stavba, záhrada / Záhrada / Záhradné grily, krby a udiarne / Príslušenstvo k záhradným grilom",
  "Dielňa, stavba, záhrada / Záhrada / Záhradné osvetlenie",
  "Dielňa, stavba, záhrada / Dielňa",
  "Dielňa, stavba, záhrada / Dielňa / Ochranné pomôcky",
  "Dielňa, stavba, záhrada / Dielňa / Obrábanie",
  "Dielňa, stavba, záhrada / Dielňa / Obrábanie / Sústruhy a príslušenstvo",
  "Dielňa, stavba, záhrada / Dielňa / Dielenské vybavenie",
  "Dielňa, stavba, záhrada / Dielňa / Dielenské vybavenie / Dielenský nábytok",
  "Dielňa, stavba, záhrada / Dielňa / Dielenské vybavenie / Hasiace prístroje a hydranty",
  "Dielňa, stavba, záhrada / Dielňa / Dielenské vybavenie / Manipulačná technika",
  "Dielňa, stavba, záhrada / Dielňa / Dielenské vybavenie / Upratovacia technika",
  "Dielňa, stavba, záhrada / Dielňa / Dielenské vybavenie / Bezpečnostné značenie",
  "Dielňa, stavba, záhrada / Dielňa / Pneumatické zariadenia",
  "Dielňa, stavba, záhrada / Dielňa / Pneumatické zariadenia / Kompresory a príslušenstvo",
  "Dielňa, stavba, záhrada / Dielňa / Zváranie",
  "Dielňa, stavba, záhrada / Dielňa / Spájkovanie",
  "Dielňa, stavba, záhrada / Domácnosť",
  "Dielňa, stavba, záhrada / Bazény a doplnky",
  "Dielňa, stavba, záhrada / Bazény a doplnky / Čistenie bazénov",
  "Dielňa, stavba, záhrada / Bazény a doplnky / Doplnky k bazénom",
  "Dielňa, stavba, záhrada / Stavba",
  "Dielňa, stavba, záhrada / Stavba / Farby a laky",
  "Dielňa, stavba, záhrada / Stavba / Farby a laky / Farby, laky na drevo",
  "Dielňa, stavba, záhrada / Stavba / Tepelná izolácia",
  "Dielňa, stavba, záhrada / Stavba / Tepelná izolácia / Izolačné materiály",
  "Dielňa, stavba, záhrada / Stavba / Ploty",
  "Dielňa, stavba, záhrada / Stavba / Schody a zábradlia",
  "Dielňa, stavba, záhrada / Stavba / Voda, plyn, kúrenie",
  "Dielňa, stavba, záhrada / Stavba / Voda, plyn, kúrenie / Kanalizácie",
  "Dielňa, stavba, záhrada / Stavba / Voda, plyn, kúrenie / Potreby pre inštalatérov",
  "Dielňa, stavba, záhrada / Stavba / Fotovoltaika",
  "Dielňa, stavba, záhrada / Stavba / Garáže a vráta",
  "Dielňa, stavba, záhrada / Stavba / Stavebný materiál",
  "Dielňa, stavba, záhrada / Stavba / Stavebný materiál / Stavebná chémia",
  "Dielňa, stavba, záhrada / Stavba / Stavebný materiál / Drevené dosky",
  "Dielňa, stavba, záhrada / Stavba / Strechy a komíny",
  "Dielňa, stavba, záhrada / Stavba / Strechy a komíny / Strešné okná a doplnky",
  "Dielňa, stavba, záhrada / Stavba / Odvodnenie a drenáže",
  "Dielňa, stavba, záhrada / Stavba / Železiarstvo",
  "Dielňa, stavba, záhrada / Stavba / Železiarstvo / Spojovací materiál",
  "Dielňa, stavba, záhrada / Stavba / Železiarstvo / Spojovací materiál / Hmoždinky",
  "Dielňa, stavba, záhrada / Stavba / Železiarstvo / Spojovací materiál / Matice",
  "Dielňa, stavba, záhrada / Stavba / Železiarstvo / Spojovací materiál / Podložky",
  "Dielňa, stavba, záhrada / Stavba / Železiarstvo / Spojovací materiál / Skrutky",
  "Dielňa, stavba, záhrada / Stavba / Železiarstvo / Spojovací materiál / Vruty",
  "Dielňa, stavba, záhrada / Stavba / Železiarstvo / Kovanie a zámky",
  "Dielňa, stavba, záhrada / Stavba / Podlahy a obklady",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Stavebná technika",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Stavebná technika / Betónovanie",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Stavebná technika / Hutnenie a vibrovanie",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Stavebná technika / Preprava materiálu",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Stavebná technika / Rezanie, zváranie, vŕtanie",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Murárske náradie",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Meradlá a meracie prístroje",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Meradlá a meracie prístroje / Detektory, senzory",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Meradlá a meracie prístroje / Meranie roviny",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Meradlá a meracie prístroje / Meranie teploty a vlhkosti",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Meradlá a meracie prístroje / Meranie uhlov",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Meradlá a meracie prístroje / Meranie napätia",
  "Dielňa, stavba, záhrada / Stavba / Stavebná technika a náradie / Meradlá a meracie prístroje / Meranie vzdialenosti",
  "Dielňa, stavba, záhrada / Stavba / Dvere, okná",
  "Dielňa, stavba, záhrada / Stavba / Dvere, okná / Dvere a zárubne",
  "Dielňa, stavba, záhrada / Stavba / Dvere, okná / Dvere a zárubne / Dvere",
  "Dielňa, stavba, záhrada / Stavba / Dvere, okná / Okná a parapety",
  "Dielňa, stavba, záhrada / Stavba / Elektromateriály",
  "Dielňa, stavba, záhrada / Stavba / Elektromateriály / Elektroinštalačný materiál",
  "Dielňa, stavba, záhrada / Stavba / Elektromateriály / Elektroinštalačný materiál / Centrálne vysávania",
  "Dielňa, stavba, záhrada / Stavba / Elektromateriály / Elektroinštalačný materiál / Ističe, poistky, rozvádzače",
  "Dielňa, stavba, záhrada / Stavba / Elektromateriály / Elektroinštalačný materiál / Kabeláž",
  "Dielňa, stavba, záhrada / Stavba / Elektromateriály / Elektroinštalačný materiál / Zásuvky, vypínače, termostaty",
  "Dielňa, stavba, záhrada / Stavba / Elektromateriály / Domová komunikácia",
  "Dielňa, stavba, záhrada / Kúrenie a ohrev vody",
  "Dielňa, stavba, záhrada / Kúrenie a ohrev vody / Kúrenie",
  "Dielňa, stavba, záhrada / Kúrenie a ohrev vody / Kúrenie / Krby a kachle",
  "Dielňa, stavba, záhrada / Kúrenie a ohrev vody / Kúrenie / Radiátory a príslušenstvo",
  "Dielňa, stavba, záhrada / Kúrenie a ohrev vody / Odvod spalín",
  "Dielňa, stavba, záhrada / Kúrenie a ohrev vody / Ohrev vody",
  "Dielňa, stavba, záhrada / Kúrenie a ohrev vody / Podpaľovače a tuhé palivá",
  "Dielňa, stavba, záhrada / Kúrenie a ohrev vody / Ohrievače",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Ručné náradie",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Ručné náradie / Gola sady a príslušenstvo",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Elektrické náradie",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Elektrické náradie / Kladivá",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Elektrické náradie / Frézy a frézky",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Elektrické náradie / Píly",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Elektrické náradie / Brúsky a príslušenstvo",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Elektrické náradie / Vŕtačky a príslušenstvo",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Elektrické náradie / Mikronáradie a príslušenstvo",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Elektrické náradie / Elektrické dielenské pištole",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Akumulátorové náradie",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Akumulátorové náradie / Batérie a nabíjačky k aku náradiu",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Pneumatické náradie",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Náradie do dielne / Pneumatické náradie / Pneumatické pištole a príslušenstvo",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Záhradné náradie a technika",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Záhradné náradie a technika / Záhradné náradie",
  "Dielňa, stavba, záhrada / Náradie pre dielňu a záhradu / Záhradné náradie a technika / Záhradné náradie / Záhradnícke nožnice",
  "Domáce a osobné spotrebiče",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príslušenstvo ku kuchynským spotrebičom",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príslušenstvo ku kuchynským spotrebičom / Príslušenstvo ku kuchynským robotom",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príslušenstvo ku kuchynským spotrebičom / Príslušenstvo pre mixéry a šľahače",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príprava jedla",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príprava jedla / Malé kuchynské spotrebiče",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príprava jedla / Malé kuchynské spotrebiče / Krájanie a strúhanie",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príprava jedla / Malé kuchynské spotrebiče / Mixovanie a šľahanie",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príprava jedla / Malé kuchynské spotrebiče / Pečenie a opekanie",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príprava jedla / Malé kuchynské spotrebiče / Varenie a vyprážanie",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príprava jedla / Malé kuchynské spotrebiče / Vychytávky do kuchyne",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príprava nápojov",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príprava nápojov / Kávovary a príprava kávy",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Príprava nápojov / Kávovary a príprava kávy / Príslušenstvo ku kávovarom",
  "Domáce a osobné spotrebiče / Kuchynské spotrebiče / Filtrácia vody",
  "Domáce a osobné spotrebiče / Starostlivosť o telo a zdravie",
  "Domáce a osobné spotrebiče / Starostlivosť o telo a zdravie / Starostlivosť o telo",
  "Domáce a osobné spotrebiče / Starostlivosť o telo a zdravie / Starostlivosť o zdravie",
  "Domáce a osobné spotrebiče / Starostlivosť o telo a zdravie / Starostlivosť o zdravie / Elektrické zubné kefky a príslušenstvo",
  "Domáce a osobné spotrebiče / Starostlivosť o telo a zdravie / Starostlivosť o zdravie / Ústne sprchy a príslušenstvo",
  "Domáce a osobné spotrebiče / Starostlivosť o telo a zdravie / Starostlivosť o vlasy a fúzy",
  "Domáce a osobné spotrebiče / Starostlivosť o telo a zdravie / Starostlivosť o vlasy a fúzy / Príslušenstvo pre starostlivosť o vlasy a fúzy",
  "Domáce a osobné spotrebiče / Chladenie a kúrenie",
  "Domáce a osobné spotrebiče / Starostlivosť o bielizeň",
  "Domáce a osobné spotrebiče / Starostlivosť o bielizeň / Šijacie stroje a príslušenstvo",
  "Domáce a osobné spotrebiče / Upratovacie spotrebiče",
  "Domáce a osobné spotrebiče / Upratovacie spotrebiče / Príslušenstvo pre upratovacie spotrebiče",
  "Domáce a osobné spotrebiče / Úprava vzduchu",
  "Drogéria",
  "Drogéria / Pranie bielizne",
  "Drogéria / Pranie bielizne / Pracie prostriedky",
  "Drogéria / Čistiace prostriedky",
  "Drogéria / Čistiace prostriedky / Prostriedky na umývanie riadu",
  "Drogéria / Čistiace prostriedky / Leštiace prostriedky",
  "Drogéria / Upratovacie pomôcky",
  "Drogéria / Ekologická domácnosť",
  "Drogéria / Prípravky proti hmyzu a škodcom",
  "Elektronika",
  "Elektronika / TV, audio, video",
  "Elektronika / TV, audio, video / Projekčná technika",
  "Elektronika / TV, audio, video / Projekčná technika / Príslušenstvo k projektorom",
  "Elektronika / TV, audio, video / Videoprehrávače a rekordéry",
  "Elektronika / TV, audio, video / Prenosné audio",
  "Elektronika / TV, audio, video / Hi-Fi komponenty",
  "Elektronika / TV, audio, video / Satelitná a digitálna technika",
  "Elektronika / TV, audio, video / 3D technológie",
  "Elektronika / TV, audio, video / Reproduktory, soundbary",
  "Elektronika / TV, audio, video / Reproduktory, soundbary / Príslušenstvo k reproduktorom",
  "Elektronika / TV, audio, video / Príslušenstvo k TV, audio-video",
  "Elektronika / TV, audio, video / Príslušenstvo k TV, audio-video / Audio príslušenstvo",
  "Elektronika / TV, audio, video / Príslušenstvo k TV, audio-video / Audio príslušenstvo / Príslušenstvo pre gramofóny",
  "Elektronika / TV, audio, video / Príslušenstvo k TV, audio-video / Audio príslušenstvo / Príslušenstvo k slúchadlám",
  "Elektronika / TV, audio, video / Príslušenstvo k TV, audio-video / Príslušenstvo k TV",
  "Elektronika / TV, audio, video / Príslušenstvo k TV, audio-video / Príslušenstvo k TV / Antény",
  "Elektronika / TV, audio, video / Príslušenstvo k TV, audio-video / Príslušenstvo k TV / Držiaky a stolíky",
  "Elektronika / TV, audio, video / DJ technika",
  "Elektronika / Mobily, tablety, nositeľná elektronika",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Príslušenstvo k mobilom",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Príslušenstvo k mobilom / Náhradné diely pre mobilné telefóny",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Príslušenstvo k mobilom / Držiaky a statívy",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Príslušenstvo k mobilom / Batérie a nabíjačky pre mobily",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Príslušenstvo k mobilom / Slúchadlá, reproduktory, handsfree",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Príslušenstvo k mobilom / Puzdrá, sklá, fólie",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Príslušenstvo k mobilom / Puzdrá, sklá, fólie / Puzdrá a kryty na mobilné telefóny",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Klasické a bezdrôtové telefóny",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Tablety a čítačky",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Tablety a čítačky / Príslušenstvo k tabletom",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Tablety a čítačky / Príslušenstvo k čítačkám kníh",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Vysielačky, príslušenstvo",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Príslušenstvo k nositeľnej elektronike",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Príslušenstvo k nositeľnej elektronike / Príslušenstvo k inteligentným hodinkám",
  "Elektronika / Mobily, tablety, nositeľná elektronika / Príslušenstvo k nositeľnej elektronike / Príslušenstvo k inteligentným náramkom",
  "Elektronika / Počítače a notebooky",
  "Elektronika / Počítače a notebooky / Software",
  "Elektronika / Počítače a notebooky / Príslušenstvo k počítačom",
  "Elektronika / Počítače a notebooky / Príslušenstvo k počítačom / Káble a konektory",
  "Elektronika / Počítače a notebooky / Počítačové komponenty",
  "Elektronika / Počítače a notebooky / Počítačové komponenty / Rozširujúce karty pre počítač",
  "Elektronika / Počítače a notebooky / Tlačiarne a príslušenstvo",
  "Elektronika / Počítače a notebooky / Tlačiarne a príslušenstvo / Náplne a farby do tlačiarní",
  "Elektronika / Počítače a notebooky / Sieťové prvky",
  "Elektronika / Počítače a notebooky / Sieťové prvky / Servery a príslušenstvá",
  "Elektronika / Počítače a notebooky / Klávesnice a myši",
  "Elektronika / Počítače a notebooky / Príslušenstvo k notebookom",
  "Elektronika / Počítače a notebooky / Záložné zdroje",
  "Elektronika / Inteligentná domácnosť",
  "Elektronika / Inteligentná domácnosť / Wearables",
  "Elektronika / Inteligentná domácnosť / Zabezpečenie inteligentnej domácnosti",
  "Elektronika / Inteligentná domácnosť / Inteligentné osvetlenie",
  "Elektronika / Foto, kamery, optika",
  "Elektronika / Foto, kamery, optika / Foto a kamery",
  "Elektronika / Foto, kamery, optika / Príslušenstvo k fotoaparátom",
  "Elektronika / Foto, kamery, optika / Príslušenstvo k fotoaparátom / Statívy a príslušenstvo",
  "Elektronika / Foto, kamery, optika / Príslušenstvo k fotoaparátom / Objektívy a doplnky",
  "Elektronika / Foto, kamery, optika / Príslušenstvo k fotoaparátom / Rámčeky, albumy, fotopapiere",
  "Elektronika / Foto, kamery, optika / Príslušenstvo k fotoaparátom / Blesky a príslušenstvo",
  "Elektronika / Foto, kamery, optika / Príslušenstvo k fotoaparátom / Batérie a nabíjačky pre foto a video",
  "Elektronika / Foto, kamery, optika / Príslušenstvo k fotoaparátom / Tašky, puzdrá, batohy",
  "Elektronika / Foto, kamery, optika / Príslušenstvo k fotoaparátom / Diaľkové spúšte a káble",
  "Elektronika / Foto, kamery, optika / Ateliérové vybavenie",
  "Elektronika / Foto, kamery, optika / Príslušenstvo ku kamerám",
  "Elektronika / Foto, kamery, optika / Fotokomory",
  "Elektronika / Foto, kamery, optika / Fotokomory / Vybavenie fotokomory",
  "Elektronika / Foto, kamery, optika / Ďalekohľady, mikroskopy",
  "Elektronika / Foto, kamery, optika / Drony a príslušenstvo",
  "Elektronika / Batérie a nabíjanie",
  "Elektronika / Batérie a nabíjanie / Batérie",
  "Elektronika / Batérie a nabíjanie / Nabíjačky",
  "Elektronika / Domáce spotrebiče",
  "Elektronika / Domáce spotrebiče / Vybavenie domu",
  "Erotické pomôcky",
  "Erotické pomôcky / Erotická kozmetika a kondómy",
  "Erotické pomôcky / Erotická literatúra a filmy",
  "Erotické pomôcky / Erotické hry a hračky",
  "Erotické pomôcky / Vibrátory a dildá",
  "Erotické pomôcky / Erotické oblečenie, bielizeň a doplnky",
  "Erotické pomôcky / Erotické oblečenie, bielizeň a doplnky / Dámska erotická bielizeň",
  "Erotické pomôcky / BDSM a fetiš",
  "Erotické pomôcky / Análne hračky",
  "Erotické pomôcky / Erotické pomôcky pre ženy",
  "Erotické pomôcky / Erotické pomôcky pre mužov",
  "Erotické pomôcky / Erotický nábytok a doplnky",
  "Filmy, knihy, hry",
  "Filmy, knihy, hry / Spoločenské hry a zábava",
  "Filmy, knihy, hry / Spoločenské hry a zábava / Pohybové a športové hry",
  "Filmy, knihy, hry / Spoločenské hry a zábava / Šach, šachové figúrky a šachovnice",
  "Filmy, knihy, hry / Spoločenské hry a zábava / Poker",
  "Filmy, knihy, hry / Spoločenské hry a zábava / Logické a vedomostné hry",
  "Filmy, knihy, hry / Spoločenské hry a zábava / Stolové hry",
  "Filmy, knihy, hry / Čítanie",
  "Filmy, knihy, hry / Čítanie / Doplnky ku knihám a čítačkám",
  "Filmy, knihy, hry / Mapy, sprievodcovia a nástenné mapy",
  "Filmy, knihy, hry / Počúvanie",
  "Filmy, knihy, hry / Počúvanie / Hudobné nosiče",
  "Gaming",
  "Gaming / Herné príslušenstvo",
  "Gaming / Herné príslušenstvo / Príslušenstvo k herným konzolám",
  "Gaming / Herné príslušenstvo / Herné ovládače",
  "Gaming / Hry na PC a konzoly",
  "Gaming / Hry na PC a konzoly / Hry na PlayStation",
  "Gaming / Hry na PC a konzoly / Hry na Xbox",
  "Gaming / Hry na PC a konzoly / Hry na Nintendo",
  "Gaming / Virtuálna realita",
  "Hobby",
  "Hobby / Rybárske potreby",
  "Hobby / Rybárske potreby / Rybársky camping",
  "Hobby / Rybárske potreby / Rybárske oblečenie",
  "Hobby / Rybárske potreby / Rybárske návnady, nástrahy a príslušenstvo",
  "Hobby / Rybárske potreby / Rybárska bižutéria",
  "Hobby / Rybárske potreby / Rybárske navijaky a príslušenstvo",
  "Hobby / Rybárske potreby / Rybárske prúty a príslušenstvo",
  "Hobby / Rybárske potreby / Rybárske vlasce a lanká",
  "Hobby / Rybárske potreby / Vylovenie a starostlivosť o úlovok",
  "Hobby / Fajčiarske potreby",
  "Hobby / Fajčiarske potreby / Vodné fajky a príslušenstvo",
  "Hobby / Fajčiarske potreby / Tabakové výrobky a príslušenstvo",
  "Hobby / Fajčiarske potreby / Tabakové výrobky a príslušenstvo / Cigarety a príslušenstvo",
  "Hobby / Fajčiarske potreby / Tabakové výrobky a príslušenstvo / Cigary a príslušenstvo",
  "Hobby / Fajčiarske potreby / Tabakové výrobky a príslušenstvo / Fajky a príslušenstvo",
  "Hobby / Fajčiarske potreby / Elektronické cigarety",
  "Hobby / Fajčiarske potreby / Elektronické cigarety / Zahrievaný tabak a príslušenstvo",
  "Hobby / Fajčiarske potreby / Elektronické cigarety / Zahrievaný tabak a príslušenstvo / Náplne do nahrievaných zariadení",
  "Hobby / Fajčiarske potreby / Elektronické cigarety / E-cigarety",
  "Hobby / Fajčiarske potreby / Elektronické cigarety / E-cigarety / Komponenty pre zostavenie e-cigariet",
  "Hobby / Zberateľstvo",
  "Hobby / Investičné kovy",
  "Hobby / Športové ocenenia a vlajky",
  "Hobby / Galantéria a metráž",
  "Hobby / Galantéria a metráž / Krajčírske potreby",
  "Hobby / Galantéria a metráž / Textilná galantéria",
  "Hobby / Galantéria a metráž / Textilná galantéria / Tvrdá galantéria",
  "Hobby / Galantéria a metráž / Textilná galantéria / Tvrdá galantéria / Pletacie ihlice a háčiky",
  "Hobby / Galantéria a metráž / Textilná galantéria / Tvrdá galantéria / Šijacie ihly a špendlíky",
  "Hobby / Galantéria a metráž / Textilná galantéria / Záclonová galantéria",
  "Hobby / Galantéria a metráž / Textilná galantéria / Podprsenkové a plavkové polotovary",
  "Hobby / Galantéria a metráž / Textilná galantéria / Mäkká galantéria",
  "Hobby / Prírodné produkty",
  "Hobby / Prírodné produkty / Konopné produkty",
  "Hobby / Hudobniny",
  "Hobby / Hudobniny / Hudobné nástroje",
  "Hobby / Hudobniny / Hudobné nástroje / Bicie nástroje",
  "Hobby / Hudobniny / Hudobné nástroje / Bicie nástroje / Bicie súpravy",
  "Hobby / Hudobniny / Hudobné nástroje / Bicie nástroje / Paličky a metličky",
  "Hobby / Hudobniny / Hudobné nástroje / Bicie nástroje / Perkusie",
  "Hobby / Hudobniny / Hudobné nástroje / Dychové nástroje",
  "Hobby / Hudobniny / Hudobné nástroje / Dychové nástroje / Flauty",
  "Hobby / Hudobniny / Hudobné nástroje / Dychové nástroje / Hoboje a fagoty",
  "Hobby / Hudobniny / Hudobné nástroje / Dychové nástroje / Trúbky, kornety a krídlovky",
  "Hobby / Hudobniny / Hudobné nástroje / Klávesové nástroje",
  "Hobby / Hudobniny / Hudobné nástroje / Sláčikové nástroje",
  "Hobby / Hudobniny / Hudobné nástroje / Strunové nástroje",
  "Hobby / Hudobniny / Hudobné nástroje / Strunové nástroje / Gitary",
  "Hobby / Hudobniny / Hudobné nosiče a noty",
  "Hobby / Hudobniny / Zvuková technika",
  "Hobby / Hudobniny / Zvuková technika / Zvukové aparatúry a príslušenstvo",
  "Hobby / Pre domácich majstrov a záhradkárov",
  "Hobby / Zábava",
  "Hobby / Zábava / Párty a oslavy",
  "Hobby / Zábava / Párty a oslavy / Párty dekorácie",
  "Hobby / Zábava / Párty a oslavy / Kostýmy a rekvizity",
  "Hobby / Zábava / Svadobné doplnky",
  "Hobby / Zábava / Svadobné doplnky / Svadobná výzdoba a dekorácie",
  "Hobby / Zábava / Svadobné doplnky / Svadobné oznámenia a knihy hostí",
  "Hobby / Zábava / Originálne darčeky",
  "Hobby / Zábava / Originálne darčeky / Žartovné predmety a Gadgets",
  "Hobby / Zábava / Balenie darčekov a darčekové potreby",
  "Hračky",
  "Hračky / Stavebnice a kocky",
  "Hračky / Hračky na záhradu",
  "Hračky / Hračky na záhradu / Detské vozítka a príslušenstvo",
  "Hračky / Hračky na záhradu / Pieskoviská a doplnky",
  "Hračky / Hračky na záhradu / Detské ihriská a preliezky",
  "Hračky / Hračky na záhradu / Zimné radovánky",
  "Hračky / Hračky na záhradu / Vodné radovánky",
  "Hračky / Hračky na záhradu / Vonkajšie športové hry",
  "Hračky / Hry na profesie a napodobňovanie",
  "Hračky / Hry na profesie a napodobňovanie / Detské kuchynky a príslušenstvo",
  "Hračky / Hry na profesie a napodobňovanie / Detské obchodíky a príslušenstvo",
  "Hračky / Bábiky a doplnky pre bábiky",
  "Hračky / Plyšové hračky",
  "Hračky / Detské zbrane a príslušenstvo",
  "Hračky / Detské autá a doprava",
  "Hračky / Detské autá a doprava / Autodráhy",
  "Hračky / Detské autá a doprava / Vláčiky pre deti",
  "Hračky / Detské autá a doprava / Vláčiky pre deti / Modelové vláčiky",
  "Hračky / Detské autá a doprava / RC modely a príslušenstvo",
  "Hračky / Interaktívne a robotické hračky",
  "Hračky / Motorické a didaktické hračky",
  "Hračky / Bábky a bábkové divadlá",
  "Hračky / Hrkálky a hryzadlá",
  "Hračky / Kreatívne hračky a tvorenie pre deti",
  "Chovateľské potreby",
  "Chovateľské potreby / Akvaristika",
  "Chovateľské potreby / Akvaristika / Doplnky do akvárií",
  "Chovateľské potreby / Akvaristika / Starostlivosť a údržba akvária",
  "Chovateľské potreby / Akvaristika / Príslušenstvo k akváriám",
  "Chovateľské potreby / Akvaristika / Vybavenie a nábytok k akváriám",
  "Chovateľské potreby / Akvaristika / Akváriová technika",
  "Chovateľské potreby / Akvaristika / Akváriová technika / Vzduchovanie do akvária",
  "Chovateľské potreby / Teráristika",
  "Chovateľské potreby / Teráristika / Terárijná technika",
  "Chovateľské potreby / Teráristika / Vybavenie terárií",
  "Chovateľské potreby / Pre mačky",
  "Chovateľské potreby / Pre mačky / Hygiena a kozmetika pre mačky",
  "Chovateľské potreby / Pre mačky / Mačacie toalety a podstielky",
  "Chovateľské potreby / Pre psov",
  "Chovateľské potreby / Pre psov / Pelechy, búdy a dvierka pre psov",
  "Chovateľské potreby / Pre psov / Cestovanie so psy",
  "Chovateľské potreby / Pre psov / Hygiena a kozmetika pre psov",
  "Chovateľské potreby / Pre psov / Hygiena a kozmetika pre psov / Starostlivosť o psiu srsť",
  "Chovateľské potreby / Pre psov / Obojky a vôdzky pre psov",
  "Chovateľské potreby / Pre psov / Výcvik a psie športy",
  "Chovateľské potreby / Pre psov / Výcvik a psie športy / Základné výcvikové pomôcky pre psov",
  "Chovateľské potreby / Pre psov / Krmivo pre psov",
  "Chovateľské potreby / Pre psov / Krmivo pre psov / Barf",
  "Chovateľské potreby / Pre vtáky",
  "Chovateľské potreby / Pre vtáky / Vybavenie klietok a voliér",
  "Chovateľské potreby / Pre kone",
  "Chovateľské potreby / Pre hlodavce",
  "Chovateľské potreby / Pre hlodavce / Hygiena a kozmetika pre hlodavce",
  "Chovateľské potreby / Pre hlodavce / Vybavenie klietok pre hlodavce",
  "Chovateľské potreby / Pre hospodárske zvieratá",
  "Chovateľské potreby / Pre hospodárske zvieratá / Domčeky a úkryty pre hospodárske zvieratá",
  "Chovateľské potreby / Pre hospodárske zvieratá / Potreby pre hospodárske zvieratá",
  "Chovateľské potreby / Pre hospodárske zvieratá / Potreby pre hospodárske zvieratá / Potreby pre hydinu",
  "Chovateľské potreby / Veterinárna lekáreň",
  "Chovateľské potreby / Veterinárna lekáreň / Antiparazitiká pre zvieratá",
  "Chovateľské potreby / Veterinárna lekáreň / Antiparazitiká pre zvieratá / Neveterinárne antiparazitiká",
  "Chovateľské potreby / Veterinárna lekáreň / Vitamíny a doplnky stravy pre zvieratá",
  "Jedlo a nápoje",
  "Jedlo a nápoje / Nealkoholické nápoje",
  "Jedlo a nápoje / Alkoholické nápoje",
  "Jedlo a nápoje / Čerstvé potraviny",
  "Jedlo a nápoje / Čerstvé potraviny / Maslá, margaríny a nátierky",
  "Jedlo a nápoje / Čerstvé potraviny / Údeniny a lahôdky",
  "Jedlo a nápoje / Trvanlivé potraviny",
  "Jedlo a nápoje / Trvanlivé potraviny / Slané snacky",
  "Jedlo a nápoje / Trvanlivé potraviny / Džemy, medy, čokokrémy",
  "Jedlo a nápoje / Trvanlivé potraviny / Cestoviny, ryža, strukoviny",
  "Jedlo a nápoje / Trvanlivé potraviny / Hotové a instantné jedlá",
  "Jedlo a nápoje / Trvanlivé potraviny / Hotové a instantné jedlá / Instantné jedlá a prílohy",
  "Jedlo a nápoje / Trvanlivé potraviny / Hotové a instantné jedlá / Instantné jedlá a prílohy / Instantné zmesi na pečenie",
  "Jedlo a nápoje / Trvanlivé potraviny / Konzervované potraviny",
  "Jedlo a nápoje / Trvanlivé potraviny / Prísady na varenie a pečenie",
  "Jedlo a nápoje / Trvanlivé potraviny / Omáčky, kečupy, horčice",
  "Jedlo a nápoje / Trvanlivé potraviny / Dia, racio, bio potraviny",
  "Jedlo a nápoje / Trvanlivé potraviny / Cukrárské suroviny",
  "Jedlo a nápoje / Trvanlivé potraviny / Korenie a ochucovadlá",
  "Jedlo a nápoje / Trvanlivé potraviny / Korenie a ochucovadlá / Korenie",
  "Jedlo a nápoje / Trvanlivé potraviny / Majonézy, tatárske omáčky a dresingy",
  "Jedlo a nápoje / Mrazené potraviny",
  "Jedlo a nápoje / Sladkosti",
  "Jedlo a nápoje / Čaje a bylinky",
  "Jedlo a nápoje / Káva a kávové kapsuly",
  "Jedlo a nápoje / Káva a kávové kapsuly / Káva",
  "Jedlo a nápoje / Rastlinné alternatívy",
  "Jedlo a nápoje / Instantné a energetické nápoje",
  "Jedlo a nápoje / Mliečne a chladené potraviny",
  "Jedlo a nápoje / Pekáreň a cukráreň",
  "Jedlo a nápoje / Pekáreň a cukráreň / Pečivo",
  "Jedlo a nápoje / Oriešky, sušené plody a semená",
  "Kancelária a papiernictvo",
  "Kancelária a papiernictvo / Kancelárske potreby",
  "Kancelária a papiernictvo / Kancelárske potreby / Organizácia a archivácia dokumentov",
  "Kancelária a papiernictvo / Kancelárske potreby / Korekčné a lepiace prostriedky",
  "Kancelária a papiernictvo / Kancelárske potreby / Vizuálna komunikácia",
  "Kancelária a papiernictvo / Kancelárske potreby / Pečiatky a príslušenstvo",
  "Kancelária a papiernictvo / Kancelárske potreby / Zošívačky a dierovače",
  "Kancelária a papiernictvo / Kancelárske potreby / Poznámkové bloky a diáre",
  "Kancelária a papiernictvo / Kancelárska technika a vybavenie prevádzok",
  "Kancelária a papiernictvo / Kancelárska technika a vybavenie prevádzok / Príslušenstvo kancelárskej techniky",
  "Kancelária a papiernictvo / Kancelárska technika a vybavenie prevádzok / Pokladničné systémy",
  "Kancelária a papiernictvo / Školské potreby",
  "Kancelária a papiernictvo / Školské potreby / Rysovacie potreby",
  "Kancelária a papiernictvo / Školské potreby / Školské zošity a doplnky",
  "Kancelária a papiernictvo / Papiernictvo",
  "Kancelária a papiernictvo / Papiernictvo / Písacie potreby a príslušenstvo",
  "Kancelária a papiernictvo / Papiernictvo / Písacie potreby a príslušenstvo / Kaligrafia",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Kreatívne tvorenie",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Kreatívne tvorenie / Keramika",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Kreatívne tvorenie / Maľovanie na textil a hodváb",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Kreatívne tvorenie / Decoupage",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Kreatívne tvorenie / Scrapbooking",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Kreatívne tvorenie / Batikovanie",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Kreatívne tvorenie / Vystrihovanie a lepenie",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Kreatívne tvorenie / Enkaustika",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Kreatívne tvorenie / Tvorenie zo živice",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Papiere na kreslenie a kartóny",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Ručné práce",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Ručné práce / Vyšívanie a pletenie",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Ručné práce / Pletenie z prútia",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Potreby na maľovanie",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Potreby na maľovanie / Maľovanie na plátno",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Potreby na maľovanie / Umelecké farby",
  "Kancelária a papiernictvo / Papiernictvo / Výtvarné a kreatívne potreby / Potreby na kreslenie",
  "Kancelária a papiernictvo / Papiernictvo / Priania a pohľadnice",
  "Kozmetika a parfumy",
  "Kozmetika a parfumy / Kozmetika",
  "Kozmetika a parfumy / Vlasy",
  "Kozmetika a parfumy / Vlasy / Starostlivosť o vlasy",
  "Kozmetika a parfumy / Vlasy / Farbenie vlasov",
  "Kozmetika a parfumy / Pleť",
  "Kozmetika a parfumy / Pleť / Starostlivosť o pleť",
  "Kozmetika a parfumy / Pleť / Odličovanie a čistenie pleti",
  "Kozmetika a parfumy / Pleť / Holenie a starostlivosť o fúzy",
  "Kozmetika a parfumy / Pleť / Holenie a starostlivosť o fúzy / Holenie",
  "Kozmetika a parfumy / Pleť / Holenie a starostlivosť o fúzy / Starostlivosť o fúzy",
  "Kozmetika a parfumy / Pleť / Holenie a starostlivosť o fúzy / Starostlivosť po holení",
  "Kozmetika a parfumy / Telo",
  "Kozmetika a parfumy / Telo / Depilácia a epilácia",
  "Kozmetika a parfumy / Telo / Sprcha a kúpeľ",
  "Kozmetika a parfumy / Telo / Strie a celulitída",
  "Kozmetika a parfumy / Telo / Starostlivosť o pokožku",
  "Kozmetika a parfumy / Dekoratívna kozmetika",
  "Kozmetika a parfumy / Dekoratívna kozmetika / Pery",
  "Kozmetika a parfumy / Dekoratívna kozmetika / Oči a obočie",
  "Kozmetika a parfumy / Dekoratívna kozmetika / Tvár",
  "Kozmetika a parfumy / Nechty",
  "Kozmetika a parfumy / Nechty / Nástroje a pomôcky na modeláciu nechtov",
  "Kozmetika a parfumy / Nechty / Prístroje na starostlivosť o nechty",
  "Kozmetika a parfumy / Zuby",
  "Kozmetika a parfumy / Zuby / Medzizubná starostlivosť",
  "Kozmetika a parfumy / Opaľovanie",
  "Kozmetika a parfumy / Uši",
  "Kozmetika a parfumy / Hygiena a hygienické potreby",
  "Kozmetika a parfumy / Hygiena a hygienické potreby / Intímna hygiena",
  "Kozmetika a parfumy / Hygiena a hygienické potreby / Intímna hygiena / Menštruačné pomôcky",
  "Kozmetika a parfumy / Hygiena a hygienické potreby / Vreckovky",
  "Kozmetika a parfumy / Kozmetické pomôcky",
  "Kozmetika a parfumy / Deti",
  "Kozmetika a parfumy / Deti / Detská kozmetika",
  "Oblečenie, obuv a doplnky",
  "Oblečenie, obuv a doplnky / Pánske oblečenie",
  "Oblečenie, obuv a doplnky / Pánske oblečenie / Pánska spodná bielizeň",
  "Oblečenie, obuv a doplnky / Pánske oblečenie / Pánske vrchné oblečenie",
  "Oblečenie, obuv a doplnky / Pánske oblečenie / Pánske nohavice",
  "Oblečenie, obuv a doplnky / Pánske oblečenie / Pánske nočné prádlo a župany",
  "Oblečenie, obuv a doplnky / Pánske oblečenie / Pánske obleky a saká",
  "Oblečenie, obuv a doplnky / Pánske oblečenie / Pánske tričká a tielka",
  "Oblečenie, obuv a doplnky / Dámske oblečenie",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Dámska spodná bielizeň",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Dámska spodná bielizeň / Dámske pančuchy a pančucháče",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Dámske vrchné oblečenie",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Tehotenské a dojčiace oblečenie",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Dámske nohavice",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Dámska nočná bielizeň a župany",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Dámske plavky a tuniky",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Dámske plavky a tuniky / Plážové doplňky",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Dámske blúzky a košele",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Dámske súpravy a overaly",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Dámske tričká a tielka",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Dámske šaty a sukne",
  "Oblečenie, obuv a doplnky / Dámske oblečenie / Dámske saká a kostýmy",
  "Oblečenie, obuv a doplnky / Detské oblečenie",
  "Oblečenie, obuv a doplnky / Detské oblečenie / Dojčenské oblečenie",
  "Oblečenie, obuv a doplnky / Detské oblečenie / Detské mikiny a svetre",
  "Oblečenie, obuv a doplnky / Detské oblečenie / Detské vrchné oblečenie",
  "Oblečenie, obuv a doplnky / Detské oblečenie / Detské doplnky",
  "Oblečenie, obuv a doplnky / Detské oblečenie / Detské ponožky a pančuchy",
  "Oblečenie, obuv a doplnky / Detské oblečenie / Detská nočná bielizeň a župany",
  "Oblečenie, obuv a doplnky / Detské oblečenie / Detské tričká a tielka",
  "Oblečenie, obuv a doplnky / Módne doplnky",
  "Oblečenie, obuv a doplnky / Módne doplnky / Doplnky do vlasov",
  "Oblečenie, obuv a doplnky / Módne doplnky / Cestovná batožina a príslušenstvo",
  "Oblečenie, obuv a doplnky / Módne doplnky / Opasky a traky",
  "Oblečenie, obuv a doplnky / Módne doplnky / Pánske doplnky",
  "Oblečenie, obuv a doplnky / Módne doplnky / Slnečné okuliare a puzdrá na okuliare",
  "Oblečenie, obuv a doplnky / Módne doplnky / Hodinky a šperky",
  "Oblečenie, obuv a doplnky / Módne doplnky / Hodinky a šperky / Šperky",
  "Oblečenie, obuv a doplnky / Módne doplnky / Hodinky a šperky / Hodinky a príslušenstvo",
  "Oblečenie, obuv a doplnky / Módne doplnky / Peňaženky a spony na peniaze",
  "Oblečenie, obuv a doplnky / Módne doplnky / Batohy, tašky a kabelky",
  "Oblečenie, obuv a doplnky / Módne doplnky / Batohy, tašky a kabelky / Doplnky ku kabelkám",
  "Oblečenie, obuv a doplnky / Módne doplnky / Prívesky na kľúče a kľúčenky",
  "Oblečenie, obuv a doplnky / Módne doplnky / Pokrývky hlavy",
  "Oblečenie, obuv a doplnky / Módne doplnky / Šatky, šály a nákrčníky",
  "Oblečenie, obuv a doplnky / Obuv",
  "Oblečenie, obuv a doplnky / Obuv / Doplnky k obuvi",
  "Oblečenie, obuv a doplnky / Obuv / Dámske topánky",
  "Oblečenie, obuv a doplnky / Obuv / Pánske topánky",
  "Oblečenie, obuv a doplnky / Obuv / Detské topánky",
  "Šport a outdoor",
  "Šport a outdoor / Outdoorové vybavenie",
  "Šport a outdoor / Outdoorové vybavenie / Varenie v prírode",
  "Šport a outdoor / Outdoorové vybavenie / Varenie v prírode / Rozrobenie ohňa v prírode",
  "Šport a outdoor / Outdoorové vybavenie / Turistika a kemping",
  "Šport a outdoor / Outdoorové vybavenie / Turistika a kemping / Kempingový nábytok",
  "Šport a outdoor / Outdoorové vybavenie / Čelovky a svietidlá",
  "Šport a outdoor / Outdoorové vybavenie / Fľaše a pitné vaky",
  "Šport a outdoor / Outdoorové vybavenie / Ostatné outdoorové vybavenie",
  "Šport a outdoor / Športy",
  "Šport a outdoor / Športy / Fitness",
  "Šport a outdoor / Športy / Fitness / Fitness oblečenie",
  "Šport a outdoor / Športy / Fitness / Fitness oblečenie / Pánske fitness oblečenie",
  "Šport a outdoor / Športy / Fitness / Fitness oblečenie / Dámske fitness oblečenie",
  "Šport a outdoor / Športy / Fitness / Joga a príslušenstvo",
  "Šport a outdoor / Športy / Fitness / Fitness rukavice, opasky a trhačky",
  "Šport a outdoor / Športy / Fitness / Pomôcky na cvičenie",
  "Šport a outdoor / Športy / Fitness / Pomôcky na cvičenie / Záťaže na telo",
  "Šport a outdoor / Športy / Fitness / Pomôcky na cvičenie / Pomôcky na posilňovanie",
  "Šport a outdoor / Športy / Fitness / Trenažéry",
  "Šport a outdoor / Športy / Fitness / Posilňovanie",
  "Šport a outdoor / Športy / Fitness / Posilňovanie / Činky a príslušenstvo",
  "Šport a outdoor / Športy / Cyklistika",
  "Šport a outdoor / Športy / Cyklistika / Komponenty na bicykel",
  "Šport a outdoor / Športy / Cyklistika / Komponenty na bicykel / Prevody na bicykel",
  "Šport a outdoor / Športy / Cyklistika / Komponenty na bicykel / Komponenty na pohon kolies",
  "Šport a outdoor / Športy / Cyklistika / Komponenty na bicykel / Ovládanie na bicykel",
  "Šport a outdoor / Športy / Cyklistika / Oblečenie a topánky na bicykel",
  "Šport a outdoor / Športy / Cyklistika / Náradie na bicykel a údržba",
  "Šport a outdoor / Športy / Cyklistika / Kolobežky a príslušenstvo",
  "Šport a outdoor / Športy / Cyklistika / Príslušenstvo na bicykel",
  "Šport a outdoor / Športy / Loptové športy",
  "Šport a outdoor / Športy / Loptové športy / Volejbal",
  "Šport a outdoor / Športy / Loptové športy / Futbal",
  "Šport a outdoor / Športy / Loptové športy / Futbal / Kopačky na futbal",
  "Šport a outdoor / Športy / Loptové športy / Futbal / Futbalové oblečenie",
  "Šport a outdoor / Športy / Loptové športy / Florbal",
  "Šport a outdoor / Športy / Loptové športy / Nohejbal",
  "Šport a outdoor / Športy / Loptové športy / Basketbal",
  "Šport a outdoor / Športy / Loptové športy / Hádzaná",
  "Šport a outdoor / Športy / Loptové športy / Baseball & softball",
  "Šport a outdoor / Športy / Loptové športy / Americký futbal",
  "Šport a outdoor / Športy / Loptové športy / Rugby",
  "Šport a outdoor / Športy / Loptové športy / Kriket",
  "Šport a outdoor / Športy / Vodné športy",
  "Šport a outdoor / Športy / Vodné športy / Vodácke vybavenie",
  "Šport a outdoor / Športy / Vodné športy / Potápačské vybavenie",
  "Šport a outdoor / Športy / Vodné športy / Oblečenie a obuv do vody",
  "Šport a outdoor / Športy / Vodné športy / Plavecké potreby",
  "Šport a outdoor / Športy / Vodné športy / Adrenalínové vodné športy",
  "Šport a outdoor / Športy / Vodné športy / Adrenalínové vodné športy / Wakeboarding",
  "Šport a outdoor / Športy / Vodné športy / Adrenalínové vodné športy / Kiteboarding",
  "Šport a outdoor / Športy / Golf",
  "Šport a outdoor / Športy / Golf / Golfová obuv",
  "Šport a outdoor / Športy / Golf / Golfové palice",
  "Šport a outdoor / Športy / Zimné športy",
  "Šport a outdoor / Športy / Zimné športy / Bežecké lyžovanie",
  "Šport a outdoor / Športy / Zimné športy / Zjazdové lyžovanie",
  "Šport a outdoor / Športy / Zimné športy / Hokej",
  "Šport a outdoor / Športy / Zimné športy / Hokej / Hokejový hráč",
  "Šport a outdoor / Športy / Zimné športy / Hokej / Hokejový brankár",
  "Šport a outdoor / Športy / Zimné športy / Snowboarding",
  "Šport a outdoor / Športy / Zimné športy / Skialpinistické vybavenia",
  "Šport a outdoor / Športy / Skate & in-line",
  "Šport a outdoor / Športy / Skate & in-line / Skateboardy",
  "Šport a outdoor / Športy / Skate & in-line / In-line korčuľovanie",
  "Šport a outdoor / Športy / Horolezectvo",
  "Šport a outdoor / Športy / Horolezectvo / Istenie",
  "Šport a outdoor / Športy / Horolezectvo / Blokanty a kladky",
  "Šport a outdoor / Športy / Horolezectvo / Ľadovcové vybavenie",
  "Šport a outdoor / Športy / Horolezectvo / Lezecké príslušenstvo",
  "Šport a outdoor / Športy / Športová výživa",
  "Šport a outdoor / Športy / Športová výživa / Proteinové doplnky",
  "Šport a outdoor / Športy / Strelecké sporty",
  "Šport a outdoor / Športy / Strelecké sporty / Airsoft",
  "Šport a outdoor / Športy / Strelecké sporty / Airsoft / Airsoftové zbrane a príslušenstvo",
  "Šport a outdoor / Športy / Strelecké sporty / Lukostreľba",
  "Šport a outdoor / Športy / Strelecké sporty / Lukostreľba / Luky a príslušenstvo",
  "Šport a outdoor / Športy / Strelecké sporty / Lukostreľba / Kuše a príslušenstvo",
  "Šport a outdoor / Športy / Strelecké sporty / Army a lovecké oblečenie",
  "Šport a outdoor / Športy / Strelecké sporty / Army a lovecké doplnky",
  "Šport a outdoor / Športy / Strelecké sporty / Športová streľba",
  "Šport a outdoor / Športy / Strelecké sporty / Športová streľba / Príslušenstvo pre zbrane",
  "Šport a outdoor / Športy / Strelecké sporty / Športová streľba / Zbrane bez ZP",
  "Šport a outdoor / Športy / Ostatné športy",
  "Šport a outdoor / Športy / Ostatné športy / Bojové športy",
  "Šport a outdoor / Športy / Ostatné športy / Bojové športy / Box",
  "Šport a outdoor / Športy / Ostatné športy / Bojové športy / Sebaobrana",
  "Šport a outdoor / Športy / Ostatné športy / Bojové športy / Kimona a opasky",
  "Šport a outdoor / Športy / Ostatné športy / Bojové športy / Nože a meče na bojové športy",
  "Šport a outdoor / Športy / Ostatné športy / Terče a šípky",
  "Šport a outdoor / Športy / Ostatné športy / Bowling",
  "Šport a outdoor / Športy / Ostatné športy / Beh",
  "Šport a outdoor / Športy / Ostatné športy / Biliard a príslušenstvo",
  "Šport a outdoor / Športy / Ostatné športy / Elektrovozidlá",
  "Šport a outdoor / Športy / Ostatné športy / Tanec",
  "Šport a outdoor / Športy / Atletika",
  "Šport a outdoor / Športy / Atletika / Atletické náčinie",
  "Šport a outdoor / Športy / Jazdectvo",
  "Šport a outdoor / Športy / Jazdectvo / Vybavenie pre kone",
  "Šport a outdoor / Športy / Jazdectvo / Výbava pre jazdcov",
  "Šport a outdoor / Športy / Raketové športy",
  "Šport a outdoor / Športy / Raketové športy / Badminton",
  "Šport a outdoor / Športy / Raketové športy / Tenis",
  "Šport a outdoor / Športy / Raketové športy / Tenis / Tenisová obuv",
  "Šport a outdoor / Športy / Raketové športy / Tenis / Tenisové doplnky",
  "Šport a outdoor / Športy / Raketové športy / Stolný tenis",
  "Šport a outdoor / Športy / Raketové športy / Squash",
  "Šport a outdoor / Športy / Raketové športy / Padel",
  "Šport a outdoor / Športy / Športová obuv",
  "Šport a outdoor / Športy / Športová obuv / Pánska športová obuv",
  "Šport a outdoor / Športy / Športová obuv / Dámska športová obuv",
  "Šport a outdoor / Športy / Športová obuv / Detská športová obuv",
  "Šport a outdoor / Športy / Trampolíny a príslušenstvo",
  "Šport a outdoor / Športy / Trampolíny a príslušenstvo / Príslušenstvo k trampolínam",
  "Šport a outdoor / Športtestery a inteligentné prístroje",
  "Veľké spotrebiče",
  "Veľké spotrebiče / Práčky a sušičky",
  "Veľké spotrebiče / Spotrebiče do kuchyne",
  "Veľké spotrebiče / Spotrebiče do kuchyne / Príslušenstvo k veľkým kuchynským spotrebičom",
  "Veľké spotrebiče / Spotrebiče do kuchyne / Chladenie a mrazenie",
  "Veľké spotrebiče / Spotrebiče do kuchyne / Varenie a ohrievanie",
  "Veľké spotrebiče / Spotrebiče do kuchyne / Varenie a ohrievanie / Vybavenie profesionálnych kuchýň",
  "Zdravie",
  "Zdravie / Zdravotnícke potreby",
  "Zdravie / Zdravotnícke potreby / Športová medicína",
  "Zdravie / Zdravotnícke potreby / Kompenzačné pomôcky",
  "Zdravie / Zdravotné prístroje",
  "Zdravie / Očná optika",
  "Zdravie / Lieky a doplnky stravy",
  "Zdravie / Lieky a doplnky stravy / Vitamíny a doplnky stravy",
  "Zdravie / Koronavírus",
  "Zdravie / Ochrana dýchacích ciest",
];

export default categories_list;
