import React, { createContext, useContext, useState } from "react";

const LeaveModalContext = createContext();

export const LeaveModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shopName, setShopName] = useState("");
  const [onConfirm, setOnConfirm] = useState(() => {});
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const openModal = (name, confirmCallback, authState) => {
    setShopName(name);
    setOnConfirm(() => confirmCallback);
    setIsAuthenticated(authState);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const confirmLeave = () => {
    onConfirm();
    closeModal();
  };

  return (
    <LeaveModalContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
        confirmLeave,
        shopName,
        isAuthenticated,
      }}
    >
      {children}
    </LeaveModalContext.Provider>
  );
};

export const useLeaveModal = () => useContext(LeaveModalContext);
