// src/pages/Login.js
import React from "react";
import { useNavigate } from "react-router-dom";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import LoginForm from "../components/auth/LoginForm";

export default function LoginPage() {
  const navigate = useNavigate();

  const handleLoginSuccess = () => {
    navigate("/");
  };

  return (
    <>
      <BasicNavbar />
      <LoginForm onSuccess={handleLoginSuccess} />
      <Footer />
    </>
  );
}
