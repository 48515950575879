import React, { useState } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Drawer,
} from "@mui/material";
import { IoGrid, IoEye } from "react-icons/io5";
import { FaThList } from "react-icons/fa";
import { useTranslation } from "react-i18next";
const SortByOptions = ({ setSort, view, setView }) => {
  const { t } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State to control mobile drawer

  const sortOptions = [
    { label: t("Cena") + " ↑", value: "price_asc" },
    { label: t("Cena") + " ↓", value: "price_desc" },
    { label: t("Názov") + " ↑", value: "alphabetical_asc" },
    { label: t("Názov") + " ↓", value: "alphabetical_desc" },
    { label: t("Hodnotenie") + " ↓", value: "rating_desc" },
    { label: t("Hodnotenie") + " ↑", value: "rating_asc" },
    { label: t("Najviac recenzií") + " ↓", value: "reviews_desc" },
    { label: t("Najmenej recenzií ↑"), value: "reviews_asc" },
  ];

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleViewChange = (selectedView) => {
    setView(selectedView);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <Box
      className="flex flex-col md:flex-row md:text-base text-xs mt-2 px-4"
      alignItems="center"
      justifyContent="flex-end"
    >
      {/* Mobile Button to Open Side Menu */}
      <div className="md:hidden flex w-full justify-end mb-2">
        <button onClick={toggleMobileMenu} className="border-1 p-2 shadow-lg">
          {t("Zobrazenie")}
        </button>
      </div>

      {/* Drawer for Mobile View */}
      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={toggleMobileMenu}
        sx={{ display: { xs: "block", md: "none" } }} // Show only on mobile
      >
        <Box sx={{ width: 250, padding: 2 }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            {t("Nastavenia zobrazenia")}
          </Typography>
          <div className="flex flex-col items-start">
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              {t("Zobrazenie")}:
            </Typography>
            <div>
              <IconButton
                onClick={() => handleViewChange("grid")}
                color={view === "grid" ? "primary" : "default"}
              >
                <IoGrid />
              </IconButton>
              <IconButton
                onClick={() => handleViewChange("list")}
                color={view === "list" ? "primary" : "default"}
              >
                <FaThList />
              </IconButton>
            </div>
            <Typography variant="body1" sx={{ marginTop: 3, marginBottom: 1 }}>
              {t("Zoradiť podľa")}:
            </Typography>
            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
              <Select
                id="sort-by-select"
                onChange={handleSortChange}
                displayEmpty
                defaultValue=""
              >
                {sortOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Box>
      </Drawer>

      {/* Sorting and View Options for Larger Screens */}
      <div className="hidden md:flex flex-row items-center">
        <Typography variant="body1" sx={{ marginRight: 2 }}>
          {t("Zobrazenie")}:
        </Typography>
        <IconButton
          onClick={() => handleViewChange("grid")}
          color={view === "grid" ? "primary" : "default"}
        >
          <IoGrid />
        </IconButton>
        <IconButton
          onClick={() => handleViewChange("list")}
          color={view === "list" ? "primary" : "default"}
        >
          <FaThList />
        </IconButton>
      </div>
      <div className="hidden md:flex flex-row items-center">
        <Typography variant="body1" sx={{ marginRight: 2 }}>
          {t("Zoradiť podľa")}:
        </Typography>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <Select id="sort-by-select" onChange={handleSortChange} displayEmpty>
            {sortOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Box>
  );
};

export default SortByOptions;
