import React from "react";
import { createRoot } from "react-dom/client";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

const GoogleMap = ({ lat, lng }) => {
  // Convert lat and lng to floats
  const latitude = parseFloat(lat);
  const longitude = parseFloat(lng);

  return (
    <APIProvider apiKey={"AIzaSyCvEVgUiARvPqiEXZ9WWdXwtABuB41_8WQ"}>
      <Map
        className="w-full h-48"
        defaultCenter={{ lat: latitude, lng: longitude }}
        defaultZoom={12}
        gestureHandling={"greedy"}
        disableDefaultUI={false}
      >
        {/* Add a Marker component to place a pin on the map */}
        <Marker position={{ lat: latitude, lng: longitude }} />
      </Map>
    </APIProvider>
  );
};

export default GoogleMap;
