import { Image } from "@nextui-org/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function HowItWorks() {
  const { t } = useTranslation();
  return (
    <>
      <motion.div
        className="py-16 w-full bg-lightgray/5 mt-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="flex flex-col gap-8 max-w-screen-2xl mx-auto ">
          <motion.div
            className="text-center text-blue font-semibold"
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            {t("Krok po kroku")}
          </motion.div>
          <motion.div
            className="text-center text-5xl font-bold"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            {t("Ako to v skratke funguje")}
          </motion.div>
          <motion.div
            className="flex lg:flex-row flex-wrap mx-4 gap-8"
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="max-w-[326px] bg-white p-6 rounded-xl shadow-md mx-auto">
              <div className="">
                <svg
                  className="-ml-7"
                  width="115"
                  height="113"
                  viewBox="0 0 115 113"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_1996_9383)">
                    <rect
                      x="24.5386"
                      y="16.2609"
                      width="66.4615"
                      height="64"
                      rx="14"
                      fill="#3D81C2"
                    />
                  </g>
                  <path
                    d="M70.35 45.9375H67.75V43.1875C67.75 42.8094 67.4575 42.5 67.1 42.5H65.8C65.4425 42.5 65.15 42.8094 65.15 43.1875V45.9375H62.55C62.1925 45.9375 61.9 46.2469 61.9 46.625V48C61.9 48.3781 62.1925 48.6875 62.55 48.6875H65.15V51.4375C65.15 51.8156 65.4425 52.125 65.8 52.125H67.1C67.4575 52.125 67.75 51.8156 67.75 51.4375V48.6875H70.35C70.7075 48.6875 71 48.3781 71 48V46.625C71 46.2469 70.7075 45.9375 70.35 45.9375ZM54.1 48C56.9722 48 59.3 45.5379 59.3 42.5C59.3 39.4621 56.9722 37 54.1 37C51.2278 37 48.9 39.4621 48.9 42.5C48.9 45.5379 51.2278 48 54.1 48ZM57.74 49.375H57.0616C56.1597 49.8133 55.1562 50.0625 54.1 50.0625C53.0437 50.0625 52.0444 49.8133 51.1384 49.375H50.46C47.4456 49.375 45 51.9617 45 55.15V56.9375C45 58.0762 45.8734 59 46.95 59H61.25C62.3266 59 63.2 58.0762 63.2 56.9375V55.15C63.2 51.9617 60.7544 49.375 57.74 49.375Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1996_9383"
                      x="0.538574"
                      y="0.260864"
                      width="114.461"
                      height="112"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="8" />
                      <feGaussianBlur stdDeviation="12" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.239216 0 0 0 0 0.505882 0 0 0 0 0.760784 0 0 0 0.24 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1996_9383"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1996_9383"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>{" "}
              <div className="flex flex-col gap-2">
                <div className="font-semibold">{t("Registrácia zdarma")}</div>
                <div className="text-lightgray text-sm">
                  {t(
                    "Registrácia na Bilionbuy je úplne zdarma. Nárok na cashback a bonusy vzniká len registrovaným."
                  )}
                </div>
              </div>
            </div>
            <div className="max-w-[326px] bg-white p-6 rounded-xl shadow-md mx-auto">
              <div className="">
                <svg
                  className="-ml-7"
                  width="115"
                  height="112"
                  viewBox="0 0 115 112"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_1996_9383)">
                    <rect
                      x="24"
                      y="16"
                      width="66.4615"
                      height="64"
                      rx="14"
                      fill="#A6CF4A"
                    />
                  </g>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M57.2294 62.1673C65.3543 62.1673 71.9409 55.8247 71.9409 48.0007C71.9409 40.1766 65.3543 33.834 57.2294 33.834C49.1044 33.834 42.5178 40.1766 42.5178 48.0007C42.5178 55.8247 49.1044 62.1673 57.2294 62.1673ZM58.7005 40.9173C58.7005 40.1349 58.0419 39.5007 57.2294 39.5007C56.4169 39.5007 55.7582 40.1349 55.7582 40.9173V48.0007C55.7582 48.3764 55.9132 48.7367 56.1891 49.0024L59.867 52.5441C60.4415 53.0973 61.373 53.0973 61.9475 52.5441C62.522 51.9908 62.522 51.0938 61.9475 50.5406L58.7005 47.4138V40.9173Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1996_9383"
                      x="0"
                      y="0"
                      width="114.461"
                      height="112"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="8" />
                      <feGaussianBlur stdDeviation="12" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.65098 0 0 0 0 0.811765 0 0 0 0 0.290196 0 0 0 0.24 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1996_9383"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1996_9383"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>{" "}
              <div className="flex flex-col gap-2">
                <div className="font-semibold">{t("Porovnanie")}</div>
                <div className="text-lightgray text-sm">
                  {t(
                    "Na Bilionbuy si môžete porovnať kvalitu, cenu a cashback tovaru, ktorý máte záujem si zakúpiť."
                  )}
                </div>
              </div>
            </div>
            <div className="max-w-[326px] bg-white p-6 rounded-xl shadow-md mx-auto">
              <div className="">
                <svg
                  className="-ml-7"
                  width="115"
                  height="112"
                  viewBox="0 0 115 112"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_1996_9383)">
                    <rect
                      x="24.4614"
                      y="16"
                      width="66.4615"
                      height="64"
                      rx="14"
                      fill="#FF60A8"
                    />
                  </g>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M51.2021 35.5C50.4851 35.5 49.904 36.0596 49.904 36.75V38H48.6059C46.4552 38 44.7117 39.6789 44.7117 41.75V56.75C44.7117 58.8211 46.4552 60.5 48.6059 60.5H66.779C68.9297 60.5 70.6732 58.8211 70.6732 56.75V41.75C70.6732 39.6789 68.9297 38 66.779 38H65.4809V36.75C65.4809 36.0596 64.8997 35.5 64.1828 35.5C63.4659 35.5 62.8847 36.0596 62.8847 36.75V38H52.5001V36.75C52.5001 36.0596 51.919 35.5 51.2021 35.5ZM53.7982 49.25C53.7982 48.5596 54.3794 48 55.0963 48H64.1828C64.8997 48 65.4809 48.5596 65.4809 49.25C65.4809 49.9404 64.8997 50.5 64.1828 50.5H55.0963C54.3794 50.5 53.7982 49.9404 53.7982 49.25ZM49.904 54.25C49.904 53.5596 50.4851 53 51.2021 53H58.9905C59.7074 53 60.2886 53.5596 60.2886 54.25C60.2886 54.9404 59.7074 55.5 58.9905 55.5H51.2021C50.4851 55.5 49.904 54.9404 49.904 54.25Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1996_9383"
                      x="0.461426"
                      y="0"
                      width="114.461"
                      height="112"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="8" />
                      <feGaussianBlur stdDeviation="12" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.376471 0 0 0 0 0.658824 0 0 0 0.24 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1996_9383"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1996_9383"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>{" "}
              <div className="flex flex-col gap-2">
                <div className="font-semibold">{t("Nákup")}</div>
                <div className="text-lightgray text-sm">
                  {t(
                    "Nákup konkrétneho tovaru sa realizuje prostredníctvom prekliku “do obchodu” cez konkrétny e-shop."
                  )}
                </div>
              </div>
            </div>
            <div className="max-w-[326px] bg-white p-6 rounded-xl shadow-md mx-auto">
              <div className="">
                <svg
                  className="-ml-7"
                  width="116"
                  height="112"
                  viewBox="0 0 116 112"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_1996_9383)">
                    <rect
                      x="24.9231"
                      y="16"
                      width="66.4615"
                      height="64"
                      rx="14"
                      fill="#FB6238"
                    />
                  </g>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M58.1539 60.5C65.3229 60.5 71.1346 54.9036 71.1346 48C71.1346 41.0964 65.3229 35.5 58.1539 35.5C50.9848 35.5 45.1731 41.0964 45.1731 48C45.1731 54.9036 50.9848 60.5 58.1539 60.5ZM58.1539 38C57.437 38 56.8558 38.5596 56.8558 39.25V40.5C54.7051 40.5 52.9616 42.1789 52.9616 44.25V44.9481C52.9616 46.5622 54.0341 47.9952 55.6243 48.5057L59.8624 49.866C60.3925 50.0362 60.75 50.5139 60.75 51.0519V51.75C60.75 52.4404 60.1688 53 59.4519 53H56.8558C56.1389 53 55.5577 52.4404 55.5577 51.75C55.5577 51.0596 54.9765 50.5 54.2596 50.5C53.5427 50.5 52.9616 51.0596 52.9616 51.75C52.9616 53.8211 54.7051 55.5 56.8558 55.5V56.75C56.8558 57.4404 57.437 58 58.1539 58C58.8708 58 59.4519 57.4404 59.4519 56.75V55.5C61.6027 55.5 63.3462 53.8211 63.3462 51.75V51.0519C63.3462 49.4378 62.2736 48.0048 60.6834 47.4943L56.4453 46.134C55.9152 45.9638 55.5577 45.4861 55.5577 44.9481V44.25C55.5577 43.5596 56.1389 43 56.8558 43H59.4519C60.1688 43 60.75 43.5596 60.75 44.25C60.75 44.9404 61.3312 45.5 62.0481 45.5C62.765 45.5 63.3462 44.9404 63.3462 44.25C63.3462 42.1789 61.6027 40.5 59.4519 40.5V39.25C59.4519 38.5596 58.8708 38 58.1539 38Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1996_9383"
                      x="0.923096"
                      y="0"
                      width="114.461"
                      height="112"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="8" />
                      <feGaussianBlur stdDeviation="12" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.376471 0 0 0 0 0.658824 0 0 0 0.24 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1996_9383"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1996_9383"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>{" "}
              <div className="flex flex-col gap-2">
                <div className="font-semibold">{t("Cashback a bonusy")}</div>
                <div className="text-lightgray text-sm">
                  {t(
                    "Vaše cashback bonusy a provízie z nákupov vašej obchodnej siete môžete sledovať po prihlásení sa."
                  )}
                </div>
              </div>
            </div>{" "}
          </motion.div>
        </div>
      </motion.div>
    </>
  );
}
