import React, { useRef, useState, useEffect } from "react";
import EmailEditor from "react-email-editor";
import {
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import api from "../../services/api";

const AdminEmailEditor = () => {
  const emailEditorRef = useRef(null);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [loading, setLoading] = useState(true);
  const [templateType, setTemplateType] = useState("");
  const [language, setLanguage] = useState("");
  const [newTemplateName, setNewTemplateName] = useState("");
  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    fetchTemplates();
  }, [templateType, language]);

  useEffect(() => {
    if (emailEditorRef.current?.editor) {
      emailEditorRef.current.editor.addEventListener(
        "design:updated",
        function (updates) {
          console.log("Design was updated:", updates);
        }
      );
    }
  }, [editorLoaded]);

  const fetchTemplates = async () => {
    try {
      const params = new URLSearchParams();
      if (templateType) params.append("type", templateType);
      if (language) params.append("language", language);

      const response = await api.get(`email-templates/?${params.toString()}`);
      setTemplates(response.data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
    setLoading(false);
  };

  const loadTemplate = async (templateId) => {
    if (!templateId || loading || !editorLoaded) return;

    setLoading(true);
    try {
      const response = await api.get(`email-templates/${templateId}/`);
      if (emailEditorRef.current?.editor) {
        // Parse the design if it's a string
        const design =
          typeof response.data.design === "string"
            ? JSON.parse(response.data.design)
            : response.data.design;
        emailEditorRef.current.editor.loadDesign(design);
      }
    } catch (error) {
      console.error("Error loading template:", error);
    } finally {
      setLoading(false);
    }
  };

  const exportTemplate = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log("Export HTML:", html);
      console.log("Export design JSON:", design);
      // TODO: Handle the exported data (e.g., save to backend)
    });
  };

  const saveTemplate = async () => {
    if (!newTemplateName) {
      alert("Please enter a template name");
      return;
    }

    // Use exportHtml to get both design and HTML
    emailEditorRef.current.editor.exportHtml(async (data) => {
      try {
        const payload = {
          name: newTemplateName,
          design: data.design, // Save the design JSON
          html: data.html, // Optionally save the HTML too
          type: templateType,
          language: language,
        };

        const response = await api.post("email-templates/", payload);
        if (response.status === 201) {
          setNewTemplateName("");
          fetchTemplates();
        }
      } catch (error) {
        console.error("Error saving template:", error);
      }
    });
  };

  const handleTemplateChange = (event) => {
    const newTemplateId = event.target.value;
    setSelectedTemplate(newTemplateId);
    if (editorLoaded) {
      loadTemplate(newTemplateId);
    }
  };

  return (
    <Box>
      <Box
        sx={{ marginBottom: 2, display: "flex", alignItems: "center", gap: 2 }}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Template Type</InputLabel>
          <Select
            value={templateType}
            label="Template Type"
            onChange={(e) => setTemplateType(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="marketing">Marketing</MenuItem>
            <MenuItem value="transactional">Transactional</MenuItem>
            <MenuItem value="notification">Notification</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Language</InputLabel>
          <Select
            value={language}
            label="Language"
            onChange={(e) => setLanguage(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="es">Spanish</MenuItem>
            <MenuItem value="fr">French</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="New Template Name"
          value={newTemplateName}
          onChange={(e) => setNewTemplateName(e.target.value)}
          sx={{ minWidth: 200 }}
        />
      </Box>
      <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center" }}>
        <FormControl sx={{ minWidth: 200, marginRight: 2 }}>
          <InputLabel id="template-select-label">Select a template</InputLabel>
          <Select
            labelId="template-select-label"
            value={selectedTemplate}
            label="Select a template"
            onChange={handleTemplateChange}
          >
            {templates.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={exportTemplate}
          sx={{ marginRight: 1 }}
        >
          Export
        </Button>
        <Button variant="contained" onClick={saveTemplate}>
          Save
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <EmailEditor
          ref={emailEditorRef}
          onReady={() => {
            console.log("Email editor is ready");
            setEditorLoaded(true);
            if (selectedTemplate) {
              loadTemplate(selectedTemplate);
            }
          }}
          options={{
            appearance: {
              theme: "white",
            },
            features: {
              preview: true,
            },
            tools: {
              // Enable all tools
              image: {
                enabled: true,
              },
              text: {
                enabled: true,
              },
              // ... other tools you want to enable
            },
          }}
        />
      )}
    </Box>
  );
};

export default AdminEmailEditor;
