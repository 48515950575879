import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async"; // Import Helmet for SEO
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import ShopHero from "../components/ShopHero";
import ProductSlider from "../components/ProductSlider";
import api from "../services/api";
import { useTranslation } from "react-i18next";

export default function ViewShop() {
  let { id } = useParams();
  const [searchOpen, setSearchOpen] = useState(false);
  const [shopData, setShopData] = useState(null);
  const { t } = useTranslation();
  const currentLocale = localStorage.getItem("i18nextLng");

  const fetchShopData = async () => {
    try {
      const response = await api.get(`get-shop-data/`, {
        params: {
          shop_id: id,
        },
      });
      setShopData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchShopData();
  }, [id]); // Add id to the dependency array

  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>
          {shopData ? `${shopData.name} - Bilionbuy` : "Shop - Bilionbuy"}
        </title>
        <meta
          name="description"
          content={
            shopData
              ? `Discover products, offers, and more from ${shopData.name} on Bilionbuy.`
              : "Find a wide variety of shops and products on Bilionbuy."
          }
        />
        <meta
          name="keywords"
          content="shop, online store, Bilionbuy, products, offers"
        />
        <link
          rel="canonical"
          href={`https://www.bilionbuy.com/view-shop/${id}`}
        />
        {/* Open Graph Meta Tags for Social Sharing */}
        <meta
          property="og:title"
          content={
            shopData ? `${shopData.name} - Bilionbuy` : "Shop - Bilionbuy"
          }
        />
        <meta
          property="og:description"
          content={
            shopData
              ? `Explore ${shopData.name}'s products and offers on Bilionbuy.`
              : "Find a wide variety of shops and products on Bilionbuy."
          }
        />
        <meta
          property="og:url"
          content={`https://www.bilionbuy.com/view-shop/${id}`}
        />
        <meta property="og:type" content="website" />
        {shopData && shopData.shop_logo && (
          <meta property="og:image" content={shopData.shop_logo} />
        )}
        {/* Structured Data for Search Engines */}
        {shopData && (
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Store",
              name: shopData.shop_name,
              url: `https://www.bilionbuy.com/view-shop/${id}`,
              description: `Explore ${shopData.shop_name}'s products and offers on Bilionbuy.`,
              image: shopData.shop_logo,
            })}
          </script>
        )}
      </Helmet>

      {/* Main Content */}
      <div className="sticky top-0 z-50">
        <BasicNavbar setSearchOpen={setSearchOpen} />
      </div>
      <div>
        {shopData ? <ShopHero data={shopData} t={t} /> : null}
        <div className="pt-16"></div>
        {/* Product slider section - only show for Slovak locale */}
        {shopData && shopData.feed_url !== null && currentLocale === "sk" && (
          <>
            <div className="text-5xl font-bold mx-auto text-center pt-16">
              {t("Najpopulárnejšie produkty z ")} {shopData.name}
            </div>
            <ProductSlider
              title={``}
              text={``}
              type="product"
              fetchCall={`get-single-shop-products?shop_id=${shopData.id}`}
            />
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
