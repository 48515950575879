import React from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import AccountInfo from "../components/AccountInfo";

export default function ProductView() {
  //   let { id } = useParams();
  return (
    <>
      <BasicNavbar />

      <AccountInfo />
      <Footer />
    </>
  );
}
