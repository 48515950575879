import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isTokenExpired, logoutUser } from "./auth";

function AuthCheck({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("jwt");

    if (token && isTokenExpired(token)) {
      logoutUser();
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  return children;
}

export default AuthCheck;
