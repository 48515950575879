import React, { useState } from "react";
import api from "../services/api";
import { Select, SelectItem } from "@nextui-org/select";
import {
  Button,
  Spinner,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Checkbox,
  Input,
  Textarea,
} from "@nextui-org/react";
import { toast } from "react-toastify";
import { MdOutlineStar } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const CreateReview = ({ productId, productData }) => {
  const modalSize = "5xl";
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const [selectedStar, setSelectedStar] = useState(0);
  const [hoveredStar, setHoveredStar] = useState(0);

  const [formData, setFormData] = useState({
    type: "product",
    mark: "",
    text: "",
    pros: [],
    cons: [],
    recommend: false,
    product_id: productId,
    shop_id: "",
  });

  const [editingIndex, setEditingIndex] = useState(null);
  const [editingType, setEditingType] = useState("");
  const [currentProsInput, setCurrentProsInput] = useState("");
  const [currentConsInput, setCurrentConsInput] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = (key) => {
    setFormData((prev) => ({
      ...prev,
      mark: key,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const response = await api.post(`/create-review/`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success(response.data.message || "Review created successfully!");
    } catch (error) {
      console.error(error);
      toast.error("Failed to create review.");
    } finally {
      setLoading(false);
      onOpenChange();
    }
  };

  const handleStarClick = (value) => {
    setSelectedStar(value);
    setFormData((prev) => ({
      ...prev,
      mark: value,
    }));
  };

  const handleStarHover = (value) => {
    setHoveredStar(value);
  };

  const handleStarLeave = () => {
    setHoveredStar(0);
  };

  const handlePlusEnter = (e) => {
    if (e.key === "Enter" && currentProsInput.trim() !== "") {
      if (editingType === "pros" && editingIndex !== null) {
        const updatedPros = [...formData.pros];
        updatedPros[editingIndex] = currentProsInput.trim();
        setFormData((prev) => ({
          ...prev,
          pros: updatedPros,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          pros: [...prev.pros, currentProsInput.trim()],
        }));
      }
      setCurrentProsInput("");
      setEditingIndex(null);
      setEditingType("");
    }
  };

  const handleConsEnter = (e) => {
    if (e.key === "Enter" && currentConsInput.trim() !== "") {
      if (editingType === "cons" && editingIndex !== null) {
        const updatedCons = [...formData.cons];
        updatedCons[editingIndex] = currentConsInput.trim();
        setFormData((prev) => ({
          ...prev,
          cons: updatedCons,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          cons: [...prev.cons, currentConsInput.trim()],
        }));
      }
      setCurrentConsInput("");
      setEditingIndex(null);
      setEditingType("");
    }
  };

  const handleEditPlus = (index) => {
    setEditingIndex(index);
    setEditingType("pros");
    setCurrentProsInput(formData.pros[index]);
  };

  const handleDeletePlus = (index) => {
    setFormData((prev) => ({
      ...prev,
      pros: prev.pros.filter((_, i) => i !== index),
    }));
  };

  const handleEditCons = (index) => {
    setEditingIndex(index);
    setEditingType("cons");
    setCurrentConsInput(formData.cons[index]);
  };

  const handleDeleteCons = (index) => {
    setFormData((prev) => ({
      ...prev,
      cons: prev.cons.filter((_, i) => i !== index),
    }));
  };
  const { t } = useTranslation();
  return (
    <>
      <div
        onClick={onOpen}
        className="text-lightgray rounded-lg cursor-pointer my-4"
      >
        {loading ? (
          <Spinner className="text-white" />
        ) : (
          <a className="border-1 p-2 text-white font-bold bg-blue px-8 rounded-3xl text-xs">
            {t("Pridať recenziu")}
          </a>
        )}
      </div>
      <Modal size={modalSize} isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-3xl font-bold uppercase">
                &nbsp;
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-col text-center justify-center gap-4">
                  <div className="flex justify-center">
                    <img
                      src={productData.product_image_url}
                      className="max-w-32 max-h-32 rounded-xl shadow-lg p-2"
                    />
                  </div>
                  <div className="text-sm text-lightgray">
                    {productData.product_name}
                  </div>
                  <div className="text-3xl text-primary font-semibold">
                    Ako hodnotíte tento výrobok?
                  </div>
                  <div className="flex flex-row gap-2 justify-center text-5xl text-lightgray">
                    {[1, 2, 3, 4, 5].map((value) => (
                      <MdOutlineStar
                        key={value}
                        onClick={() => handleStarClick(value)}
                        onMouseEnter={() => handleStarHover(value)}
                        onMouseLeave={handleStarLeave}
                        className={`cursor-pointer ${
                          hoveredStar >= value || selectedStar >= value
                            ? "text-yellow-400"
                            : ""
                        }`}
                      />
                    ))}
                  </div>
                  <div>
                    <div className="flex flex-row gap-4">
                      <div className="text-left text-success w-1/2 max-w-1/2">
                        <label>
                          Popíšte plusy:
                          <Input
                            name="pros"
                            onKeyDown={handlePlusEnter}
                            variant="bordered"
                            value={currentProsInput}
                            onChange={(e) =>
                              setCurrentProsInput(e.target.value)
                            }
                            startContent={<FaPlusCircle />}
                          ></Input>
                        </label>
                        <ul>
                          {formData.pros.map((item, index) => (
                            <li
                              key={index}
                              className="flex items-center ml-4 my-3 max-w-1/2"
                            >
                              <span className="inline">
                                <FaPlusCircle className="inline" />
                                &nbsp;
                                {item}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                  onClick={() => handleEditPlus(index)}
                                  className="inline"
                                >
                                  <span className="text-xs text-warning border-warning border-1 rounded-lg p-1 inline">
                                    Upraviť
                                  </span>
                                </button>
                                <button
                                  onClick={() => handleDeletePlus(index)}
                                  className="inline"
                                >
                                  <span className="text-xs text-danger border-danger ml-2 border-1 rounded-lg p-1 inline">
                                    Zmazať
                                  </span>
                                </button>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="text-left text-danger w-1/2 max-w-1/2">
                        <label>
                          Popíšte mínusy:
                          <Input
                            name="cons"
                            onKeyDown={handleConsEnter}
                            variant="bordered"
                            value={currentConsInput}
                            onChange={(e) =>
                              setCurrentConsInput(e.target.value)
                            }
                            startContent={<FaMinusCircle />}
                          ></Input>
                        </label>
                        <ul>
                          {formData.cons.map((item, index) => (
                            <li
                              key={index}
                              className="flex items-center ml-4 my-3"
                            >
                              <span className="inline">
                                <FaMinusCircle className="inline" />
                                &nbsp;
                                {item}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                  onClick={() => handleEditCons(index)}
                                  className="inline"
                                >
                                  <span className="text-xs text-warning border-warning border-1 rounded-lg p-1 inline">
                                    Upraviť
                                  </span>
                                </button>
                                <button
                                  onClick={() => handleDeleteCons(index)}
                                  className="inline"
                                >
                                  <span className="text-xs text-danger border-danger ml-2 border-1 rounded-lg p-1 inline">
                                    Zmazať
                                  </span>
                                </button>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="text-left mt-6">
                      <label className="text-left">
                        Popíšte svoju skúsenosť slovom:
                        <Textarea
                          name="text"
                          value={formData.text}
                          onChange={handleChange}
                          variant="bordered"
                        ></Textarea>
                      </label>
                    </div>
                    {/* <div className="border-1 rounded-lg my-4 p-4">
                      <div className="text-left flex flex-row gap-4 items-center">
                        <IoCloudUploadOutline className="text-2xl text-primary" />{" "}
                        <span className="text-primary underline">
                          Pridať fotografie
                        </span>{" "}
                        <span className="text-lightgray text-sm">
                          ( .PNG, .JPG, max 5 MB )
                        </span>
                      </div>
                    </div> */}
                    <div className="text-left mt-6">
                      <Checkbox
                        type="checkbox"
                        name="recommend"
                        checked={formData.recommend}
                        onChange={handleChange}
                        variant="bordered"
                      />
                      Tento produkt by som odporúčil priateľom
                    </div>
                  </div>
                </div>
                <form className="flex flex-col gap-4">
                  <div className="hidden">
                    <label>
                      Type:
                      <select
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                      >
                        <option value="product">Product</option>
                        <option value="shop">Shop</option>
                      </select>
                    </label>
                  </div>
                  {formData.type === "shop" && (
                    <div className="hidden">
                      <label>
                        Shop ID:
                        <input
                          type="text"
                          name="shop_id"
                          value={formData.shop_id}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                  )}
                  {formData.type === "product" && (
                    <div className="hidden">
                      <label>
                        Product ID:
                        <Input
                          type="text"
                          name="product_id"
                          min={0}
                          max={5}
                          value={formData.product_id}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                  )}
                </form>
              </ModalBody>
              <ModalFooter className="flex justify-center">
                <Button
                  color="danger"
                  variant="light"
                  onPress={onClose}
                  className="px-12"
                >
                  Zrušiť
                </Button>
                <Button
                  color="primary"
                  onPress={handleSubmit}
                  className="px-12"
                >
                  {loading ? <Spinner className="text-white" /> : "Uložiť"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateReview;
