import React from "react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import WishlistComponent from "../components/WishlistComponent";

export default function ProductView() {
  //   let { id } = useParams();
  return (
    <>
      <BasicNavbar />

      <WishlistComponent />
      <Footer />
    </>
  );
}
