// src/pages/Register.js
import React from "react";
import { useNavigate } from "react-router-dom";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import RegisterForm from "../components/auth/RegisterForm";

export default function Register() {
  const navigate = useNavigate();

  // Callback function to handle navigation after success
  const handleSuccess = () => {
    navigate("/");
  };

  return (
    <>
      <BasicNavbar />
      <RegisterForm onSuccess={handleSuccess} />
      <Footer />
    </>
  );
}
