import React, { useState, useEffect, useCallback } from "react";
import { ToastContainer } from "react-toastify";
import CategoryMappings from "../components/admin/CategoryMappings";
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  CssBaseline,
  Tabs,
  Tab,
  Avatar,
  Menu,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  ShoppingCart as ProductsIcon,
  Category as CategoriesIcon,
  CloudUpload as UploadIcon,
  Receipt as TransactionsIcon,
  Article as BlogIcon,
  ExpandMore as ExpandMoreIcon,
  Store as StoreIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import { Pagination } from "@mui/material";

import AdminStats from "../components/AdminStats";
import AdminTable from "../components/AdminTable";
import UserTable from "../components/admin/UserTable";
import ProductsTable from "../components/admin/ProductsTable";
import ProductMerge from "../components/admin/ProductMerge";
import CategoriesTable from "../components/admin/CategoriesTable";
import UploadCSV from "../components/admin/UploadCSV";
import TransactionsTable from "../components/admin/TransactionsTable";
import BlogsTable from "../components/BlogsTable";
import ProfileComponent from "../components/ProfileComponent";
import { logo_url } from "../data/common";
import AdminEmailEditor from "../components/admin/AdminEmailEditor";
import VariantsDuplicatesSuggestions from "../components/admin/VariantsDuplicatesSuggestions";
import ProductsMainTable from "../components/admin/ProductsTable/ProductsMainTable";
import CategoryAssignmentTable from "../components/admin/CategoryAssignmentTable";
import LandingOffersTable from "../components/admin/LandingOffers/LandingOffersTable";
import { OfferTypesTable } from "../components/admin/LandingOffers/OfferTypesTable";
import WalletTransactionsAdmin from "../components/admin/WalletTransactionsAdmin";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    maxWidth: "100%",
    overflowX: "hidden",
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Dashboard() {
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // You can adjust this value as needed

  // useEffect(() => {
  //   // Fetch products when the component mounts or page changes
  //   fetchProducts(page);
  // }, [page]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { text: "Prehľad", icon: <DashboardIcon />, value: 0 },
    { text: "Používatelia", icon: <PeopleIcon />, value: 1 },
    { text: "Produkty", icon: <ProductsIcon />, value: 2 },
    { text: "Kategórie", icon: <CategoriesIcon />, value: 3 },
    { text: "Nahrávania", icon: <UploadIcon />, value: 4 },
    { text: "Transakcie", icon: <TransactionsIcon />, value: 5 },
    { text: "Správa blogu", icon: <BlogIcon />, value: 6 },
    { text: "Obchody", icon: <StoreIcon />, value: 7 },
    { text: "E-maily", icon: <EmailIcon />, value: 8 },
  ];

  // const fetchProducts = async (currentPage) => {
  //   try {
  //     const response = await fetch(
  //       `/api/products?page=${currentPage}&limit=${itemsPerPage}`
  //     );
  //     const data = await response.json();
  //     setProducts(data.products);
  //     setTotalPages(Math.ceil(data.total / itemsPerPage));
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //   }
  // };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="otvoriť menu"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              component="a"
              href="/"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <img
                src="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/bilionbuy_white_logo.png"
                alt="BBLogo"
                style={{ maxHeight: "40px" }}
              />
            </Box>
            <ProfileComponent />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader />
          <List>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.text}
                onClick={() => setTabValue(item.value)}
                selected={tabValue === item.value}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="admin záložky"
          >
            <Tab label="Prehľad" />
            <Tab label="Používatelia" />
            <Tab label="Produkty" />
            <Tab label="Kategórie" />
            <Tab label="Nahrávania" />
            <Tab label="Transakcie" />
            <Tab label="Správa blogu" />
            <Tab label="Obchody" />
            <Tab label="E-maily" />
          </Tabs>
          <Box sx={{ maxWidth: "100%", overflowX: "auto" }}>
            {tabValue === 0 && (
              <Box>
                <Box mb={2}>
                  <Typography variant="h6"></Typography>
                  {/* <AdminStats /> */}
                </Box>
                {/* <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Obchody</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AdminTable />
                </AccordionDetails>
              </Accordion> */}
              </Box>
            )}
            {tabValue === 1 && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Tabuľka používateľov</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <UserTable />
                </AccordionDetails>
              </Accordion>
            )}
            {tabValue === 2 && (
              <Box>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Produkty, kategórie a parametre</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ maxWidth: "100%", overflowX: "auto" }}>
                      <ProductsMainTable />
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Správa typov ponúk</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <OfferTypesTable />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Správa ponúk na úvodnej stránke</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <LandingOffersTable />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Tabuľka produktov</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ProductsTable />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Zlúčenie produktov</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ProductMerge />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Návrhy</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <VariantsDuplicatesSuggestions />
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            {tabValue === 3 && (
              <Box>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Tabuľka kategórií</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CategoriesTable />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Priradenie kategórií</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CategoryAssignmentTable />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Mapovanie kategórií</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CategoryMappings />
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            {tabValue === 4 && (
              <Box>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Import kliknutí</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <UploadCSV
                        postRoute="process-dognet-clicks/"
                        label="Dognet kliknutia"
                      />
                      <UploadCSV
                        postRoute="process-ehub-clicks/"
                        label="eHub kliknutia"
                      />
                      <UploadCSV
                        postRoute="process-affial-clicks/"
                        label="Affial kliknutia"
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Import transakcií</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" flexDirection="column" gap={2}>
                      {/* Add transaction upload components here */}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            {tabValue === 5 && (
              <Box>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Tabuľka transakcií</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TransactionsTable />
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Správa transakcií peňaženky</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <WalletTransactionsAdmin />
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            {tabValue === 6 && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Správa blogu</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <BlogsTable />
                </AccordionDetails>
              </Accordion>
            )}
            {tabValue === 7 && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Tabuľka obchodov</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AdminTable />
                </AccordionDetails>
              </Accordion>
            )}
            {tabValue === 8 && (
              <div>
                <div>Editor e-mailov</div>
                <AdminEmailEditor />
              </div>
            )}
          </Box>
        </Main>
      </Box>
    </>
  );
}
