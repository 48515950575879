// utils/AdminNavs.js

import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export const navigation = [
  { name: "Dashboard", href: "/dashboard", icon: HomeIcon, current: true },
  { name: "Blog", href: "/blog-admin", icon: UsersIcon, current: false },
  { name: "Projekty", href: "#", icon: FolderIcon, current: false },
  { name: "Kalendár", href: "#", icon: CalendarIcon, current: false },
  { name: "Dokumenty", href: "#", icon: DocumentDuplicateIcon, current: false },
  { name: "Reporty", href: "#", icon: ChartPieIcon, current: false },
];

export const teams = [
  { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
  { id: 2, name: "Tailwind Labs", href: "#", initial: "T", current: false },
  { id: 3, name: "Workcation", href: "#", initial: "W", current: false },
];

export const userNavigation = [
  { name: "Your profile", href: "#" },
  { name: "Sign out", href: "#" },
];
