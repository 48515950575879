import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import MyZoneMenu from "../components/MyZoneMenu";
import MyZoneContent from "../components/MyZoneContent";
import { SwipeableDrawer } from "@mui/material"; // Import SwipeableDrawer from MUI

export default function MyZone() {
  const [activeItemId, setActiveItemId] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control drawer visibility

  // Functions to handle opening and closing of the drawer
  const handleDrawerOpen = () => setIsMenuOpen(true);
  const handleDrawerClose = () => setIsMenuOpen(false);

  // Updated function to handle URL hash changes
  const handleHashChange = () => {
    const hash = window.location.hash.slice(1); // Remove the '#' from the hash
    const menuItems = {
      dashboard: 0,
      profile: 1,
      invitations: 2,
      purchases: 3,
      reviews: 4,
      structure: 5,
      commissions: 6,
      invites: 7,
      materials: 8,
    };

    if (hash in menuItems) {
      setActiveItemId(menuItems[hash]);
    }
  };

  // Use effect to handle initial load and hash changes
  useEffect(() => {
    handleHashChange(); // Handle initial load
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <>
      <Helmet>
        {/* Use the robots meta tag to tell search engines not to index this page */}
        <meta name="robots" content="noindex, nofollow" />
        {/* Provide a descriptive title for authenticated users */}
        <title>My Zone - User Dashboard</title>
        {/* Add a description for logged-in users */}
        <meta
          name="description"
          content="Access your personalized zone for managing products, preferences, and settings."
        />
      </Helmet>
      <BasicNavbar />
      <div className="overflow-x-hidden">
        {/* Swipeable Drawer for Menu on Medium and Smaller Screens */}
        <SwipeableDrawer
          anchor="left"
          open={isMenuOpen}
          onClose={handleDrawerClose} // Close drawer when swiped left or clicking outside
          onOpen={handleDrawerOpen} // Open drawer when swiped right
          disableSwipeToOpen={false} // Ensure swiping to open is enabled
          swipeAreaWidth={30} // Width of the area that can trigger the swipe
        >
          <div className="w-[300px]">
            <MyZoneMenu
              setActiveItem={setActiveItemId}
              activeItemId={activeItemId}
              onMenuToggle={handleDrawerClose} // Corrected: Pass the function to close the drawer
            />
          </div>
        </SwipeableDrawer>

        {/* Content Area */}
        <div className="flex flex-row justify-center gap-2">
          {/* Hide the Menu on Medium and Smaller Screens */}
          <div className="pt-12 hidden xl:block">
            <MyZoneMenu
              setActiveItem={setActiveItemId}
              activeItemId={activeItemId}
              onMenuToggle={() => {}} // Provide a no-op function
            />
          </div>

          {/* Content Takes Full Width on Smaller Screens */}
          <div className="w-full xl:w-2/3 px-4">
            {/* Pass the toggle function to the child component */}
            <MyZoneContent
              activeItemId={activeItemId}
              onMenuToggle={handleDrawerOpen} // Pass function to open menu
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
