import React from "react";
import Carrer from "./myzone/Carrer";
import Invites from "./myzone/Invites";
import Structure from "./myzone/Structure";
import Clicks from "./myzone/Clicks";
import Reviews from "./myzone/Reviews";
import Commision from "./myzone/Commision";
import InviteAFriend from "./myzone/InviteAFriend";
import Edit from "./myzone/Edit";
import Materials from "./myzone/Materials";
import { useTranslation } from "react-i18next";

// Map the component for each activeItemId

const MyZoneContent = ({ activeItemId, onMenuToggle }) => {
  const { t } = useTranslation();

  const componentMap = {
    0: <Carrer />,
    1: <Edit />,
    2: <Invites />,
    3: <Clicks />,
    4: <Reviews />,
    5: <Structure />,
    6: <Commision />,
    7: <InviteAFriend />,
    8: <Materials />,
  };

  const renderContent = () => {
    const selectedComponent = componentMap[activeItemId];

    // If the component exists, clone it and pass down the onMenuToggle prop
    if (selectedComponent) {
      return React.cloneElement(selectedComponent, { onMenuToggle, t });
    }

    // Default case
    return <div>Moja Zóna Content</div>;
  };

  return <div>{renderContent()}</div>;
};

export default MyZoneContent;
