import { useState, useEffect } from "react";
import api from "../services/api";
import {
  Image,
  Button,
  Spinner,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import { FaStar } from "react-icons/fa";
import ImageGallery from "react-image-gallery";
import GoogleMap from "./GoogleMap";
import { APIProvider } from "@vis.gl/react-google-maps";

const TravelItem = ({ id }) => {
  const [data, setData] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = useState("full");

  const fetchData = async () => {
    try {
      const response = await api.get(`get-travel-item-data/${id}/`);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const images = data?.additional_photos?.photo?.length
    ? data.additional_photos.photo.map((url) => ({
        original: url,
        thumbnail: url,
      }))
    : [];

  useEffect(() => {
    fetchData();
  }, []);

  const renderHalfStar = () => (
    <div className="relative inline-block w-4 h-4">
      <FaStar className="text-gray-300 absolute top-0 left-0" />
      <FaStar
        className="text-orange absolute top-0 left-0"
        style={{ clipPath: "inset(0 50% 0 0)" }}
      />
    </div>
  );

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    return (
      <div className="flex items-center">
        {Array(fullStars)
          .fill(0)
          .map((_, index) => (
            <FaStar key={index} className="text-orange" />
          ))}
        {hasHalfStar && renderHalfStar()}
        {Array(emptyStars)
          .fill(0)
          .map((_, index) => (
            <FaStar key={index} className="text-gray-200" />
          ))}
      </div>
    );
  };

  const handleOpenModal = (size) => {
    setSize(size);
    onOpen();
  };

  if (!data) {
    return (
      <div className="flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-12 max-w-screen-xl">
      <div className="flex flex-col gap-6">
        <div className="text-orange">
          {data?.destination_country} | {data?.desitanation_locality}
        </div>
        {data.additional_photos && data.additional_photos.photo?.length >= 3 ? (
          <div className="grid grid-cols-3 gap-4 h-[400px]">
            <div className="col-span-2 h-full overflow-hidden">
              <img
                src={data?.image}
                className="w-full h-full object-cover rounded-lg cursor-pointer"
                onClick={() => handleOpenModal("xl")}
              />
            </div>

            <div
              className="grid grid-rows-2 gap-4 h-full"
              onClick={() => handleOpenModal("xl")}
            >
              <div className="overflow-hidden h-full">
                <img
                  src={data?.additional_photos.photo[1]}
                  className="w-full h-[200px] object-cover rounded-lg cursor-pointer"
                />
              </div>
              <div className="grid grid-cols-2 gap-4 h-full overflow-hidden">
                <div className="overflow-hidden h-full">
                  <img
                    src={data?.additional_photos.photo[2]}
                    className="w-full h-full object-cover rounded-lg cursor-pointer"
                  />
                </div>
                <div className="overflow-hidden h-full">
                  <img
                    src={data?.additional_photos.photo[3]}
                    className="w-full h-full object-cover rounded-lg cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <img
            src={data?.image}
            className="w-full h-[400px] object-cover rounded-lg"
          />
        )}

        <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
          <ModalContent>
            {(onClose) => (
              <>
                <ModalBody>
                  <div className="">
                    <ImageGallery items={images} />
                  </div>
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>
        <div className="rounded-lg">
          {data.hotel_coords &&
          data.hotel_coords[0] !== null &&
          data.hotel_coords[1] !== null ? (
            <GoogleMap lat={data.hotel_coords[0]} lng={data.hotel_coords[1]} />
          ) : null}
        </div>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col gap-4 pt-4">
            <div className="font-bold text-4xl">
              {data?.destination_hotel_name}
            </div>
            <div className="flex items-center">
              {renderStars(data?.hotel_rating)}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-right text-2xl font-bold text-orange">
              <span className="text-lightgray text-base">od</span>{" "}
              {data.min_price} {data.currency ? data.currency : "€"}
            </div>
            <Button className="bg-orange text-white px-8 rounded-3xl">
              Zobraziť ponuky &rarr;
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelItem;
