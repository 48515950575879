import { Button } from "@nextui-org/react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import { Input, Image, Checkbox, Link } from "@nextui-org/react";
import { useState, useEffect } from "react";
import api from "../services/api";
import { registerUser } from "../utils/Register";
import login from "../utils/Login";
import React from "react";
import validator from "validator";
import GoogleSignIn from "../components/GoogleComponent";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export default function RegisterComponent() {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState(""); // Assuming you want to capture email from input
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState("junior2nextui.org");
  const [isSelected, setIsSelected] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const minLength = 8; // Define minimum password length
    const maxLength = 20; // Define maximum password length
    const errors = [];

    // Check minimum length
    if (!validator.isLength(password, { min: minLength })) {
      errors.push(`Heslo musí obsahovať aspoň ${minLength} znakov.`);
    }

    // Check maximum length
    if (!validator.isLength(password, { max: maxLength })) {
      errors.push(`Heslo nesmie obsahovať viac ako ${maxLength} znakov.`);
    }

    return errors.length === 0 ? { valid: true } : { valid: false, errors };
  };

  const validateEmail = (email) => {
    return validator.isEmail(email);
  };

  const validateConfirmPassword = (password, confirmPassword) => {
    return password === confirmPassword;
  };

  const emailIsInvalid = React.useMemo(() => {
    if (email === "") return false;
    return validateEmail(email) ? false : true;
  }, [email]);

  const passwordIsInvalid = React.useMemo(() => {
    if (password === "") return false;
    return validatePassword(password);
  }, [password]);

  const confirmPasswordIsInvalid = React.useMemo(() => {
    if (confirmPassword === "") return false;
    return validateConfirmPassword(password, confirmPassword) ? false : true;
  }, [confirmPassword]);

  const handleRegister = () => {
    const referralCode = localStorage.getItem("referralCode");
    registerUser({ email, password, confirmPassword, referralCode })
      .then(() => {
        console.log("Registered successfully!");
        const log_in = login(email, password, setIsLoading);
        navigate("/");
      })
      .catch((err) => {
        alert("Failed to register");
        console.log(err); // It's good to log the error for debugging
      });
  };

  const handleReferal = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const ref = queryParams.get("ref"); // 'ref' is the query parameter for the referral code

    if (ref) {
      // Store the referral code in localStorage
      localStorage.setItem("referralCode", ref);
      console.log("Referral: ", ref);
    }
  };
  useEffect(() => {
    handleReferal();
  }, []); // The empty dependency array ensures this runs only once on mount

  return (
    <>
      <div className="py-24">
        <div className="flex w-full items-center justify-center lg:gap-24 px-4 lg:px-0">
          <div className="">
            {" "}
            <Link
              href="/login"
              className="flex flex-row gap-2 items-center text-blue justify-center lg:justify-end"
            >
              {t("Už ste členom")}? <FaUser />
            </Link>
            <div className="flex gap-12 py-12 flex-col lg:flex-row items-center text-center lg:text-left lg:items-left">
              <div className="text-5xl font-bold">{t("Registrácia")}</div>
              <div className="max-w-[300px]">
                {t(
                  "We need you to help us with some basic information for your account creation. Here are our terms and conditins. Please read them carefully. We are GDRP compliiant"
                )}
              </div>
            </div>
            <hr />
            <div className="grid lg:grid-cols-2 grid-rows-2 gap-x-8 gap-y-4 py-4">
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="firstname"
                variant="bordered"
                name="fisrtname"
                type="text"
                label={t("Meno")}
                placeholder="John"
                required
                className="block  w-full border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="lastname"
                variant="bordered"
                name="lastname"
                type="text"
                label={t("Priezvisko")}
                placeholder="Smith"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="company"
                variant="bordered"
                name="company"
                type="text"
                label="Firma"
                placeholder="John Smith Inc."
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                variant="bordered"
                name="email"
                type="email"
                label="Email"
                placeholder="johnsmith@bilionbuy.com"
                autoComplete="email"
                emailIsInvalid={emailIsInvalid}
                color={emailIsInvalid ? "danger" : "success"}
                errorMessage={emailIsInvalid && "Please enter a valid email"}
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <hr />
            <div className="grid lg:grid-cols-2 gap-8 py-4">
              <Input
                value={password}
                id="password"
                variant="bordered"
                name="password"
                type="password"
                label="Heslo"
                placeholder="********"
                autoComplete="current-password"
                required
                onChange={(e) => setPassword(e.target.value)}
                passwordIsInvalid={passwordIsInvalid.valid}
                color={!passwordIsInvalid.valid ? "danger" : "success"}
                errorMessage={passwordIsInvalid && passwordIsInvalid.errors}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <Input
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                id="password"
                variant="bordered"
                name="password"
                type="password"
                label="Potvrďte heslo"
                placeholder="********"
                autoComplete="current-password"
                required
                confirmPasswordIsInvalid={confirmPasswordIsInvalid}
                color={confirmPasswordIsInvalid ? "danger" : "success"}
                errorMessage={
                  confirmPasswordIsInvalid && "Passwords do not match"
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <hr />
            <div>
              <div className="flex flex-row justify-between gap-2 py-8">
                <Checkbox isSelected={isSelected} onValueChange={setIsSelected}>
                  {t("I agree with")}{" "}
                  <Link
                    className="font-bold"
                    href="/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("terms and conditions")}
                  </Link>
                </Checkbox>
                <Button
                  isDisabled={true}
                  color="primary"
                  className="flex justify-center rounded-3xl px-8 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm "
                  onClick={handleRegister}
                >
                  {t("Registrovať")}
                </Button>
              </div>{" "}
            </div>
            {/* <GoogleSignIn className=""></GoogleSignIn> */}
          </div>
          <div>
            <Image
              className="hidden lg:block"
              src="https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/img/tree.png"
              isBlurred
            ></Image>
          </div>
        </div>
      </div>
    </>
  );
}
