import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { ProductSearch } from "./ProductSearch";
import api from "../../../services/api";
import { toast } from "react-toastify";

export const OfferModal = ({
  open,
  onClose,
  offer,
  type,
  country,
  onSuccess,
}) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedShop, setSelectedShop] = useState("");
  const [discount, setDiscount] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      if (offer) {
        setSelectedProduct({
          id: offer.product.id,
          product_name: offer.product.product_name,
          product_description: offer.product.product_description,
          product_image_url: offer.product.product_image_url,
          linkedshops: [
            {
              id: offer.shop.id,
              shop_name: offer.shop.name,
            },
          ],
        });
        setSelectedShop(offer.shop.id);
        setDiscount(offer.discount?.toString() || "");
      } else {
        setSelectedProduct(null);
        setSelectedShop("");
        setDiscount("");
      }
    }
  }, [open, offer]);

  const handleShopChange = (event) => {
    setSelectedShop(event.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedProduct || !selectedShop) {
      toast.error("Prosím vyberte produkt a obchod");
      return;
    }

    setLoading(true);
    try {
      const payload = {
        type,
        country,
        product_id: selectedProduct.id,
        shop_id: selectedShop,
        discount: discount ? parseFloat(discount) : null,
      };

      let response;
      if (offer) {
        response = await api.put(`update-offer/${offer.id}/`, payload);
      } else {
        response = await api.post("create-offer/", payload);
      }

      toast.success(`Ponuka bola úspešne ${offer ? "upravená" : "vytvorená"}`);
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error submitting offer:", error);
      toast.error(`Nepodarilo sa ${offer ? "upraviť" : "vytvoriť"} ponuku`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown
    >
      <DialogTitle>
        {offer ? "Upraviť ponuku" : "Vytvoriť novú ponuku"}
      </DialogTitle>
      <DialogContent>
        <div className="space-y-4 mt-4">
          <ProductSearch
            selectedProduct={selectedProduct}
            onSelect={setSelectedProduct}
            country={country}
            placeholder="Vyhľadať produkt"
          />

          {selectedProduct && selectedProduct.linkedshops && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="shop-select-label">Obchod</InputLabel>
              <Select
                labelId="shop-select-label"
                id="shop-select"
                value={selectedShop}
                onChange={handleShopChange}
                label="Obchod"
              >
                {selectedProduct.linkedshops.map((shop) => (
                  <MenuItem key={shop.id} value={shop.id}>
                    {shop.shop_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            fullWidth
            label="Zľava (%) - voliteľné"
            type="number"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            sx={{ mt: 2 }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Zrušiť
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Ukladá sa..." : "Uložiť"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
