import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { Add, Remove, Clear, Check } from "@mui/icons-material";
import { toast } from "react-toastify";
import RichTextEditor from "../RichTextEditor";
import DualAutocomplete from "../DualAutocomplete";
import AutoselectFetchCreate from "../AutoselectFetchCreate";
import api from "../../services/api";
import debounce from "lodash/debounce";
import Chip from "@mui/material/Chip";

const ProductEditModal = ({ isOpen, onClose, productId, onUpdate }) => {
  const [editedProduct, setEditedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [iframes, setIframes] = useState([]);
  const [additionalPhotos, setAdditionalPhotos] = useState([]);
  const [variantGroup, setVariantGroup] = useState(null);
  const [nameError, setNameError] = useState("");
  const [showParameterInput, setShowParameterInput] = useState(false);
  const [editingParameter, setEditingParameter] = useState(null);
  const [newParameter, setNewParameter] = useState({ name: "", value: "" });
  const [linkedShops, setLinkedShops] = useState([]);
  const [foreignLinkedShops, setForeignLinkedShops] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryInputValue, setCategoryInputValue] = useState("");

  useEffect(() => {
    const fetchProductData = async () => {
      if (isOpen && productId) {
        try {
          setEditedProduct(null);
          const response = await api.get(`get-product-edit-data/${productId}/`);
          const productData = response.data;

          console.log("Fetched product data:", productData);

          setEditedProduct(productData);
          setContent(productData.product_description || "");
          setIframes(productData.video_iframe || []);
          setAdditionalPhotos(productData.additional_photos || []);
          setVariantGroup(productData.variant_group || null);
          setLinkedShops(productData.linked_shops || []);
          setForeignLinkedShops(productData.foreign_linked_shops || []);

          if (productData.product_assigned_category) {
            fetchCategory(productData.product_assigned_category);
          }
        } catch (error) {
          console.error("Error fetching product data:", error);
          toast.error("Failed to load product data");
          onClose();
        }
      }
    };

    fetchProductData();
  }, [isOpen, productId]);

  useEffect(() => {
    console.log("Modal state:", { isOpen, editedProduct });
  }, [isOpen, editedProduct]);

  useEffect(() => {
    // Fetch all categories when the modal opens
    fetchCategories();
  }, [isOpen]);

  const fetchCategories = async () => {
    try {
      const response = await api.get("get-admin-categories/");
      setCategories(response.data.results);
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Failed to load categories");
    }
  };

  const fetchCategory = async (categoryId) => {
    try {
      const response = await api.get(`get-category/?id=${categoryId}`);
      const categoryData = Array.isArray(response.data)
        ? response.data[0]
        : response.data;
      setSelectedCategory(categoryData);
      setCategoryInputValue(categoryData.category_fullname || "");
    } catch (error) {
      console.error("Error fetching category:", error);
      toast.error("Failed to load product category");
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.length > 2) {
        try {
          const response = await api.get(`search-categories/?query=${query}`);
          const newOptions = Array.isArray(response.data) ? response.data : [];
          setCategories(newOptions);
        } catch (error) {
          console.error("Error searching categories: ", error);
        }
      }
    }, 300),
    []
  );

  const handleCategoryInputChange = (event, newInputValue) => {
    setCategoryInputValue(newInputValue);
    debouncedSearch(newInputValue);
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
    setEditedProduct((prev) => ({
      ...prev,
      product_assigned_category: newValue ? newValue.id : null,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prev) => ({ ...prev, [name]: value }));
    if (name === "product_name") setNameError("");
  };

  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  const handleVariantGroupChange = (newVariantGroup) => {
    setVariantGroup(newVariantGroup);
  };

  const resetVariantGroup = () => {
    setVariantGroup(null);
  };

  const handleAddPhoto = () => {
    setAdditionalPhotos([...additionalPhotos, ""]);
  };

  const handleRemovePhoto = (index) => {
    setAdditionalPhotos(additionalPhotos.filter((_, i) => i !== index));
  };

  const handlePhotoChange = (index, value) => {
    const updatedPhotos = [...additionalPhotos];
    updatedPhotos[index] = value;
    setAdditionalPhotos(updatedPhotos);
  };

  const handleAddIframe = () => {
    setIframes([...iframes, ""]);
  };

  const handleRemoveIframe = (index) => {
    setIframes(iframes.filter((_, i) => i !== index));
  };

  const handleIframeChange = (index, value) => {
    const updatedIframes = [...iframes];
    updatedIframes[index] = value;
    setIframes(updatedIframes);
  };

  const handleEditParameter = (index) => {
    setEditingParameter(index);
    const param = editedProduct.parameters[index];
    setNewParameter({ name: param.name, value: param.value });
  };

  const confirmParameter = () => {
    if (!newParameter.name || !newParameter.value) {
      toast.error("Názov a hodnota parametra nemôžu byť prázdne");
      return;
    }
    if (editingParameter !== null) {
      setEditedProduct((prev) => {
        const updatedParameters = [...prev.parameters];
        updatedParameters[editingParameter] = newParameter;
        return { ...prev, parameters: updatedParameters };
      });
      setEditingParameter(null);
    } else {
      const exists = editedProduct.parameters.some(
        (param) =>
          param.name === newParameter.name && param.value === newParameter.value
      );
      if (!exists) {
        setEditedProduct((prev) => ({
          ...prev,
          parameters: [...prev.parameters, newParameter],
        }));
      } else {
        toast.info("Parameter už existuje.");
      }
      setShowParameterInput(false);
    }
    setNewParameter({ name: "", value: "" });
  };

  const handleDeleteParameter = (index) => {
    setEditedProduct((prev) => {
      const updatedParameters = [...prev.parameters];
      updatedParameters.splice(index, 1);
      return { ...prev, parameters: updatedParameters };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setNameError("");

    try {
      const response = await api.post("update-product/", {
        product_id: editedProduct.id,
        product_name: editedProduct.product_name,
        product_description: content,
        product_manufacturer: editedProduct.product_manufacturer,
        product_category: editedProduct.product_category,
        product_price_vat: editedProduct.product_price_vat,
        product_params: editedProduct.parameters,
        product_assigned_category: selectedCategory
          ? selectedCategory.id
          : null,
        product_ean: editedProduct.product_ean,
        video_url: editedProduct.video_url,
        video_iframe: iframes,
        additional_photos: additionalPhotos,
        variant_group: variantGroup ? variantGroup.id : null,
      });

      if (response.status === 200) {
        toast.success("Produkt bol úspešne aktualizovaný!");
        onClose();
        // If you have a function to refresh the product list, call it here
        // onProductUpdated(editedProduct.id);
      } else {
        throw new Error("Failed to update the product");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data === "Duplicate product name"
      ) {
        setNameError("The name already exists");
      } else {
        console.error("Chyba pri aktualizácii produktu:", error);
        toast.error("Aktualizácia zlyhala: " + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: 1000,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
    overflowY: "auto",
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="product-edit-modal"
      aria-describedby="product-edit-form"
    >
      <Box sx={modalStyle}>
        {!editedProduct ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Typography variant="h6" component="h2" mb={2}>
              Upraviť produkt: {editedProduct.product_name}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Názov produktu"
                  name="product_name"
                  value={editedProduct.product_name || ""}
                  onChange={handleInputChange}
                  error={!!nameError}
                  helperText={nameError}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Výrobca"
                  name="product_manufacturer"
                  value={editedProduct.product_manufacturer || ""}
                  onChange={handleInputChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="EAN"
                  name="product_ean"
                  value={editedProduct.product_ean || ""}
                  onChange={handleInputChange}
                />
                <Typography variant="body1" mt={2}>
                  Pôvodná kategória: {editedProduct.product_category}
                </Typography>
                <Autocomplete
                  disablePortal
                  id="category-selector"
                  options={categories}
                  getOptionLabel={(option) => option?.category_fullname || ""}
                  renderOption={(props, option) => (
                    <li {...props}>{option?.category_fullname || ""}</li>
                  )}
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  onInputChange={handleCategoryInputChange}
                  inputValue={categoryInputValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Priradená kategória"
                      variant="outlined"
                      margin="normal"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                />
                Skupina variantov
                <AutoselectFetchCreate
                  label="Skupina variantov"
                  value={variantGroup}
                  onChange={handleVariantGroupChange}
                  onReset={resetVariantGroup}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  src={editedProduct.product_image_url}
                  alt={editedProduct.product_name}
                  style={{
                    width: "100%",
                    maxHeight: 300,
                    objectFit: "contain",
                  }}
                />
              </Grid>
            </Grid>

            <Typography variant="h6" mt={2} mb={1}>
              Popis produktu
            </Typography>
            <RichTextEditor
              content={content}
              setContent={handleContentChange}
            />

            <Typography variant="h6" mt={2} mb={1}>
              Parametre
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
              {editedProduct?.parameters?.map((param, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleEditParameter(index)}
                  >
                    {param.name}: {param.value}
                  </Button>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteParameter(index)}
                  >
                    <Clear />
                  </IconButton>
                </Box>
              ))}
            </Box>
            {showParameterInput ? (
              <Box display="flex" alignItems="center" gap={1}>
                <DualAutocomplete
                  initialName={newParameter.name}
                  initialValue={newParameter.value}
                  onNameChange={(name) =>
                    setNewParameter((prev) => ({ ...prev, name }))
                  }
                  onValueChange={(value) =>
                    setNewParameter((prev) => ({ ...prev, value }))
                  }
                />
                <IconButton onClick={confirmParameter}>
                  <Check />
                </IconButton>
                <IconButton onClick={() => setShowParameterInput(false)}>
                  <Clear />
                </IconButton>
              </Box>
            ) : (
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() => setShowParameterInput(true)}
              >
                Pridať parameter
              </Button>
            )}

            <Typography variant="h6" mt={2} mb={1}>
              Dodatočné fotky
            </Typography>
            {additionalPhotos.map((photo, index) => (
              <Grid
                container
                spacing={2}
                alignItems="center"
                key={index}
                mb={1}
              >
                <Grid item xs={10}>
                  <TextField
                    label={`URL fotky ${index + 1}`}
                    variant="outlined"
                    value={photo}
                    onChange={(e) => handlePhotoChange(index, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleRemovePhoto(index)}>
                    <Remove />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button startIcon={<Add />} onClick={handleAddPhoto}>
              Pridať fotku
            </Button>

            <Typography variant="h6" mt={2} mb={1}>
              Video iFramy
            </Typography>
            {iframes.map((iframe, index) => (
              <Grid
                container
                spacing={2}
                alignItems="center"
                key={index}
                mb={1}
              >
                <Grid item xs={10}>
                  <TextField
                    label={`iFrame ${index + 1}`}
                    variant="outlined"
                    value={iframe}
                    onChange={(e) => handleIframeChange(index, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleRemoveIframe(index)}>
                    <Remove />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button startIcon={<Add />} onClick={handleAddIframe}>
              Pridať iFrame
            </Button>

            <Typography variant="h6" mt={2} mb={1}>
              Priradené obchody
            </Typography>
            {linkedShops.map((shop) => (
              <Box
                key={shop.id}
                display="flex"
                justifyContent="space-between"
                p={1}
                mb={1}
                bgcolor="background.paper"
              >
                <a href={shop.url}>{shop.shop_name}</a>
                <Typography>{shop.price_vat}</Typography>
              </Box>
            ))}

            <Typography variant="h6" mt={2} mb={1}>
              Zahraničné obchody
            </Typography>
            {foreignLinkedShops.map((shop) => (
              <Box
                key={shop.id}
                display="flex"
                justifyContent="space-between"
                p={1}
                mb={1}
                bgcolor="background.paper"
              >
                <a href={shop.url}>{shop.shop_name}</a>
                <Typography>{shop.price_vat}</Typography>
              </Box>
            ))}

            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button onClick={onClose} sx={{ mr: 1 }}>
                Zrušiť
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Uložiť zmeny"}
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default ProductEditModal;
