import React, { useState } from "react";
import { TextField, Button, CircularProgress } from "@mui/material";
import { Save } from "@mui/icons-material";

const EANInput = React.memo(({ product, onUpdate }) => {
  const [localEAN, setLocalEAN] = useState(product.product_ean || "");
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setLocalEAN(newValue);
    setIsEditing(newValue !== product.product_ean && newValue !== "");
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await onUpdate(localEAN);
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving EAN:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        value={localEAN}
        onChange={handleChange}
        variant="outlined"
        size="small"
        disabled={isSaving}
      />
      {isEditing && (
        <Button
          onClick={handleSave}
          startIcon={isSaving ? <CircularProgress size={20} /> : <Save />}
          size="small"
          style={{ marginLeft: 8 }}
          disabled={isSaving}
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      )}
    </div>
  );
});

export default EANInput;
