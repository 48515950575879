import React, { useState } from "react";
import { TextField, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";

const ParameterInput = ({ product, param, onUpdate, onDelete }) => {
  const [value, setValue] = useState(param.value);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    if (value !== param.value) {
      onUpdate(value);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        size="small"
        fullWidth
      />
      <IconButton size="small" onClick={onDelete}>
        <Delete fontSize="small" />
      </IconButton>
    </div>
  );
};

export default ParameterInput;
