import React, { useState, useEffect } from "react";
import { Image } from "@nextui-org/react";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Card,
  Typography,
  CircularProgress, // Import CircularProgress from Material UI for spinner
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Spinner } from "@nextui-org/react";
import api from "../../services/api";

export const ProductCard = ({ itemData }) => {
  return (
    <div className="border-1 p-2 rounded-xl shadow-md bg-background/60 dark:bg-default-100/50 md:w-[450px] w-[320px]">
      <div>
        <div className="flex flex-row items-center gap-4 overflow-hidden">
          <div className="">
            <Image
              alt={itemData.product_name}
              className="object-contain w-16 h-16 max-w-[6rem] max-h-[6rem]"
              shadow="none"
              src={itemData.product_image_url}
            />
          </div>
          <div className="">
            <div className="">
              <div className="">
                <h3 className="font-semibold text-foreground/90 md:line-clamp-1 line-clamp-2 md:w-auto w-[220px]">
                  {itemData.product_name}
                </h3>
                <h1 className="text-xs text-lightgray line-clamp-2 w-[220px]">
                  {itemData.product_description}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductSelection = ({
  onProductSelect,
  targetProduct,
  mergeProducts,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productName, setProductName] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false); // State to manage loading

  const handlePlusClick = () => {
    setIsModalOpen(true);
  };

  const handleSearch = async () => {
    setLoading(true); // Set loading to true when starting the search
    try {
      const response = await api.get(`get-product-data`, {
        params: { product_name: productName, product_url: productUrl },
      });

      const data = response.data;
      const productsArray = Object.keys(data).map((key) => data[key]);

      // Filter out products that are already selected
      const filteredProducts = productsArray.filter(
        (product) =>
          product.id !== (targetProduct && targetProduct.id) &&
          !mergeProducts.some((p) => p.id === product.id)
      );

      setSearchResults(filteredProducts);
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setLoading(false); // Set loading to false when the search is complete
    }
  };

  const handleProductSelect = (product) => {
    onProductSelect(product);
    setIsModalOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          width: 400,
          height: 800,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          border: "1px dashed rgba(0, 0, 0, 0.12)",
        }}
        onClick={handlePlusClick}
      >
        <AddIcon sx={{ fontSize: 60, color: "rgba(0, 0, 0, 0.54)" }} />
      </Card>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Vyhľadať produkty</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Názov produktu"
            fullWidth
            variant="outlined"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="URL"
            fullWidth
            variant="outlined"
            value={productUrl}
            onChange={(e) => setProductUrl(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Zrušiť</Button>
          <Button onClick={handleSearch}>Hľadať</Button>
        </DialogActions>
        <DialogContent>
          {loading ? (
            <div className="flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            searchResults.map((product) => (
              <div
                key={product.id}
                onClick={() => handleProductSelect(product)}
                className="mb-2 cursor-pointer"
              >
                <ProductCard itemData={product} />
              </div>
            ))
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const SelectedProduct = ({ data, onRemove }) => {
  return (
    <div className="w-96 h-[800px] flex flex-col items-center border border-solid rounded-lg shadow-md border-gray-200 p-4 relative">
      <button
        className="absolute top-2 right-2 text-red-500 hover:text-red-700"
        onClick={onRemove}
      >
        ✕
      </button>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">{data.product_name}</h2>
      </div>

      <div className="flex justify-center items-center max-w-[200px] max-h-[200px] overflow-hidden mb-4">
        <img
          src={data.product_image_url}
          alt={data.product_name}
          className="w-full h-full object-contain"
        />
      </div>

      <div className="mb-2">
        <p className="text-sm text-gray-600">{data.assigned_category_path}</p>
      </div>

      <div className="mb-2">
        <p className="text-sm line-clamp-6">{data.product_description}</p>
      </div>

      <div className="mb-4">
        <p className="text-sm text-gray-600">{data.parameters}</p>
      </div>

      {data.linkedshops.map((linkedshop, index) => (
        <div key={index} className="flex justify-between w-full mb-2">
          <p className="text-sm">{linkedshop.shop_name}</p>
          <p className="text-sm">
            {linkedshop.price_vat} {linkedshop.currency}
          </p>
        </div>
      ))}
    </div>
  );
};

const ProductMerge = () => {
  const [targetProduct, setTargetProduct] = useState(null);
  const [mergeProducts, setMergeProducts] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to manage loading

  const handleTargetProductSelect = (product) => {
    setTargetProduct(product);
  };

  const handleMergeProductSelect = (product) => {
    const isProductAlreadyAdded = mergeProducts.some(
      (p) => p.id === product.id
    );
    const isTargetProduct = targetProduct && targetProduct.id === product.id;
    if (isProductAlreadyAdded || isTargetProduct) {
      console.warn(
        "Product already added to merge list or is the target product"
      );
      return;
    }
    setMergeProducts([...mergeProducts, product]);
  };

  const handleRemoveTargetProduct = () => {
    setTargetProduct(null);
  };

  const handleRemoveProduct = (index) => {
    setMergeProducts(mergeProducts.filter((_, i) => i !== index));
  };

  const handleOpenConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleConfirmMerge = async () => {
    setIsLoading(true); // Start loading
    try {
      const productsToMergeIds = mergeProducts.map((product) => product.id);
      const response = await api.post("merge-products/", {
        target: targetProduct.id,
        products_to_merge: productsToMergeIds,
      });

      if (response.status === 200) {
        toast.success("Products merged successfully!");
        setTargetProduct(null);
        setMergeProducts([]);
      } else {
        toast.error("Error merging products.");
      }
    } catch (error) {
      toast.error("Error merging products.");
    } finally {
      setIsLoading(false); // End loading
      setIsConfirmationModalOpen(false); // Close the modal after the operation
    }
  };

  const isMergeButtonEnabled = targetProduct && mergeProducts.length > 0;

  return (
    <div className="p-4 flex flex-col gap-4">
      <div className="flex flex-row overflow-x-scroll">
        {/* Target Product Section */}
        <div className="pr-4">
          <h6 className="text-lg font-semibold mb-4">Cieľový produkt</h6>
          {targetProduct ? (
            <SelectedProduct
              data={targetProduct}
              onRemove={handleRemoveTargetProduct}
            />
          ) : (
            <ProductSelection
              onProductSelect={handleTargetProductSelect}
              targetProduct={targetProduct}
              mergeProducts={mergeProducts}
            />
          )}
        </div>

        {/* Divider Line */}
        <div className="border-r border-gray-300 mx-4"></div>

        {/* Merge Products Section */}
        <div className="pl-4">
          <h6 className="text-lg font-semibold mb-4">Produkty na spojenie</h6>

          {/* Flex container for merge products and "+" button */}
          <div className="flex items-center gap-4 flex-row overflow-x-scroll">
            {mergeProducts.map((product, index) => (
              <SelectedProduct
                key={index}
                data={product}
                onRemove={() => handleRemoveProduct(index)}
              />
            ))}
            {/* "+" Button to add more products */}
            <ProductSelection
              onProductSelect={handleMergeProductSelect}
              targetProduct={targetProduct}
              mergeProducts={mergeProducts}
            />
          </div>
        </div>
      </div>

      {/* Confirmation Button */}
      <button
        className={`bg-primary text-white px-4 py-2 rounded shadow hover:bg-blue-600 ${
          !isMergeButtonEnabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={handleOpenConfirmationModal}
        disabled={!isMergeButtonEnabled}
      >
        Potvrdiť zlúčenie
      </button>

      {/* Confirmation Modal */}
      <Dialog
        open={isConfirmationModalOpen}
        onClose={handleCloseConfirmationModal}
        disableScrollLock={true}
      >
        <DialogTitle>Potvrdiť zlúčenie</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Ste si istí, že chcete tieto produkty zlúčiť?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmationModal}
            color="primary"
            disabled={isLoading}
          >
            Zrušiť
          </Button>
          <Button
            onClick={handleConfirmMerge}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Potvrďte"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductMerge;
