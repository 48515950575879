import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ReferralCodeHandler = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const refCode = searchParams.get("ref");

    if (refCode) {
      localStorage.setItem("referralCode", refCode);
    }
  }, [location]);

  return <>{children}</>;
};

export default ReferralCodeHandler;
