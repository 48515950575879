import React from "react";
import { useState, useMemo } from "react";
import api from "../services/api";
import { Button, Textarea, Image, Spinner } from "@nextui-org/react";
import validator from "validator";
import { motion, AnimatePresence } from "framer-motion";
import { FaCopy } from "react-icons/fa";
import { Tooltip } from "@nextui-org/tooltip";
import { useTranslation } from "react-i18next";

const SupportMail = () => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState("");
  const copyText = () => {
    const text = document.getElementById("copyText").innerText;
    navigator.clipboard.writeText(text);
  };

  const validateEmail = (email) => {
    return validator.isEmail(email);
  };

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 20,
      maxHeight: 0, // Use maxHeight for smoother transitions
      overflow: "hidden",
      transition: {
        maxHeight: { duration: 0.5, ease: "easeInOut" },
        opacity: { delay: 0.5 },
        y: { delay: 0.5 },
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      maxHeight: 200, // Adjust based on the content size, should be more than the content's natural height
      transition: {
        maxHeight: { duration: 0.5, ease: "easeInOut" },
        opacity: { duration: 0.5, delay: 0.5 },
        y: { duration: 0.5, delay: 0.5 },
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        opacity: { duration: 0.3 },
        y: { duration: 0.3 },
        maxHeight: { duration: 0.5, delay: 0.3, ease: "easeInOut" },
      },
      transitionEnd: {
        maxHeight: 0,
      },
    },
  };

  const sendSupportInquiry = async () => {
    setIsLoading(true);
    setSuccess(""); // Reset success message initially
    setError(""); // Reset error message initially
    try {
      const response = await api.post("support-message/", {
        user_email: localStorage.getItem("username"),
        text: text,
      });
      setSuccess("Správa bola úspešne odoslaná");
      setTimeout(() => {
        setSuccess(""); // Clear success message after 5 seconds
      }, 5000);
    } catch (error) {
      console.error("An error occurred:", error);
      setError(error.response.data.message);
      setTimeout(() => {
        setError(""); // Clear success message after 5 seconds
      }, 5000);
    }
    setIsLoading(false);
  };
  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-center">
        <Image
          src="https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/img/bb-white.png"
          className="w-52 "
        />
      </div>
      <div className="text-xl font-bold text-black text-center">
        {t("Pomoc a podpora")}
      </div>
      <div>
        <Textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder=""
          variant="bordered"
        />
      </div>
      <div>
        <Button
          className="bg-blue text-white w-full py-6"
          onClick={sendSupportInquiry}
        >
          {isLoading ? <Spinner color="white" /> : <a>{t("Odoslať")}</a>}
        </Button>
      </div>
      <AnimatePresence>
        {error && (
          <motion.div
            key="error"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
          >
            <p className="text-red-500 border-1 border-red-500 rounded-lg p-4 text-sm">
              {error}
            </p>
          </motion.div>
        )}
        {success && (
          <motion.div
            key="success"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
          >
            <p className="text-green-500 border-1 border-green-500 rounded-lg p-4 text-sm">
              {success}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default SupportMail;
