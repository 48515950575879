import React, { useState, useEffect } from "react";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import api from "../services/api"; // Use your custom API service

function CustomAutocomplete({ fetchCall, createCall, value, setValue }) {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  // Function to fetch options from the API
  const fetchOptions = async (query) => {
    setLoading(true);
    try {
      const response = await api.get(fetchCall, { params: { query } }); // Use custom API service
      setOptions(response.data);
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false);
    }
  };

  // Effect to fetch options when the input value changes
  useEffect(() => {
    if (inputValue) {
      fetchOptions(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue]);

  const handleCreate = async (newValue) => {
    try {
      const response = await api.post(createCall, { query: newValue });
      const createdVariant = response.data;

      setValue(createdVariant); // Correctly update the value using setValue prop
      setOptions((prev) => [...prev, createdVariant]);
    } catch (error) {
      console.error("Error creating new item:", error);
    }
  };

  return (
    <Autocomplete
      disablePortal
      value={value} // Controlled by parent component
      onChange={(event, newValue) => {
        if (newValue && newValue.inputValue) {
          // When the "+ Create" option is selected
          handleCreate(newValue.inputValue);
        } else {
          setValue(newValue); // Update the selected value directly from the parent
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      filterOptions={(x) => x}
      options={options.concat({
        inputValue,
        label: `+ Vytvoriť "${inputValue}"`,
      })}
      getOptionLabel={(option) =>
        option.inputValue ? option.label : option.name
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Vyhľadť alebo vytvorť"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default CustomAutocomplete;
