import React, { useEffect } from "react";
import { Card, CardBody, Image, Button, Link } from "@nextui-org/react";
import { HeartIcon } from "./HeartIcon";

export const TravelCard = ({ itemData }) => {
  useEffect(() => {
    // Get original body overflow value to restore later
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  return (
    <Card
      isBlurred
      className="border-none bg-background/60 dark:bg-default-100/50 md:w-[450px] w-[320px]"
      shadow="md"
      as={Link}
      href={`/view-travel/${itemData.id}`}
    >
      <CardBody>
        <div className="flex flex-row items-center gap-4 overflow-hidden">
          <div className="">
            <Image
              alt={itemData.image}
              className="object-contain w-16 h-16 max-w-[6rem] max-h-[6rem]"
              shadow="none"
              src={itemData.image}
            />
          </div>
          <div className="">
            <div className="">
              <div className="">
                <h3 className="font-semibold text-foreground/90 line-clamp-1">
                  {itemData.destination_hotel_name}
                </h3>
                <h1 className="text-xs text-lightgray line-clamp-2">
                  {itemData.destination_country} |{" "}
                  {itemData.desitanation_locality}
                </h1>
                {/* Additional components can be uncommented or added here */}
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
