import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce"; // Import debounce from lodash
import { NextUIProvider, Skeleton, Card, Button } from "@nextui-org/react";
import { TextField } from "@mui/material"; // Import TextField from MUI
import { Helmet } from "react-helmet-async"; // Import Helmet for SEO
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import SearchBar from "../components/SearchBar";
import api from "../services/api";
import { useTranslation } from "react-i18next";
import Translate from "../components/TranslationAPI";
import ShopCardLarge from "../components/ShopCardLarge";

function Shops() {
  const [searchOpen, setSearchOpen] = useState(false);
  const [selected, setSelected] = useState("all");
  const [shops, setShops] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [nameFilter, setNameFilter] = useState("");
  const [debouncedNameFilter, setDebouncedNameFilter] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  const registerClick = async (shop) => {
    try {
      await api.post(`register-click/`, {
        shopId: shop.id,
      });
    } catch (error) {
      console.error("Error registering click: ", error);
    }
  };

  const handleRegisterClick = (e, shop) => {
    registerClick(shop);
  };

  const selectFilter = (value) => {
    if (selected === value) {
      setSelected("all");
    } else {
      setSelected(value);
    }
    setPage(1);
    setShops([]);
    setTotalCount(0); // Reset total count
  };

  const fetchSlectionItems = async () => {
    try {
      const response = await api.get(`get-shop-tags/`);
      setOptions(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchShops = async (isLoadMore = false) => {
    if (isLoadMore) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }
    setIsSearching(true);

    try {
      const response = await api.get(`display-shops/`, {
        params: {
          category: selected,
          page: page,
          page_size: 24,
          name: nameFilter,
        },
      });
      setShops((prevShops) =>
        isLoadMore
          ? [...prevShops, ...response.data.results]
          : response.data.results
      );
      setTotalCount(response.data.count);

      if (response.data.next) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
      setIsSearching(false);
    }
  };

  // Create a debounced version of setDebouncedNameFilter
  const debouncedSetNameFilter = useCallback(
    debounce((value) => {
      setDebouncedNameFilter(value);
    }, 300), // 300ms delay
    []
  );

  // Update nameFilter state and trigger debounced function
  const handleNameFilterChange = (e) => {
    const value = e.target.value;
    setNameFilter(value);
    debouncedSetNameFilter(value);
    setIsSearching(true); // Indicate that a search is in progress
  };

  useEffect(() => {
    fetchSlectionItems();
    fetchShops();
  }, [selected, debouncedNameFilter]); // Use debouncedNameFilter instead of nameFilter

  useEffect(() => {
    if (page > 1) fetchShops(true);
  }, [page]);

  const { t } = useTranslation();

  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Shops - Explore Online Stores | Bilionbuy</title>
        <meta
          name="description"
          content="Discover and explore various online shops with great offers, cashback, and rewards at Bilionbuy."
        />
        <meta
          name="keywords"
          content="online shops, cashback, Bilionbuy, rewards"
        />
        <link rel="canonical" href="https://www.bilionbuy.com/shops" />
        {/* Open Graph Meta Tags for Social Sharing */}
        <meta
          property="og:title"
          content="Shops - Explore Online Stores | Bilionbuy"
        />
        <meta
          property="og:description"
          content="Discover and explore various online shops with great offers, cashback, and rewards at Bilionbuy."
        />
        <meta property="og:url" content="https://www.bilionbuy.com/shops" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.bilionbuy.com/images/shops-preview.jpg"
        />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Shops - Explore Online Stores | Bilionbuy"
        />
        <meta
          name="twitter:description"
          content="Discover and explore various online shops with great offers, cashback, and rewards at Bilionbuy."
        />
        <meta
          name="twitter:image"
          content="https://www.bilionbuy.com/images/shops-preview.jpg"
        />
        {/* Structured Data for Search Engines */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Shops - Explore Online Stores",
            description:
              "Discover and explore various online shops with great offers, cashback, and rewards at Bilionbuy.",
            url: "https://www.bilionbuy.com/shops",
          })}
        </script>
      </Helmet>

      {/* Main Content */}
      <div className="mx-auto max-w-8xl sm:px-6 lg:px-0">
        <div className="sticky top-0 z-50">
          <BasicNavbar setSearchOpen={setSearchOpen} />
        </div>
        <SearchBar
          isOpen={searchOpen}
          closeSearch={() => setSearchOpen(false)}
        />
        <div className="max-w-screen-2xl mx-auto">
          <div className="flex flex-wrap gap-2 text-lightgray my-12 px-4 xl:px-0 text-xs xl:text-base">
            {" "}
            {options.map((item) => (
              <div
                className={`border-1 shadow-lg px-4 py-2 font-semibold rounded-xl cursor-pointer ${
                  selected === item.key
                    ? "bg-primary text-white"
                    : "bg-white hover:border-primary hover:text-primary"
                }`}
                key={item.key}
                onClick={() => selectFilter(item.key)}
              >
                {t(item.value)}
              </div>
            ))}
          </div>
        </div>
        {/* Add name filter input */}
        <div className="my-8 px-4 xl:px-0 max-w-7xl mx-auto">
          <TextField
            fullWidth
            variant="outlined"
            label={t("Vyhľadať")}
            value={nameFilter}
            onChange={handleNameFilterChange}
            className="bg-white"
          />
        </div>
        <div className="mx-auto max-w-7xl px-12 lg:px-8">
          <div className="-mx-6 grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {(loading || isSearching) &&
              Array(24)
                .fill()
                .map((_, index) => (
                  <Card
                    key={`initial-skeleton-${index}`}
                    className="p-2 text-center text-sm w-full"
                    radius="lg"
                  >
                    <Skeleton className="rounded-lg">
                      <div className="h-24 rounded-lg bg-gray-200"></div>
                    </Skeleton>
                    <div className="space-y-3">
                      <Skeleton className="w-3/5 rounded-lg mx-auto">
                        <div className="h-3 w-full rounded-lg bg-gray-200 mx-auto"></div>
                      </Skeleton>
                      <Skeleton className="w-4/5 rounded-lg mx-auto">
                        <div className="h-3 w-full rounded-lg bg-gray-300 mx-auto"></div>
                      </Skeleton>
                      <Skeleton className="w-2/5 rounded-lg mx-auto">
                        <div className="h-3 w-full rounded-lg bg-gray-200 mx-auto"></div>
                      </Skeleton>
                    </div>
                  </Card>
                ))}

            {!loading && !isSearching && totalCount === 0 && (
              <div className="col-span-full text-center py-8">
                <p className="text-lg text-gray-600">
                  {t(
                    "Žiadne obchody neboli nájdené. Skúste upraviť svoje vyhľadávanie."
                  )}
                </p>
              </div>
            )}

            {!loading &&
              !isSearching &&
              shops?.map((shop) => (
                <ShopCardLarge
                  key={shop.id}
                  shop={shop}
                  onRegisterClick={handleRegisterClick}
                />
              ))}

            {loadingMore &&
              Array(24)
                .fill()
                .map((_, index) => (
                  <Card
                    key={`loadmore-skeleton-${index}`}
                    className="p-2 text-center text-sm w-full"
                    radius="lg"
                  >
                    <Skeleton className="rounded-lg">
                      <div className="h-24 rounded-lg bg-gray-200"></div>
                    </Skeleton>
                    <div className="space-y-3">
                      <Skeleton className="w-3/5 rounded-lg mx-auto">
                        <div className="h-3 w-full rounded-lg bg-gray-200 mx-auto"></div>
                      </Skeleton>
                      <Skeleton className="w-4/5 rounded-lg mx-auto">
                        <div className="h-3 w-full rounded-lg bg-gray-300 mx-auto"></div>
                      </Skeleton>
                      <Skeleton className="w-2/5 rounded-lg mx-auto">
                        <div className="h-3 w-full rounded-lg bg-gray-200 mx-auto"></div>
                      </Skeleton>
                    </div>
                  </Card>
                ))}
          </div>

          {!loadingMore && !loading && hasMore && (
            <div className="text-center my-6">
              <Button
                size="sm"
                variant="flat"
                className="text-white bg-primary"
                onPress={() => setPage((prev) => prev + 1)}
              >
                {t("Zobraziť viac")}
              </Button>
            </div>
          )}
        </div>
        <div className="pt-24">
          <hr />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Shops;
