import React from "react";
import { Navigate } from "react-router-dom";
import { isTokenExpired, logoutUser } from "../services/auth";

const PrivateRoute = ({ element: Component, ...rest }) => {
  const token = localStorage.getItem("jwt");
  if (!token || isTokenExpired(token)) {
    logoutUser();
    return <Navigate to="/login" replace />;
  }

  return Component;
};

export default PrivateRoute;
