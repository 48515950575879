import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import ResetPasswordForm from "../components/auth/ResetPasswordForm";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = React.useState(false);

  const handleResetSuccess = () => {
    setShowSuccess(true);
    // Delay navigation to show the animation
    setTimeout(() => {
      navigate("/login");
    }, 2000);
  };

  return (
    <>
      <BasicNavbar />
      <AnimatePresence>
        {showSuccess && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="fixed top-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded"
            role="alert"
          >
            <p>{t("Heslo bolo úspešne obnovené")}</p>
          </motion.div>
        )}
      </AnimatePresence>
      <ResetPasswordForm token={token} onSuccess={handleResetSuccess} />
      <Footer />
    </>
  );
}
