import React, { useMemo, useRef, useState, useEffect } from "react";
import ReactQuill from "react-quill";
import Quill from "quill";

import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";

import api from "../services/api";

// Assign Quill to the window object to ensure the image resize module can access it
window.Quill = Quill;

const RichTextEditor = ({ setContent, content }) => {
  Quill.register("modules/imageResize", ImageResize);

  const quillRef = useRef();
  const [value, setValue] = useState(content || "");

  useEffect(() => {
    setValue(content);
  }, [content]);

  const imageHandler = async () => {
    const editor = quillRef.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        const formData = new FormData();
        formData.append("image", file);

        try {
          const response = await api.post("upload-blog-image/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          const url = response.data.url;
          editor.insertEmbed(editor.getSelection(), "image", url);
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      } else {
        alert("You can only upload images.");
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    }),
    []
  );

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleChange = (content) => {
    setValue(content);
    setContent(content);
  };

  return (
    <ReactQuill
      theme="snow"
      ref={quillRef}
      value={value}
      modules={modules}
      formats={formats}
      onChange={handleChange}
    />
  );
};

export default RichTextEditor;
