import React from "react";
import { NextUIProvider, Input } from "@nextui-org/react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import HowItWorks from "../components/HowItWorks";
import AffiliateBenefits from "../components/AffiliateBenefits";
import HowCashbackWorks from "../components/HowCashbackWorks";
import RegisterComponent from "../components/RegisterComponent";
import WhatPeopleSay from "../components/WhatPeopleSay";

import { useState } from "react";

import SearchBar from "../components/SearchBar";

function Home() {
  const [searchOpen, setSearchOpen] = useState(false);

  return (
    <NextUIProvider>
      <div className="mx-auto max-w-8xl sm:px-6 lg:px-0 ">
        <div className=" sticky top-0 z-50">
          <BasicNavbar setSearchOpen={setSearchOpen} />
        </div>
        <SearchBar
          isOpen={searchOpen}
          closeSearch={() => setSearchOpen(false)}
        />
        <Hero className="" />,
        <HowItWorks className="" />
        <AffiliateBenefits className="" />
        <HowCashbackWorks className="" />
        <WhatPeopleSay className="" />
        <RegisterComponent />
        <Footer className="" />
      </div>
    </NextUIProvider>
  );
}

export default Home;
