import React, { useState } from "react";
import { Helmet } from "react-helmet-async"; // Import Helmet for SEO
import { NextUIProvider } from "@nextui-org/react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import BlogList from "../components/BlogList";
import SearchBar from "../components/SearchBar";
import { useTranslation } from "react-i18next";

function Blogs() {
  const [searchOpen, setSearchOpen] = useState(false);
  const { i18n } = useTranslation(); // Get the current language from i18next

  return (
    <NextUIProvider>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Blogs - Bilionbuy</title>
        <meta
          name="description"
          content="Discover the latest blogs, tips, and guides on shopping, products, and more at Bilionbuy. Stay informed with our expert-written articles."
        />
        <meta
          name="keywords"
          content="blogs, shopping tips, product guides, Bilionbuy, articles"
        />
        <link rel="canonical" href="https://www.bilionbuy.com/blogs" />
        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="Blogs - Bilionbuy" />
        <meta
          property="og:description"
          content="Explore our latest blogs, tips, and guides on shopping and products at Bilionbuy."
        />
        <meta property="og:url" content="https://www.bilionbuy.com/blogs" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.bilionbuy.com/images/blogs-cover.jpg"
        />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Blogs - Bilionbuy" />
        <meta
          name="twitter:description"
          content="Read the latest blogs, tips, and product guides on Bilionbuy."
        />
        <meta
          name="twitter:image"
          content="https://www.bilionbuy.com/images/blogs-cover.jpg"
        />
        {/* Structured Data for Search Engines */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Blog",
            name: "Bilionbuy Blogs",
            url: "https://www.bilionbuy.com/blogs",
            description:
              "Discover the latest blogs, tips, and guides on shopping, products, and more at Bilionbuy.",
            publisher: {
              "@type": "Organization",
              name: "Bilionbuy",
              logo: {
                "@type": "ImageObject",
                url: "https://www.bilionbuy.com/images/logo.png",
              },
            },
          })}
        </script>
      </Helmet>

      <div className="mx-auto max-w-8xl sm:px-6 lg:px-0">
        <div className="sticky top-0 z-50">
          <BasicNavbar setSearchOpen={setSearchOpen} />
        </div>
        <SearchBar
          isOpen={searchOpen}
          closeSearch={() => setSearchOpen(false)}
        />
        {/* Pass the current language to BlogList */}
        <BlogList language={i18n.language} />
        <Footer />
      </div>
    </NextUIProvider>
  );
}

export default Blogs;
