import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../services/api";
import { Button, Link } from "@nextui-org/react";
import Translate from "./TranslationAPI";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { i18n } = useTranslation(); // Get the current language
  // const { t } = useTranslation();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await api.get("list-blogs/");
        const data = response.data;

        // Extract categories from the blogs
        const categoriesSet = new Set(data.map((blog) => blog.category));
        setCategories([...categoriesSet]);

        // Update blogs with language-specific content
        setBlogs(data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, [i18n.language]); // Re-fetch blogs when the language changes

  const handleCategoryClick = (category) => {
    setSelectedCategories((prevSelectedCategories) =>
      prevSelectedCategories.includes(category)
        ? prevSelectedCategories.filter((c) => c !== category)
        : [...prevSelectedCategories, category]
    );
  };

  // Dynamically select language-specific fields
  const languageCode = i18n.language;
  const filteredBlogs = selectedCategories.length
    ? blogs.filter((blog) => selectedCategories.includes(blog.category))
    : blogs;

  const { t } = useTranslation();
  return (
    <div className="max-w-screen-2xl mx-auto">
      <div>
        <div className="text-center text-blue font-semibold mt-16">
          {t("Preštudujte si viac o možnostiach komunity Bilionbuy")}
        </div>
        <div className="text-center font-semibold xl:text-6xl text-3xl mt-8">
          {t(
            "Členstvo v komunite Bilionbuy prináša neobmedzené možnosti. Články v tomto blogu pomáhajú rýchlejšie a ľahšie uchopiť príležitosti komunity."
          )}
        </div>

        <div className="flex flex-wrap gap-4 justify-center my-8 mt-16">
          {categories.map((category, index) => (
            <div
              key={index}
              className={`px-8 py-2 border-1 shadow-lg rounded-full hover:cursor-pointer ${
                selectedCategories.includes(category)
                  ? "bg-primary text-white"
                  : ""
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {t(category)}
            </div>
          ))}
        </div>

        <div className="flex flex-wrap gap-24 justify-center my-16">
          {filteredBlogs.map((blog) => {
            const title = blog[`title_${languageCode}`];
            const perex = blog[`perex_${languageCode}`];

            return (
              <div
                key={blog.id}
                className="flex flex-col p-4 m-2 rounded-xl bg-white shadow-lg w-[400px] border-1"
              >
                <div className="w-full h-[200px] overflow-hidden rounded-lg">
                  <img
                    src={blog.cover_photo_url}
                    alt={title}
                    className="w-full h-full object-cover"
                  />
                </div>

                <div className="mt-6 font-bold text-lg">
                  {title ? title : <Translate text={blog["title_sk"]} />}
                </div>
                <div className="mt-4 text-gray-600 line-clamp-6">
                  {perex ? perex : <Translate text={blog["perex_sk"]} />}
                </div>
                <div className="mt-16">
                  <Button
                    className="bg-blue text-white rounded-3xl"
                    as={Link}
                    href={"blog/" + blog.id}
                  >
                    {t("Čítať viac")} &rarr;
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogList;
