import { useEffect, useState, useCallback } from "react";
import api from "../services/api";
import { Button, Link } from "@nextui-org/react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { useTranslation } from "react-i18next";
import Translate from "./TranslationAPI";

const BlogLanding = () => {
  const { t, i18n } = useTranslation();
  const [blogs, setBlogs] = useState([]);

  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, align: "start" },
    [Autoplay({ delay: 5000 })]
  );

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await api.get("list-blogs/");
        const data = response.data;

        // Update blogs with language-specific content
        setBlogs(data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, [i18n.language]); // Re-fetch blogs when the language changes

  const previousSlide = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const nextSlide = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  // Dynamically select language-specific fields
  const languageCode = i18n.language;

  return (
    <div className="max-w-screen-2xl mx-auto">
      <div>
        <h1 className="text-center text-blue font-semibold mt-16">
          {t("Preštudujte si viac o možnostiach komunity Bilionbuy")}
        </h1>
        <h2 className="text-center font-semibold lg:text-6xl text-3xl mt-8">
          {t(
            "Členstvo v komunite Bilionbuy prináša neobmedzené možnosti. Články v tomto blogu pomáhajú rýchlejšie a ľahšie uchopiť príležitosti komunity."
          )}
        </h2>
        <div className="flex justify-center my-6">
          <Button
            as={Link}
            href="/blog"
            className="bg-blue text-white font-semibold rounded-3xl px-16 text-base py-2"
            aria-label="View all blog posts"
          >
            {t("Pozrieť všetko")} &rarr;
          </Button>
        </div>

        {/* Carousel for all screens */}
        <div className="px-4 overflow-hidden">
          <div className="embla" ref={emblaRef}>
            <div className="embla__container flex">
              {blogs.map((blog) => {
                const title = blog[`title_${languageCode}`];
                const perex = blog[`perex_${languageCode}`];
                return (
                  <div
                    key={blog.id}
                    className="embla__slide flex-[0_0_100%] md:flex-[0_0_50%] lg:flex-[0_0_33%] p-4"
                  >
                    <div className="flex flex-col m-2 rounded-xl bg-white shadow-lg w-full max-w-[380px] md:w-[380px] h-[600px] border-1 relative mx-auto">
                      <div className="w-full h-[200px] overflow-hidden rounded-lg relative">
                        <img
                          src={blog.cover_photo_url}
                          alt={`Cover photo for blog post titled ${title}`}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute top-4 right-4 bg-pink px-6 rounded-3xl py-2 text-white font-semibold shadow-lg">
                          <Translate text={blog.category} />
                        </div>
                      </div>

                      <h3 className="px-4 mt-6 font-bold text-lg">
                        {title ? title : <Translate text={blog["title_sk"]} />}
                      </h3>
                      <p className="px-4 mt-4 text-gray-600 line-clamp-6">
                        {perex ? perex : <Translate text={blog["perex_sk"]} />}
                      </p>
                      <div className="absolute bottom-3 right-3">
                        <Button
                          className="bg-blue text-white rounded-3xl"
                          as={Link}
                          href={"blog/" + blog.id}
                          aria-label={`Read more about ${title}`}
                        >
                          {t("Čítať viac")} &rarr;
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogLanding;
