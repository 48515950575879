import MyZoneTable from "./MyZoneTable";
import StatItem from "./StatItem";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { FaLink } from "react-icons/fa";
import { Tooltip } from "@nextui-org/react";
import { IoIosArrowBack } from "react-icons/io";
import React from "react";

export default function Invites({ onMenuToggle, t }) {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  // const [userInfo, setUserInfo] = useState([]);

  const copyRefferalCode = () => {
    navigator.clipboard.writeText(
      "https://www.bilionbuy.com/register/?ref=" +
        localStorage.getItem("refcode")
    );
  };

  // const fetchUserInfo = async () => {
  //   try {
  //     const response = await api.get("get-user-data/");
  //     setUserInfo(response.data);
  //   } catch (error) {
  //     console.error("fetchUserData -> error", error);
  //   }
  // };

  const fetchUserData = async () => {
    try {
      const response = await api.get("get-user-stats/");
      setUserData(response.data);
    } catch (error) {
      console.error("fetchData -> error", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await api.get("get-invites/");
      setData(response.data);
    } catch (error) {
      console.error("fetchData -> error", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchUserData();
    // fetchUserInfo();
  }, []);

  const heads = [
    t("Dátum"),
    "e-mail",
    t("Meno"),
    t("Pôvod registrácie"),
    "status",
  ];
  return (
    <div className="pb-12">
      <div className="lg:block hidden uppercase font-bold pt-12 text-3xl">
        {t("Moje pozvánky")}
      </div>
      <div
        className="lg:hidden uppercase font-bold py-12 text-2xl flex items-center gap-2 cursor-pointer"
        onClick={onMenuToggle}
      >
        <IoIosArrowBack className="h-6 w-6 cursor-pointer text-gray-700" />
        {t("Moje pozvánky")}
      </div>
      {userData.length > 0 && (
        <div className="grid xl:grid-cols-2 xl:gap-24 gap-4 py-6">
          <StatItem
            title={userData[0].title}
            value={userData[0].value}
            stat={userData[0].stat}
            timespan={userData[0].timespan}
            color={userData[0].color}
          />
          <StatItem
            title={userData[1].title}
            value={userData[1].value}
            stat={userData[1].stat}
            timespan={userData[1].timespan}
            color={userData[1].color}
          />
        </div>
      )}

      <div className="flex 2xl:flex-row flex-col 2xl:gap-24 gap-8 items-center justify-center xl:py-12">
        <div className="flex flex-col gap-4 2xl:pb-8 2xl:my-12">
          <div className="text-2xl 2xl:text-left font-semibold uppercase text-center">
            {t("Váš odporúčateľský link")}
          </div>
          <div className="flex flex-row gap-2 scale-75">
            <input
              className="w-[400px] border-1 rounded-lg p-2 border-primary text-primary"
              type="text"
              disabled
              value={
                "https://www.bilionbuy.com/register?ref=" +
                localStorage.getItem("refcode")
              }
            />
            <Tooltip content="Klikni pre skopírovanie odporúčateľského odkazu!">
              <div
                className="p-4 rounded-full border-1 border-primary hover:cursor-pointer"
                onClick={copyRefferalCode}
              >
                <FaLink className="text-primary" />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="p-8 border-1 rounded-lg shadow-lg">
          <div className="text-2xl uppercase font-semibold">
            {t("Nezabudnite priateľovi spomenúť výhody Bilionbuy")}
          </div>
          <div>
            <ul className="flex flex-col gap-2 list-disc ml-4 mt-4">
              <li>
                {t(
                  "Môžete nakupovať produkty z ponuky Bilionbuy, ale aj z ponuky partnerov"
                )}
              </li>
              <li>{t("Za každý nákup získate odmenu vo forme cashbacku")}</li>
              <li>
                {t("Ste odmeňovaný aj za nákupy ľudí, ktorých odporúčite")}
              </li>
              <li>
                {t(
                  "Komunita je medzinárodná, môžete pozývať aj ľudí zo zahraničia"
                )}
              </li>
              <li>{t("Úplne zdarma, bez žiadnych poplatkov")}</li>
              <li>{t("Vhodné pre influencerov a obchodníkov")}</li>
              <li>
                {t(
                  "Žiadne obmedzujúce podmienky na výplaty cashbackov a odmien"
                )}
              </li>
              <li>{t("Jednoduchý odporúčací systém prostedníctvom linku")}</li>
              <li>{t("Vzdelávací systém na podporu vašich zárobkov")}</li>
            </ul>
          </div>
        </div>
      </div>

      {/* New title and subtitle */}
      <div className="mt-12 mb-6 ">
        <h2 className="text-3xl font-bold mb-2">
          {t("Zoznam pozvaných ľudí cez pozvánku na webe Bilionbuy")}
        </h2>
        <p className="text-sm text-gray-600">
          {t(
            "V zozname nenájdete pozvaných ľudí cez odporúčací link zaslaný napr. SMS, Whatsapp, Messenger, Telegram a pod."
          )}
        </p>
      </div>

      <div className="overflow-x-auto md:overflow-hidden mt-12">
        {/* Loop through each data item to create a card layout for mobile screens */}
        {data.map((item, index) => (
          <div
            key={index}
            className="border border-gray-200 rounded-lg p-4 mb-4 shadow-sm md:hidden"
          >
            <div className="flex flex-col space-y-2">
              <div>
                <span className="font-semibold">{t("Dátum")}: </span>
                {item.formatted_date}
              </div>
              <div>
                <span className="font-semibold">E-mail: </span>
                {item.email}
              </div>
              <div>
                <span className="font-semibold">{t("Meno")}: </span>
                {item.name ? item.name : t("Neznáme")}
              </div>
              <div>
                <span className="font-semibold">
                  {t("Pôvod registrácie")}:{" "}
                </span>
                {item.origin ? item.origin : t("Neznáme")}
              </div>
              <div>
                <span className="font-semibold whitespace-nowrap">
                  Status:{" "}
                </span>
                <a
                  className={`py-1 px-2 rounded-md text-white font-semibold ${
                    item.status === "registered"
                      ? "bg-green"
                      : item.status === "pending"
                        ? "bg-primary"
                        : "bg-yellow-500"
                  }`}
                >
                  {item.status === "registered"
                    ? t("Registrácia potvrdená")
                    : item.status === "pending"
                      ? t("Zaslaná pozvánka")
                      : t("Čakajúce")}
                </a>
              </div>
            </div>
          </div>
        ))}

        {/* Table layout for larger screens */}
        <table className="min-w-full bg-white border-separate border-spacing-y-8 hidden md:table">
          <thead>
            <tr className="w-full uppercase text-sm leading-normal">
              {heads.map((head, index) => (
                <th key={index} className="py-3 px-6 text-left">
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm font-light">
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td colSpan={5}>
                    <hr />
                  </td>
                </tr>
                <tr className="border-t border-gray-200">
                  <td className="py-3 px-6 text-left font-semibold">
                    {item.formatted_date}
                  </td>
                  <td className="py-3 px-6 text-left">{item.email}</td>
                  <td className="py-3 px-6 text-left">
                    {item.name || t("Neznáme")}
                  </td>
                  <td className="py-3 px-6 text-left">
                    {item.origin || t("Neznáme")}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    <a
                      className={`py-2 px-4 rounded-md text-white font-semibold ${
                        item.status === "registered"
                          ? "bg-green"
                          : item.status === "pending"
                            ? "bg-primary"
                            : "bg-yellow-500"
                      }`}
                    >
                      {item.status === "registered"
                        ? t("Registrácia potvrdená")
                        : item.status === "pending"
                          ? t("Zaslaná pozvánka")
                          : t("Čakajúce")}
                    </a>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
