import React, { createContext, useContext, useState } from "react";

const NavbarContext = createContext();

// In NavbarContext.js or where you define your context
export const NavbarProvider = ({ children }) => {
  const [activeCategory, setActiveCategory] = useState(null);

  const toggleCategory = (category) => {
    setActiveCategory((prev) => (prev === category ? null : category));
  };

  return (
    <NavbarContext.Provider value={{ activeCategory, toggleCategory }}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbar = () => useContext(NavbarContext);
