import React, { useEffect } from "react";
import { Card, CardBody } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

export const TextCard = ({ itemData, href }) => {
  // useEffect(() => {
  //   // Get original body overflow value to restore later
  //   const originalStyle = window.getComputedStyle(document.body).overflow;
  //   document.body.style.overflow = "hidden";

  //   return () => {
  //     document.body.style.overflow = originalStyle;
  //   };
  // }, []);

  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    navigate(href + itemData);
  };

  return (
    <Card
      className="border-none bg-white  px-4 rounded-3xl"
      as="a"
      href={href + itemData}
      onClick={handleClick}
    >
      <CardBody>
        <div className="text-sm font-semibold">{itemData}</div>
      </CardBody>
    </Card>
  );
};
