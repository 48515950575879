import { Avatar } from "@nextui-org/react";
import InvitesTables from "../components/InvitesTable";
import { IoIosMenu } from "react-icons/io";
import { Accordion, AccordionItem } from "@nextui-org/react";
import MyReviews from "../components/MyReviews";
export default function MyAccount() {
  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8  mt-20  rounded-lg mb-16">
      <div className="flex flex-row justify-between  sm:px-0 bg-white border-1 shadow-md rounded-md p-4">
        <div className="flex flex-row p-8">
          <div className="">
            <Avatar
              className=""
              size="lg"
              src={localStorage.getItem("user_image")}
            />
          </div>
          <div className="px-5">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              Môj účet
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              {localStorage.getItem("username")}
            </p>
          </div>
        </div>
        <Accordion>
          <AccordionItem>
            <div></div>
          </AccordionItem>
        </Accordion>
      </div>
      <div>
        <InvitesTables />
      </div>
      <div>
        <MyReviews />
      </div>

      <div className="my-16 w-full   flex flex-row justify-between gap-16">
        <div className=" shadow-md border-1 max-w-[350px] p-8 flex flex-col gap-4 hover:shadow-lg">
          <h1 className="text-2xl font-bold">
            Ako mám pozvať svojho priateľa?
          </h1>
          <p className="hover:cursor-copy">
            Napríklad, pošli mu tento text: <br />
            Poslal som ti pozvánku do Bilionbuy. Je to porovnávač nákupov, ktorý
            odmeňuje zákazníkov z každého nákupu.
          </p>{" "}
        </div>
        <div className=" shadow-md border-1 max-w-[350px] p-8 flex flex-col gap-4 hover:shadow-lg">
          <h1 className="text-2xl font-bold">
            V čom je lepší oproti konkurencii?
          </h1>
          <p>
            V konkurencii porovnáš cenu a kvalitu. Na bilionbuy navyše porovnáš
            aj produkty zo zahraničia, porovnáš aj výšku cashbacku a môžeš
            získať ďalšie bonusy za odporúčanie.
          </p>
        </div>

        <div className=" shadow-md border-1 max-w-[350px] p-8 flex flex-col gap-4 hover:shadow-lg">
          <h1 className="text-2xl font-bold">
            V čom je lepší oproti konkurencii?
          </h1>
          <p>
            V konkurencii porovnáš cenu a kvalitu. Na bilionbuy navyše porovnáš
            aj produkty zo zahraničia, porovnáš aj výšku cashbacku a môžeš
            získať ďalšie bonusy za odporúčanie.
          </p>
        </div>
      </div>
    </div>
  );
}
