import React, { createContext, useContext, useState, useEffect } from "react";
import AgeConfirmationModal from "../components/modals/AgeConfirmationModal";

const AdultContentContext = createContext();

export const useAdultContent = () => {
  const context = useContext(AdultContentContext);
  if (!context) {
    throw new Error(
      "useAdultContent must be used within an AdultContentProvider"
    );
  }
  return context;
};

export const AdultContentProvider = ({ children }) => {
  const [hasConfirmedAge, setHasConfirmedAge] = useState(() => {
    const saved = localStorage.getItem("hasConfirmedAge");
    return saved === "true";
  });
  const [showAgeModal, setShowAgeModal] = useState(false);
  const [pendingCallback, setPendingCallback] = useState(null);

  const requestAgeConfirmation = (callback) => {
    if (hasConfirmedAge) {
      callback();
      return;
    }
    setShowAgeModal(true);
    setPendingCallback(() => callback);
  };

  const handleConfirm = () => {
    setHasConfirmedAge(true);
    localStorage.setItem("hasConfirmedAge", "true");
    setShowAgeModal(false);
    if (pendingCallback) {
      pendingCallback();
      setPendingCallback(null);
    }
    window.location.reload();
  };

  const handleCancel = () => {
    setShowAgeModal(false);
    setPendingCallback(null);
  };

  const resetConfirmation = () => {
    setHasConfirmedAge(false);
    localStorage.removeItem("hasConfirmedAge");
  };

  return (
    <AdultContentContext.Provider
      value={{
        hasConfirmedAge,
        showAgeModal,
        requestAgeConfirmation,
        resetConfirmation,
      }}
    >
      {children}
      {showAgeModal && (
        <AgeConfirmationModal
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </AdultContentContext.Provider>
  );
};
