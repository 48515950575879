import React, { useEffect, useState, useCallback } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner,
  Autocomplete,
  AutocompleteItem,
  Skeleton,
} from "@nextui-org/react";
import { TextField, IconButton, Grid } from "@mui/material";
import { Add, Remove, Clear, Check } from "@mui/icons-material";
import { toast } from "react-toastify";
import RichTextEditor from "../../components/RichTextEditor";
import categories_list from "../../utils/data";
import AutoselectFetchCreate from "../AutoselectFetchCreate";
import DualAutocomplete from "../DualAutocomplete";
import api, { cancelRequest } from "../../services/api";
import { debounce } from "lodash";

const ProductsTable = () => {
  const [data, setData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [content, setContent] = useState("");
  const [iframes, setIframes] = useState([]);
  const [additionalPhotos, setAdditionalPhotos] = useState([]);
  const [pageSize, setPageSize] = useState(5); // State for page size
  const [variantGroup, setVariantGroup] = useState(null); // State for variant group
  const [nameError, setNameError] = useState(""); // State for product name error
  const [showParameterInput, setShowParameterInput] = useState(false);
  const [editingParameter, setEditingParameter] = useState(null); // State for the currently edited parameter
  const [newParameter, setNewParameter] = useState({ name: "", value: "" }); // New state for the parameter being added
  const [urlFilter, setUrlFilter] = useState(""); // New state for URL filter
  const [eanFilter, setEanFilter] = useState(""); // New state for EAN filter

  const renderSkeletons = () => {
    return Array(5)
      .fill()
      .map((_, index) => (
        <div
          key={index}
          className="flex flex-row justify-between items-center p-4"
        >
          <div className="flex flex-row items-center gap-4">
            <Skeleton className="w-16 h-16 rounded" />
            <Skeleton className="h-4 w-40" />
          </div>
          <Skeleton className="h-8 w-20" />
        </div>
      ));
  };

  const fetchData = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get("get-admin-products/", {
        params: {
          filter_name: nameFilter,
          filter_url: urlFilter,
          filter_ean: eanFilter,
          page,
          page_size: pageSize,
        },
      });

      const results = response.data.results || [];
      const count = response.data.count || 0;

      setData(results);
      setCurrentPage(page);
      setTotalPages(Math.ceil(count / pageSize));
    } catch (error) {
      toast.error("Failed to fetch products");
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchData = useCallback(
    debounce((page) => {
      fetchData(page);
    }, 300),
    [nameFilter, urlFilter, eanFilter, pageSize]
  );

  // const handleSearchClick = () => {
  //   debouncedFetchData(1);
  // };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      debouncedFetchData(newPage);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleAutocompleteChange = (value) => {
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      product_assigned_category: categories_list[value],
    }));
  };

  const updateProduct = async () => {
    setIsLoading(true);
    setMessage("");
    setNameError("");

    try {
      const response = await api.post("update-product/", {
        product_id: selectedProduct.id,
        product_name: selectedProduct.product_name,
        product_description: content,
        product_manufacturer: selectedProduct.product_manufacturer,
        product_category: selectedProduct.product_category,
        product_price_vat: selectedProduct.product_price_vat,
        product_params: selectedProduct.parameters, // Include updated parameters
        product_assigned_category: selectedProduct.product_assigned_category,
        product_ean: selectedProduct.product_ean,
        video_url: selectedProduct.video_url,
        video_iframe: iframes,
        additional_photos: additionalPhotos,
        variant_group: variantGroup ? variantGroup.id : null,
      });

      if (response.status === 200) {
        setMessage("Product updated successfully!");
        toast.success("Product updated successfully!");
        setSuccess(true);
        fetchData(currentPage);
        closeModal();
      } else {
        throw new Error("Failed to update the product");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data === "Duplicate product name"
      ) {
        setNameError("The name already exists");
      } else {
        setMessage("Update failed: " + error.message);
        toast.error("Update failed: " + error.message);
      }
      setSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = (product) => {
    setSelectedProduct(product);
    setContent(product.product_description);
    setIframes(product.video_iframe || []);
    setAdditionalPhotos(product.additional_photos || []);
    setVariantGroup(product.variant_group || null);
    onOpen();
  };

  // When editing a parameter
  const handleEditParameter = (index) => {
    setEditingParameter(index);
    const param = selectedProduct.parameters[index];
    setNewParameter({ name: param.name, value: param.value });
  };

  // Confirm Parameter
  const confirmParameter = () => {
    if (!newParameter.name || !newParameter.value) {
      toast.error("Parameter name and value cannot be empty");
      return;
    }
    if (editingParameter !== null) {
      // Editing existing parameter
      setSelectedProduct((prevProduct) => {
        const updatedParameters = [...prevProduct.parameters];
        updatedParameters[editingParameter] = newParameter;
        return {
          ...prevProduct,
          parameters: updatedParameters,
        };
      });
      setEditingParameter(null);
    } else {
      // Adding new parameter
      const exists = selectedProduct.parameters.some(
        (param) =>
          param.name === newParameter.name && param.value === newParameter.value
      );
      if (!exists) {
        setSelectedProduct((prevProduct) => ({
          ...prevProduct,
          parameters: [...prevProduct.parameters, newParameter],
        }));
      } else {
        toast.info("Parameter already exists.");
      }
      setShowParameterInput(false);
    }
    setNewParameter({ name: "", value: "" });
  };

  const handleDeleteParameter = (index) => {
    setSelectedProduct((prevProduct) => {
      const updatedParameters = [...prevProduct.parameters];
      updatedParameters.splice(index, 1);
      return {
        ...prevProduct,
        parameters: updatedParameters,
      };
    });
  };

  // const confirmParameter = () => {
  //   if (!newParameter.name || !newParameter.value) {
  //     toast.error("Parameter name and value cannot be empty");
  //     return;
  //   }
  //   if (editingParameter !== null) {
  //     // Editing existing parameter
  //     setSelectedProduct((prevProduct) => {
  //       const updatedParameters = [...prevProduct.parameters];
  //       updatedParameters[editingParameter] = newParameter;
  //       return {
  //         ...prevProduct,
  //         parameters: updatedParameters,
  //       };
  //     });
  //     setEditingParameter(null);
  //   } else {
  //     // Adding new parameter
  //     const exists = selectedProduct.parameters.some(
  //       (param) =>
  //         param.name === newParameter.name && param.value === newParameter.value
  //     );
  //     if (!exists) {
  //       setSelectedProduct((prevProduct) => ({
  //         ...prevProduct,
  //         parameters: [...prevProduct.parameters, newParameter],
  //       }));
  //     } else {
  //       toast.info("Parameter already exists.");
  //     }
  //     setShowParameterInput(false);
  //   }
  //   setNewParameter({ name: "", value: "" }); // Reset newParameter state
  // };

  const cancelParameterEdit = () => {
    setEditingParameter(null);
    setShowParameterInput(false);
    setNewParameter({ name: "", value: "" }); // Reset newParameter state
  };

  const handleVariantGroupChange = (newVariantGroup) => {
    setVariantGroup(newVariantGroup);
  };

  const resetVariantGroup = () => {
    setVariantGroup(null);
  };

  const closeModal = () => {
    onOpenChange(false);
    setSelectedProduct(null);
  };

  const handleAddPhoto = () => {
    setAdditionalPhotos([...additionalPhotos, ""]);
  };

  const handleRemovePhoto = (index) => {
    setAdditionalPhotos(additionalPhotos.filter((_, i) => i !== index));
  };

  const handlePhotoChange = (index, value) => {
    const updatedPhotos = [...additionalPhotos];
    updatedPhotos[index] = value;
    setAdditionalPhotos(updatedPhotos);
  };

  const handleSearchClick = async () => {
    setIsSearching(true);
    await fetchData();
    setIsSearching(false);
  };

  const handleAddIframe = () => {
    setIframes([...iframes, ""]);
  };

  const handleRemoveIframe = (index) => {
    setIframes(iframes.filter((_, i) => i !== index));
  };

  const handleIframeChange = (index, value) => {
    const updatedIframes = [...iframes];
    updatedIframes[index] = value;
    setIframes(updatedIframes);
  };

  return (
    <div className="border-1 p-8 rounded-lg shadow-lg flex flex-col">
      <div className="text-3xl font-bold">Produkty</div>

      <div className="mt-4">
        <div className="flex flex-row justify-between my-2">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-bold">Názov</span>
            <input
              type="text"
              className="border-1 rounded-lg"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
            <span className="font-bold">URL</span>
            <input
              type="text"
              className="border-1 rounded-lg"
              value={urlFilter}
              onChange={(e) => setUrlFilter(e.target.value)}
              placeholder="Vložte URL"
            />
            {/* New EAN filter input */}
            <span className="font-bold">EAN</span>
            <input
              type="text"
              className="border-1 rounded-lg"
              value={eanFilter}
              onChange={(e) => setEanFilter(e.target.value)}
              placeholder="Vložte EAN"
            />
            <Button
              className="border-1 bg-black text-white p-1 text-sm rounded-lg"
              onClick={handleSearchClick}
              disabled={isSearching}
            >
              {isSearching ? <Spinner size="sm" color="white" /> : "Vyhľadať"}
            </Button>
          </div>
          <div className="border-1 p-2 rounded-lg">Pridať +</div>
        </div>

        {/* Page Size Selector */}
        <div className="flex flex-row justify-start items-center my-4">
          <label htmlFor="pageSize" className="mr-2 font-semibold">
            Počet na stránku:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={handlePageSizeChange}
            className="border-1 rounded-lg bg-white p-1"
          >
            {[5, 10, 20, 50, 100, 250, 1000].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        <div className="border-1 flex flex-col">
          {isLoading ? (
            renderSkeletons()
          ) : data.length > 0 ? (
            data.map((product) => (
              <div key={product.id}>
                <div className="flex flex-row justify-between items-center p-4">
                  <div className="flex flex-row items-center gap-4">
                    <img
                      src={product.product_image_url}
                      className="max-w-16 max-h-16"
                      alt=""
                    />
                    <div>{product.product_name}</div>
                  </div>
                  <div className="flex items-center gap-4">
                    {/* New column for total assignments */}
                    <div className="text-sm">
                      Počet priradení:{" "}
                      {(product.foreign_linked_shops?.length || 0) +
                        (product.linked_shops?.length || 0)}
                    </div>
                    <Button
                      auto
                      bordered
                      color="primary"
                      onPress={() => openModal(product)}
                    >
                      Detail
                    </Button>
                  </div>
                </div>
                <hr />
              </div>
            ))
          ) : (
            <div>No products available</div>
          )}
        </div>
        <div className="flex flex-row justify-center gap-4 my-4 items-center">
          <Button
            className="border-1 bg-black text-white p-1 text-sm rounded-lg"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Späť
          </Button>
          <span>
            Strana {currentPage} z {totalPages}
          </span>
          <Button
            className="border-1 bg-black text-white p-1 text-sm rounded-lg"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Ďalej
          </Button>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="5xl"
        scrollBehavior="inside"
      >
        <ModalContent className="p-8">
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <a href={"/view-product/" + selectedProduct?.slug}>
                  {selectedProduct?.product_name}
                </a>
              </ModalHeader>
              <ModalBody>
                {selectedProduct && (
                  <>
                    <div className="flex flex-col">
                      <div className="flex flex-row gap-6">
                        <div className="w-64 h-64">
                          <img
                            src={selectedProduct.product_image_url}
                            className="object-cover w-full h-full"
                            alt={selectedProduct.product_name}
                          />
                        </div>
                        <div className="">
                          <div className="font-semibold">
                            Názov produktu:
                            <TextField
                              type="text"
                              name="product_name"
                              value={selectedProduct.product_name}
                              onChange={(e) => {
                                handleInputChange(e);
                                setNameError("");
                              }}
                              error={!!nameError}
                              helperText={nameError}
                              variant="outlined"
                              className="mt-2"
                              fullWidth
                            />
                          </div>
                          <div className="font-semibold">
                            Výrobca:{" "}
                            <input
                              type="text"
                              name="product_manufacturer"
                              value={selectedProduct.product_manufacturer}
                              onChange={handleInputChange}
                              className="border-1 px-2 py-1 rounded-lg"
                            />
                          </div>
                          <div className="font-semibold">
                            Pôvodná kategória:{" "}
                            {selectedProduct.product_category}
                          </div>
                          <div className="font-semibold flex flex-col my-4 w-full">
                            <Autocomplete
                              label="Priradená kategória"
                              className="w-full"
                              variant="bordered"
                              size="sm"
                              fullWidth={true}
                              defaultInputValue={
                                selectedProduct.category_fullname
                              }
                              onSelectionChange={handleAutocompleteChange}
                            >
                              {categories_list.map((category, index) => (
                                <AutocompleteItem key={index} value={category}>
                                  {category}
                                </AutocompleteItem>
                              ))}
                            </Autocomplete>
                          </div>
                          <div className="font-semibold">
                            EAN:{" "}
                            <input
                              type="text"
                              name="product_ean"
                              placeholder={
                                selectedProduct.product_ean
                                  ? selectedProduct.product_ean
                                  : "Neznámy"
                              }
                              value={selectedProduct.product_ean}
                              onChange={handleInputChange}
                              className="border-1 px-2 py-1 rounded-lg"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="my-2">
                        <RichTextEditor
                          setContent={setContent}
                          content={content}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="font-semibold">Priradené obchody:</div>
                      <div>
                        {selectedProduct.linked_shops.map((shop) => (
                          <a
                            key={shop.id}
                            href={shop.url}
                            className="flex flex-row justify-between hover:bg-gray-100 p-2 rounded-lg my-2 hover:cursor-pointer"
                          >
                            <div>{shop.shop_name}</div>
                            <div>{shop.price_vat}</div>
                          </a>
                        ))}
                      </div>
                    </div>

                    {/* New section for foreign linked shops */}
                    <div className="mt-4">
                      <div className="font-semibold">Zahraničné obchody:</div>
                      <div>
                        {selectedProduct.foreign_linked_shops.map((shop) => (
                          <a
                            key={shop.id}
                            href={shop.url}
                            className="flex flex-row justify-between hover:bg-gray-100 p-2 rounded-lg my-2 hover:cursor-pointer"
                          >
                            <div>{shop.shop_name}</div>
                            <div>{shop.price_vat}</div>
                          </a>
                        ))}
                      </div>
                    </div>

                    {/* Parameters Section */}
                    <div className="mt-4">
                      <div className="font-semibold mb-2">Parametre:</div>
                      <ul className="flex flex-wrap gap-2">
                        {Array.isArray(selectedProduct.parameters) &&
                        selectedProduct.parameters.length > 0 ? (
                          selectedProduct.parameters.map((param, index) =>
                            editingParameter === index ? (
                              <div
                                key={index}
                                className="flex items-center gap-2"
                              >
                                <DualAutocomplete
                                  initialName={newParameter.name}
                                  initialValue={newParameter.value}
                                  onNameChange={(name) =>
                                    setNewParameter((prev) => ({
                                      ...prev,
                                      name,
                                    }))
                                  }
                                  onValueChange={(value) =>
                                    setNewParameter((prev) => ({
                                      ...prev,
                                      value,
                                    }))
                                  }
                                />
                                <IconButton
                                  color="primary"
                                  onClick={confirmParameter}
                                >
                                  <Check />
                                </IconButton>
                                <IconButton
                                  sx={{ color: "red" }}
                                  onClick={cancelParameterEdit}
                                >
                                  <Clear />
                                </IconButton>
                              </div>
                            ) : (
                              <li
                                key={index}
                                className="bg-black text-white px-4 py-1 rounded-3xl cursor-pointer flex items-center text-xs"
                                onClick={() => handleEditParameter(index)}
                              >
                                <span className="mr-2">
                                  {param.name}: {param.value}
                                </span>
                                <IconButton
                                  sx={{ color: "#FFFFFF" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteParameter(index);
                                  }}
                                >
                                  <Clear />
                                </IconButton>
                              </li>
                            )
                          )
                        ) : (
                          <li>No parameters available</li>
                        )}
                      </ul>

                      {/* Add Parameter Section */}
                      <div className="flex items-center gap-2 mt-4">
                        {showParameterInput ? (
                          <>
                            <DualAutocomplete
                              onNameChange={(name) =>
                                setNewParameter((prev) => ({ ...prev, name }))
                              }
                              onValueChange={(value) =>
                                setNewParameter((prev) => ({ ...prev, value }))
                              }
                            />
                            <IconButton
                              color="primary"
                              onClick={confirmParameter}
                            >
                              <Check />
                            </IconButton>
                            <IconButton
                              sx={{ color: "red" }}
                              onClick={cancelParameterEdit}
                            >
                              <Clear />
                            </IconButton>
                          </>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<Add />}
                            onClick={() => {
                              setShowParameterInput(true);
                              setNewParameter({ name: "", value: "" });
                            }}
                            className="text-white bg-primary px-4 rounded-3xl text-xs"
                          >
                            Pridať parameter
                          </Button>
                        )}
                      </div>
                    </div>

                    <div className="my-4">
                      <div className="font-semibold mb-4">Fotky:</div>
                      {additionalPhotos.map((photo, index) => (
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          key={index}
                          className="mb-4"
                        >
                          <Grid item xs={10}>
                            <TextField
                              label={`Photo URL ${index + 1}`}
                              variant="outlined"
                              value={photo}
                              onChange={(e) =>
                                handlePhotoChange(index, e.target.value)
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              color="secondary"
                              onClick={() => handleRemovePhoto(index)}
                            >
                              <Remove />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Add />}
                        onClick={handleAddPhoto}
                        className="text-white bg-primary px-4 rounded-3xl text-xs"
                      >
                        Pridať fotku (URL)
                      </Button>
                    </div>

                    <div className="my-4">
                      <div className="font-semibold mb-4">Video iFrames:</div>
                      {iframes.map((iframe, index) => (
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          key={index}
                          className="mb-4"
                        >
                          <Grid item xs={10}>
                            <TextField
                              label={`iFrame ${index + 1}`}
                              variant="outlined"
                              value={iframe}
                              onChange={(e) =>
                                handleIframeChange(index, e.target.value)
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              color="secondary"
                              onClick={() => handleRemoveIframe(index)}
                            >
                              <Remove />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Add />}
                        onClick={handleAddIframe}
                        className="text-white bg-primary px-4 rounded-3xl text-xs"
                      >
                        Pridať iFrame
                      </Button>
                    </div>
                  </>
                )}
                {isLoading && <Spinner />}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Zavrieť
                </Button>
                <Button color="primary" onPress={updateProduct}>
                  Uložiť
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ProductsTable;
