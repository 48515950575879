import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "../services/api";
import PropTypes from "prop-types";

const CountrySelector = ({ selectedCountries = [], setSelectedCountries }) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await api.get(`get-aviable-countries/`);
        const countryOptions = Object.entries(response.data).map(
          ([key, value]) => ({
            key,
            value,
          })
        );
        setOptions(countryOptions);
      } catch (error) {
        console.error("Error fetching countries: ", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    if (options.length > 0 && selectedCountries.length > 0) {
      const preSelected = options.filter((option) =>
        selectedCountries.includes(option.key)
      );
      setSelectedOptions(preSelected);
    }
  }, [options, selectedCountries]);

  const handleChange = (event, newValue) => {
    setSelectedOptions(newValue);
    const selectedKeys = newValue.map((option) => option.key);
    setSelectedCountries(selectedKeys);
  };

  return (
    <Autocomplete
      multiple
      id="countries-selector"
      options={options}
      getOptionLabel={(option) => option.value}
      value={selectedOptions}
      onChange={handleChange}
      disablePortal
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  );
};

CountrySelector.propTypes = {
  selectedCountries: PropTypes.array,
  setSelectedCountries: PropTypes.func.isRequired,
};

export default CountrySelector;
