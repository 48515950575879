import React, { useState, useEffect } from "react";
import { Image, Skeleton } from "@nextui-org/react";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Card,
  Typography,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Spinner } from "@nextui-org/react";
import api from "../../services/api";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Add this mock data at the top of the file, after the imports
const mockSuggestedProducts = [
  {
    id: 1,
    name: "T-Shirt Red",
    sku: "TS-RED-001",
    price: 19.99,
    image_url: "https://example.com/images/tshirt-red.jpg",
  },
  {
    id: 2,
    name: "T-Shirt Blue",
    sku: "TS-BLUE-001",
    price: 19.99,
    image_url: "https://example.com/images/tshirt-blue.jpg",
  },
  {
    id: 3,
    name: "T-Shirt Green",
    sku: "TS-GREEN-001",
    price: 19.99,
    image_url: "https://example.com/images/tshirt-green.jpg",
  },
  {
    id: 4,
    name: "Jeans Slim Fit",
    sku: "JS-SLIM-001",
    price: 49.99,
    image_url: "https://example.com/images/jeans-slim.jpg",
  },
  {
    id: 5,
    name: "Jeans Regular Fit",
    sku: "JS-REG-001",
    price: 49.99,
    image_url: "https://example.com/images/jeans-regular.jpg",
  },
];

// New ProductCard component
const ProductCard = ({ product, isSelected, onClick, onRemove }) => {
  return (
    <Card
      className={`p-4 cursor-pointer relative ${
        isSelected ? "border-primary border-2" : ""
      }`}
      onClick={() => onClick(product)}
    >
      <button
        className="absolute top-2 right-2 text-red-500 hover:text-red-700"
        onClick={(e) => {
          e.stopPropagation();
          onRemove(product.id);
        }}
      >
        ✕
      </button>
      <div className="flex flex-col items-center">
        <Image
          src={product.image_url}
          alt={product.name}
          width={100}
          height={100}
          objectFit="cover"
        />
        <Typography variant="h6" className="mt-2">
          {product.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Kód: {product.sku}
        </Typography>
        <Typography variant="body1" className="mt-1">
          {product.price.toFixed(2)} €
        </Typography>
      </div>
    </Card>
  );
};

const VariantsDuplicatesSuggestions = () => {
  const [products, setProducts] = useState([]);
  const [selectedMainProduct, setSelectedMainProduct] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupType, setGroupType] = useState(null); // 'variant' or 'duplicate'
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [variantGroups, setVariantGroups] = useState([]);
  const [draggedProduct, setDraggedProduct] = useState(null);

  useEffect(() => {
    fetchSuggestedProducts();
  }, []);

  const fetchSuggestedProducts = async () => {
    setIsLoading(true);
    try {
      // Simulate API delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setProducts(mockSuggestedProducts);

      // Simulate fetching multiple variant group information
      const mockVariantGroups = [
        {
          id: 101,
          name: "T-Shirt Variants",
          products: [1, 2],
        },
        {
          id: 102,
          name: "Jeans Variants",
          products: [4, 5],
        },
      ];
      setVariantGroups(mockVariantGroups);
    } catch (error) {
      console.error("Error fetching suggested products:", error);
      toast.error("Error fetching suggested products");
    } finally {
      setIsLoading(false);
    }
  };

  const handleProductSelect = (product) => {
    const isProductAlreadyAdded = products.some((p) => p.id === product.id);
    if (!isProductAlreadyAdded) {
      setProducts([...products, product]);
    }
  };

  const handleRemoveProduct = (productId) => {
    setProducts(products.filter((p) => p.id !== productId));
    if (selectedMainProduct && selectedMainProduct.id === productId) {
      setSelectedMainProduct(null);
    }
  };

  const handleMainProductSelect = (product) => {
    if (selectedMainProduct && selectedMainProduct.id === product.id) {
      // Deselect if the same product is clicked again
      setSelectedMainProduct(null);
    } else {
      // Select the new product
      setSelectedMainProduct(product);
    }
  };

  const handleOpenConfirmationModal = (type) => {
    setGroupType(type);
    setIsConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleConfirmGrouping = async () => {
    setIsLoading(true);
    try {
      const endpoint =
        groupType === "variant"
          ? "create-variant-group"
          : "create-duplicate-group";
      const payload = {
        products: products.map((p) => p.id),
        ...(groupType === "duplicate" && {
          main_product: selectedMainProduct.id,
        }),
      };

      const response = await api.post(endpoint, payload);

      if (response.status === 200) {
        toast.success(`${groupType} group created successfully!`);
        setProducts([]);
        setSelectedMainProduct(null);
      } else {
        toast.error(`Error creating ${groupType} group.`);
      }
    } catch (error) {
      console.error(`Error creating ${groupType} group:`, error);
      toast.error(`Error creating ${groupType} group.`);
    } finally {
      setIsLoading(false);
      setIsConfirmationModalOpen(false);
    }
  };

  const handleOpenSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const handleCloseSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  const handleAddProduct = (product) => {
    const isProductAlreadyAdded = products.some((p) => p.id === product.id);
    if (!isProductAlreadyAdded) {
      setProducts([...products, product]);
    }
    setIsSearchModalOpen(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceId = result.source.droppableId;
    const destId = result.destination.droppableId;

    if (sourceId === "productList" && destId.startsWith("group-")) {
      // Moving from product list to a group
      const productId = parseInt(result.draggableId);
      const groupId = parseInt(destId.split("-")[1]);
      handleAddToExistingGroup(groupId, productId);
    } else if (sourceId.startsWith("group-") && destId === "productList") {
      // Moving from a group to the product list
      const productId = parseInt(result.draggableId.split("-")[2]);
      const groupId = parseInt(sourceId.split("-")[1]);
      handleRemoveFromExistingGroup(groupId, productId);
    } else if (sourceId.startsWith("group-") && destId.startsWith("group-")) {
      // Moving between groups
      const productId = parseInt(result.draggableId.split("-")[2]);
      const sourceGroupId = parseInt(sourceId.split("-")[1]);
      const destGroupId = parseInt(destId.split("-")[1]);
      handleMoveProductBetweenGroups(sourceGroupId, destGroupId, productId);
    }
  };

  const handleAddToExistingGroup = async (groupId, productId) => {
    setIsLoading(true);
    try {
      const product = products.find((p) => p.id === productId);
      const group = variantGroups.find((g) => g.id === groupId);

      if (!product || !group || group.products.includes(productId)) {
        return;
      }

      const payload = {
        group_id: groupId,
        product_ids: [productId],
      };

      const response = await api.post("add-to-variant-group", payload);

      if (response.status === 200) {
        toast.success("Product added to existing variant group successfully!");
        // Update local state
        setVariantGroups((prevGroups) =>
          prevGroups.map((g) =>
            g.id === groupId
              ? { ...g, products: [...g.products, productId] }
              : g
          )
        );
        setProducts((prevProducts) =>
          prevProducts.filter((p) => p.id !== productId)
        );
      } else {
        toast.error("Error adding product to existing variant group.");
      }
    } catch (error) {
      console.error("Error adding product to existing variant group:", error);
      toast.error("Error adding product to existing variant group.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveFromExistingGroup = async (groupId, productId) => {
    setIsLoading(true);
    try {
      const payload = {
        group_id: groupId,
        product_ids: [productId],
      };

      const response = await api.post("remove-from-variant-group", payload);

      if (response.status === 200) {
        toast.success("Product removed from variant group successfully!");
        // Update local state
        setVariantGroups((prevGroups) =>
          prevGroups.map((g) =>
            g.id === groupId
              ? { ...g, products: g.products.filter((id) => id !== productId) }
              : g
          )
        );
        const removedProduct = mockSuggestedProducts.find(
          (p) => p.id === productId
        );
        if (removedProduct) {
          setProducts((prevProducts) => [...prevProducts, removedProduct]);
        }
      } else {
        toast.error("Error removing product from variant group.");
      }
    } catch (error) {
      console.error("Error removing product from variant group:", error);
      toast.error("Error removing product from variant group.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleMoveProductBetweenGroups = async (
    sourceGroupId,
    destGroupId,
    productId
  ) => {
    setIsLoading(true);
    try {
      const payload = {
        source_group_id: sourceGroupId,
        dest_group_id: destGroupId,
        product_id: productId,
      };

      const response = await api.post("move-product-between-groups", payload);

      if (response.status === 200) {
        toast.success("Product moved between groups successfully!");
        // Update local state
        setVariantGroups((prevGroups) =>
          prevGroups.map((g) => {
            if (g.id === sourceGroupId) {
              return {
                ...g,
                products: g.products.filter((id) => id !== productId),
              };
            } else if (g.id === destGroupId) {
              return { ...g, products: [...g.products, productId] };
            }
            return g;
          })
        );
      } else {
        toast.error("Error moving product between groups.");
      }
    } catch (error) {
      console.error("Error moving product between groups:", error);
      toast.error("Error moving product between groups.");
    } finally {
      setIsLoading(false);
    }
  };

  // New function to get unassigned products
  const getUnassignedProducts = () => {
    const assignedProductIds = variantGroups.flatMap((group) => group.products);
    return products.filter(
      (product) => !assignedProductIds.includes(product.id)
    );
  };

  return (
    <div className="p-4 flex flex-col gap-4">
      <h1 className="text-2xl font-bold mb-4">Návrhy variantov a duplikátov</h1>

      {isLoading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {[...Array(8)].map((_, index) => (
            <Skeleton key={index} className="rounded-lg">
              <div className="h-[200px] rounded-lg bg-default-300"></div>
            </Skeleton>
          ))}
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="productList" direction="horizontal">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex flex-wrap gap-4"
              >
                {getUnassignedProducts().map((product, index) => (
                  <Draggable
                    key={product.id}
                    draggableId={`${product.id}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ProductCard
                          product={product}
                          isSelected={
                            selectedMainProduct &&
                            selectedMainProduct.id === product.id
                          }
                          onClick={handleMainProductSelect}
                          onRemove={handleRemoveProduct}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <Card
                  sx={{
                    width: 200,
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    border: "1px dashed rgba(0, 0, 0, 0.12)",
                  }}
                  onClick={handleOpenSearchModal}
                >
                  <AddIcon
                    sx={{ fontSize: 60, color: "rgba(0, 0, 0, 0.54)" }}
                  />
                </Card>
              </div>
            )}
          </Droppable>

          <div className="mt-4">
            <Typography variant="h6" className="mb-2">
              Skupiny variantov:
            </Typography>
            {variantGroups.map((group) => (
              <Droppable
                key={group.id}
                droppableId={`group-${group.id}`}
                direction="horizontal"
              >
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="mb-4 p-4 bg-gray-100 rounded-lg"
                  >
                    <Typography variant="subtitle1" className="font-bold mb-2">
                      Skupina: {group.name}
                    </Typography>
                    <div className="flex flex-wrap gap-4">
                      {group.products.map((productId, index) => {
                        const product = mockSuggestedProducts.find(
                          (p) => p.id === productId
                        );
                        return product ? (
                          <Draggable
                            key={productId}
                            draggableId={`group-${group.id}-${productId}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ProductCard
                                  product={product}
                                  isSelected={false}
                                  onClick={() => {}}
                                  onRemove={() => {}}
                                />
                              </div>
                            )}
                          </Draggable>
                        ) : null;
                      })}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </div>

          <div className="flex gap-4 mt-4">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenConfirmationModal("variant")}
              disabled={products.length < 2}
            >
              Vytvoriť skupinu variantov
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleOpenConfirmationModal("duplicate")}
              disabled={products.length < 2 || !selectedMainProduct}
            >
              Vytvoriť skupinu duplikátov
            </Button>
          </div>
        </DragDropContext>
      )}

      <Dialog
        open={isConfirmationModalOpen}
        onClose={handleCloseConfirmationModal}
        disableScrollLock={true}
      >
        <DialogTitle>
          Potvrdiť vytvorenie skupiny{" "}
          {groupType === "variant" ? "variantov" : "duplikátov"}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Ste si istý, že chcete vytvoriť skupinu{" "}
            {groupType === "variant" ? "variantov" : "duplikátov"} s týmito
            produktmi?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmationModal}
            color="primary"
            disabled={isLoading}
          >
            Zrušiť
          </Button>
          <Button
            onClick={handleConfirmGrouping}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Potvrdiť"}
          </Button>
        </DialogActions>
      </Dialog>

      <ProductSelection
        isOpen={isSearchModalOpen}
        onClose={handleCloseSearchModal}
        onProductSelect={handleAddProduct}
        selectedProducts={products}
      />
    </div>
  );
};

// Modify the ProductSelection component to work as a modal
const ProductSelection = ({
  isOpen,
  onClose,
  onProductSelect,
  selectedProducts,
}) => {
  const [productName, setProductName] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await api.get(`get-product-data`, {
        params: { product_name: productName, product_url: productUrl },
      });

      const data = response.data;
      const productsArray = Object.keys(data).map((key) => data[key]);

      // Filter out products that are already selected
      const filteredProducts = productsArray.filter(
        (product) => !selectedProducts.some((p) => p.id === product.id)
      );

      setSearchResults(filteredProducts);
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Vyhľadať produkty</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Názov produktu"
          fullWidth
          variant="outlined"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="URL"
          fullWidth
          variant="outlined"
          value={productUrl}
          onChange={(e) => setProductUrl(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Zrušiť</Button>
        <Button onClick={handleSearch}>Vyhľadať</Button>
      </DialogActions>
      <DialogContent>
        {loading ? (
          <div className="flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          searchResults.map((product) => (
            <div
              key={product.id}
              onClick={() => onProductSelect(product)}
              className="mb-2 cursor-pointer"
            >
              <ProductCard product={product} />
            </div>
          ))
        )}
      </DialogContent>
    </Dialog>
  );
};

export default VariantsDuplicatesSuggestions;
