import React, { useState } from "react";
import api from "../services/api";
import { Button, Spinner } from "@nextui-org/react";

const CreateBlog = ({
  title_sk,
  title_cs,
  title_en,
  title_de,
  title_pl,
  perex_sk,
  perex_cs,
  perex_en,
  perex_de,
  perex_pl,
  content_sk,
  content_cs,
  content_en,
  content_de,
  content_pl,
  category,
  coverPhoto,
  blogImages = [], // Ensure default value is an array
  author,
  onSuccess,
  onError,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();

    // Ensure no ID is included in formData
    if (formData.has("id")) {
      formData.delete("id"); // Just in case, remove id if it somehow exists
    }

    // Append language-specific fields
    formData.append("title_sk", title_sk);
    formData.append("title_cs", title_cs);
    formData.append("title_en", title_en);
    formData.append("title_de", title_de);
    formData.append("title_pl", title_pl);
    formData.append("perex_sk", perex_sk);
    formData.append("perex_cs", perex_cs);
    formData.append("perex_en", perex_en);
    formData.append("perex_de", perex_de);
    formData.append("perex_pl", perex_pl);
    formData.append("content_sk", content_sk);
    formData.append("content_cs", content_cs);
    formData.append("content_en", content_en);
    formData.append("content_de", content_de);
    formData.append("content_pl", content_pl);

    formData.append("category", category);
    formData.append("author", author);

    if (coverPhoto) {
      formData.append("cover_photo", coverPhoto);
    }

    // Check if blogImages is an array before using forEach
    if (Array.isArray(blogImages)) {
      blogImages.forEach((image) => {
        formData.append("blog_images", image);
      });
    } else {
      console.warn("blogImages is not an array:", blogImages);
    }

    try {
      const response = await api.post("create-blog/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      onSuccess("Blog created successfully!");
    } catch (error) {
      console.error(error);
      onError("Failed to create blog.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        onPress={handleSubmit}
        className="text-white bg-black px-4 py-2 rounded-lg font-bold my-4"
        disabled={loading}
      >
        {loading ? <Spinner className="text-white" /> : "Vytvoriť"}
      </Button>
    </div>
  );
};

export default CreateBlog;
