import React, { useState } from "react";
import {
  Input,
  Textarea,
  Image,
  Button,
  Accordion,
  AccordionItem,
} from "@nextui-org/react";
import RichTextEditor from "../components/RichTextEditor";
import UpdateBlog from "./UpdateBlog";
import CreateBlog from "./CreateBlog";

const BlogCreateOrEdit = ({ blogContent, create, onSuccess, onError }) => {
  const [id, setId] = useState(blogContent.id || null);

  // Individual state for each language-specific field
  const [title_sk, setTitleSk] = useState(blogContent.title_sk || "");
  const [title_cs, setTitleCs] = useState(blogContent.title_cs || "");
  const [title_en, setTitleEn] = useState(blogContent.title_en || "");
  const [title_de, setTitleDe] = useState(blogContent.title_de || "");
  const [title_pl, setTitlePl] = useState(blogContent.title_pl || "");

  const [perex_sk, setPerexSk] = useState(blogContent.perex_sk || "");
  const [perex_cs, setPerexCs] = useState(blogContent.perex_cs || "");
  const [perex_en, setPerexEn] = useState(blogContent.perex_en || "");
  const [perex_de, setPerexDe] = useState(blogContent.perex_de || "");
  const [perex_pl, setPerexPl] = useState(blogContent.perex_pl || "");

  const [content_sk, setContentSk] = useState(blogContent.content_sk || "");
  const [content_cs, setContentCs] = useState(blogContent.content_cs || "");
  const [content_en, setContentEn] = useState(blogContent.content_en || "");
  const [content_de, setContentDe] = useState(blogContent.content_de || "");
  const [content_pl, setContentPl] = useState(blogContent.content_pl || "");

  const [category, setCategory] = useState(blogContent.category || "");
  const [author, setAuthor] = useState(blogContent.author || "");
  const [blogImages, setBlogImages] = useState(blogContent.blog_images || []);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [coverPhotoUrl, setCoverPhotoUrl] = useState(
    blogContent.cover_photo_url || ""
  );

  const handleCoverPhotoChange = (e) => {
    const file = e.target.files[0];
    setCoverPhoto(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setCoverPhotoUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      {/* Cover Photo Section */}
      <div className="text-2xl font-bold my-4">Titulný obrázok (Cover)</div>
      <div>
        {coverPhotoUrl ? (
          <Image
            src={coverPhotoUrl}
            className="w-full h-[300px] object-cover rounded-lg hover:cursor-pointer"
            onClick={() => document.getElementById("cover-photo-input").click()}
          />
        ) : (
          <div
            className="flex justify-center text-center text-black/50 items-center w-full h-[300px] border-1 border-dashed border-black/50 rounded-xl hover:cursor-pointer"
            onClick={() => document.getElementById("cover-photo-input").click()}
          >
            Click here to upload
          </div>
        )}
        <input
          type="file"
          id="cover-photo-input"
          className="hidden"
          onChange={handleCoverPhotoChange}
        />
      </div>

      {/* Other Fields */}
      <div className="text-2xl font-bold my-2 mt-8">Kategória</div>
      <div>
        <Input
          variant="bordered"
          type="text"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </div>

      <div className="text-2xl font-bold my-2 mt-8">Autor</div>
      <div>
        <Input
          variant="bordered"
          type="text"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
        />
      </div>

      {/* Language Sections */}
      <div className="text-2xl font-bold my-4">Jazykové Verzie</div>
      <Accordion defaultExpandedKeys={["sk"]}>
        <AccordionItem title="Slovak (SK)" key="sk">
          <LanguageTabContent
            title={title_sk}
            setTitle={setTitleSk}
            perex={perex_sk}
            setPerex={setPerexSk}
            content={content_sk}
            setContent={setContentSk}
            blogImages={blogImages}
            setBlogImages={setBlogImages}
          />
        </AccordionItem>

        <AccordionItem title="Czech (CS)" key="cs">
          <LanguageTabContent
            title={title_cs}
            setTitle={setTitleCs}
            perex={perex_cs}
            setPerex={setPerexCs}
            content={content_cs}
            setContent={setContentCs}
            blogImages={blogImages}
            setBlogImages={setBlogImages}
          />
        </AccordionItem>

        <AccordionItem title="English (EN)" key="en">
          <LanguageTabContent
            title={title_en}
            setTitle={setTitleEn}
            perex={perex_en}
            setPerex={setPerexEn}
            content={content_en}
            setContent={setContentEn}
            blogImages={blogImages}
            setBlogImages={setBlogImages}
          />
        </AccordionItem>

        {/* Added German (DE) */}
        <AccordionItem title="German (DE)" key="de">
          <LanguageTabContent
            title={title_de}
            setTitle={setTitleDe}
            perex={perex_de}
            setPerex={setPerexDe}
            content={content_de}
            setContent={setContentDe}
            blogImages={blogImages}
            setBlogImages={setBlogImages}
          />
        </AccordionItem>

        {/* Added Polish (PL) */}
        <AccordionItem title="Polish (PL)" key="pl">
          <LanguageTabContent
            title={title_pl}
            setTitle={setTitlePl}
            perex={perex_pl}
            setPerex={setPerexPl}
            content={content_pl}
            setContent={setContentPl}
            blogImages={blogImages}
            setBlogImages={setBlogImages}
          />
        </AccordionItem>
      </Accordion>

      {/* Action Buttons */}
      <div className="flex justify-end pt-3 gap-2">
        {create ? (
          <CreateBlog
            title_sk={title_sk}
            title_cs={title_cs}
            title_en={title_en}
            title_de={title_de}
            title_pl={title_pl}
            perex_sk={perex_sk}
            perex_cs={perex_cs}
            perex_en={perex_en}
            perex_de={perex_de}
            perex_pl={perex_pl}
            content_sk={content_sk}
            content_cs={content_cs}
            content_en={content_en}
            content_de={content_de}
            content_pl={content_pl}
            category={category}
            coverPhoto={coverPhoto}
            author={author}
            blogImages={blogImages}
            onSuccess={onSuccess}
            onError={onError}
          />
        ) : (
          <UpdateBlog
            id={id}
            title_sk={title_sk}
            title_cs={title_cs}
            title_en={title_en}
            title_de={title_de}
            title_pl={title_pl}
            perex_sk={perex_sk}
            perex_cs={perex_cs}
            perex_en={perex_en}
            perex_de={perex_de}
            perex_pl={perex_pl}
            content_sk={content_sk}
            content_cs={content_cs}
            content_en={content_en}
            content_de={content_de}
            content_pl={content_pl}
            category={category}
            coverPhoto={coverPhoto}
            author={author}
            blogImages={blogImages}
            onSuccess={onSuccess}
            onError={onError}
          />
        )}
        <Button className="bg-white text-black border-1 border-black font-bold">
          Zahodiť zmeny
        </Button>
      </div>
      <hr className="my-4" />
    </>
  );
};

// Separate component for the language-specific fields
const LanguageTabContent = ({
  title,
  setTitle,
  perex,
  setPerex,
  content,
  setContent,
  blogImages,
  setBlogImages,
}) => (
  <>
    <div className="text-2xl font-bold my-2">Názov</div>
    <div>
      <Input
        variant="bordered"
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
    </div>

    <div className="text-2xl font-bold my-2 mt-8">Perex</div>
    <div>
      <Textarea
        variant="bordered"
        type="text"
        value={perex}
        onChange={(e) => setPerex(e.target.value)}
      />
    </div>

    <div className="text-2xl font-bold my-2 mt-8">Obsah</div>
    <div className="w-full">
      <RichTextEditor
        setContent={setContent}
        content={content}
        blogImages={blogImages}
        setBlogImages={setBlogImages}
      />
    </div>
  </>
);

export default BlogCreateOrEdit;
