import axios from "axios";
import { isTokenExpired, logoutUser } from "./auth";

// Create an instance of axios with a custom config
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Map to store AbortController instances
const abortControllers = new Map();
api.cancelToken = axios.CancelToken;
api.isCancel = axios.isCancel;

// Interceptor to add the auth token and custom headers to requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwt");
    const locale = localStorage.getItem("locale") || "sk";

    if (locale) {
      config.headers["Destination"] = locale;
    }

    if (token) {
      if (isTokenExpired(token)) {
        logoutUser();
        window.location.href = "/login"; // Redirect to login page
        return Promise.reject(new Error("Token expired"));
      }
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    // Create and store an AbortController for this request
    const controller = new AbortController();
    config.signal = controller.signal;
    abortControllers.set(config.url, controller);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor to handle responses
api.interceptors.response.use(
  (response) => {
    // Remove the AbortController for this request
    abortControllers.delete(response.config.url);
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      logoutUser();
      window.location.href = "/login"; // Redirect to login page
    }
    // Remove the AbortController for this request
    if (error.config) {
      abortControllers.delete(error.config.url);
    }
    return Promise.reject(error);
  }
);

// Function to cancel a request
export const cancelRequest = (url) => {
  const controller = abortControllers.get(url);
  if (controller) {
    controller.abort();
    abortControllers.delete(url);
  }
};

export default api;
