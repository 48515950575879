import React, { useState, useEffect } from "react";
import { TextField, Select, MenuItem } from "@mui/material";
import { Pagination as MUIPagination } from "@mui/material";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";
import { Chip } from "@nextui-org/react";

import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner,
  Pagination,
} from "@nextui-org/react";
import api from "../services/api";
import { ToastContainer, toast } from "react-toastify";
import TagsSelector from "./TagsSelector";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Image, Link } from "@nextui-org/react";
import CountrySelector from "./CountrySelector";
import CategorySelector from "./CategorySelector"; // Add this import

const EditButton = ({ data, onUpdate }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [formData, setFormData] = useState({
    ...data,
    shoptags: data.shoptags || [], // Ensure shoptags defaults to an array
  });
  const [selectedTags, setSelectedTags] = useState(
    formData.shoptags ? formData.shoptags.map((tag) => tag.id) : []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const [additionalCommissionGroups, setAdditionalCommissionGroups] = useState(
    Array.isArray(data.additional_commision_groups)
      ? data.additional_commision_groups.map((group) => ({
          ...group,
          pk: group.pk || "",
          ok: group.ok || "",
          sk: group.sk || "",
          type: group.type || "Kategória", // Add this line
        }))
      : []
  );
  const [isExpanded, setIsExpanded] = useState({ [-1]: false });
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [productsData, setProductsData] = useState(null);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [productsFetchError, setProductsFetchError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [currentCommissionData, setCurrentCommissionData] = useState(null);
  const productsPerPage = 12;
  const [selectedCountries, setSelectedCountries] = useState(
    data.additional_countries || []
  );
  const [selectedCountry, setSelectedCountry] = useState(data.country || "");
  const [selectedCategories, setSelectedCategories] = useState(
    data.categories ? data.categories.map((category) => category) : []
  );
  const [partnerShopData, setPartnerShopData] = useState(null);

  const countryOptions = [
    { value: "", label: "Vyberte krajinu" },
    { value: "sk", label: "Slovensko" },
    { value: "cz", label: "Česko" },
    { value: "hu", label: "Maďarsko" },
    { value: "pl", label: "Poľsko" },
    { value: "ro", label: "Rumunsko" },
    { value: "bg", label: "Bulharsko" },
    { value: "at", label: "Rakúsko" },
    { value: "de", label: "Nemecko" },
    { value: "it", label: "Taliansko" },
    { value: "fr", label: "Francúzsko" },
    { value: "es", label: "Španielsko" },
    { value: "hr", label: "Chorvátsko" },
    { value: "rs", label: "Srbsko" },
    { value: "si", label: "Slovinsko" },
    { value: "nl", label: "Holandsko" },
    { value: "be", label: "Belgicko" },
    { value: "pt", label: "Portugalsko" },
    { value: "gr", label: "Grécko" },
    { value: "se", label: "Švédsko" },
    { value: "dk", label: "Dánsko" },
    { value: "fi", label: "Fínsko" },
    { value: "no", label: "Nórsko" },
    { value: "ch", label: "Švajčiarsko" },
    { value: "ie", label: "Írsko" },
    { value: "uk", label: "Spojené kráľovstvo" },
    { value: "cn", label: "Čína" },
    { value: "ca", label: "Kanada" },
    { value: "us", label: "USA" },
  ];
  const handleCountryChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const country = event.target.value;
    setSelectedCountry(country);
    setFormData({ ...formData, country: country });
  };

  const stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    // Sync formData.shoptags with selectedTags only when selectedTags change
    setFormData((prevFormData) => ({
      ...prevFormData,
      shoptags: selectedTags,
    }));
  }, [selectedTags]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      additional_commision_groups: additionalCommissionGroups,
    }));
  }, [additionalCommissionGroups]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      additional_countries: selectedCountries,
    }));
  }, [selectedCountries]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      categories: selectedCategories,
    }));
  }, [selectedCategories]);

  const handleAddCommissionGroup = () => {
    setAdditionalCommissionGroups([
      ...additionalCommissionGroups,
      { name: "", value: "", pk: "", ok: "", sk: "", type: "Kategória" }, // Add a default type
    ]);

    setIsExpanded((prev) => ({
      ...prev,
      [additionalCommissionGroups.length]: false,
    })); // Add new expanded state for the new group
  };

  const toggleExpand = (index) => {
    setIsExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleRemoveCommissionGroup = (index) => {
    setAdditionalCommissionGroups(
      additionalCommissionGroups.filter((_, i) => i !== index)
    );
  };

  const handleCommissionGroupChange = (index, field, newValue) => {
    const updatedGroups = [...additionalCommissionGroups];
    updatedGroups[index][field] = newValue;
    setAdditionalCommissionGroups(updatedGroups);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTagsChange = (tagIDs) => {
    // Update selectedTags directly, handleTagsChange receives IDs directly
    setSelectedTags(tagIDs);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.patch(`shop-update/${formData.id}`, {
        ...formData,
        categories: selectedCategories, // Ensure categories are included in the update
      });

      if (response.status === 200) {
        toast.success("Shop updated successfully!");
        onUpdate(response.data);
      } else {
        throw new Error("Failed to update the shop");
      }
    } catch (error) {
      toast.error("Update failed: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRedirectURL = async () => {
    try {
      const response = await api.get("get-shop-redirect/", {
        params: { shop_id: formData.id },
      });
      setRedirectUrl(response.data);
    } catch (error) {
      toast.error("Failed to fetch redirect URL: " + error.message);
    }
  };

  const fetchCommissionProducts = async (commissionData, page = 1) => {
    setCurrentCommissionData(commissionData);
    setIsLoadingProducts(true);
    setProductsFetchError(null);
    setProductsData(null);
    setIsProductModalOpen(true); // Open the modal immediately

    try {
      const response = await api.get("get-commision-products", {
        params: {
          shop_id: formData.id,
          commission_data: JSON.stringify(commissionData),
          page: page,
        },
      });
      setProductsData(response.data.products);
      setPaginationInfo(response.data.pagination);
      setCurrentPage(response.data.pagination.current_page);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setProductsFetchError("No products found for this commission");
      } else {
        setProductsFetchError(
          "Failed to fetch commission products: " + error.message
        );
      }
    } finally {
      setIsLoadingProducts(false);
    }
  };

  const handlePageChange = (page) => {
    fetchCommissionProducts(currentCommissionData, page);
  };

  useEffect(() => {
    const fetchPartnerShopData = async () => {
      if (data.shop_user) {
        try {
          const response = await api.get(
            `get-partner-shop-admin-data/${data.id}`
          );
          setPartnerShopData(response.data);
        } catch (error) {
          toast.error("Failed to fetch partner shop data: " + error.message);
        }
      }
    };

    if (isOpen) {
      fetchPartnerShopData();
    }
  }, [isOpen, data.shop_user, data.id]);

  const handlePartnerShopSave = async () => {
    try {
      const response = await api.put("save-partner-shop-admin/", {
        shop_id: partnerShopData.id,
        partner_data: partnerShopData,
      });

      if (response.status === 200) {
        toast.success("Partner shop data updated successfully!");
      }
    } catch (error) {
      toast.error("Failed to update partner shop data: " + error.message);
    }
  };

  return (
    <>
      <Button className="bg-white border-1 rounded-lg" onPress={onOpen}>
        Edit
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="5xl"
        scrollBehavior={scrollBehavior}
        disableEnforceFocus
        disableAutoFocus
      >
        <ModalContent className="p-8">
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Edit Shop
              </ModalHeader>
              <ModalBody>
                <form onSubmit={handleSubmit} className="space-y-4 w-full">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Názov:
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                    </label>
                  </div>
                  {/* Add the logo_url field here, right after the name field */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Logo URL:
                      <input
                        type="text"
                        name="logo_url"
                        value={formData.logo_url || ""}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                    </label>
                  </div>
                  {/* TOGO: implementovat upravit krajinu */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Krajina:
                    </label>
                    <div>
                      <select
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      >
                        {countryOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Add the CountrySelector component right after the main country selector */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Ďalšie zobrazovacie krajiny:
                    </label>
                    <CountrySelector
                      selectedCountries={selectedCountries}
                      setSelectedCountries={setSelectedCountries}
                    />
                  </div>

                  <div>
                    Pôvod{" "}
                    <select
                      name="origin"
                      value={formData.origin}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="bilionbuy">bilionbuy</option>
                      <option value="ehub">ehub</option>
                      <option value="affial">affial</option>
                      <option value="dognet">dognet</option>
                      <option value="cj">cj</option>
                      <option value="affiliateport">affiliateport</option>
                      <option value="mylead">mylead</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Typ obchodu:
                      <select
                        name="shop_type"
                        value={formData.shop_type || ""}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">-</option>
                        <option value="Shopping">Shopping</option>
                        <option value="Travel">Travel</option>
                        <option value="Food">Food</option>
                        <option value="Second Hand">Second Hand</option>
                        <option value="Services">Services</option>
                      </select>
                    </label>
                  </div>
                  <div>
                    Status (agent)
                    <select
                      name="agent_status"
                      value={formData.agent_status ? formData.agent_status : 6}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="1">Chceme osloviť</option>
                      <option value="2">Oslovený</option>
                      <option value="3">Požiadané - affiliate</option>
                      <option value="4">Dohodnuté - zaregistrované</option>
                      <option value="5">Dohodnuté - affiliate</option>
                      <option value="6"></option>
                    </select>
                  </div>
                  <div>
                    Kampaňový link
                    <input
                      type="text"
                      name="campaing_link"
                      value={formData.campaing_link}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    Encodovaný link
                    <input
                      type="text"
                      name="encoded_link"
                      value={formData.encoded_link}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span>Redirectový link</span>
                    <div className="flex flex-row justify-between items-center gap-2">
                      <div className="flex-grow">
                        <a
                          className="block w-full px-4 py-2 border rounded-lg text-sm placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          href={redirectUrl.url ? redirectUrl.url : ""}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {redirectUrl.url}
                        </a>
                      </div>
                      <button
                        className="border-1 rounded-lg px-4 py-2"
                        type="button"
                        onClick={fetchRedirectURL}
                      >
                        Vygenerovať
                      </button>
                    </div>
                  </div>
                  <div>
                    <label className=" text-sm font-medium text-gray-700 flex flex-row gap-2 items-center">
                      Má feed?:
                      <select
                        className="mt-1 block w-16 px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        name="has_feed"
                        value={
                          formData.has_feed === true
                            ? "true"
                            : formData.has_feed === false
                              ? "false"
                              : ""
                        }
                        onChange={(e) =>
                          handleInputChange({
                            target: {
                              name: "has_feed",
                              value:
                                e.target.value === "true"
                                  ? true
                                  : e.target.value === "false"
                                    ? false
                                    : null,
                            },
                          })
                        }
                      >
                        <option value=""></option> {/* Blank option for null */}
                        <option value="true">Ano</option>{" "}
                        {/* Option for true */}
                        <option value="false">Nie</option>{" "}
                        {/* Option for false */}
                      </select>
                    </label>
                  </div>
                  {/* Conditionally render the XML FEED URL field */}
                  {formData.has_feed && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        XML FEED URL:
                        <input
                          type="text"
                          name="feed_url"
                          value={formData.feed_url || ""}
                          onChange={handleInputChange}
                          className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                      </label>
                    </div>
                  )}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Web URL:
                      <input
                        type="text"
                        name="web_url"
                        value={formData.web_url}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                    </label>
                  </div>
                  {/* Add the CategorySelector component here, just before the TagsSelector */}
                  <div>
                    {/* <label className="block text-sm font-medium text-gray-700">
                      Categories:
                    </label> */}
                    <CategorySelector
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                    />
                  </div>

                  <div>
                    <div className="text-xs text-lightgray">
                      {/* *jednotlivé tagy oddeľujte čiarkou */}
                    </div>
                    <TagsSelector
                      selectedTags={selectedTags}
                      setSelectedTags={handleTagsChange}
                    />
                  </div>
                  {/* Additional Commission Groups Field */}
                  <div className="my-4 flex flex-col gap-3">
                    <div className="font-semibold mb-4">Provízne skupiny:</div>

                    {/* Default Cashback (Ostatné) */}
                    <div className="flex flex-col gap-2 mb-2">
                      <div className="flex items-center gap-4">
                        <TextField
                          label="Skupina"
                          value="Ostatné"
                          disabled
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                        <TextField
                          label="Hodnota"
                          value={formData.default_cashback || ""}
                          onChange={(e) =>
                            handleInputChange({
                              target: {
                                name: "default_cashback",
                                value: e.target.value,
                              },
                            })
                          }
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                        <button
                          type="button"
                          onClick={() => toggleExpand(-1)}
                          className="bg-primary text-white px-2 py-1 rounded-md"
                        >
                          {isExpanded[-1] ? "Skryť" : "Viac"}
                        </button>
                      </div>

                      {isExpanded[-1] && (
                        <div className="flex items-center gap-4 mt-2">
                          <TextField
                            label="Priamy koeficient"
                            value={formData.shop_direct_coeff || ""}
                            onChange={(e) =>
                              handleInputChange({
                                target: {
                                  name: "shop_direct_coeff",
                                  value: e.target.value,
                                },
                              })
                            }
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                          <TextField
                            label="Odporúčateľský koeficient"
                            value={formData.shop_refferal_coeff || ""}
                            onChange={(e) =>
                              handleInputChange({
                                target: {
                                  name: "shop_refferal_coeff",
                                  value: e.target.value,
                                },
                              })
                            }
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                          <TextField
                            label="Štruktúrny koeficient"
                            value={formData.shop_ml_coeff || ""}
                            onChange={(e) =>
                              handleInputChange({
                                target: {
                                  name: "shop_ml_coeff",
                                  value: e.target.value,
                                },
                              })
                            }
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        </div>
                      )}
                    </div>

                    {/* Existing Commission Groups */}
                    {Array.isArray(additionalCommissionGroups) &&
                    additionalCommissionGroups.length > 0 ? (
                      additionalCommissionGroups.map((group, index) => (
                        <div key={index} className="flex flex-col gap-2 mb-2">
                          <div className="flex items-center gap-4">
                            <select
                              value={group.type || ""}
                              onChange={(e) =>
                                handleCommissionGroupChange(
                                  index,
                                  "type",
                                  e.target.value
                                )
                              }
                              className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            >
                              <option value="Kategória">Kategória</option>
                              <option value="Značka">Značka</option>
                            </select>
                            <TextField
                              label="Názov"
                              value={group.name}
                              onChange={(e) =>
                                handleCommissionGroupChange(
                                  index,
                                  "name",
                                  e.target.value
                                )
                              }
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                            <TextField
                              label="Hodnota"
                              value={group.value}
                              onChange={(e) =>
                                handleCommissionGroupChange(
                                  index,
                                  "value",
                                  e.target.value
                                )
                              }
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                            <button
                              type="button"
                              onClick={() => fetchCommissionProducts(group)}
                              className="bg-primary text-white p-2 rounded-md"
                              disabled={isLoadingProducts}
                            >
                              <MagnifyingGlassIcon className="h-5 w-5" />
                            </button>
                            <button
                              type="button"
                              onClick={() => toggleExpand(index)}
                              className="bg-primary text-white px-2 py-1 rounded-md"
                            >
                              {isExpanded[index] ? "Skryť" : "Viac"}
                            </button>
                            <button
                              type="button"
                              onClick={() => handleRemoveCommissionGroup(index)}
                              className="bg-red-500 text-white px-2 py-1 rounded-md"
                            >
                              -
                            </button>
                          </div>

                          {(isExpanded[index] ||
                            group.pk ||
                            group.ok ||
                            group.sk) &&
                          isExpanded[index] ? (
                            <div className="flex items-center gap-4 mt-2">
                              <TextField
                                label="Priamy koeficient"
                                value={group.pk}
                                onChange={(e) =>
                                  handleCommissionGroupChange(
                                    index,
                                    "pk",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                              <TextField
                                label="Odporúčateľský koeficient"
                                value={group.ok}
                                onChange={(e) =>
                                  handleCommissionGroupChange(
                                    index,
                                    "ok",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                              <TextField
                                label="Štruktúrny koeficient"
                                value={group.sk}
                                onChange={(e) =>
                                  handleCommissionGroupChange(
                                    index,
                                    "sk",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                          ) : null}
                        </div>
                      ))
                    ) : (
                      <p className="text-sm text-gray-500">
                        Zatiaľ neboli pridané žiadne ďalšie provízne skupiny
                      </p>
                    )}

                    {/* Add Group Button */}
                    <Button
                      type="button"
                      onClick={handleAddCommissionGroup}
                      className="bg-primary text-white px-4 py-2 rounded-md mt-2 w-[200px]"
                    >
                      Pridať skupinu
                    </Button>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Popis:
                      <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        rows="3"
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                    </label>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Ďalšie informácie:
                      <textarea
                        name="additional_admin_info"
                        value={formData.additional_admin_info}
                        onChange={handleInputChange}
                        rows="3"
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                    </label>
                  </div>
                  {data.shop_user && partnerShopData && (
                    <div className="mt-8 border-t pt-4">
                      <div className="font-semibold mb-4">
                        Údaje o partnerskom obchode
                      </div>
                      <div className="bg-gray-50 p-4 rounded-lg space-y-4">
                        {/* Status Dropdown */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Status:
                          </label>
                          <select
                            value={partnerShopData.status}
                            onChange={(e) =>
                              setPartnerShopData({
                                ...partnerShopData,
                                status: e.target.value,
                              })
                            }
                            className="mt-1 block w-full px-4 py-2 text-sm border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          >
                            <option value="pending">Čaká na schválenie</option>
                            <option value="approved">Schválené</option>
                            <option value="rejected">Odmietnuté</option>
                            <option value="incomplete">Neúplné údaje</option>
                          </select>
                        </div>
                        {/* Contact Name */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Kontaktné meno:
                          </label>
                          <input
                            type="text"
                            value={partnerShopData.contact_name}
                            onChange={(e) =>
                              setPartnerShopData({
                                ...partnerShopData,
                                contact_name: e.target.value,
                              })
                            }
                            className="mt-1 block w-full px-4 py-2 text-sm border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                        {/* Contact Information */}
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Kontaktný e-mail:
                            </label>
                            <input
                              type="email"
                              value={partnerShopData.contact_email}
                              onChange={(e) =>
                                setPartnerShopData({
                                  ...partnerShopData,
                                  contact_email: e.target.value,
                                })
                              }
                              className="mt-1 block w-full px-4 py-2 text-sm border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Kontaktný telefón:
                            </label>
                            <input
                              type="tel"
                              value={partnerShopData.contact_phone}
                              onChange={(e) =>
                                setPartnerShopData({
                                  ...partnerShopData,
                                  contact_phone: e.target.value,
                                })
                              }
                              className="mt-1 block w-full px-4 py-2 text-sm border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                          </div>
                        </div>

                        {/* Company Information */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Názov spoločnosti:
                          </label>
                          <input
                            type="text"
                            value={partnerShopData.company_name}
                            onChange={(e) =>
                              setPartnerShopData({
                                ...partnerShopData,
                                company_name: e.target.value,
                              })
                            }
                            className="mt-1 block w-full px-4 py-2 text-sm border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>

                        {/* Tax Information */}
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              DIČ:
                            </label>
                            <input
                              type="text"
                              value={partnerShopData.tax_id}
                              onChange={(e) =>
                                setPartnerShopData({
                                  ...partnerShopData,
                                  tax_id: e.target.value,
                                })
                              }
                              className="mt-1 block w-full px-4 py-2 text-sm border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              VAT:
                            </label>
                            <input
                              type="text"
                              value={partnerShopData.vat_id}
                              onChange={(e) =>
                                setPartnerShopData({
                                  ...partnerShopData,
                                  vat_id: e.target.value,
                                })
                              }
                              className="mt-1 block w-full px-4 py-2 text-sm border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                          </div>
                        </div>

                        {/* Currency Selection */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Preferovaná mena:
                          </label>
                          <select
                            value={partnerShopData.preferred_currency}
                            onChange={(e) =>
                              setPartnerShopData({
                                ...partnerShopData,
                                preferred_currency: e.target.value,
                              })
                            }
                            className="mt-1 block w-full px-4 py-2 text-sm border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          >
                            <option value="EUR">EUR</option>
                            <option value="USD">USD</option>
                            <option value="CZK">CZK</option>
                            <option value="PLN">PLN</option>
                          </select>
                        </div>

                        {/* Save Button */}
                        <div className="mt-4 flex justify-end">
                          <Button
                            onClick={handlePartnerShopSave}
                            className="px-4 py-2 bg-black text-white text-base rounded-md shadow-sm focus:outline-none"
                          >
                            Uložiť zmeny v partnerskom obchode
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex gap-2 font-bold">
                    <Button
                      type="submit"
                      className="px-4 py-2 bg-white text-success text-base rounded-md w-full shadow-sm focus:outline-none ring-2 ring-success"
                    >
                      {isLoading ? (
                        <Spinner color="success" size="sm" />
                      ) : (
                        "Uložiť"
                      )}
                    </Button>
                    <Button
                      onPress={onClose}
                      className="px-4 py-2 bg-white text-danger text-base rounded-md w-full shadow-sm focus:outline-none ring-2 ring-danger"
                    >
                      Zrušiť zmeny
                    </Button>
                  </div>
                  {!isLoading && message && (
                    <div
                      className={`text-center py-4 line-clamp-2 ${
                        success ? "bg-success" : "bg-danger"
                      } text-white`}
                    >
                      {message}
                    </div>
                  )}
                </form>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* Products modal */}
      <Modal
        isOpen={isProductModalOpen}
        onOpenChange={() => setIsProductModalOpen(false)}
        size="3xl"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Produkty pre túto províznu skupinu</ModalHeader>
              <ModalBody>
                {isLoadingProducts ? (
                  <div className="flex justify-center items-center h-64">
                    <Spinner size="lg" />
                  </div>
                ) : productsFetchError ? (
                  <p className="text-danger text-center">
                    {productsFetchError}
                  </p>
                ) : productsData && productsData.length > 0 ? (
                  <table className="min-w-full table-fixed divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th className="text-left">Produkt</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {productsData.map((product) => (
                        <tr key={product.id}>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 hover:bg-slate-50">
                            <a
                              href={`/view-product/${product.slug}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex flex-row gap-4 text-lg items-center"
                            >
                              <div>
                                <img
                                  src={product.img}
                                  className="max-w-16 max-h-16"
                                  alt={product.name || "Product image"}
                                />
                              </div>
                              <div>{product.name}</div>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-center">No products data available.</p>
                )}
              </ModalBody>
              <ModalFooter>
                {paginationInfo && (
                  <div className="flex flex-row gap-2 items-center">
                    <div className="text-sm text-gray-700">
                      Strana {paginationInfo.current_page} z{" "}
                      {paginationInfo.total_pages} | Celkový počet položiek:{" "}
                      {paginationInfo.total_items}
                    </div>
                    <MUIPagination
                      count={paginationInfo.total_pages}
                      page={paginationInfo.current_page}
                      onChange={(event, page) => handlePageChange(page)}
                      color="primary"
                    />
                  </div>
                )}
                <Button onPress={onClose}>Zavrieť</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditButton;
